import { StaffPlanning } from "./actions";
import * as actionTypes from "./actionTypes";

export interface IStaffPlanningState {
  countriesWhitelist: string[];
}

export const INITIAL_STATE: IStaffPlanningState = {
  countriesWhitelist: []
};

const reducers = (state: IStaffPlanningState = INITIAL_STATE, action: StaffPlanning) => {
  if (action.type === actionTypes.GET_STAFF_PLANNING_COUNTRY_WHITELIST_SUCCESS) {
    return { ...state, countriesWhitelist: action.countries };
  } else {
    return state;
  }
};

export default reducers;
