import { memo } from "react";
import { I18n } from "react-redux-i18n";
import { useFilters } from "../../filters3/filters/hooks/useFilters";
import { trackAnalyticsEvent } from "../../../../core/hooks/useAnalytics";

const ApplyButton = () => {
  const { apply, changed, isValid } = useFilters();

  const handleClickOnApplyButton = () => {
    trackAnalyticsEvent("click_apply_filter");
    apply();
  };

  const disabled = !changed || !isValid;

  return (
    <button
      data-testid={`filters-apply-btn${disabled ? "-disabled" : ""}`}
      className="vtmn-btn vtmn-btn_variant--primary vtmn-btn_size--small"
      disabled={disabled}
      onClick={handleClickOnApplyButton}
    >
      {I18n.t("filters.apply")}
    </button>
  );
};

export default memo(ApplyButton);
