import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Store } from "../../models/store";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

interface StoreDto {
  country_id: string;
  id: number;
  label: string;
  store_forecast: string;
}

interface IStoresResponse {
  content: StoreDto[];
}

export const QUERY_KEY_STORES = "get-stores";

const getStoresFn = async (config: AxiosRequestConfig): Promise<Store[]> => {
  const response: AxiosResponse<IStoresResponse> = await axios(config);
  return response.data.content.map(item => {
    return {
      id: item.id,
      country_id: item.country_id,
      label: item.label,
      store_forecast: item.store_forecast
    };
  });
};

export const useApiGetStores = (options?: UseQueryOptionsWithoutQueryKey<Store[]>) => {
  const url = "/stores";
  const config: AxiosRequestConfig = { method: "get", url };
  return useQuery<Store[], Error>({
    queryKey: [QUERY_KEY_STORES, url],
    queryFn: () => getStoresFn(config),
    staleTime: Infinity,
    ...options
  });
};
