import * as React from "react";
import { IconButton, Icon } from "@mui/material";

import { DataManagerContext, ICellValue } from "components/common/data-manager/data-context";
import { TypedValue } from "core/utils/computation-engine";
import { joinLabels } from "core/utils/accounts";
import { ROWS } from "core/utils/rows";

export interface IDumbProps {
  isLocked: boolean;
  unlockWeek: () => void;
}

export interface IProps {
  weekLabel: string;
}

export interface IIconLockOpenProps {
  isLocked: boolean;
  unlockWeek: () => void;
}

export const HoursLockedIconOpen: React.FunctionComponent<IIconLockOpenProps> = ({ unlockWeek, isLocked }) => {
  return !isLocked ? (
    <IconButton className="hours-locked-icon fixed" onClick={unlockWeek} data-testid="hours-locked-icon" size="large">
      <Icon className="cell-with-icon__icon">lock_open</Icon>
    </IconButton>
  ) : null;
};

export const DumbHoursLockedIcon: React.FunctionComponent<IDumbProps> = ({ unlockWeek, isLocked }) => {
  return isLocked ? (
    <IconButton className="hours-locked-icon fixed" onClick={unlockWeek} data-testid="hours-locked-icon" size="large">
      <Icon className="cell-with-icon__icon">lock</Icon>
    </IconButton>
  ) : null;
};

const HoursLockedIcon: React.FunctionComponent<IProps> = React.memo(({ weekLabel }) => {
  const {
    data: { accounts },
    setCellsValues,
    overriddenContext: { lockedWeeks, setLockedWeeks }
  } = React.useContext(DataManagerContext);
  const isLocked = weekLabel !== ROWS.TOTAL && lockedWeeks[weekLabel];
  const unlockWeek = () => {
    const { [weekLabel]: accountsToUnlock, ...updatedLockedWeeks } = lockedWeeks;
    const cellsValues = Object.keys(accountsToUnlock).reduce<ICellValue[]>((cellsValues, accountId) => {
      accountsToUnlock[accountId].forEach(sportId => {
        const rowId = joinLabels([weekLabel, accountId]);
        const cellToAdd: ICellValue = {
          cellCoordinates: {
            columnId: sportId,
            rowId
          },
          type: TypedValue.Pilotable,
          value: accounts[rowId].cells[sportId].value as number
        };
        cellsValues.push(cellToAdd);
      });
      return cellsValues;
    }, []);
    setCellsValues(cellsValues);
    setLockedWeeks(updatedLockedWeeks);
  };

  return <DumbHoursLockedIcon isLocked={isLocked} unlockWeek={unlockWeek} />;
});

export default HoursLockedIcon;
