import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { WebInterface } from "../../constants/web-interfaces";
import { initialTemplatesSorted, TemplatesSorted, Visibility } from "../../models/template";
import { cloneDeep } from "lodash";
import { ITemplate } from "../../utils/templates";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

export interface IResponseTemplateContent {
  id: number;
  label: string;
  is_super_default?: boolean;
  is_default?: boolean;
  is_copy?: boolean;
  is_visible?: boolean;
  owner?: string;
  account_hierarchy: IAccountHierarchy[];
  group_hierarchy?: [];
  web_interface_list: WebInterface[];
  is_owner?: boolean;
  is_editable?: boolean;
}

export interface IAccountHierarchy {
  account: string;
  index: number;
  children?: IAccountChild[];
  is_hidden?: boolean;
  isHiddenForComputation?: boolean;
}

export interface IAccountChild {
  account: string;
  index: number;
}

export interface IAggregateHierarchy {
  id: number;
  aggregate: string;
  index: number;
  is_spatial_computation: boolean;
  is_hidden?: boolean;
  is_toggleable?: boolean;
  label_code: string;
  can_select_year?: boolean;
  from: string;
  to: string;
  comparison_allowed?: boolean;
}

export interface ITemplateResponse {
  content: IResponseTemplateContent[];
}

export const QUERY_KEY_TEMPLATES = "get-templates";

const getTemplatesFn = async (config: AxiosRequestConfig): Promise<TemplatesSorted> => {
  const axiosPromise: AxiosResponse<ITemplateResponse> = await axios(config);
  const response: IResponseTemplateContent[] = axiosPromise.data.content.map((template: IResponseTemplateContent) => {
    return {
      id: template.id,
      label: template.label,
      is_super_default: template.is_super_default,
      is_default: template.is_default,
      is_copy: template.is_copy,
      is_visible: template.is_visible,
      owner: template.owner,
      account_hierarchy: template.account_hierarchy,
      web_interface_list: template.web_interface_list,
      is_owner: template.is_owner,
      is_editable: template.is_editable
    };
  });
  return deserializeTemplate(response);
};

export const useApiGetTemplates = (options?: UseQueryOptionsWithoutQueryKey<TemplatesSorted>) => {
  const url = `/templates`;
  const config: AxiosRequestConfig = { method: "get", url };
  return useQuery<TemplatesSorted, Error>({
    queryKey: [QUERY_KEY_TEMPLATES, url],
    queryFn: () => getTemplatesFn(config),
    staleTime: Infinity,
    ...options
  });
};

const deserializeTemplate = (templates: IResponseTemplateContent[]): TemplatesSorted => {
  const accumulator: TemplatesSorted = cloneDeep(initialTemplatesSorted);
  return templates.reduce((acc, template: ITemplate) => {
    const key: Visibility = template.is_visible ? Visibility.IsVisible : Visibility.NotVisible;
    return { ...acc, [key]: [...acc[key], template] };
  }, accumulator);
};
