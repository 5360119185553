import { ReactElement } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

interface ICardItemProps {
  title: string;
  id?: string;
  suptitle?: string;
  leftIcon?: ReactElement;
  right?: ReactElement;
  rightWithClassName?: ReactElement;
  children?: ReactElement | ReactElement[];
}

const CardItem = ({ title, suptitle, right, leftIcon, rightWithClassName, id, children, ...props }: ICardItemProps) => {
  const dataTestId = "card-item" + (id ? "-" + id : "");
  return (
    <Card id={id} className="card-item" data-testid={dataTestId}>
      <div>
        <CardContent className="card-item__content" {...props}>
          {leftIcon ? <div className="card-item__icon">{leftIcon}</div> : null}

          <div className="card-item__left">
            {suptitle ? <div className="card-item__left__suptitle">{suptitle}</div> : null}
            <div className="card-item__left__title">{title}</div>
          </div>

          {right ? <div className="card-item__right">{right}</div> : null}

          {rightWithClassName}
        </CardContent>
      </div>
      {children}
    </Card>
  );
};

export default CardItem;
