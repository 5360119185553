import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IUpwardForecastHistoryItem } from "../rest/queries/upward-forecast-history";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

export interface IUpwardForecastHistoryItemDto {
  activated: boolean;
  date: string;
}

export const QUERY_KEY_UPWARD_FORECAST_HISTORY = "get-upward-forecast-history";

const getQueryFn = async (config: AxiosRequestConfig): Promise<IUpwardForecastHistoryItem[]> => {
  const axiosPromise: AxiosResponse<IUpwardForecastHistoryItemDto[]> = await axios(config);
  const groups = axiosPromise.data.map(group => {
    return {
      activated: group.activated,
      date: group.date
    };
  });
  return groups.reverse();
};

export const useApiGetForecastHistory = (
  countryId: string,
  storeId?: number,
  options?: UseQueryOptionsWithoutQueryKey<IUpwardForecastHistoryItem[]>
) => {
  const url = `/forecast/history/${countryId}${storeId ? `/${storeId}` : ""}`;
  const config: AxiosRequestConfig = { method: "get", url };
  return useQuery<IUpwardForecastHistoryItem[], Error>({
    queryKey: [QUERY_KEY_UPWARD_FORECAST_HISTORY, url],
    queryFn: () => getQueryFn(config),
    ...options
  });
};
