import { ArrowContainer, Popover } from "react-tiny-popover";
import { I18n } from "react-redux-i18n";
import { HEADER_PREFERENCES } from "../../../../core/constants/translations";
import { links } from "core/constants/translations/popover-header";
import { NavLink } from "react-router-dom";
import { getPathFromRouteId } from "../../../../core/utils/route-utils";
import { trackAnalyticsEvent } from "../../../../core/hooks/useAnalytics";

export const PopoverHeaderPreferences = ({ isOpenPopover, setIsOpenPopover, buttonRef }) => {
  const activeStyle = { backgroundColor: "none" };

  return (
    <Popover
      containerStyle={{ zIndex: "10000", overflow: "visible" }}
      isOpen={isOpenPopover}
      padding={10}
      onClickOutside={() => setIsOpenPopover(false)}
      positions={["bottom", "left"]}
      ref={buttonRef}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={"white"}
          arrowSize={10}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div className="mg-popover-header-preferences" onClick={() => setIsOpenPopover(!isOpenPopover)}>
            <div className="mg-popover-header-preferences-title">
              <span>{I18n.t(HEADER_PREFERENCES)}</span>
            </div>
            <div className="mg-popover-header-preferences-links">
              {links.map(item => {
                return (
                  <NavLink
                    className="mg-popover-header-preferences-link"
                    to={getPathFromRouteId(item.link)}
                    style={({ isActive }) => (isActive ? activeStyle : undefined)}
                    end
                    key={item?.key}
                    onClick={() => trackAnalyticsEvent(item.googleAnalyticsAction)}
                  >
                    <div className="mg-popover-header-preferences-link-icon">
                      <span className="material-icons-outlined">{item.icon}</span>
                    </div>
                    <div className="mg-popover-header-preferences-link-text">{I18n.t(item.title)}</div>
                  </NavLink>
                );
              })}
            </div>
          </div>
        </ArrowContainer>
      )}
    >
      <div onClick={() => setIsOpenPopover(!isOpenPopover)} className="mg-button-header">
        <div className="mg-button-header__icon mg-button-header__icon__country">
          <span className="material-icons-outlined notranslate" id="trigger-popover-preferences">
            settings
          </span>{" "}
        </div>
        <span className="mg-button-header__text">{I18n.t(HEADER_PREFERENCES)}</span>
      </div>
    </Popover>
  );
};
