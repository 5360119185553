import SportsGroupList from "components/specific/favorite-sports/sport-group-list";
import StoresGroupList from "components/specific/favorite-stores/store-group-list";
import { I18n } from "react-redux-i18n";
import { GROUPS_MANAGEMENT_SPORTS, GROUPS_MANAGEMENT_STORES } from "core/constants/translations/groups";
import OutsideClickHandler from "react-outside-click-handler";

export interface SubheaderPopover {
  groupClicked: string;
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}
const SubheaderPopover = ({ groupClicked, setIsOpen, isOpen }: SubheaderPopover) => {
  const handleOutsideClick = event => {
    if (event.target.id == "shareButton" || event.target.id == "shareButtonIcon") {
      return;
    }

    if (
      event.target != document.getElementById("trigger-popover-sports") &&
      event.target != document.getElementById("trigger-popover-stores")
    ) {
      setIsOpen(false);
    }

    setIsOpen(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div className="mg-popover-groups">
        <div className="mg-popover-groups-title">
          {groupClicked === "sport" ? (
            <span>{I18n.t(GROUPS_MANAGEMENT_SPORTS)}</span>
          ) : (
            <span>{I18n.t(GROUPS_MANAGEMENT_STORES)}</span>
          )}

          <div className="mg-popover-groups-title__close" onClick={() => setIsOpen(!isOpen)}>
            <span className="material-icons-outlined notranslate">close</span>
          </div>
        </div>
        <div className="mg-popover-groups-body">
          {groupClicked === "sport" ? <SportsGroupList /> : <StoresGroupList />}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default SubheaderPopover;
