import * as React from "react";
import { ROBOT_ERROR_IMG } from "core/constants/images";

interface IErrorPageProps {
  title: string;
  children: React.ReactNode;
}

const ErrorPage = ({ title, children }: IErrorPageProps) => (
  <div className="information-container error-container">
    <img src={ROBOT_ERROR_IMG} className="robot-img" alt="error" />
    <div>
      <h2>{title}</h2>
    </div>
    <div>{children}</div>
  </div>
);

export default ErrorPage;
