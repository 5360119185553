import { useCallback, useMemo, useState } from "react";
import { DragDropContext, Droppable, DropResult, DroppableProvided } from "react-beautiful-dnd";
import { I18n } from "react-redux-i18n";
import Icon from "components/common/icons/customIcon";
import { SPORT_GROUP_LIST_TYPE } from "core/constants/list-types";
import SportFields from "components/specific/favorite-sports/sport-fields";
import SportsGroupFavoriteList from "components/specific/favorite-sports/sport-group-favorite-list";
import { ISport } from "core/api/rest/queries/select-sport";

import useObjFromList from "core/hooks/useObjFromList";
import { Sport } from "core/models/api/sports-piloting/sports-piloting";
import { ISportsGroup, ISportsGroupComposition } from "core/models/api/sports-group/sports-group-piloting";
import { useApiGetSport } from "../../../core/api/sport/useApiGetSport";

interface ISportsGroupCompositionProps {
  sportsGroup: ISportsGroup;
  isEditable: boolean;
  onSportsGroupChange: (sportsGroup: ISportsGroup | Partial<ISportsGroup>) => void;
  currentStoreId?: number;
  isFetchingSports: boolean;
}

interface IDumbSportsGroupCompositionProps extends ISportsGroupCompositionProps {
  sports: ISport[];
}

const EMPTY_ARRAY = [];

/**
 * Check if all the sports belong to the same store
 */
const checkMonoStore = (sports: ISport[]) => {
  if (sports.length <= 1) return "NON_RELEVANT";
  const { store_id } = sports[0];
  for (const sport of sports) {
    if (sport.store_id !== store_id) {
      return "MULTI";
    }
  }
  return "MONO";
};
const flatSportsList = (sportList: Sport[]): ISportsGroupComposition[] =>
  sportList.map((s: Sport, index: number) => ({ sport_id: s.id, index }));

const DumbSportsGroupComposition = ({
  isEditable,
  sportsGroup,
  onSportsGroupChange,
  sports
}: IDumbSportsGroupCompositionProps) => {
  const [sportsFetchedByIds, setSportsFetchedByIds] = useState(EMPTY_ARRAY);
  const allSports = useObjFromList([...sports, ...sportsFetchedByIds], "id");

  const sportList = useMemo(
    () =>
      sportsGroup
        ? sportsGroup.composition
            .sort((a, b) => a.index - b.index)
            .reduce(
              (acc: ISport[], sport: ISportsGroupComposition) =>
                allSports[sport.sport_id] ? [...acc, allSports[sport.sport_id]] : acc,
              []
            )
        : [],
    [sportsGroup, allSports]
  );
  const disabledItemsIds = useMemo(() => sportList.map((sport: ISport) => sport.id), [sportList]);

  const onDragEndSport = useCallback(
    (event: DropResult) => {
      if (!event || !event.destination) return;
      const {
        draggableId: sportId,
        destination: { index }
      } = event;
      const sport = allSports[sportId];
      const formattedSportId = Number(sportId);
      const newSportList = sportList.filter((sport: ISport) => sport.id !== formattedSportId);
      newSportList.splice(index, 0, sport);
      onSportsGroupChange({ ...sportsGroup, composition: flatSportsList(newSportList) });
    },
    [allSports, sportList, onSportsGroupChange, sportsGroup]
  );

  const onAddSportsToGroup = useCallback(
    (ids: number[], currentStoreId: number, sports: Sport[]) => {
      if (ids && ids.length > 0 && currentStoreId) {
        const newSports = sports?.filter(sport => sport && ids.includes(sport.id));
        const newSportList = [...sportList, ...newSports];
        if (newSportList) {
          onSportsGroupChange({ ...sportsGroup, composition: flatSportsList(newSportList) });
          setSportsFetchedByIds([...sportsFetchedByIds, ...newSports]);
        }
        return null;
      }
    },
    [sportList, onSportsGroupChange, sportsGroup, sportsFetchedByIds]
  );

  const onRemoveSport = useCallback(
    (index: number) => {
      const newSportList = sportList.filter((_, i: number) => index !== i);
      onSportsGroupChange({ ...sportsGroup, composition: flatSportsList(newSportList) });
    },
    [onSportsGroupChange, sportsGroup, sportList]
  );

  const monoStore = checkMonoStore(sportList);
  let monoStoreAlert = null;

  if (monoStore === "MONO") {
    monoStoreAlert = <div>{I18n.t("settings.sport_groups.mono_store")}</div>;
  } else if (monoStore === "MULTI") {
    monoStoreAlert = <div>{I18n.t("settings.sport_groups.multi_store")}</div>;
  }

  return (
    <div className="mg-groups-composition">
      <DragDropContext onDragEnd={onDragEndSport}>
        {isEditable ? (
          <div className="mg-groups-composition__column" id="sports_group_editor_available_sports">
            <div className="mg-groups-composition__column__title">
              {I18n.t("settings.sport_groups.available_sports")}
            </div>
            <SportFields onAddSportsToGroup={onAddSportsToGroup} disabledItemsIds={disabledItemsIds} />
          </div>
        ) : (
          <div className="mg-groups-composition__column">
            <div className="">
              <Icon name="clear_all" />
            </div>
          </div>
        )}
        <div className="mg-groups-composition__column" id="sports_group_editor_new_sports">
          <div className="mg-groups-composition__column__title">{I18n.t("settings.sport_groups.group_sports")}</div>
          {monoStoreAlert}
          <Droppable droppableId={`${SPORT_GROUP_LIST_TYPE}-drop`} isDropDisabled={!isEditable}>
            {(dropProvided: DroppableProvided) => (
              <SportsGroupFavoriteList
                type={SPORT_GROUP_LIST_TYPE}
                dropProvided={dropProvided}
                sportList={sportList}
                onRemoveSport={isEditable && onRemoveSport}
                isDragDisabled={!isEditable}
              />
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  );
};

const SportsGroupComposition = (props: ISportsGroupCompositionProps) => {
  const { sportsGroup } = props;
  const { data: dataSports, isLoading: isLoadingDataSports } = useApiGetSport(
    { ids: sportsGroup.composition.map(s => s.sport_id) },
    { enabled: !!sportsGroup.id }
  );

  return sportsGroup.id && !isLoadingDataSports ? (
    <DumbSportsGroupComposition {...props} sports={dataSports} />
  ) : (
    <DumbSportsGroupComposition {...props} sports={[]} />
  );
};

export default SportsGroupComposition;
