import "./init";
import "./sass/index.scss";
import "./core";

if (import.meta.env.NODE_ENV === "development") {
  // In development, to provide custom data for the developer experience
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require("./core/mocking/browser");
  worker.start();
}
