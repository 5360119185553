import * as React from "react";
import { I18n } from "react-redux-i18n";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import { SelectContext } from "../select";
import SelectAction from "./select-action";

const SelectAll: React.FunctionComponent = () => {
  const { isMultiple, selectedValue, handleChange, data, disabledItemsIds } = React.useContext(SelectContext);

  const flattenDataIds = React.useMemo(() => {
    return Object.keys(data).reduce((acc, groupId) => {
      const groupDataIds = data[groupId].data.map(item => item.id);
      return [...acc, ...groupDataIds];
    }, []);
  }, [data]);

  const selectedValueNotInData = React.useMemo(() => {
    if (isMultiple) {
      return (selectedValue as number[]).filter(selectedId => !flattenDataIds.includes(selectedId));
    }
    return [];
  }, [isMultiple, flattenDataIds, selectedValue]);

  if (!isMultiple) {
    throw new Error("The `value` prop must be an array when using the `Select` component with `multiple`");
  }

  const enabledFlattenDataIds = React.useMemo(() => {
    return flattenDataIds.filter(dataId => !disabledItemsIds.includes(dataId));
  }, [flattenDataIds, disabledItemsIds]);

  const flattenDataLength = flattenDataIds.length;
  const areAllItemsSelected = flattenDataLength === (selectedValue as number[]).length - selectedValueNotInData.length;

  const handleActionClick = React.useCallback(() => {
    handleChange(areAllItemsSelected ? selectedValueNotInData : [...flattenDataIds, ...selectedValueNotInData]);
  }, [handleChange, areAllItemsSelected, selectedValueNotInData, flattenDataIds]);

  return (
    <SelectAction
      id="select-all"
      disabled={!enabledFlattenDataIds.length}
      onClick={handleActionClick}
      title={areAllItemsSelected ? I18n.t("select.deselect_all") : I18n.t("select.select_all")}
      icon={areAllItemsSelected ? <DoneAllIcon /> : null}
    />
  );
};

export default SelectAll;
