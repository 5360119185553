import { I18n } from "react-redux-i18n";
import { Divider, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

import { useStoresObj } from "core/hooks/useFiltersLabels";
import DateUtils from "core/utils/date";
import { StoreEventById } from "core/models/store-events";

interface IEventListProps {
  events: StoreEventById[];
}

interface IEventListItemProps extends Omit<StoreEventById, "store_id" | "id"> {
  storeLabel: string;
}

const EventListItem = ({ storeLabel, start_date, end_date, description }: IEventListItemProps) => {
  const eventHeader = `${storeLabel} : ${I18n.t("event.daterange", {
    start_date: DateUtils.dayToYearMonthLabel(start_date),
    end_date: DateUtils.dayToYearMonthLabel(end_date)
  })}`;

  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <span className="material-icons-outlined notranslate" style={{ color: "#3D9ACC" }}>
          event_note
        </span>
      </ListItemIcon>
      <ListItemText
        primary={eventHeader}
        secondary={description}
        secondaryTypographyProps={{ style: { overflowWrap: "break-word" } }}
      />
    </ListItem>
  );
};

const EventList: React.FunctionComponent<IEventListProps> = ({ events }) => {
  const eventsLastIndex = events.length - 1;
  const stores = useStoresObj();
  return (
    <List>
      {events.map(({ id, store_id, ...others }, index) => {
        const showDivider = index !== eventsLastIndex;
        return (
          <>
            <EventListItem storeLabel={stores[store_id]?.label} {...others} />
            {showDivider ? <Divider variant="middle" component="li" /> : null}
          </>
        );
      })}
    </List>
  );
};

export default EventList;
