import * as React from "react";
import classnames from "classnames";
import { MenuItem as MuiMenuItem, ListItemText, ListItemIcon } from "@mui/material";
import Icon from "components/common/icons/icon";
import CheckIcon from "@mui/icons-material/Check";

import { SelectContext, Value } from "./select";
import { IDataSource } from "./index";

export interface IMenuItemProps {
  dataSource: IDataSource;
  className: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const SelectMenuItem: React.FunctionComponent<IMenuItemProps> = ({ dataSource, className, disabled, style }) => {
  const { selectedValue, selectItemLabelFormat, toggleItem, isMultiple } = React.useContext(SelectContext);
  const isSelected = React.useMemo(() => {
    if (isMultiple) {
      return selectedValue && (selectedValue as Value[]).includes(dataSource.id);
    }
    return selectedValue === dataSource.id;
  }, [isMultiple, selectedValue, dataSource.id]);

  const handleItemClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event.stopPropagation();
      if (isMultiple || !isSelected) {
        toggleItem(dataSource.id);
      }
    },
    [isSelected, isMultiple, toggleItem, dataSource.id]
  );

  return (
    <MuiMenuItem
      key={dataSource.id}
      disabled={disabled}
      value={dataSource.label}
      onClick={handleItemClick}
      className={classnames("select__menu-item", className, { selected: isSelected })}
      style={style}
    >
      {dataSource.leftIcon && (
        <ListItemIcon>
          <Icon className="menu-item-icon-left" name={dataSource.leftIcon} />
        </ListItemIcon>
      )}

      <ListItemText primary={selectItemLabelFormat ? selectItemLabelFormat(dataSource) : dataSource.label} />

      {dataSource.rightIcon && (
        <ListItemIcon classes={{ root: "menu-item-icon-right" }}>
          <Icon name={dataSource.rightIcon} />
        </ListItemIcon>
      )}

      {isMultiple && isSelected ? <CheckIcon /> : null}
    </MuiMenuItem>
  );
};

export default React.memo(SelectMenuItem);
