import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { I18n } from "react-redux-i18n";
import ReactGA4 from "react-ga4";

import { AppStore, ReduxDispatch } from "core/redux/store";
import { setToken } from "core/redux/user/actions";
import BlockedPage from "pages/errors/blocked-page";
import BlockedPageOffLine from "pages/errors/blocked-page-offline";
import ErrorBoundary from "components/common/error/error-boundary";
import RessourcesProvider from "core/providers/ressources-provider";
import TranslationsProvider from "core/providers/translations-provider";
import { validateUserAccess } from "core/utils/user";
import AppRouter from "core/routing/router";
import LoadingDialog from "../components/common/loader/loading-progress";
import { useLocation } from "react-router";
import { useDatadog } from "./hooks/useDatadog";
import { useAuth } from "react-oidc-context";
import { useAuthUserInfo } from "./hooks/useAuthUserInfo";

export const App = () => {
  const dispatch = useDispatch<ReduxDispatch>();
  const auth = useAuth();
  const userInfo = useAuthUserInfo();
  const history = useLocation();

  useEffect(() => {
    const userToken = auth.user?.access_token;
    if (validateUserAccess(userToken)) {
      dispatch(setToken(userToken));
    } else if (!auth.isLoading) {
      auth.signinRedirect();
    }
  }, [auth]);

  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent();
    });
  }, [auth.events, auth.signinSilent]);

  useEffect(() => {
    return auth.events.addAccessTokenExpired(() => {
      auth.signinRedirect();
    });
  }, [auth.events, auth.signinRedirect]);

  useEffect(() => {
    ReactGA4.send({ hitType: "pageview", page: history.pathname });
  }, [history]);

  // Datadog
  useDatadog(auth.isAuthenticated ? userInfo : null);

  // env var can only be string
  if (import.meta.env.REACT_APP_IS_APP_OFFLINE === "true") {
    return (
      <BlockedPageOffLine
        msgFr={import.meta.env.REACT_APP_OFFLINE_MSG_FR}
        msgEn={import.meta.env.REACT_APP_OFFLINE_MSG_EN}
      />
    );
  }

  // env var can only be string
  if (import.meta.env.REACT_APP_IS_APP_BLOCKED === "true") {
    return (
      <TranslationsProvider>
        <BlockedPage title={I18n.t("unavailable.title")} content={I18n.t("unavailable.content")} />
      </TranslationsProvider>
    );
  }

  if (!auth.isAuthenticated) return <LoadingDialog />;

  return (
    <>
      <ErrorBoundary>
        <RessourcesProvider>
          <AppRouter />
        </RessourcesProvider>
      </ErrorBoundary>
    </>
  );
};
// add i18n to force the update
const mapStateToProps = ({ i18n }: AppStore) => ({
  i18n
});

export default connect(mapStateToProps)(App);
