import * as React from "react";
import { IContentCellProps } from "@decathlon/react-table";
import classNames from "classnames";
import { Fragment } from "react";

export interface ICellItem<Props = any> {
  id: string;
  component: React.ComponentType<Props> | React.NamedExoticComponent<Props>;
  componentProps?: Props;
}

export interface ICellWithItemsProps<ICellContentProps = any> extends IContentCellProps {
  value?: string | number;
  cellContent?: React.ComponentType;
  cellContentProps?: ICellContentProps;
  items?: ICellItem[];
  className?: string;
}

const CellWithItems: React.FunctionComponent<ICellWithItemsProps> = ({
  cellContent: CellContent,
  items,
  cellContentProps,
  className,
  ...props
}) => {
  const { value } = props;
  return (
    <div className={classNames("cell-with-items", className)}>
      {CellContent ? (
        <CellContent {...props} {...cellContentProps} />
      ) : (
        <div className="cell-content">
          <div className="cell-content-value" title={value.toString()}>
            {value}
          </div>
        </div>
      )}
      {items?.length ? (
        <div className="cell-items">
          {items.map(({ component: ItemComponent, componentProps }, index) => {
            const aggregatedProps = { ...props, ...componentProps };
            return (
              <Fragment key={index}>
                <ItemComponent {...aggregatedProps} />
              </Fragment>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default CellWithItems;
