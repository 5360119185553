import { useCallback, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { CoachAvatar } from "./coach-avatar";
import { useApiGetCoach } from "core/api/coach/useApiGetCoach";
import { useFilters } from "components/common/filters3/filters/hooks/useFilters";
import { withFiltersManager } from "components/common/filters3/filters/hoc/manager";
import { useApiMenuGetUserPreferences } from "../../../../core/api/menu/useApiMenuGetUserPreferences";
import { getTeam } from "core/hooks/useTeams";
import { useApiGetTeams } from "core/api/team/useApiGetTeams";

const isStoredTeamAndStoreValid = (teamId, storeId) => teamId && storeId;

export const DumbCoach = () => {
  const [avatarIsVisible, setAvatarIsVisible] = useState(false);

  const avatarSpring = useSpring({
    zIndex: avatarIsVisible ? "10" : null,
    opacity: avatarIsVisible ? 1 : 0
  });

  const storedTeamId = JSON.parse(sessionStorage.getItem("__mgf_snap(team)"))?.team;
  const storedStoreId = JSON.parse(sessionStorage.getItem("__mgf_snap(store_id)"))?.store_id;

  const { data: user } = useApiMenuGetUserPreferences();
  const favoriteTeamId = user.preferences.default_filters.team;
  const favoriteStoreId = user.preferences.default_filters.store_id;

  const teamId = isStoredTeamAndStoreValid(storedTeamId, storedStoreId) ? storedTeamId : favoriteTeamId;
  const storeId = isStoredTeamAndStoreValid(storedTeamId, storedStoreId) ? storedStoreId : favoriteStoreId;

  const { data: teams } = useApiGetTeams({ storeId });
  const team = getTeam(teams, teamId);
  const { data } = useApiGetCoach(teamId, storeId, { enabled: !!team });
  const isTrajectoryRealized = data?.trajectory_actions?.trajectory_realized;

  const traj = data
    ? data.trajectory_actions
    : { action_title: "", call_to_action_title: "", trajectory_realized: true, year: 9999 };

  useEffect(() => {
    setAvatarIsVisible(data && !isTrajectoryRealized);
  }, [data, isTrajectoryRealized]);

  return (
    <animated.div
      id="coach-avatar"
      style={{
        ...avatarSpring
      }}
    >
      {avatarIsVisible ? <CoachAvatar setAvatarIsVisible={setAvatarIsVisible} trajectoryActions={traj} /> : null}
    </animated.div>
  );
};

const Coach = () => {
  const { appliedFilters } = useFilters();

  const CoachRender = useCallback(() => {
    return <DumbCoach />;
  }, [appliedFilters]);

  return <CoachRender />;
};

export default withFiltersManager(Coach, {});
