import { Store } from "redux";
import { setLocale, syncTranslationWithStore } from "react-redux-i18n";

export const LOCALES_MAPPING = {
  fr: "fr-FR"
};

// tslint:disable-next-line:no-namespace
declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    getNavigatorLanguage: () => string;
  }
}

if (!window.getNavigatorLanguage) {
  window.getNavigatorLanguage = () => navigator.language;
}

export const getNavigatorLanguage = (): string => {
  const locale = localStorage.getItem("mg-language") || window.getNavigatorLanguage();
  return LOCALES_MAPPING[locale] || locale;
};

export const dispatchTranslations = (store: Store<any>, userLocale: string) => {
  syncTranslationWithStore(store);
  const setLocaleDispatchCallback = setLocale(userLocale);
  setLocaleDispatchCallback(store.dispatch);
};
