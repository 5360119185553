import ReactQuill from "react-quill";

export const DirectorWordQuillTextarea = ({ newData, setNewData, modules, formats }) => (
  <ReactQuill
    theme="snow"
    value={newData}
    onChange={content => setNewData(content)}
    modules={modules}
    formats={formats}
  />
);
