import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { I18n } from "react-redux-i18n";
import { QUERY_KEY_DIRECTOR } from "./useApiGetDirector";

export const useApiPutDirectorWord = (storeId: number) => {
  const queryClient = useQueryClient();
  const directorWordEndpoint = `/dir_message/${storeId}`;
  return useMutation({
    mutationFn: newMessage => axios.put(directorWordEndpoint, newMessage).then(response => response.data),
    onSuccess: () => {
      toast.info(I18n.t("pilotable.data_saved"));
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_DIRECTOR, directorWordEndpoint] });
    },
    onError: () => {
      toast.error(I18n.t("error.global.title"));
    }
  });
};
