import { ITemplate } from "core/utils/templates";

export enum Visibility {
  IsVisible = "is-visible",
  NotVisible = "not-visible",
  IsUsable = "is-usable"
}

export type TemplatesSorted = {
  [key in Visibility]: ITemplate[];
};

export const initialTemplatesSorted: TemplatesSorted = {
  [Visibility.IsVisible]: [],
  [Visibility.NotVisible]: [],
  [Visibility.IsUsable]: []
};
