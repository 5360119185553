import { useCallback, useState } from "react";
import { I18n } from "react-redux-i18n";

import SelectStore from "components/common/filters/select-store";
import SelectSportMulti from "components/common/filters/select-sport-multi";
import { Sport } from "core/models/api/sports-piloting/sports-piloting";
import { useApiGetSport } from "core/api/sport/useApiGetSport";

interface IFavoriteSportFieldProps {
  onAddSportsToGroup: (value: number[], currentStoreId: number, sports: Sport[]) => void;
  disabledItemsIds: ((items: Sport[]) => number[]) | number[];
}

const SportFields = ({ onAddSportsToGroup, disabledItemsIds }: IFavoriteSportFieldProps) => {
  const [currentStoreId, setCurrentStoreId] = useState<number>(null);
  const [newSportsIds, setNewSportsId] = useState<number[]>([]);
  const { data: sports } = useApiGetSport({ storeId: currentStoreId }, { enabled: !!currentStoreId });
  const updateCurrentStoreId = (value: number) => {
    setCurrentStoreId(value);
  };

  const handleDepartmentChange = useCallback((sportIds: number[]) => {
    setNewSportsId([...newSportsIds, ...sportIds]);
  }, []);

  const handleValidation = useCallback(() => {
    onAddSportsToGroup(newSportsIds, currentStoreId, sports);
    setNewSportsId([]);
  }, [onAddSportsToGroup, newSportsIds, sports]);

  return (
    <div className="favorite-sport-fields">
      <SelectStore
        id="user-preferences_select_store"
        storeId={currentStoreId}
        onChange={updateCurrentStoreId}
        hasBottomLine
        disableDefaultStore
      />
      {currentStoreId ? (
        <div style={{ display: "flex" }}>
          <SelectSportMulti
            id="favorite_sport_field_sport_search"
            onChange={handleDepartmentChange}
            hasBottomLine
            value={newSportsIds}
            storeId={currentStoreId}
            disabledItemsIds={disabledItemsIds}
            isMultiple
          />
          <button
            style={{ marginTop: "20px" }}
            data-testid="add-favorite-departments"
            className="vtmn-btn vtmn-btn_variant--primary vtmn-btn_size--small"
            disabled={newSportsIds.length === 0}
            onClick={handleValidation}
          >
            {I18n.t("add")}
          </button>
        </div>
      ) : null}
    </div>
  );
};
export default SportFields;
