import DateUtils from "core/utils/date";
import { TimelineLabelType } from "core/models/timeline";
import { getSubPropsMapping, IPropsMapping } from "core/utils/common";
import * as actionTypes from "core/redux/filters/actionTypes";
import { Filters } from "core/redux/filters/actions";
import { UserAction } from "core/redux/user/actions";

interface ICachedFilterValue {
  value: unknown;
  dependenciesValues: PartialFilters;
}

export interface ICachedFiltersValues {
  [key: string]: ICachedFilterValue;
}

export type IDependantFiltersChanges = {
  [key in IFilterKey]?: PartialFilters;
};

export enum PERIOD {
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
  BEGINNING_OF_YEAR = "BEGINNING_OF_YEAR"
}

export interface IFilters {
  sport: number | null;
  sports_group: number | null;
  channel: number | null;
  template: number | null;
  year: number | null;
  year_init: number | null;
  month: number | null;
  store_id: number | null;
  team: number | null;
  week: number | null;
  from: string | null;
  to: string | null;
  aggregate: number | null;
  frame: number | null;
  timeline: string | null;
  weekPeriod: PERIOD | null;
  country: string | null;
  region: string | null;
  stores_group: number | null;
}

export type PartialFilters = Partial<IFilters>;

export type IFilter = Partial<IFilters>;

export type IFilterKey = keyof IFilters;

export type IFilterValue = number | string | PERIOD | string[];

export enum FilterKey {
  COUNTRY = "country",
  REGION = "region",
  SPORT = "sport",
  SPORTS_GROUP = "sports_group",
  TEMPLATE = "template",
  CHANNEL = "channel",
  YEAR = "year",
  YEAR_INIT = "year_init",
  MONTH = "month",
  TIMELINE = "timeline",
  STORE_ID = "store_id",
  TEAM = "team",
  WEEK = "week",
  AGGREGATE = "aggregate",
  FRAME = "frame",
  WEEK_PERIOD = "weekPeriod",
  STORES_GROUP = "stores_group",
  FROM = "from",
  TO = "to"
}

const date = DateUtils.getCurrentDate();
const timeline = Object.keys(TimelineLabelType)[0];

/**
 * we want to initialize the week for the whole app
 * with the previous week of the current week except when it's first year week
 */
export const initializeWeek = (date: Date): number => {
  const weekNumber = DateUtils.getWeekNumber(date);
  if (weekNumber > 1) {
    return weekNumber - 1;
  }

  return weekNumber;
};

const defaultFromTo = DateUtils.getDefaultFromTo();

export const DEFAULT_FILTERS: IFilters = {
  sport: null,
  sports_group: null,
  template: null,
  channel: null,
  store_id: null,
  year: DateUtils.getYearWeekFromDate(date),
  year_init: DateUtils.getYearWeekFromDate(date),
  month: DateUtils.getMonthNumeric(date),
  week: initializeWeek(date),
  from: DateUtils.dateToYearWeekFormat(defaultFromTo[0]),
  to: DateUtils.dateToYearWeekFormat(defaultFromTo[6]),
  aggregate: null,
  team: null,
  frame: 1,
  timeline,
  weekPeriod: PERIOD.MONTHLY,
  country: null,
  region: null,
  stores_group: -1
};

const NOT_NULLABLE_FILTERS = [FilterKey.TEMPLATE, FilterKey.FRAME, FilterKey.TIMELINE, FilterKey.WEEK_PERIOD];

export const DEFAULT_DEPENDENCIES_FILTERS_CHANGES: IDependantFiltersChanges = {
  [FilterKey.STORE_ID]: {
    [FilterKey.TEAM]: null,
    [FilterKey.SPORT]: null
  }
};

export const DEFAULT_DEPENDENCIES_FILTERS_MAPPING = getSubPropsMapping(DEFAULT_DEPENDENCIES_FILTERS_CHANGES);

export const CACHED_FILTERS_VALUES: ICachedFiltersValues = {};

// `teamSportBySport` reducer
/**
 * save the last value for all dependencies
 * @param filters
 * @param dependantFiltersMapping
 * @param cache
 */
export const savePrevFilterDependencies = (
  filters: Partial<IFilters>,
  dependantFiltersMapping: IPropsMapping = DEFAULT_DEPENDENCIES_FILTERS_MAPPING,
  cache: ICachedFiltersValues = CACHED_FILTERS_VALUES
): ICachedFiltersValues => {
  return Object.keys(filters).reduce<ICachedFiltersValues>((newCache, filterKey) => {
    const dependencies = dependantFiltersMapping[filterKey];
    const hasDependencies = dependencies && dependencies.length > 0;
    const filter = filters[filterKey];
    if (hasDependencies && filter !== null) {
      dependencies.forEach(depFilterKey => {
        const depFilterValue = filters[depFilterKey];
        let currentCache: ICachedFilterValue;
        if (newCache[depFilterKey]) {
          currentCache = { ...newCache[depFilterKey] };
        } else {
          currentCache = {
            value: null,
            dependenciesValues: {}
          };
        }
        const newdependenciesValues = { ...currentCache.dependenciesValues, [filterKey]: filter };
        newCache[depFilterKey] = { value: depFilterValue, dependenciesValues: newdependenciesValues };
      });
    }
    return newCache;
  }, cache);
};

/**
 * update default filters
 * @param filters
 */
const updateDefaultFilters = (filters: Partial<IFilters>): IFilters => {
  const newFilters: Partial<IFilters> = { ...filters };
  NOT_NULLABLE_FILTERS.forEach(filterKey => {
    if (newFilters[filterKey] == null) {
      // @ts-ignore
      newFilters[filterKey] = DEFAULT_FILTERS[filterKey];
    }
  });
  return newFilters as IFilters;
};

const reducers = (state: IFilters = DEFAULT_FILTERS, action: Filters | UserAction) => {
  switch (action.type) {
    case actionTypes.SET_FILTERS: {
      const newFilters = { ...state, ...action.filters };
      savePrevFilterDependencies(newFilters);
      return updateDefaultFilters(newFilters);
    }

    case actionTypes.SET_FILTER: {
      const newState = updateDefaultFilters({ ...state, [action.filter.name]: action.filter.value });
      savePrevFilterDependencies(newState);
      return newState;
    }

    default:
      return state;
  }
};

export default reducers;
