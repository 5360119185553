import { ReactElement } from "react";
import { ListItemText, MenuItem } from "@mui/material";
import classNames from "classnames";

interface IProps {
  title: string;
  onClick: () => void;
  id?: string;
  disabled?: boolean;
  className?: string;
  icon?: ReactElement;
}

const SelectAction = ({ title, onClick, className, icon, disabled, id }: IProps) => {
  const dataTestId = "select-action" + (id ? "-" + id : "");
  return (
    <MenuItem
      data-testid={dataTestId}
      onClick={onClick}
      className={classNames("select-action__menu__item", className, { disabled })}
      disabled={disabled}
    >
      <ListItemText primary={title} />
      {icon}
    </MenuItem>
  );
};

export default SelectAction;
