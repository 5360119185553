import { Filter } from "../definitions";
import { filtersEntries } from "./filters";
import { FiltersContainer, ValidatorsReport } from "../hoc/types";

export const validateFilter = <T>(filter: Filter<T>, value: T) => {
  const validators = filter.validators;
  if (validators) {
    return validators.map(validator => {
      const isValid = validator.isValid(value);
      return isValid ? null : validator.error;
    });
  }
  return [];
};

export const getValidationReport = <T>(filters: FiltersContainer<T>) =>
  filtersEntries(filters).reduce((struct, pair) => {
    const [key, filter] = pair;
    const validation = validateFilter(filter, filter.value);
    struct[key] = validation.filter(report => !!report);
    return struct;
  }, {} as ValidatorsReport<T>);
