import { CALLBACK_PATH } from "core/utils/authentication";
import { getPathFromRouteId } from "core/utils/route-utils";
import React from "react";
import { Navigate } from "react-router";
import { RouteId } from "./route-ids";

const PostFedid: React.FC = () => {
  let pathname = window.localStorage.getItem("redirectPathname");
  if (!pathname || pathname === CALLBACK_PATH) {
    pathname = getPathFromRouteId(RouteId.Home);
  }
  const search = window.localStorage.getItem("redirectSearch");
  const route = `${pathname}${search ? search : ""}`;
  return <Navigate to={route} />;
};

export default PostFedid;
