import * as React from "react";

import classNames from "classnames";
import { blueDkt } from "core/utils/mui-dkt-theme";

export const myGameIcons = [
  "template",
  "sport-fav",
  "copy",
  "load",
  "groupe-sport",
  "equipe",
  "sport",
  "pilotage",
  "pilotage-remontant2",
  "pilotage-remontant",
  "moteur-heures",
  "modify",
  "tri",
  "area",
  "piloting",
  "surface",
  "time",
  "initialization",
  "refresh",
  "week",
  "month",
  "calendar",
  "area",
  "edit-all-past",
  "edit-all-past-disabled",
  "edit-past",
  "edit-past-disabled",
  "copy_week"
];

export interface IconProps {
  /**
   * Classes to add on the `button` element
   */
  className?: string;
  /**
   * Styles to add on the `button` element
   */
  style?: React.CSSProperties;
  /**
   * The size of the icon in pixels
   **/
  size?: number;
  /**
   * The color of the icon
   * see css vitamin colors
   **/
  color?: string;
  /**
   * The value of the icon
   **/
  name: string;
}

const Icon = (props: IconProps) => {
  const { className, style, size = 24, color = "brand-digital", ...others } = props;
  const { name } = props;
  return myGameIcons.includes(name) ? (
    <i
      className={classNames("mg-icon", { [`icon-ic_${name}`]: name }, className)}
      style={{ fontSize: size, color: color === "brand-digital" ? blueDkt : color, ...style }}
      {...others}
    />
  ) : (
    <></>
  );
};

export default Icon;
