import HeaderLinks from "./header-links";
import { Link } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import logo from "core/assets/images/mg.svg";
import { APP_TITLE } from "core/constants";

const HeaderComponent = ({ isOpen, setIsOpen }) => {
  return (
    <header>
      <nav className="mg-header" aria-label="Navbar with icons">
        <div className="mg-header-left-navigation">
          <button
            data-testid="mg-button-header-trigger-menu"
            onClick={() => setIsOpen(!isOpen)}
            className="mg-button-header__icon"
          >
            <span className="material-icons-outlined" id="trigger-menu">
              menu
            </span>
          </button>

          <div className="mg-header-brand">
            <Link to="/" onClick={() => setIsOpen(false)}>
              <img className="mg-header-logo" src={logo} />
            </Link>

            <Link to="/" onClick={() => setIsOpen(false)}>
              <span className="mg-header-title">{I18n.t(APP_TITLE)}</span>
            </Link>
          </div>
        </div>

        <div className="mg-header-right-navigation">
          <HeaderLinks />
        </div>
      </nav>
    </header>
  );
};

export default HeaderComponent;
