import * as React from "react";
import { UpwardForecastExceptionManagerContext } from "components/specific/upward-forecast/data-manager/upward-forecast-exception-context";

interface IAddButtonProps {
  isDisabled: boolean;
}

const AddButton: React.FunctionComponent<IAddButtonProps> = ({ isDisabled }) => {
  const { saveNewException } = React.useContext(UpwardForecastExceptionManagerContext);

  return (
    <>
      {isDisabled ? (
        <div className="mg-button-base disabled">
          <span className="material-icons-outlined notranslate">add</span>
        </div>
      ) : (
        <div className="mg-button-base" onClick={saveNewException}>
          <span className="material-icons-outlined notranslate">add</span>
        </div>
      )}
    </>
  );
};

export default React.memo(AddButton);
