import { ReactElement } from "react";
import classNames from "classnames";

export enum Dimensions {
  Default = "default",
  Small = "small"
}

interface IProps {
  /** The CSS class name of the toolbar. */
  className?: string;
  /** The dimension of the toolbar. */
  dimension?: Dimensions;
  children?: ReactElement | ReactElement[];
  style?: Record<string, any>;
}

const Toolbar = ({ className, children, dimension, style }: IProps) => (
  <div className={classNames("toolbar-container", className, { small: dimension === Dimensions.Small })} style={style}>
    {children}
  </div>
);

Toolbar.defaultProps = {
  dimension: Dimensions.Default
};

export default Toolbar;
