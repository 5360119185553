import * as React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { I18n } from "react-redux-i18n";
import { IUpwardForecastHistoryItem } from "core/api/rest/queries/upward-forecast-history";
import DateUtils from "core/utils/date";

const HistoryListItem: React.FunctionComponent<IUpwardForecastHistoryItem> = React.memo(({ date, activated }) => {
  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <span className="material-icons-outlined notranslate" style={{ color: "#3D9ACC" }}>
          event_note
        </span>
      </ListItemIcon>
      <ListItemText
        primary={DateUtils.dayToYearMonthLabel(DateUtils.formatDate(new Date(date)))}
        secondary={activated ? I18n.t("activated") : I18n.t("deactivated")}
        secondaryTypographyProps={{ style: { overflowWrap: "break-word" } }}
      />
    </ListItem>
  );
});

export default HistoryListItem;
