import * as React from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import classnames from "classnames";
import IconButton from "@mui/material/IconButton";

import CardItem from "components/common/cards/card-item";
import Icon from "components/common/icons/customIcon";
import { ISport } from "core/api/rest/queries/select-sport";

export interface ISportsGroupFavoriteItemProps {
  className?: string;
  sport: ISport;
  onRemoveSport?: (sportIndex: number) => void;
  dragProvided?: DraggableProvided;
  index: number;
}

const SportsGroupFavoriteItem: React.FunctionComponent<ISportsGroupFavoriteItemProps> = ({
  className,
  sport,
  onRemoveSport,
  dragProvided,
  index
}) => {
  const draggableProps = dragProvided ? dragProvided.draggableProps : {};
  const dragHandleProps = dragProvided ? dragProvided.dragHandleProps : {};
  const { department_id, label, store_id, store_label } = sport;

  const handleOnRemoveSport = React.useCallback(() => onRemoveSport(index), [onRemoveSport, index]);

  return (
    <div
      ref={dragProvided?.innerRef}
      className={classnames("sport-group-favorite-item", className)}
      {...draggableProps}
    >
      <CardItem
        {...dragHandleProps}
        title={`${department_id} - ${label}`}
        suptitle={`${store_id} - ${store_label}`}
        rightWithClassName={
          <div className="card-item__right">
            {onRemoveSport && (
              <IconButton data-testid="sport-group-favorite-item-remove-btn" onClick={handleOnRemoveSport} size="large">
                <Icon name="clear" />
              </IconButton>
            )}
          </div>
        }
      />
    </div>
  );
};

export default SportsGroupFavoriteItem;
