import axios, { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { I18n } from "react-redux-i18n";
import { toast } from "react-toastify";

import { ISportsGroup } from "core/models/api/sports-group/sports-group-piloting";
import { QUERY_KEY_SPORTS_GROUP } from "./useApiGetSportsGroup";
import { GROUPS_NOTIFICATION_SPORTS_DELETE_SUCCEDED } from "core/constants/translations/groups";

export const useApiDeleteSportsGroup = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, Error, ISportsGroup>({
    mutationFn: (sportsGroup: ISportsGroup) => {
      return axios.delete(`/sports_groups/${sportsGroup.id}`);
    },
    onError: (error: Error) => {
      toast.error(I18n.t(error?.message || "error.global.message"));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_SPORTS_GROUP] });
      toast.success(I18n.t(GROUPS_NOTIFICATION_SPORTS_DELETE_SUCCEDED));
    }
  });
};
