import IFilters from "core/models/filters";
import { setFilters, setFilters as setFiltersAction } from "core/redux/filters/actions";
import { FilterKey, IFilterKey, PartialFilters, PERIOD } from "core/redux/filters/reducers";
import { AppStore } from "core/redux/store";
import { TeamSportByStoreState } from "core/redux/team-sport-by-store";
import { includes, recordEquality } from "core/utils/data";
import { ITemplate } from "core/utils/templates";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTemplates from "./useTemplates";
import DateUtils from "core/utils/date";
import { RouteId } from "core/routing/router/route-ids";
import useView from "./useView";
import { useLocation, useNavigate } from "react-router";

const queryNumber = (s: string) => {
  const n = Number(s);
  if (isNaN(n)) {
    throw new Error(`Could not convert the value ${s} to a number`);
  }
  return n;
};
const queryNumberOrNull = (s: string) => {
  return s === "" ? null : queryNumber(s);
};
/**
 * Describe how to process a query parameter
 *
 * Query parameters not described here will be ignored
 */
const FILTER_EXTRACTORS: Record<IFilterKey, (s: string) => number | string | PERIOD> = {
  // might be missing for sport groups piloting (week or aggregate)
  aggregate: queryNumberOrNull,
  country: String,
  frame: queryNumber,
  month: queryNumber,
  sport: queryNumber,
  sports_group: queryNumber,
  stores_group: queryNumber,
  store_id: queryNumber,
  // might be missing for areas piloting
  team: queryNumberOrNull,
  template: queryNumber,
  channel: queryNumber,
  timeline: String,
  week: queryNumber,
  from: String,
  to: String,
  year: queryNumber,
  year_init: queryNumber,
  weekPeriod: (s: string) => {
    if (!(s in PERIOD)) {
      throw new Error(`Invalid value for weekPeriod: ${s}, expected one of ${Object.keys(PERIOD).join(",")}`);
    }
    return PERIOD[s] as PERIOD;
  },
  region: String
};

/**
 * Expand query parameters with last used filters and the possible last viewed sport and team for
 * the store.
 *
 * Returns the new query parameters or `null` if no change is to be applied.
 */
const extendQueryParameters = (
  relevantFilters: Array<IFilterKey>,
  urlFilters: Partial<IFilters>,
  lastUsedFilters: Partial<IFilters>,
  teamSportByStore: TeamSportByStoreState
) => {
  let storeId = urlFilters.store_id;
  const newURLFilters: Partial<IFilters> = { ...urlFilters };

  // custom logic for store, sport and team because they are connected to each other
  // add the store id if present in last used filters
  // STORE
  if (relevantFilters.includes(FilterKey.STORE_ID) && storeId == null) {
    if (lastUsedFilters.store_id != null) {
      newURLFilters.store_id = lastUsedFilters.store_id;
      storeId = lastUsedFilters.store_id;
    }
  }
  // add sport and team if relevant and possible
  if (storeId) {
    const { sport: lastSport, team: lastTeam } = teamSportByStore[storeId] || {};
    // ...but the sport is missing
    // SPORT
    if (relevantFilters.includes(FilterKey.SPORT) && urlFilters.sport == null) {
      // use the last viewed sport for the store if present
      if (lastSport) {
        newURLFilters.sport = lastSport;
      } else if (lastUsedFilters.sport) {
        newURLFilters.sport = lastUsedFilters.sport;
      }
    }
    // ...but the team is missing
    // TEAM
    if (relevantFilters.includes(FilterKey.TEAM) && urlFilters.team == null) {
      if (lastTeam) {
        // use the last viewed team for the store if present
        newURLFilters.team = lastTeam;
      } else if (lastUsedFilters.team) {
        // use the last used team.
        // The team might be inconsistent with the sport but I think they are never value at the
        // same, hence it should be good enough
        newURLFilters.team = lastUsedFilters.team;
      }
    }
  }
  // use last used filters for other parameters
  // OTHER
  for (const filter of relevantFilters) {
    if (filter !== FilterKey.SPORT && filter !== FilterKey.TEAM && filter !== FilterKey.STORE_ID) {
      if (urlFilters[filter] == null && lastUsedFilters[filter] != null) {
        newURLFilters[filter] = lastUsedFilters[filter];
      }
    }
  }

  return recordEquality(urlFilters, newURLFilters) ? null : newURLFilters;
};

/**
 * get Template Basic Id
 *  */
const getTemplateBasicId = (templates: ITemplate[]): number => {
  const templateBasic = templates?.find(template => template.is_default === true);
  return templateBasic?.id;
};

/**
 * Extract and format query parameters
 *
 * The parsing relies on `FILTER_EXTRACTORS`.
 *
 * Non relevant query parameters will be ignored.
 */
export const readQueryParameters = (
  search: string,
  relevantFilters?: Array<IFilterKey>,
  templates?: ITemplate[]
): Partial<IFilters> => {
  const searchParams = new URLSearchParams(search);
  const urlFilters: Partial<IFilters> = {};
  for (const [filterName, filterValue] of searchParams.entries()) {
    if (relevantFilters && !includes(relevantFilters, filterName)) continue;
    if (filterName in FILTER_EXTRACTORS) {
      const extractor = FILTER_EXTRACTORS[filterName];
      try {
        urlFilters[filterName] = extractor(filterValue);
      } catch (e) {
        console.error(`Error while parsing the URL: ${e.toString()}`);
      }
    }
  }

  // detect if template into urlFilters exist
  const selectedTemplate = templates?.find(template => template.id === urlFilters.template);
  const newUrlFilters = { ...urlFilters };
  // get id templateBasic
  const templateBasicId = getTemplateBasicId(templates);
  // if template not exist and urlFilters is not === {}, then templateBasicId is assigned
  if (!selectedTemplate && urlFilters.template) {
    newUrlFilters.template = templateBasicId;
  }

  return newUrlFilters;
};

/**
 * Returns a new search string based on the provided filters
 */
export const buildQueryParameters = (filters: PartialFilters) => {
  const newSearchParams = new URLSearchParams();
  for (const [filterName, filterValue] of Object.entries(filters)) {
    const filterFormatted = filterValue === null ? "" : String(filterValue);
    newSearchParams.set(filterName, String(filterFormatted));
  }
  return newSearchParams.toString();
};

/**
 * Returns a new value for last used filters by taking care of store, sport and team relation
 *
 * Returns `null` if no change has to be dispatched
 */
export const pastLastUsedFiltersFilters = (lastUsedFilters: Partial<IFilters>, patchFilters: Partial<IFilters>) => {
  const newFilters = { ...lastUsedFilters, ...patchFilters };
  // take care of setting sport and team to `null` if the store has changed and no candidate
  // were found
  if ("store_id" in patchFilters && lastUsedFilters.store_id !== patchFilters.store_id) {
    if (!("sport" in patchFilters)) {
      newFilters.sport = null;
    }
    if (!("team" in patchFilters)) {
      newFilters.team = null;
    }
  }
  return recordEquality(lastUsedFilters, newFilters) ? null : newFilters;
};

/**
 * Handle filters by relying on both the query parameters and the last used filters
 *
 * Last used filters are initialized with user preferences and are in the `redux` store.
 *
 * On page load, if no query parameters are available, the value for relevant filters available in
 * last used filters are used to build the query parameters. If some query parameters are present
 * but some relevant filters are missing, the query parameters will be extended with the last used
 * filters.
 *
 * Refinement: in addition to last used filters, the last sport and team viewed for a given store
 * saved in the `sportTeamByStore` reducer, if present, will be used in place of the last used
 * filters.
 *
 * When the user changes the filters and click the "apply filters" button, the URL is updated to
 * reflect the changes, and this hook record the query parameters (which are complete and consistent
 * given the relevant filters) as last used filters.
 *
 * The sport and the team in last used filters should always stay consistent with the store. Hence
 * if the user goes to a screen where the sport or the team is not relevant (hence not in the filter
 * bar), when the user changes the store, the sport or the team should be reset.
 *
 * At the end of the day, the filters in the URL are the source of truth for the active filters, and
 * are the one used in the API calls (the store might be ignored for some calls at the sport level
 * because the store is retrieved from the store in the API).
 *
 * The logic is a bit tricky:
 * - the query parameters should be the source of truth for active filters (on page load, the active
 *   filter are extracted from the URL)
 * - but when navigating to a new page, the query parameters are populated based on the last used
 *   filters.
 *
 * Ideally, we should guarantee this hook is only called once per page. I think `relevantFilters`
 * should be defined at the route level, and the call to this hook should be handled at the top
 * (with the routing logic).
 */
const useQueryParameters = (relevantFiltersInit: IFilterKey[]) => {
  // Ensure `relevantFilters` are valued at initialisation time only (this allows relying on
  // identity equality even if the caller gives a new object on every render)
  const refRelevantFilters = useRef(relevantFiltersInit);
  const relevantFilters = refRelevantFilters.current;
  const lastUsedFilters = useSelector<AppStore, IFilters>(state => state.filters);
  const history = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const templates = useTemplates();
  const teamSportByStore = useSelector((state: AppStore) => state.teamSportByStore);

  const activeFilters = useMemo(
    () => readQueryParameters(history.search, relevantFilters, templates),
    [relevantFilters, history.search, templates]
  );

  const setActiveFilters = useCallback(
    (newFilters: Partial<IFilters>) => {
      const currentYear = DateUtils.getCurrentYear();
      // if newFilters.year > currentYear => newFilters.week = 1
      if (newFilters?.year && newFilters?.week && newFilters.year !== currentYear) {
        newFilters.week = 1;
      } else if (newFilters?.year && newFilters?.week && newFilters.year === currentYear) {
        let week = DateUtils.getCurrentWeek();
        if (week > 1) {
          week = week - 1;
          newFilters.week = week;
        }
      }
      const newParamsString = buildQueryParameters(newFilters);
      dispatch(setFilters(newFilters));
      navigate({ search: newParamsString });
    },
    // history is stable but make eslint happy
    [history, dispatch]
  );
  const routeId = useView();

  useEffect(() => {
    // All filters set in the URL which are:
    // - valid
    // - relevant for the current page
    // - not mentioned in last used filters
    // should be added to last used filters
    //
    // All filters set in last used filters which are:
    // - not mentioned in the URL
    // - relevant for the current page
    // should be added to the URL
    const search = history.search;
    const urlFilters: Partial<IFilters> = readQueryParameters(search, relevantFilters, templates);

    // ensure the template is valid for the current user
    // if (filterName === FilterKey.TEMPLATE) {
    //   if (templates.findIndex(t => t.id === templateId) === -1) {
    //     // it seems that the API always provides a template in user preferences, but to be sure
    //     // we default to basic if there is no template in session filters
    //     if (lastUsedFilters[filterName] === null) {
    //       const templateBasic = templates[0];
    //       filters[filterName] = templateBasic.id;
    //     }
    //   }

    if (relevantFilters.every(filter => filter in urlFilters)) {
      // the URL is complete => update last used filters (ensure we do not value non relevant query
      // parameters), the URL should stay untouched
      const newFilters = pastLastUsedFiltersFilters(lastUsedFilters, urlFilters);
      if (newFilters) {
        dispatch(setFiltersAction(newFilters));
      }
      return;
    }

    // no query parameter or some query parameters are missing => rely on last used filters to
    // expand the URL
    const updatedUrlFilters = extendQueryParameters(relevantFilters, urlFilters, lastUsedFilters, teamSportByStore);

    if (updatedUrlFilters) {
      if (routeId === RouteId.ComparisonSportGroups) {
        // currently the stores_group is disabled on ComparisonSportGroups
        updatedUrlFilters.stores_group = null;
      }

      // fix MG-3475
      if (
        (routeId === RouteId.WeeklyStores ||
          routeId === RouteId.WeeklySportsGroup ||
          routeId === RouteId.WeeklyTeams ||
          routeId === RouteId.Weekly) &&
        !updatedUrlFilters.week
      ) {
        updatedUrlFilters.week = 1;
      }

      setActiveFilters(updatedUrlFilters);

      // the last used filters might be updated as well (for instance if the store in the URL does not match the store in the last used filters)
      const newFilters = pastLastUsedFiltersFilters(lastUsedFilters, updatedUrlFilters);
      if (newFilters) {
        dispatch(setFiltersAction(newFilters));
      }
    }
  }, [relevantFilters, lastUsedFilters, teamSportByStore, templates, setActiveFilters, history, dispatch, routeId]);

  return { activeFilters, setActiveFilters };
};

export default useQueryParameters;
