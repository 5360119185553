import { RouteId } from "core/routing/router/route-ids";
import { IRoute, ROUTES } from "core/routing/router/routes";
import { Route } from "react-router-dom";
import { groupBy } from "./data";
import LoadingDialog from "../../components/common/loader/loading-progress";
import { Suspense } from "react";
import ROLES_MAPPING, { ROLES } from "../constants/roles";
import { checkIsHasPermission } from "./checkIsHasPermission";
import Forbidden from "../../pages/errors/forbidden";

const ROUTES_BY_ID = groupBy(ROUTES, route => route.id);
const ROUTES_BY_PATH = groupBy(ROUTES, route => route.path);

/**
 * Return the page title based on the pathname
 *
 * /!\ `path` should not contain query parameters
 */
export const getTitleFromPath = (path: string, byPath: Record<string, IRoute<unknown>> = ROUTES_BY_PATH): string => {
  const route = byPath[path];
  return route?.name || "";
};

/**
 * Build <Route /> elements for enabled routes
 */
export const buildRouteEls = (routes: Array<IRoute>, roles: ROLES[]) =>
  routes
    .filter(route => !route.isDisabled)
    .map(({ path, component: Component, rights }) => {
      return (
        <Route
          key={path}
          path={path}
          element={
            <Protected allowedPermissions={rights[0]} roles={roles}>
              <Suspense fallback={<LoadingDialog />}>
                <Component />
              </Suspense>
            </Protected>
          }
        ></Route>
      );
    });

const Protected = ({ allowedPermissions, roles, children }) => {
  const allowed = checkIsHasPermission(roles, ROLES_MAPPING, allowedPermissions);
  return allowed ? children : <Forbidden />;
};

/**
 * Get route id from path
 *
 * To be called by a pathname as returned by `useLocation`:
 * - should not include query params
 * - should not include the possible prefix in the URL (eg. `mygame-pic`)
 */
export const getRouteIdFromPath = (path: string, byPath: Record<string, IRoute> = ROUTES_BY_PATH): RouteId => {
  const route = byPath[path];
  return route?.id;
};

/**
 * Get path from route id
 */
export const getPathFromRouteId = (routeId: RouteId, byId: Record<RouteId, IRoute> = ROUTES_BY_ID): string => {
  const route = byId[routeId];
  return route?.path || "";
};

export const getRouteFrom = (pathname: string) => {
  const routeId = getRouteIdFromPath(pathname);
  return ROUTES.find(allRoute => allRoute.id === routeId);
};
