import { Order } from "./sort";

/**
 * Takes an object and adds an `index` property to each value based on the order of the keys
 */
export const addIndexToValues = <T extends { [key: string]: any }>(
  by: T
): T extends { [keys in infer K]: infer U } ? { [key in K]: U & { index: number } } : never => {
  const tuples = Object.entries(by);
  const tuplesWithIndex = tuples
    .sort(([keyA], [keyB]) => (keyA > keyB ? 1 : keyB > keyA ? -1 : 0))
    .map(([key, value], index) => [key, { ...value, index }]);

  return Object.fromEntries(tuplesWithIndex);
};

/**
 * Tighten type inference for `Object.fromEntries`
 *
 * The types of keys and values are aligned with the types inferred from the tuples
 *
 * Default typings for `Object.fromEntries` are deliberately looser:
 * https://github.com/microsoft/TypeScript/pull/12253
 */
export const fromEntries = <K extends string | number | symbol, V>(tuples: (readonly [K, V])[]): { [key in K]: V } =>
  Object.fromEntries(tuples) as { [key in K]: V };

/**
 * Tighten type inference for `Object.entries`
 *
 * The types of keys and values are aligned with the types inferred from the tuples
 *
 * Default typings for `Object.fromEntries` are deliberately looser:
 * https://github.com/microsoft/TypeScript/pull/12253
 */
export const entries = <T extends Record<keyof T, any>>(obj: T) =>
  Object.entries(obj) as { [K in keyof T]: [K, T[K]] }[keyof T][];

/**
 * Tighten type inference for `Object.keys`
 */
export const keys = <O extends { [key: string]: any }>(obj: O) => Object.keys(obj) as Array<keyof O>;

/**
 * Tighten type inference for `Object.values`
 */
export const values = <O extends { [key: string]: any }>(obj: O) => Object.values(obj) as Array<O[keyof O]>;

/**
 * Returns an array with the rank of each item in the initial array
 */
export const rank = <T>(arr: readonly T[], order: Order = "ASC"): number[] => {
  const sorting =
    order === "ASC"
      ? ([x]: readonly [any, number], [y]: readonly [any, number]) => (x > y ? 1 : x < y ? -1 : 0)
      : ([x]: readonly [any, number], [y]: readonly [any, number]) => (y > x ? 1 : y < x ? -1 : 0);

  return arr
    .map((x, i) => [x, i] as const)
    .sort(sorting)
    .reduce((enhanced, [, pos], rank) => {
      enhanced[pos] = rank;
      return enhanced;
    }, new Array(arr.length));
};

/**
 * Returns an array of tuples by coupling up equally-positioned items
 */
export function zip<U, V>(first: readonly U[], second: readonly V[]): [U, V][];
export function zip<U, V, W>(first: readonly U[], second: readonly V[], third: readonly W[]): [U, V, W][];
// degraded typings for other lengths
export function zip(...args: (readonly any[])[]): any[][];

export function zip(...args: (readonly any[])[]) {
  if (!(args.length > 0)) {
    throw new Error("`zip` needs only one array to work on");
  }
  const [first] = args;
  const nbItems = first.length;
  if (!args.every(arr => arr.length === nbItems)) {
    throw new Error("`zip` only works with arrays of the same size");
  }

  return Array.from({ length: nbItems }, (_, i) => args.map(arr => arr[i]));
}

/**
 * Returns two arrays from an array of tuples by decoupling items
 */
export const unzip = <U, V>(arrOfTypes: Array<[x: U, y: V]>): [U[], V[]] =>
  arrOfTypes.reduce<[U[], V[]]>(
    (arr, [x, y]) => {
      arr[0].push(x);
      arr[1].push(y);
      return arr;
    },
    [[], []]
  );

/**
 * Computes the sum of the array elements
 */
export const sum = (arr: readonly number[]): number => arr.reduce((acc, value) => acc + value, 0);

/**
 * Computes the arithmetic mean of the array elements
 */
export const mean = (arr: readonly number[]): number => sum(arr) / arr.length;

/**
 * Returns the minimum value in the array
 */
export const min = (arr: readonly number[]): number => Math.min(...arr);

/**
 * Returns the maximum value in the array
 */
export const max = (arr: readonly number[]): number => Math.max(...arr);

/**
 * Distribute a value according to some weights
 *
 * The sum of weights do not need to be 1 (they are normalized).
 */
export const weightedDistribution = (value: number, weights: readonly number[]): number[] => {
  const normalizedWeights = normalizeWeights(weights);
  return normalizedWeights.map(ratio => value * ratio);
};

/**
 * Normalize weights
 *
 * The sum of normalized weights will be equal to one.
 *
 * Fails gracefully if the sum of initial weights is zero by returning a uniform distribution.
 */
export const normalizeWeights = (weights: readonly number[]): number[] => {
  const totalWeight = sum(weights);
  let normalizedWeights: number[];
  if (totalWeight === 0 || isNaN(totalWeight)) {
    // fail safe if for some reasons all the weights are equal to 0 (eg. when the turnover is null
    // for every sport, when trying to use the turnover or the theoretical hours to distributes some
    // hours, we do not want to get an error)
    // uniform distribution
    normalizedWeights = weights.map(() => 1 / weights.length);
  } else {
    normalizedWeights = weights.map(weight => weight / totalWeight);
  }
  return normalizedWeights;
};

/**
 *  round a number with a decimal point
 */
const roundDecimal = (floatNumber: number): number => {
  return Math.round(floatNumber * 10) / 10;
};

/**
 * Normalize weights for Cash Hours services
 *
 * The sum of normalized weights will be equal to one.
 *
 * Fails gracefully if the sum of initial weights is zero by returning a uniform distribution.
 */
export const normalizeWeightsCashHours = (
  cashHoursValues: readonly number[],
  cashHoursByTeam?: Record<string, number>,
  lock?: Record<string, boolean>
): number[] => {
  const totalWeight = sum(cashHoursValues.map(floatNumber => roundDecimal(floatNumber)));
  let normalizedWeights: number[];
  if (totalWeight === 0 || isNaN(totalWeight)) {
    // fail safe if for some reasons all the weights are equal to 0 (eg. when the turnover is null
    // for every sport, when trying to use the turnover or the theoretical hours to distributes some
    // hours, we do not want to get an error)
    // uniform distribution
    normalizedWeights = cashHoursValues?.map(() => 1 / cashHoursValues.length);
  } else if (lock && Object.values(lock).some(value => value === true)) {
    const normalizedWeightsWithoutLockedHoursWeights: number[] = [];
    let totalCashHourstWithoutLockedHoursWeights = 0;
    let nbLockedTeam = 0;
    const cashHoursByTeamAsList = Object.entries(cashHoursByTeam);
    cashHoursByTeamAsList?.forEach(([teamId, cashHoursValue]) => {
      if (!lock[teamId]) {
        totalCashHourstWithoutLockedHoursWeights =
          totalCashHourstWithoutLockedHoursWeights + roundDecimal(cashHoursValue);
        nbLockedTeam++;
      }
    });
    if (totalCashHourstWithoutLockedHoursWeights === 0 || isNaN(totalCashHourstWithoutLockedHoursWeights)) {
      normalizedWeights = cashHoursValues?.map(() => 1 / (cashHoursValues.length - nbLockedTeam));
    } else {
      cashHoursByTeamAsList?.forEach(([_, cashHoursValue]) => {
        normalizedWeightsWithoutLockedHoursWeights.push(cashHoursValue / totalCashHourstWithoutLockedHoursWeights);
      });
      normalizedWeights = normalizedWeightsWithoutLockedHoursWeights;
    }
  } else {
    normalizedWeights = cashHoursValues?.map(weight => weight / totalWeight);
  }
  return normalizedWeights;
};

/**
 * Distribute a value uniformly
 */
export const uniformDistribution = (value: number, length: number) => {
  const ratio = 1 / length;
  const ratioList = Array(length).fill(ratio);
  return ratioList.map(ratio => value * ratio);
};

/**
 * Builds an object by picking the listed keys from the initial object
 */
export const pick =
  <T, K extends keyof T>(...keys: K[]) =>
  (obj: T) =>
    fromEntries(keys.map(key => [key, obj[key]])) as Pick<T, K>;

/**
 * Refine typings for `Array.includes`
 *
 * Related to:
 * - https://github.com/microsoft/TypeScript/issues/26255#issuecomment-502899689
 * - https://stackoverflow.com/questions/53033854/why-does-the-argument-for-array-prototype-includessearchelement-need-the-same/53035048#53035048
 */
export const includes = <T, U extends T>(arr: readonly U[], elem: T): elem is U => {
  return arr.includes(elem as any);
};

/**
 * Refine typings for `Set.has`
 *
 * See includes notes above
 */
export const has = <T, U extends T>(data: Set<U>, searchElement: T): boolean => {
  return data.has(searchElement as any);
};
/**
 * Extract from an array the elements at the given indices
 */
export const take = <T>(arr: readonly T[], indices: number[]): T[] => indices.map(i => arr[i]);

/**
 * Exclude from an array the elements at the given indices
 */
export const exclude = <T>(arr: readonly T[], indices: number[]): T[] =>
  arr.filter((_, index) => !includes(indices, index));

/**
 * Detect differences between two sets
 *
 * It returns a tuple where the first element represents the missing entries in the first set, and
 * the second element the missing entries in the second set.
 */
export const diffSet = <T extends Set<any>>(left: T, right: T): [Set<any>, Set<any>] => {
  const missingLeft = new Set();
  const missingRight = new Set();

  left.forEach(v => {
    if (!right.has(v)) {
      missingRight.add(v);
    }
  });
  right.forEach(v => {
    if (!left.has(v)) {
      missingLeft.add(v);
    }
  });

  return [missingLeft, missingRight];
};

/**
 * Check if two records have the same keys and the same values
 */
export const recordEquality = <T>(left: Record<string, T>, right: Record<string, T>): boolean => {
  // should have the same keys
  const keyLeft = keys(left);
  const keyRight = keys(right);
  if (keyLeft.length !== keyRight.length) {
    return false;
  }
  return keyLeft.every(key => left[key] === right[key]);
};

/**
 * Detect modified values between two records and return the corresponding keys
 */
export const recordDiff = <L extends Record<string, any>, R extends Record<string, any>>(
  left: L,
  right: R
): (keyof L | keyof R)[] => {
  const consumedRight = { ...right };

  const modifiedKeys = Object.keys(left).reduce((keys, key) => {
    if (!(key in right)) {
      keys.push(key);
    } else {
      delete consumedRight[key as keyof R];
      if (left[key] !== right[key as keyof R]) {
        keys.push(key);
      }
    }
    return keys;
  }, []);

  const newKeys = keys(consumedRight);

  return [...modifiedKeys, ...newKeys];
};

/**
 * Utility to check for exhaustiveness in switch statements
 */
export const assertNever = (x: never): never => {
  throw new Error(`The value ${x} was not expected here.`);
};

/**
 * Generates an array of `size` integers starting at `start`
 */
export const range = (start: number, size: number): number[] =>
  Array.from({ length: size }).reduce<number[]>((r, _, index) => {
    r.push(index + start);
    return r;
  }, []);

/**
 * Returns the supposedly equal length from a list of arrays
 *
 * Throws if not all arrays have the same length
 */
export const sameLength = (...arrList: any[]) => {
  const length = arrList[0].length;
  for (const arr of arrList.slice(1)) {
    if (arr.length !== length) {
      throw new Error(`All arrays are expected to have the same length`);
    }
  }
  return length;
};

/**
 * Provide a ceil value with a given number of decimals
 *
 * Examples:
 * ceil(6.6, 1) = 6.6
 * ceil(6.61, 1) = 6.7
 * ceil(6.69, 1) = 6.7
 */
export const ceil = (x: number, decimals: number) => {
  if (!(decimals > 0) || Math.floor(decimals) !== decimals) {
    throw new Error("Computing the ceil value require the number of decimals to be a positive integer");
  }
  const exp = 10 ** decimals;
  return Math.ceil(x * exp) / exp;
};

/**
 * Check if an object is a superset of another object
 */
export const contains = (ref: any, patch: any): boolean =>
  Object.entries(patch).every(([key, value]) => key in ref && ref[key] === value);

/**
 * Utility function to sum values by property from an array of records
 */
export const sumRecords = <I extends string>(items: Array<Record<I, number>>): Record<I, number> => {
  const [start, ...other] = items;
  const total = { ...start };
  for (const item of other) {
    for (const [key, value] of Object.entries(item)) {
      if (!(key in total)) {
        total[key] = value;
      } else {
        total[key] += value;
      }
    }
  }
  return total;
};

const rWeekId = /^\d{4}W\d{2}$/;
/**
 * Check if a string is valid week id
 */
export const isValidWeekId = (w: string) => rWeekId.test(w);

/**
 * Group an array of items by computing dynamically an id
 */
export const groupBy = <T, U extends string>(arr: T[], fn: (item: T) => U) => {
  const by: Partial<Record<U, T>> = {};
  for (const item of arr) {
    const key = fn(item);
    by[key] = item;
  }
  return by as Record<U, T>;
};

/**
 *
 * Transpose an array of arrays
 *
 * Eg. output[i][j] = input[j][i]
 */
export const transpose = <T>(cols: Array<Array<T>>): Array<Array<T>> => {
  if (cols.length === 0) return [];
  // use the first col to create empty rows
  const [firstCol] = cols;
  const nbCols = cols.length;
  const nbRows = firstCol.length;
  return Array.from({ length: nbRows }, (_, i) => Array.from({ length: nbCols }, (_, j) => cols[j][i]));
};

/**
 * Determines if two numbers are close enough to be considered equal.
 *
 * Convenient to take care of effects of number rounding.
 */
export const closeTo = (a: number, b: number, precision = 0.1) => Math.abs(a - b) <= precision;

/**
 * Combine multiple arrays into one
 *
 * ```js
 * console.log(combine(['a', 'b'], ['c', 'd']))
 * // ['a', 'c', 'b', 'd']
 * ```
 */
export const combine = <T>(...listOfArr: Array<Array<T>>) => {
  // other option: `return zip(...listOfArr).flat(1)`
  if (listOfArr.length === 0) {
    throw new Error("At least one array should be provided");
  }

  const firstArr = listOfArr[0];
  const nbItems = firstArr.length;

  if (!listOfArr.every(arr => arr.length === nbItems)) {
    throw new Error("All arrays should have the same size");
  }

  return firstArr.flatMap((_, index) => listOfArr.map(arr => arr[index]));
};

/**
 * Parse a string and return a valid number or NaN
 */
export const parseNumberInput = (valueAsStr: string) => Number(valueAsStr.replace(",", "."));

/**
 * Get decimal and thousand separator for a given locale
 */
export const getNumberFormat = (locale: string = window.navigator.language) => {
  const info = Intl.NumberFormat(locale).formatToParts(1111.1);
  const decimal = info.find(part => part.type === "decimal")?.value || ".";
  const thousand = info.find(part => part.type === "group")?.value || "";
  return { decimal, thousand };
};
