import { FilterFormat } from "../definitions";

export const FilterFormats: {
  number: FilterFormat<number>;
  string: FilterFormat<string>;
} = {
  number: {
    stringify: num => String(num),
    parse: str => parseInt(str, 10)
  },
  string: {
    stringify: str => String(str),
    parse: str => str
  }
};
