// import memoize from "memoize-one";
import { memoize } from "lodash";

import {
  FormulaAttribute,
  IRows,
  IPathRow,
  IParentsMapping,
  IRowCell,
  updateRowCellValue,
  updateComputedRowsCells,
  getFormulaParams,
  evalFormula,
  IRowsData,
  IComputedCells,
  IPathRowWithIndex,
  getIdsFromFormula,
  ICache,
  IImpactedRow,
  IParams,
  TypedValue,
  updateRowCellType,
  IRow
} from "./index";
import { getObjectKeysSortedByIndex } from "../common";

/**
 * A hidden row can't be a parent or a child
 */
export const getParentsMapping = memoize((rows: IRows<IPathRow>): IParentsMapping => {
  const parentsPath: IParentsMapping = {};
  Object.keys(rows).forEach(rowId => {
    const row = rows[rowId];
    const children = row.children || [];
    children.forEach(subItemId => {
      parentsPath[subItemId] = parentsPath[subItemId] || [];
      parentsPath[subItemId].push(rowId);
    });
  });
  return parentsPath;
});

/** return rows ids that are directly displayed in table (first level parents rows) */
export const getRoots = (rows: IRows<IPathRowWithIndex>, parentsMapping: IParentsMapping) => {
  return getObjectKeysSortedByIndex(rows).filter(rowId => !rows[rowId].is_hidden && !parentsMapping[rowId]);
};

export const getFormula = (formulas: string[], rowId: string) => {
  return formulas.find(formula => getIdsFromFormula([formula]).includes(rowId));
};

/**
 * Return formula according to the formula attribute and the source row id present in it
 * @param formulaAttribute
 * @param sourceRowId source row id that should be in the formula
 * @param row row on which we want to get formula
 */
export const getFormulaAccordingToFormulaAttribute = (
  formulaAttribute: FormulaAttribute,
  sourceRowId: string,
  row: IImpactedRow
) => {
  const { formulas: indicatorFormula, aggregate_formula: aggregateFormula } = row;
  const formattedAggregateFormula = !aggregateFormula ? [] : [aggregateFormula];
  const formulaList = formulaAttribute === "formula" ? indicatorFormula : formattedAggregateFormula;
  return formulaList ? getFormula(formulaList, sourceRowId) : "";
};

/**
 * Return impacts mapping according to the formula attribute
 * @param cache
 * @param formulaAttribute
 */
export const getImpactsMappingAccordingToFormulaAttribute = (
  cache: ICache,
  formulaAttribute: "formula" | "aggregate_formula" = "formula"
) => {
  const {
    impactsMapping: { rowsImpactsMapping, aggregatesImpactsMapping }
  } = cache;
  return formulaAttribute === "formula" ? rowsImpactsMapping : aggregatesImpactsMapping;
};

export const getFormulaResult = (
  formula: string,
  formulaParams: IParams,
  initialValue: number,
  currentAccount: IRow
) => {
  let formulaResult = evalFormula(formula, formulaParams);
  // format formula result if not a finite number or equal to 0

  if (formulaResult === null) {
    formulaResult = initialValue === null ? initialValue : 0;
  }

  if (!currentAccount?.mask?.is_negative && formulaResult < 0) {
    formulaResult = 0;
  }

  return formulaResult;
};

export const getUpdatedRowsData = <IGenericRows extends IRows = IRows>(
  rows: IGenericRows,
  computedRowsCells: IComputedCells[],
  rowCell: IRowCell,
  type: TypedValue = TypedValue.PilotedManually,
  cache: ICache,
  formulaAttribute: "formula" | "aggregate_formula" = "formula"
): IRowsData<IGenericRows> => {
  const impactsMapping = getImpactsMappingAccordingToFormulaAttribute(cache, formulaAttribute);
  const { rowId, columnId, value } = rowCell;
  let updatedRowsData = rows;

  let newComputedRowsCells: IComputedCells[] = [...computedRowsCells];
  const row = rows[rowId];
  const cell = row && row.cells[columnId];
  if (cell && cell.value !== value) {
    /** Modify the source row that will trigger the update of impacted rows*/
    updatedRowsData = updateRowCellValue(rows, rowCell, type) as IGenericRows;
    newComputedRowsCells = updateComputedRowsCells(newComputedRowsCells, rowId, columnId, value, type);
  } else if (cell?.type !== type) {
    /**
     * If the value has not been changed
     * But if the piloted mode has been changed (ex: from manually piloted to automatically piloted)
     * update computedRowsCells
     */
    updatedRowsData = updateRowCellType(rows, rowCell, type) as IGenericRows;
    newComputedRowsCells = updateComputedRowsCells(newComputedRowsCells, rowId, columnId, value, type);
  }
  /** Go through impacted rows triggered by the initial source row id*/

  impactsMapping[rowId]?.forEach(branchSourceRow => {
    /**
     * Each impactsMapping[rowId] contains one or several branches of impacted rows ids sorted by their weight
     * Go through each branches of impacted rows ids
     */
    branchSourceRow.impactedRows.forEach(impactedRowId => {
      const updatedImpactedRow = updatedRowsData[impactedRowId];
      const { cells } = updatedImpactedRow;
      // FIX : add aggregates to cells to avoid undefined error
      if (cells[columnId] === undefined) {
        return;
      }
      // the initial_value of the impacted cell
      const { initial_value } = cells[columnId];
      /** get the impacted formula triggered by branch source row computation and compute the formula result */
      const formula = getFormulaAccordingToFormulaAttribute(
        formulaAttribute,
        branchSourceRow.label_code,
        updatedImpactedRow
      );
      const formulaParams = getFormulaParams(formula, updatedRowsData, columnId);
      const formulaResult = getFormulaResult(formula, formulaParams, initial_value, updatedImpactedRow);
      /** If the new impacted row value is different from its current value, update rows data with new impacted value */
      const impactedRow = updatedRowsData[impactedRowId];
      const impactedCell = impactedRow && impactedRow.cells[columnId];

      if (impactedCell && impactedCell.value !== formulaResult) {
        const newImpactedRow: IRowCell = { rowId: impactedRowId, columnId, value: formulaResult };
        updatedRowsData = updateRowCellValue(updatedRowsData, newImpactedRow, type) as IGenericRows;
        newComputedRowsCells = updateComputedRowsCells(
          newComputedRowsCells,
          impactedRowId,
          columnId,
          formulaResult,
          type
        );
      }
    });
  });
  return { updatedRows: updatedRowsData, updatedComputedRowsCells: newComputedRowsCells };
};
