import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { UseQueryOptionsWithoutQueryKey } from "../../../react-query-config";

export type ApiGetCountryComparisonIndicatorsMappingResponse = {
  account_label: string;
  account_country_label: string;
  need_superior: boolean;
};

export const countryComparisonIndicatorsMappingEndPoint = "country_comparison_indicators_mapping_v2";
export const QUERY_KEY_ACCOUNT_COUNTRY_RELATION = "get-account-country-relation";

export const getAccountCountryRelationFn = async (
  endpoint: string
): Promise<ApiGetCountryComparisonIndicatorsMappingResponse[]> => {
  const response = await axios.get(endpoint);
  return response?.data ?? { content: [] };
};

export const useApiGetCountryComparisonIndicatorsMapping = (
  options?: UseQueryOptionsWithoutQueryKey<ApiGetCountryComparisonIndicatorsMappingResponse[], Error>
) =>
  useQuery<ApiGetCountryComparisonIndicatorsMappingResponse[], Error>({
    queryKey: [QUERY_KEY_ACCOUNT_COUNTRY_RELATION, countryComparisonIndicatorsMappingEndPoint],
    queryFn: () => getAccountCountryRelationFn(countryComparisonIndicatorsMappingEndPoint),
    staleTime: Infinity,
    ...options
  });
