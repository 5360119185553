import { Fragment } from "react";

import SportsGroupItem from "components/specific/favorite-sports/sport-group-item";
import StoresGroupItem from "components/specific/favorite-stores/store-group-item";

import { StoresGroup } from "../../../../core/models/stores-group";
import { ISportsGroup } from "core/models/api/sports-group/sports-group-piloting";

interface GroupsReadProps {
  groups: Array<ISportsGroup> | Array<StoresGroup>;
  onEditGroup: (any) => void;
  groupSelected: "sport" | "store";
  informationWhenIsEmpty?: string;
}

const GroupsRead = ({ groups, onEditGroup, groupSelected }: GroupsReadProps) => {
  return (
    <div className="mg-groups-read">
      {groups.length !== 0 &&
        groups.map(group => {
          return (
            <Fragment key={group.id}>
              {groupSelected == "sport" ? (
                <SportsGroupItem key={group.id} sportsGroup={group} onEditSportsGroup={onEditGroup} />
              ) : (
                <StoresGroupItem key={group.id} storesGroup={group} onEditStoresGroup={onEditGroup} />
              )}
            </Fragment>
          );
        })}
    </div>
  );
};

export default GroupsRead;
