import * as React from "react";
import { ICell as ITableCell, ITableProps } from "@decathlon/react-table";
import { IData } from "core/models/data";
import { ICell } from "core/utils/accounts";
import { IDataCoordinates } from "core/utils/cells";
import { IAbstractHeader } from "core/utils/headers";
import { IComputedCells, TypedValue } from "core/utils/computation-engine";

export interface ICellsValues {
  [columnId: string]: ICellValue;
}

export interface ICellValue {
  cellCoordinates: IDataCoordinates;
  value: number;
  type?: TypedValue;
}

export interface IDataValidation {
  status: boolean;
  message?: string;
}

export interface IOrder {
  orderId: string;
  itemsOrder: Record<string, number>;
}

export type UpdateTableRows = (
  cells: IDataCoordinates[],
  updater: (
    currentCell: ITableCell<IDataCoordinates>,
    dataCoordinates?: IDataCoordinates
  ) => ITableCell<IDataCoordinates>
) => void;

export type SetCellsValues = (cells: ICellValue[], confirmUpdate?: boolean) => void;

export interface IDataManagerContext {
  hasDataBeenChanged: boolean;
  hasValidData: IDataValidation;
  data?: IData;
  tableProps?: Pick<ITableProps<IDataCoordinates>, "id" | "rows" | "columns">;
  overriddenContext?;
  isSaveInProgress: boolean;
  rowsCellsToSave?: IComputedCells[];
  currentColumnsOrder?: IOrder;
  hasCell?: ({ rowId, columnId }: IDataCoordinates) => boolean;
  getColumnHeader?: (columnId: string) => IAbstractHeader | undefined;
  getCell?: (dataCoordinates: IDataCoordinates) => ICell;
  setCellValue?: (dataCoordinates: IDataCoordinates, value: number, type?: TypedValue, confirmUpdate?: boolean) => void;
  setCellsValues?: SetCellsValues;
  updateTableRows?: UpdateTableRows;
  saveData?: (onSuccess?: () => void, onCancel?: () => void, onError?: () => void) => void;
  toEditableCells?: (
    cellCoordinates: IDataCoordinates[],
    component?: React.ComponentClass | React.FunctionComponent
  ) => void;
  toDefaultCells?: (cellCoordinates: IDataCoordinates[]) => void;
  sortTableColumns?: (order: IOrder) => void;
  resetTableColumnsOrder?: () => void;
  sortStatus?: string;
  getSortStatus?: () => string;
  setSortStatus?: (asc: string) => void;
  isLoading?: boolean;
}

export const initialContext: IDataManagerContext = {
  hasDataBeenChanged: false,
  hasValidData: { status: true },
  isSaveInProgress: false
};

export const createContext = () => React.createContext<IDataManagerContext>(initialContext);

export const DataManagerContext = React.createContext<IDataManagerContext>(initialContext);
DataManagerContext.displayName = "DataManagerContext";
