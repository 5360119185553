import { useCallback, useEffect, useMemo, useState } from "react";
import { Store } from "core/models/store";
import SelectCountry from "../upward-forecast/select-country";
import { SelectStore } from "../upward-forecast/data-manager/form/store-cell";
import { I18n } from "react-redux-i18n";

export interface IFavoriteStoreFieldProps {
  onAddFavoriteStores: (value: number[]) => void;
  disabledItemsIds?: number[];
  storeList: Store[];
}

const FavoriteStoreFields: React.FunctionComponent<IFavoriteStoreFieldProps> = ({
  onAddFavoriteStores,
  disabledItemsIds,
  storeList
}) => {
  const defaultCountry = useMemo(() => storeList?.[0]?.country_id ?? null, [storeList]);
  const [country, setCountry] = useState<string>(defaultCountry);
  const [favoriteStoresIds, setFavoriteStoresIds] = useState<number[]>([]);

  useEffect(() => {
    setCountry(defaultCountry);
  }, [defaultCountry]);

  const handleCountryChange = useCallback((value: string) => {
    setCountry(value);
    setFavoriteStoresIds([]);
  }, []);

  const handleStoreChange = useCallback((storesIds: number[]) => {
    setFavoriteStoresIds(storesIds);
  }, []);

  const handleValidation = useCallback(() => {
    onAddFavoriteStores(favoriteStoresIds);
    setFavoriteStoresIds([]);
  }, [onAddFavoriteStores, favoriteStoresIds]);

  return (
    <div className="favorite-stores-fields">
      <SelectCountry value={country} onChange={handleCountryChange} isDisabled={storeList && storeList.length >= 1} />
      {country ? (
        <div className="mg-select-and-button-wrapper">
          <SelectStore
            id="user-preferences_select_store"
            storeId={favoriteStoresIds}
            countryId={country}
            onChange={handleStoreChange}
            hasBottomLine
            disabledItemsIds={disabledItemsIds}
            isMultiple
          />
          <button
            data-testid="filters-add-btn"
            className="vtmn-btn vtmn-btn_variant--primary vtmn-btn_size--small"
            onClick={handleValidation}
            disabled={favoriteStoresIds.length === 0}
          >
            {I18n.t("add")}
          </button>
        </div>
      ) : null}
    </div>
  );
};
export default FavoriteStoreFields;
