import { Draggable, DraggableProvided, DroppableProvided } from "react-beautiful-dnd";
import { ISport } from "core/api/rest/queries/select-sport";
import SportsGroupFavoriteItem from "./sport-group-favorite-item";

export interface ISportsGroupFavoriteListProps {
  className?: string;
  sportList: ISport[];
  type: string;
  onRemoveSport?: (sportIndex: number) => void;
  dropProvided?: DroppableProvided;
  isDragDisabled?: boolean;
}

const SportsGroupFavoriteList = ({
  sportList,
  dropProvided,
  onRemoveSport,
  type,
  isDragDisabled
}: ISportsGroupFavoriteListProps) => {
  const droppableProps = dropProvided ? dropProvided.droppableProps : {};
  return (
    <div
      className="mg-subheader-form-composition__column__list mg-list-sport-group
        "
      ref={dropProvided && dropProvided.innerRef}
      {...droppableProps}
    >
      {sportList &&
        sportList.map((sport: ISport, index: number) => (
          <Draggable
            key={`${type}-${sport.id}-${index}`}
            draggableId={sport.id.toString()}
            index={index}
            isDragDisabled={isDragDisabled}
          >
            {(dragProvided: DraggableProvided) => (
              <div>
                <SportsGroupFavoriteItem
                  sport={sport}
                  onRemoveSport={onRemoveSport}
                  dragProvided={dragProvided}
                  index={index}
                />

                {dragProvided.placeholder}
              </div>
            )}
          </Draggable>
        ))}

      {dropProvided && dropProvided.placeholder}
    </div>
  );
};

export default SportsGroupFavoriteList;
