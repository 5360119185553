import { useAuth } from "react-oidc-context";
import { useEffect, useState } from "react";

export const useAuthUserInfo = () => {
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  useEffect(() => {
    setUserInfo((user?.profile as UserInfo) ?? null);
  }, [user]);
  return userInfo;
};

export type UserInfo = {
  sub: string;
  aud: string;
  iss: string;
  iat: number;
  exp: number;
  entryUUID: string;
  preferredLanguage: string;
  role: string[];
  c: string;
  mail: string;
  sitepartynumber: string;
  displayName: string;
  givenName: string;
  cn: string;
  sitetype: string;
  title: string;
  objectclass: string[];
  uuid: string;
  allsites: string;
  uid: string;
  site: string;
  federation_idp: string;
  familyName: string;
  sitename: string;
  sn: string;
  partyNumber: string;
  costcenter: string;
  jobname: string;
  "pi.sri": string;
  sid: string;
};
