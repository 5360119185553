import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

export type IDailyTemplates = {
  template_id: number;
  key: string;
  template_label: string;
  template_order: number;
};

export const QUERY_KEY_TEMPLATES = "get-daily-templates";

const getApiDailyTemplates = async (): Promise<IDailyTemplates[]> => {
  const response = await axios.get("/daily_director_cex/templates");
  return response?.data;
};

export const useApiGetApiDailyTemplates = (options?: UseQueryOptionsWithoutQueryKey<IDailyTemplates[], Error>) => {
  return useQuery<IDailyTemplates[], Error>({
    queryKey: [QUERY_KEY_TEMPLATES],
    queryFn: () => getApiDailyTemplates(),
    staleTime: Infinity,
    ...options
  });
};
