import * as React from "react";
import { I18n } from "react-redux-i18n";
import { Button } from "@mui/material";

import Dialog from "components/common/modals/dialog";
import { IUpwardForecastHistoryItem } from "core/api/rest/queries/upward-forecast-history";
import HistoryList from "./history-list";
import HistorySkeleton from "./history-skeleton";

interface IHistoryDialogProps {
  title: string;
  data: IUpwardForecastHistoryItem[];
  loading: boolean;
  isOpen: boolean;
  toggleIsOpen: () => void;
}

const HistoryDialog: React.FunctionComponent<IHistoryDialogProps> = ({
  title,
  loading,
  data,
  isOpen,
  toggleIsOpen
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      id="history-dialog"
      PaperProps={{ className: "history-dialog" }}
      title={title}
      titleClassname="history-dialog__title"
      message={loading ? <HistorySkeleton /> : <HistoryList items={data} />}
      actions={[
        <Button key="validity-alert-close-action" onClick={toggleIsOpen}>
          {I18n.t("close")}
        </Button>
      ]}
      onClose={toggleIsOpen}
    />
  );
};

export default HistoryDialog;
