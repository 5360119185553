import { useEffect, useState } from "react";
import SubheaderPopover from "components/common/mygame/subheader/popover/subheader-popover";
import { I18n } from "react-redux-i18n";
import { GROUPS_MANAGEMENT_EMPTY, GROUPS_SPORTS, GROUPS_STORES } from "core/constants/translations/groups";
import { trackAnalyticsEvent } from "../../../../../core/hooks/useAnalytics";

const SubheaderGroups = ({ shortcutSportsGroups, shortcutStoresGroups }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [groupClicked, setGroupClicked] = useState();

  const handlePopover = group => {
    if (group !== groupClicked) setGroupClicked(group);
    setIsOpen(true);

    if (group === groupClicked) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen === false) {
      setGroupClicked(null);
    }
  }, [isOpen]);

  const handleClickShortcutManageGroups = (groupType: string) => {
    const googleAnalyticsAction =
      groupType === "sport" ? "click_shortcut_group_management_sport" : "click_shortcut_group_management_store";
    trackAnalyticsEvent(googleAnalyticsAction);
    handlePopover(groupType);
  };
  return (
    <>
      {shortcutSportsGroups || shortcutStoresGroups ? (
        <div className="mg-subheader-nav-groups__manage vtmn-typo_text-3">{I18n.t(GROUPS_MANAGEMENT_EMPTY)}</div>
      ) : null}

      {shortcutSportsGroups && (
        <div onClick={() => handleClickShortcutManageGroups("sport")}>
          <button id="trigger-popover-sports" className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small">
            {I18n.t(GROUPS_SPORTS)}
          </button>
        </div>
      )}

      {shortcutStoresGroups && (
        <div onClick={() => handleClickShortcutManageGroups("store")}>
          <button
            className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small mg-subheader-shortcut"
            id="trigger-popover-stores"
          >
            {I18n.t(GROUPS_STORES)}
          </button>
        </div>
      )}

      {isOpen && <SubheaderPopover groupClicked={groupClicked} setIsOpen={setIsOpen} isOpen={isOpen} />}
    </>
  );
};

export default SubheaderGroups;
