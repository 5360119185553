import { I18n } from "react-redux-i18n";

const Forbidden = () => (
  <div className="content-grid container-fluid not-found" data-testid="forbidden_page">
    <div className="row parent-headband">
      <div className="col-sm-12">
        <h3>{I18n.t("noAccess")}</h3>
      </div>
    </div>
  </div>
);

export default Forbidden;
