import logo from "core/assets/images/coach.svg";
import { VtmnButton } from "@vtmn/react";
import { ICoachActions } from "core/api/coach/useApiGetCoach";
import { RouteId } from "core/routing/router/route-ids";
import { useNavigate } from "react-router";
import { getPathFromRouteId } from "core/utils/route-utils";
import { COACH_GREETING, COACH_LATER } from "core/constants/translations";
import { I18n } from "react-redux-i18n";
import { useApiMenuGetUserPreferences } from "core/api/menu/useApiMenuGetUserPreferences";
import { trackAnalyticsEvent } from "../../../../core/hooks/useAnalytics";

type ICoachAvatar = {
  setAvatarIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  trajectoryActions: ICoachActions;
};
export const CoachAvatar = ({ setAvatarIsVisible, trajectoryActions }: ICoachAvatar) => {
  const { data: user } = useApiMenuGetUserPreferences();
  const navigate = useNavigate();
  const actionTitle1 = trajectoryActions?.action_title.split("\n")?.[0];
  const actionTitleLine2 = trajectoryActions?.action_title.split("\n")?.[1];

  const handleFollowCoachClick = () => {
    trackAnalyticsEvent(`click_coach_follow_annual_init`);
    setAvatarIsVisible(false);
    navigate(`${getPathFromRouteId(RouteId.AnnualInitializationTeams)}?year=${trajectoryActions?.year}`);
  };

  const handleCancelCoachClick = () => {
    trackAnalyticsEvent(`click_coach_cancel`);
    setAvatarIsVisible(false);
  };

  return (
    <>
      <div className="mg-coach-avatar">
        <div className="mg-coach-avatar-block">
          <div className="mg-coach-avatar-block-informations">
            <div>
              {I18n.t(COACH_GREETING)} {user.user_name} 👋
            </div>
            <div>{actionTitle1}</div>
            {actionTitleLine2 && <span className="mg-coach-avatar-block-informations-moment">{actionTitleLine2}</span>}
          </div>
          <div className="mg-coach-avatar-block-buttons">
            <VtmnButton onClick={handleFollowCoachClick} style={{ color: "#3643ba" }} variant="secondary" size="small">
              {trajectoryActions?.call_to_action_title}
            </VtmnButton>
            <div className="mg-coach-avatar-button">
              <VtmnButton onClick={handleCancelCoachClick} style={{ backgroundColor: "#3643ba" }} size="small">
                {I18n.t(COACH_LATER)}
              </VtmnButton>
            </div>
          </div>
        </div>
      </div>
      <div className="mg-coach-avatar-block-image">
        <img alt="avatar-logo" src={logo} />
      </div>
    </>
  );
};
