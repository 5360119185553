import { Context, useContext } from "react";

import { FilterContext } from "../definitions";
import { FiltersContext } from "../context/context";

export const useFilters = <FiltersDefinition>() => {
  const context = useContext<FilterContext<FiltersDefinition>>(
    FiltersContext as Context<FilterContext<FiltersDefinition>>
  );

  if (!context) {
    throw new Error("FiltersContext needs to be implemented in component tree");
  }

  return {
    ...context
  };
};
