import { I18n } from "react-redux-i18n";

import { Icons } from "core/constants/icons";
import LogCardMessage from "./log-card-message";
import Button from "../buttons/button";

interface IProps {
  refetch?: () => void | null;
  error?: any;
}

const reload = () => {
  window.location.reload();
};

const ErrorCardMessage = ({ error, refetch = reload }: IProps) => {
  if (error) {
    console.error(error);
  }
  return (
    <LogCardMessage iconName={Icons.Error} messageKey="error.global.message">
      <Button className="dkt-button-size-small dkt-button-info" onClick={refetch}>
        {I18n.t("retry")}
      </Button>
    </LogCardMessage>
  );
};

export default ErrorCardMessage;
