import { SnapshotId } from "../definitions";
import { AppliedFilters } from "../hoc/types";

const getSnapshotPlaceholder = (id: SnapshotId) => `__mgf_snap(${id})`;

const saveSnapshotValues = (id: SnapshotId, values) => {
  sessionStorage.setItem(getSnapshotPlaceholder(id), JSON.stringify(values));
};

const saveFilterSnapshot = <T>(
  id: SnapshotId,
  filters: AppliedFilters<T>,
  withFilters: Array<keyof T> = Object.keys(filters) as (keyof T)[]
) => {
  const values = Object.entries(filters)
    .filter(f => withFilters.includes(f[0] as keyof T))
    .reduce((struct, pair) => {
      const [name, value] = pair as [string, unknown];
      struct[name] = value;
      return struct;
    }, {});
  saveSnapshotValues(id, values);
};

const getFilterSnapshot = (id: SnapshotId) => {
  const values = sessionStorage.getItem(getSnapshotPlaceholder(id));
  if (values) {
    return JSON.parse(values);
  }
  return null;
};

const getFilterSnapshots = (ids: Array<SnapshotId>) => {
  return ids.reduce((struct, id) => ({ ...struct, ...(getFilterSnapshot(id) || {}) }), {});
};

export { getFilterSnapshots, getFilterSnapshot, saveFilterSnapshot, getSnapshotPlaceholder, saveSnapshotValues };
