import { ReactElement } from "react";
import { InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import { I18n } from "react-redux-i18n";
import { IDataGroup } from "./index";

export type IData = Record<string, IDataGroup>;

export interface IChildrenProps {
  filteredData: IData;
}

export interface ISearchProps {
  id: string;
  onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>, filteredData: IData) => void;
  nbItems: number;
  nbFilteredItems: number;
  searchedText: string;
  validateSearch?: () => void;
  setSearchedText: (newText: string) => void;
  className?: string;
  placeholder?: string;
  children?: ReactElement;
}

const Search = ({
  id,
  placeholder,
  setSearchedText,
  searchedText,
  nbItems,
  nbFilteredItems,
  children
}: ISearchProps): ReactElement => {
  return (
    <>
      <div className="search-input" data-testid={id}>
        <TextField
          id="search"
          autoFocus
          fullWidth
          autoComplete="off"
          placeholder={placeholder}
          value={searchedText}
          onChange={e => setSearchedText(e.target.value)}
          data-testid={`${id}-input`}
          InputProps={{
            startAdornment: (
              <InputAdornment className="search-input__icon" position="start">
                <span className="material-icons-outlined notranslate">search</span>
              </InputAdornment>
            ),
            classes: { root: "search-input__text" }
          }}
        />
      </div>

      <div className="search-children" id={`${id}_menu`} data-testid={`${id}_menu`}>
        {children}
      </div>

      <div className="search-footer">
        <span className="highlighted-text">{nbFilteredItems}</span>
        {` / ${nbItems} ${I18n.t("search.results")}`}
      </div>
    </>
  );
};

Search.defaultProps = {
  placeholder: I18n.t("filters.search")
};

export default Search;
