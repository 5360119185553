import * as React from "react";
import { I18n } from "react-redux-i18n";
import { IErrors } from "pages/templates/templates";
import PanelItemType from "./settings-panel.d";
import { TEMPLATES_FORM_BUTTON_DELETE } from "core/constants/translations/template";
import { GROUPS_SPORT_FORM_BUTTON_DELETE, GROUPS_STORE_FORM_BUTTON_DELETE } from "core/constants/translations/groups";

export interface IPanelFooterProps {
  onClose: () => void;
  item: PanelItemType;
  onSaveItem: () => void;
  isSaveDisabled: boolean;
  errors?: IErrors;
  canEdit: boolean;
  showDeleteConfirm: () => void;
  groupType: string;
}

const PanelFooter = ({
  onClose,
  item,
  onSaveItem,
  isSaveDisabled,
  canEdit,
  showDeleteConfirm,
  groupType
}: IPanelFooterProps) => {
  const isNew = React.useMemo(() => item && !item.id, [item]);

  return item ? (
    <div className="mg-groups-footer">
      <div className="mg-groups-footer__left">
        {canEdit && item && item.id ? (
          <button
            className="vtmn-btn vtmn-btn_size--small vtmn-btn_variant--secondary mg-button-delete"
            onClick={showDeleteConfirm}
          >
            {groupType == "Store" && I18n.t(GROUPS_STORE_FORM_BUTTON_DELETE)}
            {groupType == "Sport" && I18n.t(GROUPS_SPORT_FORM_BUTTON_DELETE)}
            {groupType == "Template" && I18n.t(TEMPLATES_FORM_BUTTON_DELETE)}
          </button>
        ) : null}
      </div>
      <div className="mg-groups-footer__right">
        <button className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small" onClick={onClose}>
          {I18n.t("cancel")}
        </button>
        <button
          className="vtmn-btn vtmn-btn_size--small"
          {...(!isSaveDisabled && { onClick: onSaveItem })}
          disabled={isSaveDisabled}
        >
          {isNew ? I18n.t("add") : I18n.t("save")}
        </button>
      </div>
    </div>
  ) : null;
};

export default PanelFooter;
