import { I18n } from "react-redux-i18n";
import { getRoute } from "../../menu-constants";

export const DndLink = ({ link }) => {
  const informations = getRoute(link.label);

  return (
    <>
      <div>
        <span
          className={`${
            informations.navigationMenuInformation.iconIsFilled
              ? "material-icons notranslate"
              : "material-icons-outlined notranslate"
          }`}
        >
          {informations.navigationMenuInformation.iconName}
        </span>
        <span>{I18n.t(informations.name)}</span>
      </div>
      <span className="material-icons-outlined notranslate">drag_indicator</span>
    </>
  );
};

export default DndLink;
