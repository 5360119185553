import { lazy } from "react";
// fix(MG-3600): lazy chunk loading between deployments fallback
// each import fallbacks into a function that reload the app if the chunk is not found
// it could happen after a deployment while the user hasn't reloaded its page
const chunkFailFallback = () => {
  window.location.reload();
  return null;
};

const EventsPiloting = lazy(() => import("pages/events/events-piloting").catch(chunkFailFallback));
const GroupsCreation = lazy(() => import("pages/groups-creation/groups-creation").catch(chunkFailFallback));
const Templates = lazy(() => import("pages/templates/templates").catch(chunkFailFallback));
const UserPreferences = lazy(() => import("pages/user-preferences/user-preferences").catch(chunkFailFallback));
const AnnualInitializationPiloting = lazy(() =>
  import(
    "pages/sport-piloting/annual-initialization-piloting/annual-initialization-piloting-by-groups-of-sports"
  ).catch(chunkFailFallback)
);
const AnnualInitializationTeamsPiloting = lazy(() =>
  import("pages/sport-piloting/annual-initialization-piloting/annual-initialization-piloting-by-teams").catch(
    chunkFailFallback
  )
);
const AreasPiloting = lazy(() => import("pages/sport-piloting/areas-piloting").catch(chunkFailFallback));
const ComparisonSportGroups = lazy(() =>
  import("pages/sport-piloting/comparison/comparison-by-sport-group").catch(chunkFailFallback)
);
const ComparisonSports = lazy(() =>
  import("pages/sport-piloting/comparison/comparison-by-sports").catch(chunkFailFallback)
);
const ComparisonStores = lazy(() =>
  import("pages/sport-piloting/comparison/comparison-by-stores").catch(chunkFailFallback)
);
const ComparisonTeams = lazy(() =>
  import("pages/sport-piloting/comparison/comparison-by-teams").catch(chunkFailFallback)
);
const DailyPiloting = lazy(() => import("pages/sport-piloting/daily-piloting").catch(chunkFailFallback));
const DailyPilotingStore = lazy(() =>
  import("pages/sport-piloting/daily-piloting/daily-piloting-store").catch(chunkFailFallback)
);
const DailyPilotingTeam = lazy(() =>
  import("pages/sport-piloting/daily-piloting/daily-piloting-team").catch(chunkFailFallback)
);
const SportsPiloting = lazy(() =>
  import("pages/sport-piloting/groups-of-sports-piloting/groups-of-sports-piloting-by-groups-of-sports").catch(
    chunkFailFallback
  )
);
const SportsStoresPiloting = lazy(() =>
  import("pages/sport-piloting/groups-of-sports-piloting/groups-of-sports-piloting-by-stores").catch(chunkFailFallback)
);
const SportsTeamsPiloting = lazy(() =>
  import("pages/sport-piloting/groups-of-sports-piloting/groups-of-sports-piloting-by-teams").catch(chunkFailFallback)
);
const ManagerByMonth = lazy(() => import("pages/sport-piloting/monthly-manager-piloting").catch(chunkFailFallback));
const WeeklySportsGroupsPiloting = lazy(() =>
  import("pages/sport-piloting/weekly-piloting/weekly-piloting-by-groups-of-sports").catch(chunkFailFallback)
);
const WeeklyPiloting = lazy(() =>
  import("pages/sport-piloting/weekly-piloting/weekly-piloting-by-sports").catch(chunkFailFallback)
);
const WeeklyStoresPiloting = lazy(() =>
  import("pages/sport-piloting/weekly-piloting/weekly-piloting-by-stores").catch(chunkFailFallback)
);
const WeeklyTeamsPiloting = lazy(() =>
  import("pages/sport-piloting/weekly-piloting/weekly-piloting-by-teams").catch(chunkFailFallback)
);
const Planning = lazy(() => import("pages/planning/page").catch(chunkFailFallback));
const DatavizWeekly = lazy(() => import("pages/dataviz-weekly/dataviz-weekly").catch(chunkFailFallback));
const DatavizComparison = lazy(() => import("pages/dataviz-comparison/dataviz-comparison").catch(chunkFailFallback));

const ReportingUsageByCountryIFrame = lazy(() => import("pages/reporting-usage/reporting-usage-by-country"));
const ReportingHoursEfficiencyPerCountryIFrame = lazy(
  () => import("pages/reporting-hours/reporting-hours-efficiency-per-country")
);
const ReportingHoursEfficiencyPerCountryProbableYearIFrame = lazy(
  () => import("pages/reporting-hours/reporting-hours-efficiency-per-country-probable-year")
);
const ReportingQualityForecastingIFrame = lazy(
  () => import("pages/reporting-quality-forecasting/ReportingQualityForecasting")
);
const ReportingEffitimeReliabilityIFrame = lazy(
  () => import("pages/reporting-effitime/ReportingEffitimeReliabilityIFrame")
);
const EffitimeIframe = lazy(() => import("pages/effitime/effitime"));
const SalesPerformancesIframe = lazy(() => import("pages/sales-performances/sales-performances"));

const ReportingProcessManagement = lazy(
  () => import("pages/reporting-process-management/reporting-process-management")
);
const ReportingExtraction = lazy(() => import("pages/reporting-extraction/reporting-extraction"));
const ReportingExtractionMonthlyData = lazy(
  () => import("pages/reporting-extraction/reporting-extraction-monthly-data")
);
const ReportingExtractionDayDay = lazy(() => import("pages/reporting-extraction/reporting-extraction-dayday"));
const ReportingExtractionTrajectory = lazy(() => import("pages/reporting-extraction/reporting-extraction-trajectory"));
const ReportingExtractionWeekly = lazy(() => import("pages/reporting-extraction/reporting-extraction-weekly"));

const DayByDayHourByHourEmbeded = lazy(() => import("pages/daybyday-hourbyhour/DayByDayHourByHourEmbedded"));
const SalesAnimationEmbedded = lazy(() => import("pages/embedded-tableau/sales-animation/sales-animation"));
const HomeNew = lazy(() => import("pages/home/home-new"));

const ErrorPage = lazy(() => import("pages/error/error"));
const Announcement = lazy(() => import("pages/announcement/announcement"));
const HoursCommercial = lazy(() => import("pages/hours/hours-piloting-commercial"));
const HoursCashier = lazy(() => import("pages/hours/hours-piloting-cashier"));
const HoursGeneralServices = lazy(() => import("pages/hours/hours-piloting-general-services"));

export {
  EventsPiloting,
  Templates,
  UserPreferences,
  AnnualInitializationPiloting,
  AnnualInitializationTeamsPiloting,
  AreasPiloting,
  ComparisonSportGroups,
  ComparisonSports,
  ComparisonStores,
  ComparisonTeams,
  DailyPiloting,
  DailyPilotingStore,
  DailyPilotingTeam,
  SportsPiloting,
  SportsStoresPiloting,
  SportsTeamsPiloting,
  ManagerByMonth,
  WeeklySportsGroupsPiloting,
  WeeklyPiloting,
  WeeklyStoresPiloting,
  WeeklyTeamsPiloting,
  DatavizWeekly,
  DatavizComparison,
  GroupsCreation,
  Planning,
  ReportingUsageByCountryIFrame,
  ReportingHoursEfficiencyPerCountryIFrame,
  ReportingHoursEfficiencyPerCountryProbableYearIFrame,
  ReportingQualityForecastingIFrame,
  ReportingEffitimeReliabilityIFrame,
  EffitimeIframe,
  SalesPerformancesIframe,
  ReportingProcessManagement,
  ReportingExtraction,
  ReportingExtractionMonthlyData,
  ReportingExtractionDayDay,
  ReportingExtractionTrajectory,
  ReportingExtractionWeekly,
  DayByDayHourByHourEmbeded,
  SalesAnimationEmbedded,
  HomeNew,
  Announcement,
  HoursCommercial,
  HoursCashier,
  HoursGeneralServices,
  ErrorPage
};
