import { useCallback } from "react";
import { I18n } from "react-redux-i18n";
import Panel from "components/common/settings-panel/settings-panel";
import useSelector from "core/hooks/useSelector";
import { IErrors } from "pages/templates/templates";
import StoresGroupComposition from "./store-group-composition";
import { StoresGroup } from "../../../core/models/stores-group";
import { useApiDeleteStoresGroup } from "../../../core/api/stores-group/useApiDeleteStoresGroup";
import { getSynchronizedFilters } from "../../../core/utils/filters";
import { FilterKey, IFilters, PartialFilters } from "../../../core/redux/filters/reducers";
import { setFilters } from "../../../core/redux/filters/actions";
import { useDispatch } from "react-redux";
import { ReduxDispatch } from "../../../core/redux/store";
import { useApiPostStoresGroup } from "../../../core/api/stores-group/useApiPostStoresGroup";

interface IStoresGroupPanelProps {
  storesGroup: StoresGroup;
  onClose: () => void;
  setIsActiveTab: (name: string) => void;
  onStoresGroupChange: (storesGroup: StoresGroup | Partial<StoresGroup>) => void;
  errors?: IErrors;
}

interface IDumbStoresGroupPanelProps extends IStoresGroupPanelProps {
  onSave: () => void;
  onDelete: () => void;
  isLoading: boolean;
}

const DumbSportsGroupPanel = ({
  storesGroup,
  onClose,
  onStoresGroupChange,
  onSave,
  onDelete,
  isLoading,
  errors
}: IDumbStoresGroupPanelProps) => {
  const isEditable = storesGroup ? !storesGroup.edit_disabled : true;

  const canBeSaved = useCallback(
    (hasBeenEdited: boolean) =>
      storesGroup ? hasBeenEdited && storesGroup.label && storesGroup.composition.length >= 2 : false,
    [storesGroup]
  );

  return (
    <Panel
      item={storesGroup}
      onClose={onClose}
      onSave={onSave}
      onDelete={onDelete}
      onChange={onStoresGroupChange}
      isLoading={isLoading}
      canEdit={isEditable}
      defaultTitle={I18n.t("settings.store_groups.new_store_group")}
      deleteActionLabel={I18n.t("settings.store_groups.delete_store_group")}
      deleteActionMessage={I18n.t("settings.store_groups.delete.confirm")}
      editTitle={I18n.t("settings.store_groups.title")}
      editFieldLabel={I18n.t("settings.store_groups.name_store_group")}
      checkCanBeSaved={canBeSaved}
      errors={errors}
      groupType="Store"
    >
      <>
        <label className="mg-groups-label vtmn-typo_text-3">
          {I18n.t("settings.store_groups.store_group_composition")}
        </label>

        <StoresGroupComposition
          isEditable={isEditable}
          storesGroup={storesGroup}
          onStoresGroupChange={onStoresGroupChange}
        />
      </>
    </Panel>
  );
};

const StoresGroupPanel = (props: IStoresGroupPanelProps) => {
  const { storesGroup, onClose, setIsActiveTab } = props;
  const sessionFilters = useSelector<PartialFilters>(state => state.filters);
  const dispatch = useDispatch<ReduxDispatch>();
  const postMutation = useApiPostStoresGroup();
  const deleteMutation = useApiDeleteStoresGroup();

  return (
    <DumbSportsGroupPanel
      onSave={async () => {
        await postMutation.mutateAsync(storesGroup);
        onClose();
        setIsActiveTab("read");
      }}
      onDelete={async () => {
        await deleteMutation.mutateAsync(storesGroup);
        if (storesGroup.id === sessionFilters.stores_group) {
          const newFilters = getSynchronizedFilters({ [FilterKey.STORES_GROUP]: null } as unknown as PartialFilters);
          const nextFilters = { ...sessionFilters, ...newFilters } as IFilters;
          dispatch(setFilters(nextFilters));
        }
        onClose();
        setIsActiveTab("read");
      }}
      isLoading={postMutation.isPending || deleteMutation.isPending}
      {...props}
    />
  );
};

export default StoresGroupPanel;
