import { FilterValidator } from "../definitions";

export const FilterValidators: {
  required: FilterValidator<unknown>;
} = {
  required: {
    isValid: value => value !== null && value !== undefined && (typeof value === "number" ? !isNaN(value) : true),
    error: "This field is required"
  }
};
