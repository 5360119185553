import * as React from "react";
import { IContentCellProps } from "@decathlon/react-table";
import { UpwardForecastExceptionManagerContext } from "components/specific/upward-forecast/data-manager/upward-forecast-exception-context";
import HistoryButton from "components/specific/upward-forecast/data-manager/actions/history/history-button";
import DeleteButton from "components/specific/upward-forecast/data-manager/actions/delete-button";
import AddButton from "components/specific/upward-forecast/data-manager/actions/add-button";

export interface IActionsCellProps extends IContentCellProps {
  exceptionId: number;
}

export const ActionsCell: React.FunctionComponent<IActionsCellProps> = ({ exceptionId }) => {
  const { newExceptionStoreId, getException } = React.useContext(UpwardForecastExceptionManagerContext);
  const exception = React.useMemo(() => getException(exceptionId), [exceptionId, getException]);
  return (
    <div>
      {!exceptionId ? (
        <AddButton isDisabled={!newExceptionStoreId} />
      ) : (
        <React.Fragment>
          <HistoryButton isDisabled={!exceptionId} exception={exception} />
          <DeleteButton isDisabled={!exceptionId} storeId={exception?.store_id} />
        </React.Fragment>
      )}
    </div>
  );
};

export default ActionsCell;
