import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

export interface IAllJobsViewsDto {
  id: number;
  label: string;
  route: IAllJobsViewsRouteDto[];
  editable: boolean;
  default: boolean;
  order: number;
}

export interface IAllJobsViewsRouteDto {
  index: number;
  label: string;
  type: string;
}

export const QUERY_KEY_JOBS_VIEWS = "get-jobs-views";

const getMenuData = async (): Promise<IAllJobsViewsDto[]> => {
  const response = await axios
    .get("/navigation_menu")
    .then(response => response.data)
    .then(res => {
      res.sort((a: any, b: any) => a.order - b.order);
      return res;
    })
    .then(r => {
      r.forEach((item: any) => {
        return item.route.sort((a: any, b: any) => a.index - b.index);
      });
      return r;
    });

  return response;
};

export const useApiMenuGetAllJobsViews = (options: UseQueryOptionsWithoutQueryKey<IAllJobsViewsDto[]> = {}) => {
  return useQuery<IAllJobsViewsDto[], Error>({
    queryKey: [QUERY_KEY_JOBS_VIEWS],
    queryFn: () => getMenuData(),
    staleTime: Infinity,
    ...options
  });
};
