import { IRow, ICell as ITableCell } from "@decathlon/react-table";
import classNames from "classnames";

import EditableCell, { IEditableCellProps } from "components/common/cells/editable-cell";
import { IAccounts, splitLabel, ICell } from "core/utils/accounts";
import { ACCOUNT_HEADER_ID } from "core/utils/headers";
import { formatValue, getClassName, getSubItemCells, isPositive, isNegative } from "core/utils/cells";
import { setAccountColumnIndex } from "core/utils/columns";
import { IData } from "core/models/data";
import { IMask } from "core/api/rest/queries";
import CellWithItems, { ICellItem } from "components/common/table/table-interactions-manager/cell-with-items";
import { getObjectKeysSortedByIndex } from "./common";
import { getCssConfiguration, isIndicatorProgression } from "./cssConfiguration";

export interface IGetMainColumnCellProps {
  accounts?: IAccounts;
  accountId?: string;
  accountLabelId?: string;
  isSubItem?: boolean;
  subItemsCells?: IRow[];
  accountLabel?: string;
}

export type GetCustomCellParams = {
  rowId: string;
  columnId: string;
  currentCell: ICell;
  mask: IMask;
  accounts?: IAccounts;
};

export interface IRowsGetterOptions {
  getEditableCell?: (cell: ICell, mask: IMask, rowId: string, columnId: string, id?: string) => ITableCell;
  getMainColumnCell?: (props: IGetMainColumnCellProps) => ITableCell;
  getCustomCell?: (params: GetCustomCellParams) => ITableCell;
  getGroupTitle?: (id: string) => string;
  getGroupColor?: (id: string) => string;
  getRowProps?: (rowId: string) => Partial<Omit<IRow, "id" | "cells">>;
  mainColumnId?: string;
  fixedRowsIds?: string[];
}

export enum ROWS {
  TOTAL = "TOTAL"
}

/** Get an editable cell from data cell */
export const getEditableCell = (
  { value, isEdited, initial_value }: Pick<ICell, "value" | "isEdited" | "initial_value">,
  mask: IMask,
  rowId: string,
  columnId: string,
  id: string = null,
  component = EditableCell
): ITableCell => {
  const dataCoordinates = {
    rowId,
    columnId
  };
  const cellContentProps: IEditableCellProps = {
    value: value as number,
    isEdited,
    initial_value,
    mask,
    formatValue
  };
  return {
    id: id ? `${id}_${rowId}_${columnId}` : `${rowId}_${columnId}`,
    dataCoordinates,
    cellContent: component,
    cellContentProps
  };
};

export const defaultGetMainColumnCell = ({
  subItemsCells,
  accounts,
  accountId,
  accountLabelId,
  accountLabel
}: IGetMainColumnCellProps): ITableCell => {
  const cellItems: ICellItem[] = [];
  if (accounts[accountId].is_editable) {
    return {
      id: accountLabelId,
      subItems: subItemsCells,
      cellContent: CellWithItems,
      cellContentProps: {
        id: accountLabelId,
        value: accountLabel,
        items: cellItems
      }
    };
  }

  return {
    id: accountLabelId,
    subItems: subItemsCells,
    cellContent: CellWithItems,
    cellContentProps: {
      value: accountLabel,
      items: cellItems
    }
  };
};

export const rowsGetterDefaultOptions: IRowsGetterOptions = {
  getEditableCell,
  getMainColumnCell: defaultGetMainColumnCell,
  mainColumnId: ACCOUNT_HEADER_ID,
  fixedRowsIds: []
};

/**
 * Used to give dynamic classnames to cells
 * @param tableCell
 */
export const getClassNameFalsePositiveIndicator = ({ value, className, cellContentProps }: ITableCell): string => {
  let cellValue: string | number = value ?? 0;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore cellContentProps is an object type
  if (cellContentProps?.value != null) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore cellContentProps is an object type
    cellValue = cellContentProps.value;
  }
  return classNames(className, { negative: isPositive(cellValue), positive: isNegative(cellValue) });
};

/** Get the cells which compose the rows for table */
export const getRowCells = (
  headerIds: string[],
  accountId: string,
  accounts: IAccounts,
  options: Pick<
    IRowsGetterOptions,
    "getEditableCell" | "getCustomCell" | "getMainColumnCell" | "mainColumnId"
  > = rowsGetterDefaultOptions
): ITableCell[] => {
  const getterOptions = { ...rowsGetterDefaultOptions, ...options };
  const { getEditableCell, getCustomCell, getMainColumnCell, mainColumnId } = getterOptions;

  return headerIds.map(headerId => {
    const account = accounts?.[accountId];
    const { cells, mask: rowMask, label, children } = account;
    const currentCell = cells[headerId];
    const mask = currentCell?.mask || rowMask;
    const accountLabel = (currentCell?.value && formatValue(currentCell.value, mask)) || label;
    const accountLabelId = `${label}_${headerId}`;
    const isMainAccountColumn = headerId === mainColumnId;

    if (isMainAccountColumn) {
      const subItemsCells = getSubItemCells(children, accounts, headerIds, options);
      return {
        ...getMainColumnCell({
          subItemsCells,
          accounts,
          accountId,
          accountLabelId,
          accountLabel
        }),
        className: getCssConfiguration(accountId, headerId)
      };
    } else if (!currentCell) {
      throw new Error(`No cell found for ${headerId} in account ${accountId}`);
    }

    const isPercentage = mask?.is_percentage;
    const isFalsePositive = mask?.is_false_positive;
    const getCustomClassName = mask?.is_percentage ? getClassName : undefined;
    const customCell = getCustomCell?.({ rowId: accountId, columnId: headerId, currentCell, mask, accounts });

    if (isPercentage && !isIndicatorProgression(accountId) && customCell) {
      customCell.className = getCssConfiguration(accountId, headerId);
      return customCell;
    }

    // custom cell
    if (customCell) {
      customCell.getClassName = getCustomClassName;
      customCell.className = getCssConfiguration(accountId, headerId);
      return customCell;
    }

    if (isPercentage && !isIndicatorProgression(accountId) && currentCell.is_editable) {
      const rowCell = getEditableCell(currentCell, mask, accountId, headerId);
      rowCell.className = getCssConfiguration(accountId, headerId);
      return rowCell;
    }

    // editable cell
    if (currentCell.is_editable) {
      const rowCell = getEditableCell(currentCell, mask, accountId, headerId);
      rowCell.getClassName = getCustomClassName;
      rowCell.className = getCssConfiguration(accountId, headerId);
      return rowCell;
    }

    // progression && false positive
    // if progression is negative it's a success indicator. Ex. diminution of costs
    if (isFalsePositive && isPercentage && isIndicatorProgression(accountId)) {
      return {
        id: accountLabelId,
        value: formatValue(currentCell.value, mask),
        getClassName: getClassNameFalsePositiveIndicator,
        className: getCssConfiguration(accountId, headerId)
      };
    }

    if (isPercentage && !isIndicatorProgression(accountId)) {
      return {
        id: accountLabelId,
        value: formatValue(currentCell.value, mask),
        className: getCssConfiguration(accountId, headerId)
      };
    }

    // regular cell
    return {
      id: accountLabelId,
      value: formatValue(currentCell.value, mask),
      getClassName: getCustomClassName,
      className: getCssConfiguration(accountId, headerId)
    };
  });
};

/** Get the rows which build the table with consolidated accounts and headers */
export const getRows = (data: IData, options: IRowsGetterOptions = rowsGetterDefaultOptions): IRow[] => {
  const getterOptions = { ...rowsGetterDefaultOptions, ...options };
  const { getEditableCell, getGroupTitle, getGroupColor, getCustomCell, getMainColumnCell, getRowProps, mainColumnId } =
    getterOptions;
  const { accounts, headers } = data;
  /** sort visible headers to display them with the good order from their index */
  const headerLabels = getObjectKeysSortedByIndex(headers);

  setAccountColumnIndex(headers[mainColumnId]?.index || 0);

  /** sort accounts to display them with the good order from their index **/
  const sortedAccountsLabels = getObjectKeysSortedByIndex(accounts);

  return sortedAccountsLabels.reduce((rows, accountId) => {
    const { is_hidden, is_child, are_children_fixed } = accounts[accountId];

    const isVisibleAndParentAccount = !is_hidden && !is_child;

    if (isVisibleAndParentAccount) {
      const groupTitle = getGroupTitle?.(accountId) || null;
      const groupColor = (groupTitle && getGroupColor && getGroupColor(groupTitle)) || null;
      const rowProps = getRowProps ? getRowProps(accountId) : {};
      const row: IRow = {
        id: accountId,
        cells: getRowCells(headerLabels, accountId, accounts, {
          getEditableCell,
          getCustomCell,
          getMainColumnCell,
          mainColumnId
        }),
        ...rowProps
      };

      if (are_children_fixed) row.fixSubRows = are_children_fixed;
      if (groupTitle) row.rowSpanProps = { title: groupTitle };
      if (groupColor) row.rowSpanProps.color = groupColor;

      rows.push(row);
    }
    return rows;
  }, []);
};

// [rowId, value] example: ["2019W06", 3]
export type IRowMappingByValue = [string, number];

export const getRowsMappingSortedByDescendingValue = (
  accountLabelToTarget: string,
  columnLabelToTarget: string,
  accounts: IAccounts
): IRowMappingByValue[] =>
  Object.keys(accounts)
    .reduce((rows, label) => {
      const [groupLabel, accountLabel] = splitLabel(label);

      if (accountLabel === accountLabelToTarget && groupLabel !== ROWS.TOTAL) {
        const data = accounts[label];

        // get the target cell
        let cell = data.cells[columnLabelToTarget];

        // when target cell doesn't exist, get cell of the first cells column
        if (!cell) {
          const firstCellsColumn = Object.keys(data.cells)[0];
          cell = data.cells[firstCellsColumn];
        }

        // eslint-disable-next-line no-param-reassign
        rows = [...rows, [groupLabel, cell.value]];
      }

      return rows;
    }, [])
    .reverse()
    .sort((a, b) => b[1] - a[1]);
