import { FilterKey } from "../../../../../../core/redux/filters/reducers";
import { URLFilter } from "../index";
import { FilterFormats } from "../../formats/defaults";
import { FilterValidators } from "../../validations/defaults";
import { useApiMenuGetUserPreferences } from "../../../../../../core/api/menu/useApiMenuGetUserPreferences";

const countryFilter: { [FilterKey.COUNTRY]: URLFilter<string> } = {
  [FilterKey.COUNTRY]: {
    inUrl: true,
    useDefault: () => {
      const { data: user } = useApiMenuGetUserPreferences();
      return user.preferences.default_filters.country;
    },
    snapshot: {
      id: FilterKey.COUNTRY
    },
    urlConfig: {
      format: FilterFormats.string
    },
    validators: [FilterValidators.required]
  }
};

export default countryFilter;
