import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import IApiUserData from "core/models/api-user-data";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

export const QUERY_KEY_USER_PREFERENCES = "get-user-preferences";
const getUserPreferences = async (): Promise<IApiUserData> => {
  const response = await axios.get("/user");
  return response?.data;
};

export const useApiMenuGetUserPreferences = (options?: UseQueryOptionsWithoutQueryKey<IApiUserData, Error>) => {
  return useQuery<IApiUserData, Error>({
    queryKey: [QUERY_KEY_USER_PREFERENCES],
    queryFn: () => getUserPreferences(),
    staleTime: Infinity,
    ...options
  });
};
