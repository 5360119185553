import { I18n } from "react-redux-i18n";
import { MENU_MODAL_CREATE } from "core/constants/translations/menu";
import { useContext } from "react";
import { MenuContext } from "../menu";
import _ from "lodash";

const MenuConfigurationVue = () => {
  const {
    selectedView,
    modifiedData,
    handleOpenModal,
    viewId,
    setViewId,
    isEditing,
    openModal,
    modalCreateNotContinue,
    modalCreateContinue
  } = useContext(MenuContext);
  const handleClickCreate = () =>
    handleOpenModal(openModal, I18n.t(MENU_MODAL_CREATE), modalCreateNotContinue, modalCreateContinue);

  return (
    <div className="mg-menu-configuration-vue">
      {isEditing && selectedView?.default && (
        <div className="mg-menu-configuration-vue__text__edit" onClick={handleClickCreate}>
          <span className="material-icons-outlined notranslate">add</span>
        </div>
      )}
      <div
        data-testid="mg-menu-configuration-vue__roles"
        className={`mg-menu-configuration-vue__roles ${isEditing && "isEditing"}`}
      >
        {modifiedData?.map(item => {
          return (
            <div
              data-testid={`button-vue-role`}
              key={item.id}
              className="vtmn-chip vtmn-chip_variant--single-choice vtmn-chip_size--small"
              aria-pressed={item.id === viewId ? "true" : "false"}
              onClick={() => setViewId(_.cloneDeep(item.id))}
            >
              {I18n.t(item.label)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MenuConfigurationVue;
