import { Popover } from "@mui/material";
import React, { CSSProperties, useState } from "react";
import { I18n } from "react-redux-i18n";

export interface ITemplateSharePopoverProps {
  handlePopoverClose: any;
  anchorEl: any;
  linkToShare: any;
  onCopyLinkAction: any;
}
export const TemplateSharePopover: React.FunctionComponent<ITemplateSharePopoverProps> = ({
  handlePopoverClose,
  anchorEl,
  linkToShare,
  onCopyLinkAction
}) => {
  const [anchorElLinkCopied, setAnchorElLinkCopied] = useState<HTMLElement | null>(null);
  const handlePopoverOpenLinkCopied = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLinkCopied(event.currentTarget);
    onCopyLinkAction();
    setTimeout(() => {
      setAnchorElLinkCopied(null);
    }, 1000);
  };

  const handlePopoverCloseLinkCopied = () => {
    setAnchorElLinkCopied(null);
  };

  const openPopOverLinkCopied = Boolean(anchorElLinkCopied);

  const styleTitle: CSSProperties = {
    margin: "5px",
    padding: "0",
    display: "block",
    cursor: "auto",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  };

  const styleContainer: CSSProperties = {
    padding: "5px 10px",
    margin: "0",
    backgroundColor: "#FAFBFC",
    display: "inline-flex"
  };

  const styleFullWidth: CSSProperties = {
    width: "100%"
  };

  const styleCopyConfirm: CSSProperties = {
    backgroundColor: "rgb(78 176 237)",
    padding: "5px",
    color: "white"
  };

  const openPopOverShare = Boolean(anchorEl);

  return (
    <Popover
      id="mouse-over-popover"
      sx={{ zIndex: "1500", overflow: "auto", align: "center", pointerEvents: "fill" }}
      open={openPopOverShare}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <div aria-hidden="true" style={styleTitle}>
        <div style={{ margin: "10px" }}>{I18n.t("settings.templates.share-link-message")}</div>
        <div style={{ ...styleContainer, width: "100%" }}>
          <div style={styleFullWidth}>
            <input
              style={{
                ...styleFullWidth,
                backgroundColor: "rgb(233 236 238)",
                padding: "3px"
              }}
              value={linkToShare}
              readOnly
            ></input>
          </div>
          <div
            id="mg-share-button"
            className="mg-share-button"
            onClick={handlePopoverOpenLinkCopied}
            title={I18n.t("copy")}
          >
            <span id="shareButtonIcon" className="material-icons notranslate">
              content_copy
            </span>
          </div>
        </div>
        <Popover
          id="is-copied-popover"
          sx={{
            zIndex: "1600",
            overflow: "auto",
            align: "center",
            pointerEvents: "none"
          }}
          open={openPopOverLinkCopied}
          anchorEl={anchorElLinkCopied}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          onClose={handlePopoverCloseLinkCopied}
          disableRestoreFocus
        >
          <div style={styleCopyConfirm}>{I18n.t("settings.templates.copy.confirm")}</div>
        </Popover>
      </div>
    </Popover>
  );
};
