import * as React from "react";
import { I18n } from "react-redux-i18n";

import ErrorPage from "pages/errors/error-page";

class NotFound extends React.Component {
  public render() {
    return <ErrorPage title={I18n.t("error.http.not-found.title")}>{I18n.t("error.http.not-found.message")}</ErrorPage>;
  }
}

export default NotFound;
