import * as React from "react";
import { IForecastExceptionStore } from "core/api/rest/queries/upward-forecast";

interface IUpwardForecastExceptionManagerContext {
  updateException?: (storeId: number) => void;
  clearException?: () => void;
  saveNewException?: () => void;
  getException?: (exceptionId: number) => IForecastExceptionStore;
  newExceptionStoreId?: number;
  hasDataBeenChanged: boolean;
  isSaveInProgress: boolean;
}

export const initialContext: IUpwardForecastExceptionManagerContext = {
  hasDataBeenChanged: false,
  isSaveInProgress: false
};

export const UpwardForecastExceptionManagerContext =
  React.createContext<IUpwardForecastExceptionManagerContext>(initialContext);
