import { URLFilter } from "../definitions";
import { URLReflection } from "../definitions/reflections";
import { filtersEntries } from "./filters";
import { MutableRefObject } from "react";
import { FiltersContainer } from "../hoc/types";

export const buildFiltersURLParams = <T>(
  lastFilters: MutableRefObject<FiltersContainer<T>>,
  filters: FiltersContainer<T>
): { [param: string]: string } => {
  const buildFilterParams = (struct, pair) => {
    const [key, filter] = pair as [string, URLFilter<unknown>];
    let formatter = v => String(v);
    const urlConfig = filter.urlConfig || {};
    let value = filter.value;
    if (urlConfig) {
      if (urlConfig.format) {
        formatter = urlConfig.format.stringify || formatter;
      }
      // note: lastFilters are updated on apply so if this function is called after, url value is modified
      const reflection = urlConfig.reflection;
      if (!reflection || reflection === URLReflection.ON_APPLY) {
        value = lastFilters.current[key].value;
      }
    }
    if (value !== undefined && value !== null && String(value)) {
      struct[key] = formatter(value);
    }
    return struct;
  };

  return filtersEntries(filters)
    .filter(pair => pair[1].inUrl)
    .reduce(buildFilterParams, {});
};
