import { UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";

export const oidcConfig: UserManagerSettings = {
  authority: import.meta.env.REACT_APP_FEDID_URL,
  client_id: import.meta.env.REACT_APP_FEDID_CLIENT_ID,
  redirect_uri: window.location.origin + "/callback",
  response_type: "code",
  scope: "openid profile",
  filterProtocolClaims: true,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: window.localStorage })
};

export const CALLBACK_PATH = "/callback";
