import { ToastContainer } from "react-toastify";
import HeaderComponent from "./mygame/header/header";
import SubheaderComponent from "./mygame/subheader/nav/subheader";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../core/routing/router/routes";
import { I18n } from "react-redux-i18n";
import MenuComponent from "./mygame/menu/menu";
import { useEffect, useState } from "react";
import Coach from "./mygame/coach/coach";
import { AnnouncementBanner } from "./mygame/announcement/annoucement-banner";

const AppWrapper = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const url = useLocation().pathname;
  const subheaderProps = ROUTES.find(item => item.path === url);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(false);
    }, 400);
  }, [url]);

  return (
    <div className="mg-app-wrapper">
      <HeaderComponent isOpen={isOpen} setIsOpen={setIsOpen} />
      <AnnouncementBanner />
      <SubheaderComponent
        routeId={subheaderProps?.id}
        title={subheaderProps?.name && I18n.t(subheaderProps.name)}
        description={subheaderProps?.description && I18n.t(subheaderProps.description)}
        shortcutSportsGroups={subheaderProps?.shortcutSportsGroups}
        shortcutStoresGroups={subheaderProps?.shortcutStoresGroups}
        breadcrumbs={subheaderProps?.breadcrumbs}
        isVisibleSubheader={subheaderProps?.isVisibleSubheader}
      />
      <ToastContainer />
      <MenuComponent isOpen={isOpen} setIsOpen={setIsOpen} url={url} />
      {children}
      {<Coach />}
    </div>
  );
};

export default AppWrapper;
