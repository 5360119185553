import { ArrowContainer, Popover } from "react-tiny-popover";
import { useSelector } from "react-redux";
import {
  COUNTRY_CHOOSE_LANGUAGE,
  COUNTRY_CN,
  COUNTRY_DE,
  COUNTRY_ES,
  COUNTRY_FR,
  COUNTRY_HU,
  COUNTRY_IT,
  COUNTRY_PT,
  COUNTRY_UK
} from "../../../../core/constants/translations/country";
import { I18n } from "react-redux-i18n";
import { trackAnalyticsEvent } from "../../../../core/hooks/useAnalytics";

export const PopoverHeaderCountry = ({ isOpenPopover, setIsOpenPopover, buttonRef }) => {
  const languagesList = [
    { codeFlag: "gb", codeCountry: "en", name: COUNTRY_UK },
    { codeFlag: "fr", codeCountry: "fr", name: COUNTRY_FR },
    { codeFlag: "de", codeCountry: "de", name: COUNTRY_DE },
    { codeFlag: "it", codeCountry: "it", name: COUNTRY_IT },
    { codeFlag: "es", codeCountry: "es", name: COUNTRY_ES },
    { codeFlag: "pt", codeCountry: "pt", name: COUNTRY_PT },
    { codeFlag: "hu", codeCountry: "hu", name: COUNTRY_HU },
    { codeFlag: "cn", codeCountry: "zh", name: COUNTRY_CN }
  ];
  const languageSelected = useSelector((state: any) => state?.i18n?.locale);
  const languageSelectedWithAllInformations = languagesList.find(
    language => language.codeCountry === languageSelected.split("-")[0]
  ) || { codeFlag: "gb", codeCountry: "en", name: COUNTRY_UK };

  const handleCountryLanguage = (language: string) => {
    trackAnalyticsEvent(`click_navbar_change_language`);
    localStorage.setItem("mg-language", `${language}`);
    window.location.reload();
  };

  return (
    <Popover
      containerStyle={{ zIndex: "10000", overflow: "visible" }}
      isOpen={isOpenPopover}
      padding={10}
      onClickOutside={() => setIsOpenPopover(false)}
      positions={["bottom", "left"]}
      ref={buttonRef}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={"white"}
          arrowSize={10}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div className="mg-popover-header-country" onClick={() => setIsOpenPopover(!isOpenPopover)}>
            <div className="mg-popover-header-country-title">
              <span>{I18n.t(COUNTRY_CHOOSE_LANGUAGE)}</span>
            </div>
            {languagesList.map(language => (
              <div
                className="mg-popover-header-country-language"
                key={language.name}
                onClick={() => handleCountryLanguage(language.codeCountry)}
              >
                <span className={`fi fi-${language.codeFlag}`}></span>
                <span>{I18n.t(language.name)}</span>
              </div>
            ))}
          </div>
        </ArrowContainer>
      )}
    >
      <div
        data-testid="mg-button-header-country"
        onClick={() => setIsOpenPopover(!isOpenPopover)}
        className="mg-button-header"
      >
        <div className="mg-button-header__icon mg-button-header__icon__country">
          <span className={`fi fi-${languageSelectedWithAllInformations?.codeFlag}`}></span>
        </div>
        <span className="mg-button-header__text">{I18n.t(languageSelectedWithAllInformations?.name)}</span>
      </div>
    </Popover>
  );
};
