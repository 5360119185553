import { SetFilters } from "./filters/actions";

export type TeamSportByStoreState = Record<
  string, // store id
  {
    sport?: number;
    team?: number;
  }
>;

export interface SetTeamSportByStore {
  type: "SET_TEAM_SPORT_BY_STORE";
  payload: {
    sport?: number;
    team?: number;
    store: number;
  };
}

export const setTeamSportByStore = (
  store: number,
  { sport, team }: { sport?: number; team?: number }
): SetTeamSportByStore => {
  return {
    type: "SET_TEAM_SPORT_BY_STORE",
    payload: { store, sport, team }
  };
};

/**
 * Keep track of the last sport and teams selected for a given sport
 *
 * It makes it possible to select a relevant default value for the sport and the team when the store
 * selection changes.
 *
 */
const teamSportByStoreReducer = (state: TeamSportByStoreState = {}, action: SetFilters | SetTeamSportByStore) => {
  if (action.type === "SET_FILTERS") {
    const newState = { ...state };
    const { store_id, sport, team } = action.filters;
    const storeId = String(store_id);
    const storeInfo: { sport?: number; team?: number } = newState[storeId] || {};
    if (sport) {
      storeInfo.sport = sport;
    }
    if (team) {
      storeInfo.team = team;
    }
    newState[storeId] = storeInfo;
    return newState;
  } else if (action.type === "SET_TEAM_SPORT_BY_STORE") {
    const newState = { ...state };
    const { store, sport, team } = action.payload;
    const storeId = String(store);
    const storeInfo: { sport?: number; team?: number } = newState[storeId] || {};
    if (sport) {
      storeInfo.sport = sport;
    }
    if (team) {
      storeInfo.team = team;
    }
    newState[storeId] = storeInfo;
    return newState;
  }
  return state;
};

export default teamSportByStoreReducer;
