import { Children } from "react";
const CustomModal = ({ isOpen, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="mg-modal-overlay">
      <div className="mg-modal">
        {Children.map(children, child => (
          <>{child}</>
        ))}
      </div>
    </div>
  );
};

export default CustomModal;
