import { ICell, IColumnLabel, IColumns } from "@decathlon/react-table";
import { ICexAccount } from "core/api/rest/queries";
import { IFormattedAPIData, INDICATORS } from "./accounts";
import { IDataCoordinates } from "./cells";
import { IAccountHierarchy } from "./templates";

export enum COLUMNS {
  TEAM = "TEAM",
  TOTAL = "TOTAL",
  DIFFERENCE = "DIFFERENCE",
  PHOTO = "PHOTO"
}

let ACCOUNTS_COLUMN_INDEX = 0;

export const getRowUsedToSort = (
  data: IFormattedAPIData<ICexAccount>,
  templateAccountHierarchy: IAccountHierarchy[]
): ICexAccount & { index: number } => {
  let dataUsedToSort = data[INDICATORS.TURNOVER];
  if (!dataUsedToSort) {
    dataUsedToSort = data[templateAccountHierarchy.find(account => account.index === 1)?.account];
  }
  return dataUsedToSort;
};

/**
 * Used to get the account column index position
 * @return index position
 */
export const getAccountColumnIndex = (): number => ACCOUNTS_COLUMN_INDEX;

/**
 * Used to set the account column index position
 * @param index
 * @return index position
 */
export const setAccountColumnIndex = (index: number): number => (ACCOUNTS_COLUMN_INDEX = index);

export const getScrollableColumnsLabel = (headerCells: ICell<IDataCoordinates>[], fixedIndexes: number[] = []) => {
  return headerCells.reduce<IColumnLabel[]>((result, headerCell, index) => {
    // fix this condition
    const isScrollable = !fixedIndexes.includes(index);
    if (isScrollable) {
      const { cellContentProps } = headerCell;
      let title = headerCell.value;
      let value = "";
      if (cellContentProps) {
        // @ts-ignore cellContentProps is an object type without specified properties
        title = cellContentProps.title || cellContentProps.value;
        // @ts-ignore cellContentProps is an object type without specified properties
        value = cellContentProps.title ? cellContentProps.value : "";
      }
      // @ts-ignore cellContentProps is not undefined
      result.push({ id: headerCell.id, label: `${title ? `${title}` : ""}${value ? ` - ${value}` : ""}` });
    }
    return result;
  }, []);
};

export const getDefaultColumnsProps = (
  styleWith: number,
  styleJustifyContent: string,
  accountColumnIndex = 0
): IColumns => {
  return {
    [accountColumnIndex]: {
      size: styleWith,
      style: {
        justifyContent: styleJustifyContent
      }
    }
  };
};
