import * as React from "react";

import { WebInterface } from "core/constants/web-interfaces";
import { initialTemplatesSorted, TemplatesSorted, Visibility } from "core/models/template";
import { ITemplate } from "core/utils/templates";
import { useApiGetTemplates } from "../api/template/useApiGetTemplates";

export const getVisibleTemplate = (data: TemplatesSorted) => {
  return data[Visibility.IsVisible] || [];
};

export const getIncludeTemplates = (templates: ITemplate[], includeTemplates: WebInterface[]) => {
  return templates?.filter(template => template?.web_interface_list?.some(wi => includeTemplates?.includes(wi)));
};

export const getExcludeTemplates = (templates: ITemplate[], excludeTemplates: WebInterface[]) => {
  return templates.filter(template => !template.web_interface_list.some(wi => excludeTemplates.includes(wi)));
};

const useTemplates = (
  visibleMode?: Visibility,
  includeTemplates?: WebInterface[],
  excludeTemplates?: WebInterface[],
  removedTemplates?: ITemplate[],
  sharedTemplatesAdded?: ITemplate[]
): ITemplate[] => {
  /* istanbul ignore next */
  const data = useApiGetTemplates().data || initialTemplatesSorted;

  const templatesToSelect = React.useCallback(() => {
    return getVisibleTemplate(data);
  }, [data]);

  return React.useMemo(() => {
    let templates: ITemplate[] = [];
    if (visibleMode === Visibility.IsUsable) {
      templates = templatesToSelect();
    } else {
      templates =
        visibleMode !== undefined
          ? data[visibleMode] || []
          : [...data[Visibility.IsVisible], ...data[Visibility.NotVisible]];
    }
    if (includeTemplates && includeTemplates.length) {
      /* Test already on getIncludeTemplates */
      /* istanbul ignore next */
      templates = getIncludeTemplates(templates, includeTemplates);
    }
    if (excludeTemplates && excludeTemplates.length) {
      /* Test already on getExcludeTemplates */
      /* istanbul ignore next */
      templates = getExcludeTemplates(templates, excludeTemplates);
    }
    if (removedTemplates && removedTemplates.length) {
      templates = templates.filter(template => {
        return !removedTemplates.map(removedTemplate => removedTemplate?.id).includes(template.id);
      });
    }
    if (sharedTemplatesAdded && sharedTemplatesAdded.length) {
      templates = [...templates, ...sharedTemplatesAdded];
    }
    return templates;
  }, [
    visibleMode,
    includeTemplates,
    excludeTemplates,
    removedTemplates,
    sharedTemplatesAdded,
    templatesToSelect,
    data
  ]);
};

export default useTemplates;
