import { useMutation, useQueryClient } from "@tanstack/react-query";
import { StoresGroup } from "../../models/stores-group";
import { CreateStoresGroupDto, StoresGroupDto } from "../rest/mutations/stores-group-save";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { I18n } from "react-redux-i18n";
import {
  GROUPS_NOTIFICATION_STORES_CREATION_FAILED,
  GROUPS_NOTIFICATION_STORES_CREATION_SUCCEDED,
  GROUPS_NOTIFICATION_STORES_EDITION_FAILED,
  GROUPS_NOTIFICATION_STORES_EDITION_SUCCEDED
} from "../../constants/translations/groups";
import { QUERY_KEY } from "./useApiGetStoresGroup";

export const useApiPostStoresGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (storesGroup: StoresGroup) => {
      if (storesGroup.id) {
        const dto: StoresGroupDto = {
          id: storesGroup.id,
          label: storesGroup.label,
          composition: storesGroup.composition.map(item => {
            return {
              index: item.index,
              store_id: item.store_id
            };
          }),
          region_label: storesGroup.region_label,
          is_owner: storesGroup.is_owner
        };
        return axios.put(`/stores_groups/${storesGroup.id}`, dto);
      } else {
        const dto: CreateStoresGroupDto = {
          label: storesGroup.label,
          composition: storesGroup.composition.map(item => {
            return {
              index: item.index,
              store_id: item.store_id
            };
          }),
          region_label: storesGroup.region_label,
          is_owner: storesGroup.is_owner
        };
        return axios.post("/stores_groups", dto);
      }
    },
    onSuccess: (response: AxiosResponse) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      response.config.method === "post" && toast.success(I18n.t(GROUPS_NOTIFICATION_STORES_CREATION_SUCCEDED));
      response.config.method === "put" && toast.success(I18n.t(GROUPS_NOTIFICATION_STORES_EDITION_SUCCEDED));
    },

    onError: (response: AxiosResponse) => {
      response.config.method === "post" && toast.error(I18n.t(GROUPS_NOTIFICATION_STORES_CREATION_FAILED));
      response.config.method === "put" && toast.error(I18n.t(GROUPS_NOTIFICATION_STORES_EDITION_FAILED));
    }
  });
};
