export const DESCRIPTION_HOME = "pages.description.home";
export const DESCRIPTION_HOME_WEEKLY = "pages.description.home_weekly";
export const DESCRIPTION_HOME_COMPARISON = "pages.description.home_comparison";
export const DESCRIPTION_WEEKLY_BY_SPORTS = "pages.description.weekly.by_sports";
export const DESCRIPTION_WEEKLY_BY_SPORTS_GROUPS = "pages.description.weekly.by_sports_groups";
export const DESCRIPTION_WEEKLY_BY_TEAMS = "pages.description.weekly.by_teams";
export const DESCRIPTION_WEEKLY_BY_STORES = "pages.description.weekly.by_stores";
export const DESCRIPTION_SPORTS_BY_SPORTS_GROUPS = "pages.description.sports.by_sports_groups";
export const DESCRIPTION_SPORTS_BY_TEAMS = "pages.description.sports.by_teams";
export const DESCRIPTION_SPORTS_BY_STORES = "pages.description.sports.by_stores";
export const DESCRIPTION_ANNUAL_INITIALIZATION_BY_SPORTS_GROUPS = "pages.description.initialization.by_sports_groups";
export const DESCRIPTION_ANNUAL_INITIALIZATION_BY_TEAMS = "pages.description.initialization.by_teams";
export const DESCRIPTION_HOURS = "pages.description.hours";
export const DESCRIPTION_PLANNING = "pages.description.planning";
export const DESCRIPTION_DAYS_HOURS = "pages.description.days_hours";
export const DESCRIPTION_AREAS = "pages.description.areas";
export const DESCRIPTION_DAILY = "pages.description.days_days";
export const DESCRIPTION_MONTHS = "pages.description.months";
export const DESCRIPTION_COMPARISON_BY_TEAMS = "pages.description.comparison.by_teams";
export const DESCRIPTION_COMPARISON_BY_STORES = "pages.description.comparison.by_stores";
export const DESCRIPTION_COMPARISON_BY_SPORTS = "pages.description.comparison.by_sports";
export const DESCRIPTION_COMPARISON_BY_SPORTS_GROUPS = "pages.description.comparison.by_sports_groups";
export const DESCRIPTION_ASKR = "pages.description.askr";
export const DESCRIPTION_USER_PREFERENCES = "pages.description.user_preferences";
export const DESCRIPTION_FAVORITE_SPORTS = "pages.description.favorite_sports";
export const DESCRIPTION_TEMPLATES = "pages.description.templates";
export const DESCRIPTION_EVENTS = "pages.description.events";
export const DESCRIPTION_UPWARD_FORECAST = "pages.description.upward_forecast";
export const DESCRIPTION_MY_OFFER = "pages.description.my_offer";
export const DESCRIPTION_REPORTING_EXTRACTION = "pages.description.reporting_extraction";
export const DESCRIPTION_REPORTING_PROCESS_MANAGEMENT = "pages.description.reporting_process_management";
export const DESCRIPTION_REPORTING_EXTRACTION_MONTHLY_DATA = "pages.description.reporting_extraction_monthly_data";
export const DESCRIPTION_REPORTING_EXTRACTION_DAYDAY = "pages.description.reporting_extraction_dayday";
export const DESCRIPTION_REPORTING_EXTRACTION_TRAJECTORY = "pages.description.reporting_extraction_trajectory";
export const DESCRIPTION_REPORTING_EXTRACTION_WEEKLY = "pages.description.reporting_extraction_weekly";
export const DESCRIPTION_SALES_PERFORMANCES = "pages.description.sales_performances";
export const DESCRIPTION_SCHOOL = "pages.description.school";
export const DESCRIPTION_DOCUMENT = "pages.description.document";
export const DESCRIPTION_MANAGEMENT_RULES = "pages.description.management_rules";
export const DESCRIPTION_REPORTING_USAGE_BY_COUNTRY = "reporting.usage-country-description";
export const DESCRIPTION_REPORTING_HOURS_EFFICIENCY_PER_COUNTRY = "reporting.hours_efficiency_per_country_description";
export const DESCRIPTION_REPORTING_HOURS_EFFICIENCY_PER_COUNTRY_PROBABLE_YEAR =
  "reporting.hours_efficiency_per_country_description_probable_year_description";
export const DESCRIPTION_REPORTING_QUALITY_FORECASTING = "reporting.quality_forecasting_description";
export const DESCRIPTION_REPORTING_EFFITIME_RELIABILITY = "reporting.effitime_reliability_description";
export const DESCRIPTION_EFFITIME = "pages.description.effitime";
export const DESCRIPTION_EMBEDDED_SALES_ANIMATION = "pages.description.embedded_sales_animation";
export const DESCRIPTION_HOURS_TEAM = "pages.description.hours.team";
export const DESCRIPTION_HOURS_SPORT = "pages.description.hours.sport";
export const DESCRIPTION_HOURS_CASHIER = "pages.description.hours.cashier";
export const DESCRIPTION_HOURS_GENERAL_SERVICES = "pages.description.hours.general_services";
export const DESCRIPTION_ANNOUNCEMENT = "pages.description.announcement";
