import * as React from "react";

import { IconProps } from "components/common/icons/icon";

interface IThumbUpDownIconProps extends Partial<IconProps> {
  isThumbUp?: boolean;
  color?: string;
}

export const ThumbUpDownIcon: React.FunctionComponent<IThumbUpDownIconProps> = ({ isThumbUp, color }) => {
  return (
    <>
      {isThumbUp ? (
        <span className={`material-icons-outlined notranslate mg-thumb ${color} fixed`}>thumb_up</span>
      ) : (
        <span className={`material-icons-outlined notranslate mg-thumb ${color} fixed`}>thumb_down</span>
      )}
    </>
  );
};

export default React.memo(ThumbUpDownIcon);
