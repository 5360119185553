import { useCallback, useMemo } from "react";
import { DragDropContext, Droppable, DropResult, DroppableProvided } from "react-beautiful-dnd";
import { I18n } from "react-redux-i18n";

import Icon from "components/common/icons/customIcon";
import { STORE_GROUP_LIST_TYPE } from "core/constants/list-types";
import FavoriteStoreFields from "components/specific/favorite-stores/favorite-store-fields";
import { Store } from "core/models/store";
import StoresGroupFavoriteList from "./store-group-favorite-list";
import { useStoresObj } from "core/hooks/useFiltersLabels";
import { StoresGroup, StoresIdsWithIndex } from "../../../core/models/stores-group";
import { useApiGetStores } from "../../../core/api/store/useApiGetStores";

interface IStoresGroupCompositionProps {
  storesGroup: StoresGroup;
  isEditable: boolean;
  onStoresGroupChange: (storesGroup: StoresGroup | Partial<StoresGroup>) => void;
}

interface IDumbStoresGroupCompositionProps extends IStoresGroupCompositionProps {
  stores: Store[];
}

const EMPTY_ARRAY = [];

/**
 * Check if all the stores belong to the same store
 */
const checkMonoStore = (stores: Store[]) => {
  if (stores.length <= 1) return "NON_RELEVANT";
  const { id } = stores[0];
  for (const store of stores) {
    if (store.id !== id) {
      return "MULTI";
    }
  }
  return "MONO";
};
const flatStoresList = (storeList: Store[]): StoresIdsWithIndex[] =>
  storeList?.map((s: Store, index: number) => ({ store_id: s && s.id, index }));

const DumbStoresGroupComposition = ({
  isEditable,
  storesGroup,
  onStoresGroupChange,
  stores
}: IDumbStoresGroupCompositionProps) => {
  const storesFetchedByIds: Store[] = stores || EMPTY_ARRAY;

  const allStores = useStoresObj();

  const storeList = useMemo(
    () =>
      storesGroup
        ? [...storesGroup.composition]
            .sort((a, b) => a.index - b.index)
            .reduce(
              (acc: Store[], store: StoresIdsWithIndex) =>
                allStores[store.store_id] ? [...acc, allStores[store.store_id]] : acc,
              []
            )
        : [],
    [storesGroup, allStores]
  );

  const disabledItemsIds = useMemo(() => storeList?.map((store: Store) => store.id), [storeList]);

  const onDragEndStore = useCallback(
    (event: DropResult) => {
      if (!event || !event.destination) return;
      const {
        draggableId: storeId,
        destination: { index }
      } = event;
      const store = allStores[storeId];
      const formattedStoreId = Number(storeId);
      const newStoreList = storeList.filter((storeItem: Store) => storeItem.id !== formattedStoreId);
      newStoreList.splice(index, 0, store);
      onStoresGroupChange({ ...storesGroup, composition: flatStoresList(newStoreList) });
    },
    [allStores, storeList, onStoresGroupChange, storesGroup]
  );

  const onAddFavoriteStores = useCallback(
    (ids: number[]) => {
      const newStores = ids?.map(id => storesFetchedByIds.find(store => store && store.id === id)).filter(Boolean);
      const newStoreListWithoutDuplication = [...new Set([...storeList, ...newStores])];
      if (newStoreListWithoutDuplication.length) {
        onStoresGroupChange({ ...storesGroup, composition: flatStoresList(newStoreListWithoutDuplication) });
      }
    },
    [storeList, onStoresGroupChange, storesGroup, storesFetchedByIds]
  );

  const onRemoveStore = useCallback(
    (index: number) => {
      const newStoreList = storeList.filter((_, i: number) => index !== i);
      onStoresGroupChange({ ...storesGroup, composition: flatStoresList(newStoreList) });
    },
    [onStoresGroupChange, storesGroup, storeList]
  );

  const monoStore = checkMonoStore(storeList);

  let monoStoreAlert = null;

  if (monoStore === "MONO") {
    monoStoreAlert = <div>{I18n.t("settings.store_groups.mono_store")}</div>;
  } else if (monoStore === "MULTI") {
    monoStoreAlert = <div>{I18n.t("settings.store_groups.multi_store")}</div>;
  }

  return (
    <div className="mg-groups-composition">
      <DragDropContext onDragEnd={onDragEndStore}>
        {isEditable ? (
          <div className="mg-groups-composition__column" id="sports_group_editor_available_sports">
            <div className="mg-groups-composition__column__title">
              {I18n.t("settings.store_groups.available_stores")}
            </div>
            <FavoriteStoreFields
              onAddFavoriteStores={onAddFavoriteStores}
              disabledItemsIds={disabledItemsIds}
              storeList={storeList}
            />
          </div>
        ) : (
          <div className="mg-groups-composition__column">
            <div className="">
              <Icon name="clear_all" />
            </div>
          </div>
        )}
        <div className="mg-groups-composition__column" id="sports_group_editor_new_sports">
          <div className="mg-groups-composition__column__title">{I18n.t("settings.store_groups.group_stores")}</div>
          {monoStoreAlert}
          <Droppable droppableId={`${STORE_GROUP_LIST_TYPE}-drop`} isDropDisabled={!isEditable}>
            {(dropProvided: DroppableProvided) => (
              <StoresGroupFavoriteList
                type={STORE_GROUP_LIST_TYPE}
                dropProvided={dropProvided}
                storeList={storeList}
                onRemoveStore={isEditable && onRemoveStore}
                isDragDisabled={!isEditable}
              />
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  );
};

const StoresGroupComposition = (props: IStoresGroupCompositionProps) => {
  const { data: stores } = useApiGetStores();
  return <DumbStoresGroupComposition {...props} stores={stores} />;
};

export default StoresGroupComposition;
