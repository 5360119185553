import {
  CACHED_FILTERS_VALUES,
  DEFAULT_DEPENDENCIES_FILTERS_CHANGES,
  ICachedFiltersValues,
  IFilterKey,
  PartialFilters
} from "core/redux/filters/reducers";
import { escapeRegExp } from "./escape-reg-exp";

export const caseInsensitiveTrimFilter = (searchText: string, source: string): boolean => {
  return source.toLowerCase().indexOf(searchText.toLowerCase().trim()) !== -1;
};

export const caseInsensitiveTrimFilterWithZeroIndexOrIncluding = (searchText: string, source: string): boolean => {
  const regexString = `(^${escapeRegExp(searchText)})|\\s${escapeRegExp(searchText)}`;
  const reg = new RegExp(regexString, "gmi");
  return Boolean(source.match(reg)?.length);
};

// I think it is really overcomplicated: from what I understood, `getSynchronizedFilters` idea is to
// enforce the relation between stores, team and sport, but I did not get the full logic. This logic
// is now embedded in <FilterManager />, with the help of the `teamSportByStore` reducer, but some
// legacy code relies on this.
export const getSynchronizedFilters = (
  filters: PartialFilters,
  cache: ICachedFiltersValues = CACHED_FILTERS_VALUES
): PartialFilters => {
  return Object.keys(filters).reduce((result, filterKey) => {
    const filterValue = filters[filterKey];
    const cachedValue = cache[filterKey];
    const dependenciesValues =
      cachedValue && cachedValue.value === filterValue ? { ...cachedValue.dependenciesValues } : {};
    result = {
      ...result,
      [filterKey]: filterValue,
      ...DEFAULT_DEPENDENCIES_FILTERS_CHANGES[filterKey],
      ...dependenciesValues
    };
    return result;
  }, {});
};

/**
 * Builds the `checkFilters` prop for the <FilterManager /> when either `week` or `aggregate` are
 * required (in addition to other filters)
 */
export const checkFiltersWeekOrAggregateFactory =
  (relevantFilters: Array<IFilterKey>) =>
  // either `week` or `aggregate` should be present
  // other filters are required
  (filters: PartialFilters) => {
    const { week, aggregate, from, to } = filters;
    if (week == null && aggregate == null && from == null && to == null) return false;
    return relevantFilters.every(
      f => f === "week" || f === "aggregate" || f === "from" || f === "to" || filters[f] != null
    );
  };
