import { ReactElement, useEffect } from "react";
import { useStore } from "react-redux";
import { loadTranslations, SubTranslationObject, TranslationObjects } from "react-redux-i18n";
import { getNavigatorLanguage } from "core/utils/i18n";
import es from "../../lang/es.json";
import fr from "../../lang/fr.json";
import it from "../../lang/it.json";
import pt from "../../lang/pt.json";
import zh from "../../lang/zh.json";
import cs from "../../lang/cs.json";
import de from "../../lang/de.json";
import hu from "../../lang/hu.json";
import pl from "../../lang/pl.json";
import ro from "../../lang/ro.json";
import en from "../../lang/en.json";
import hi from "../../lang/hi.json";
import { Store } from "redux";

interface ITranslationProviderProps {
  children: ReactElement;
}

const dispatchTranslations = (localLangage: string, translations: SubTranslationObject, store: Store<unknown>) => {
  const translationObjects: TranslationObjects = { [localLangage]: translations };
  const dispatchCallback = loadTranslations(translationObjects);
  dispatchCallback(store.dispatch);
};

const TranslationsProvider = ({ children }: ITranslationProviderProps) => {
  const store = useStore();
  const localLangage = getNavigatorLanguage();

  useEffect(() => {
    const lang = localLangage.split("-")[0];
    const defaultTranslations = {
      en,
      fr,
      es,
      it,
      pt,
      zh,
      cs,
      de,
      hu,
      pl,
      ro,
      hi
    };
    const mergeDeepWithEn = mergeDeep(defaultTranslations["en"].general, defaultTranslations[lang]?.general);
    const mergeDeepWithEnAggregate = mergeDeep(
      defaultTranslations["en"].aggregate,
      defaultTranslations[lang]?.aggregate
    );
    const mergeDeepWithEnAccount = mergeDeep(defaultTranslations["en"].account, defaultTranslations[lang]?.account);

    mergeDeepWithEn.aggregate = mergeDeepWithEnAggregate;
    mergeDeepWithEn.account = mergeDeepWithEnAccount;
    dispatchTranslations(localLangage, mergeDeepWithEn, store);
  }, [localLangage, store]);
  return children;
};

export default TranslationsProvider;

const mergeDeep = (target, source) => {
  // Iterate through all the keys of the source object
  for (const key in source) {
    // Check if the current key is an object itself
    if (typeof source[key] === "object" && source[key] !== null) {
      // If the target doesn't have the key or if the target key is not an object, create an empty object
      if (!target[key] || typeof target[key] !== "object") {
        target[key] = {};
      }
      // Recursively merge the properties of the source object into the corresponding target object
      mergeDeep(target[key], source[key]);
    } else {
      // If the key is not an object, simply assign the value to the target
      target[key] = source[key];
    }
  }
  // Return the merged target object
  return target;
};
