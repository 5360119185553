import { useState, useEffect } from "react";

import { IAggregateHierarchy } from "core/utils/templates";
import { IAPIAccountValues, IAPIAggregate } from "core/api/rest/queries";
import { ICells, IAccountsGetterOptions, IFormattedAPIQueryData } from "./accounts";
import { IHeaders, IHeader } from "./headers";
import { TypedValue } from "./computation-engine";
import { I18n } from "react-redux-i18n";
import { useApiGetAggregates } from "../api/aggregate/useApiGetCalenderAggregate";

export const AGGREGATE_TRANSLATION_PREFIX = "aggregate.";
interface IAggregateRange {
  from: string;
  to: string;
}
export type IAggregateOrderMapping = Partial<Record<AGGREGATES, number>>;
type AggregateTranslationReplacements = {
  year?: number;
  yearPlusOne?: number;
  yearMinusOne?: number;
};
export enum AGGREGATES {
  AGGREGATE_N = "AGGREGATE_N",
  AGGREGATE_N_MINUS_1 = "AGGREGATE_N_MINUS_1",
  AGGREGATE_N_PLUS_1 = "AGGREGATE_N_PLUS_1",
  TRAJECTORY_SPORT = "TRAJECTORY_SPORT",
  TRAJECTORY_DDA = "TRAJECTORY_DDA",
  TRAJECTORY_AGGREGATE_N = "TRAJECTORY_AGGREGATE_N",
  REALIZED_N_1 = "REALIZED_N_1",
  REALIZED_4W = "REALIZED_4W",
  PROBABLE_N = "PROBABLE_N",
  DDA = "DDA",
  PHOTO_4W = "PHOTO_4W",
  TOTAL = "TOTAL",
  DIFFERENCE = "DIFFERENCE",
  AVAILABLE_SURFACE = "AVAILABLE_SURFACE",
  TEAM = "TEAM",
  TOTAL_SPORTS = "TOTAL_SPORTS",
  DIRECTOR_PILOTING = "DIRECTOR_PILOTING",
  GROUP_OF_SPORTS = "GROUP_OF_SPORTS",
  PHOTO_1W = "PHOTO_1W",
  LAST_52W = "LAST_52W",
  YTG = "YTG",
  TRAJECTORY_YTG = "TRAJECTORY_YTG"
}

export const aggregatesPeriodsOrderMapping = {
  [AGGREGATES.REALIZED_N_1]: 0,
  [AGGREGATES.TRAJECTORY_SPORT]: 1,
  [AGGREGATES.PROBABLE_N]: 2,
  [AGGREGATES.TRAJECTORY_DDA]: 3,
  [AGGREGATES.DDA]: 4,
  [AGGREGATES.PHOTO_4W]: 5,
  [AGGREGATES.REALIZED_4W]: 6,
  [AGGREGATES.PHOTO_1W]: 7,
  [AGGREGATES.LAST_52W]: 8,
  [AGGREGATES.YTG]: 9,
  [AGGREGATES.TRAJECTORY_YTG]: 10
};

export const aggregatesPeriodsDatePickerOrderMapping = {
  [AGGREGATES.REALIZED_4W]: 0,
  [AGGREGATES.DDA]: 1,
  [AGGREGATES.PROBABLE_N]: 2,
  [AGGREGATES.REALIZED_N_1]: 3,
  [AGGREGATES.TRAJECTORY_SPORT]: 4,
  [AGGREGATES.PHOTO_4W]: 5,
  [AGGREGATES.LAST_52W]: 6,
  [AGGREGATES.YTG]: 7,
  [AGGREGATES.TRAJECTORY_YTG]: 8
};

export interface IAggregate extends IAggregateRange {
  id: number;
  aggregate: string;
  index: number;
  is_hidden?: boolean;
  label_code: string;
  is_toggleable?: boolean;
  is_spatial_computation?: boolean;
}

export interface IAggregateHeader extends IAggregateRange, IHeader {
  id: string;
  isAggregate: boolean;
  isFixed: boolean;
  isToggleable: boolean;
  isSpatialComputation: boolean;
  value: string;
}

/**
 * Used to build and get aggregates cells
 * @param aggregates
 * @param data
 * @param accountLabel
 * @param options
 * @return aggregate cells
 */
export const getAggregateCells = (
  aggregates: IAggregate[],
  data: IFormattedAPIQueryData,
  accountLabel: string,
  options?: IAccountsGetterOptions
): ICells => {
  return aggregates.reduce<ICells>((result, aggregate): ICells => {
    const aggregateFromCex = data?.data?.aggregates?.find(aggregateCex => aggregateCex.id === aggregate.id);
    const accountsValues = (data.data.data[aggregate.aggregate] as IAPIAggregate).account_values;
    const accountsValue = accountsValues[accountLabel];
    if (accountsValue) {
      const { value, is_editable } = accountsValue;
      return {
        ...result,
        [aggregate.aggregate]: {
          value,
          initial_value: value,
          is_editable: options?.disableEditableCells === true ? false : is_editable,
          isComputed: aggregateFromCex?.is_computed || false,
          type: TypedValue.Pilotable
        }
      };
    }
    return result;
  }, {});
};
/**
 * Used to build and get aggregates headers
 * @param aggregates
 * @param offsetIndex // used when there are columns headersdisplayed before aggregates headers columns
 * @return aggregate headers
 */
export const getAggregateHeaders = (aggregates: IAggregate[], offsetIndex = 0): IHeaders<IAggregateHeader> => {
  return aggregates.reduce((result, aggregate) => {
    const currentAggregate = {
      id: aggregate.aggregate,
      isAggregate: true,
      isFixed: true,
      isToggleable: true,
      value: aggregate.label_code,
      from: aggregate.from,
      to: aggregate.to,
      index: aggregate.index + offsetIndex,
      isSpatialComputation: aggregate.is_spatial_computation || false
    };

    if (aggregate.is_toggleable === false) currentAggregate.isToggleable = false;

    return {
      ...result,
      [aggregate.aggregate]: currentAggregate
    };
  }, {});
};

/** get aggregates from cex and transform it to template aggregate format */
export const formatCexAggregatesToTemplateAggregates = (aggregates: IAPIAggregate[]): IAggregateHierarchy[] =>
  aggregates?.map(aggregate => {
    const { label, from, id, is_hidden, is_spatial_computation, label_code, to, can_select_year } = aggregate;
    return {
      aggregate: label_code,
      from,
      id,
      is_spatial_computation,
      index: null,
      is_hidden,
      /**
       * Warning: Inconsistency get template api call return
       * attribute key containing label_code and key label_code containing translated label
       */
      label_code: label,
      can_select_year,
      to
    };
  });

export const generateAggregate = (
  aggregateTemplate: IAPIAggregate,
  label: string,
  label_code: string,
  account_values: IAPIAccountValues
): IAPIAggregate => ({
  ...aggregateTemplate,
  account_values,
  label,
  label_code
});

export const aggregateLabelDatePickerMapper = (aggregate: IAggregateHierarchy) => {
  return aggregatesPeriodsDatePickerOrderMapping[aggregate.aggregate] ?? 100;
};

export const useLoadAggregateLabels = () => {
  const { data: aggregates } = useApiGetAggregates();
  const [loadAggregateList, setLoadAggregateList] = useState<string[]>([]);

  useEffect(() => {
    if (aggregates) {
      setLoadAggregateList(aggregates.load_aggregate.map(aggregate => aggregate.label_code));
    }
  }, [aggregates]);

  return loadAggregateList;
};

export const getCurrentYearReplacements = (currentYear: number): AggregateTranslationReplacements => {
  return {
    year: currentYear,
    yearPlusOne: currentYear + 1,
    yearMinusOne: currentYear - 1
  };
};

export const replacePlaceholders = (translation: string, replacements: AggregateTranslationReplacements): string => {
  return Object.keys(replacements).reduce((result, key) => {
    return result.replace(`{{${key}}}`, String(replacements[key]));
  }, translation);
};

export const getTranslatedAggregateLabel = (
  aggregateLabelCode: string,
  currentYear = new Date().getFullYear()
): string => {
  const translationKey = AGGREGATE_TRANSLATION_PREFIX + aggregateLabelCode;
  const translation = I18n.t(translationKey);
  const replacements = getCurrentYearReplacements(currentYear);

  return replacePlaceholders(translation, replacements);
};
