import { Fragment } from "react";
import { I18n } from "react-redux-i18n";

import MenuAnimationsAccordion from "./menu-animations-accordion";
import MenuAnimationSingleLink from "./menu-animation-single-link";
import { MenuMultipleLinks, menuLinks } from "../menu-constants";
import { MENU_MESSAGE_EDITING, MENU_SECTION_ANIMATION } from "core/constants/translations/menu";
import MessageAlert from "components/common/mygame/MessageAlert/message-alert";

const MenuAnimation = ({ url, isOpen, isEditing }) => {
  return (
    <div className="mg-menu-animation">
      <div className="mg-menu-animation-title">
        <span>{I18n.t(MENU_SECTION_ANIMATION)}</span>
      </div>

      {isEditing && <MessageAlert icon="info" text={MENU_MESSAGE_EDITING} />}

      <div className="mg-menu-animation-links">
        {menuLinks?.map(link => {
          return (
            <Fragment key={link.name}>
              {!(link as MenuMultipleLinks).children ? (
                <MenuAnimationSingleLink link={link} />
              ) : (
                <MenuAnimationsAccordion
                  link={link}
                  sublinks={(link as MenuMultipleLinks).children}
                  url={url}
                  isOpen={isOpen}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default MenuAnimation;
