import classnames from "classnames";

import { Draggable, DraggableProvided, DroppableProvided } from "react-beautiful-dnd";
import { Store } from "core/models/store";
import StoresGroupFavoriteItem from "./store-group-favorite-item";

export interface IStoresGroupFavoriteListProps {
  className?: string;
  storeList: Store[];
  type: string;
  onRemoveStore?: (storeIndex: number) => void;
  dropProvided?: DroppableProvided;
  isDragDisabled?: boolean;
}

const StoresGroupFavoriteList = ({
  className,
  storeList,
  dropProvided,
  onRemoveStore,
  type,
  isDragDisabled
}: IStoresGroupFavoriteListProps) => {
  const droppableProps = dropProvided ? dropProvided.droppableProps : {};

  return (
    <div className={classnames("store-group-favorite-list", className)}>
      <div className="store-group-favorite-list__container">
        <div className="store-group-favorite-list__subcontainer" ref={dropProvided?.innerRef} {...droppableProps}>
          {storeList?.map((store: Store, index: number) => (
            <Draggable
              key={`${type}-${store.id}`}
              draggableId={store.id.toString()}
              index={index}
              isDragDisabled={isDragDisabled}
            >
              {(dragProvided: DraggableProvided) => (
                <div>
                  <StoresGroupFavoriteItem
                    store={store}
                    onRemoveStore={onRemoveStore}
                    dragProvided={dragProvided}
                    index={index}
                  />

                  {dragProvided.placeholder}
                </div>
              )}
            </Draggable>
          ))}

          {dropProvided?.placeholder}
        </div>
      </div>
    </div>
  );
};

export default StoresGroupFavoriteList;
