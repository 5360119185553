import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { IDefaultFilters } from "../../models/api-user-data";
import { QUERY_KEY_USER_PREFERENCES } from "./useApiMenuGetUserPreferences";

interface PreferencesDTO {
  default_filters: DefaultFiltersDTO;
}
interface DefaultFiltersDTO {
  sport: number | null;
  template: number | null;
  store_id: number | null;
  sports_group: number | null;
  team: number | null;
  frame: number | null;
  country: string | null;
  menu_navigation_id: number | null;
  channel: number | null;
}

export const useApiMenuPutUserPreferences = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (defaultFilters: IDefaultFilters) => {
      const dto: PreferencesDTO = {
        default_filters: {
          sport: defaultFilters.sport || null,
          template: defaultFilters.template || null,
          store_id: defaultFilters.store_id || null,
          team: defaultFilters.team || null,
          sports_group: defaultFilters.sports_group || null,
          frame: defaultFilters.frame || null,
          menu_navigation_id: defaultFilters.menu_navigation_id || null,
          country: defaultFilters.country || null,
          channel: defaultFilters.channel || null
        }
      };
      return axios.put("/user/preferences", dto);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_USER_PREFERENCES] });
    }
  });
};
