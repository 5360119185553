import { I18n } from "react-redux-i18n";
import getRouteDisabled from "../../animation/get-route-disabled";
import { getRoute } from "../../menu-constants";
import { trackAnalyticsEvent } from "../../../../../../core/hooks/useAnalytics";

const DefaultList = ({ selectedView, navigate }) => {
  const handleClickOnConfigurationMenu = (isDisabledLink, informations, e) => {
    const googleAnalyticsAction = `click_top_burger_menu_${informations?.googleAnalyticsAction}`;
    trackAnalyticsEvent(googleAnalyticsAction);
    isDisabledLink ? e.preventDefault() : navigate(informations?.path);
    navigate(informations?.path);
  };
  return (
    <>
      {selectedView?.route.map(link => {
        const informations = getRoute(link.label);
        const isDisabledLink = getRouteDisabled(informations?.id);
        return (
          <div
            key={link.label}
            className={`mg-menu-configuration-links__url ${isDisabledLink ? "disabled" : ""}`}
            onClick={e => handleClickOnConfigurationMenu(isDisabledLink, informations, e)}
          >
            <span
              className={`${
                informations?.navigationMenuInformation.iconIsFilled
                  ? "material-icons notranslate"
                  : "material-icons-outlined notranslate"
              }`}
            >
              {informations?.navigationMenuInformation.iconName}
            </span>
            <span>{I18n.t(informations?.name)}</span>
          </div>
        );
      })}
    </>
  );
};

export default DefaultList;
