import * as React from "react";
import { Divider } from "@mui/material";

import { SelectContext } from "./select";
import SelectMenuGroup from "./select-menu-group";

export interface IProps {
  renderActions?: () => React.ReactNode;
}

const SelectMenu = ({ renderActions }: IProps) => {
  const { data } = React.useContext(SelectContext);
  return (
    <>
      {renderActions ? renderActions() : null}
      {data
        ? Object.keys(data)
            .filter(groupId => !!data[groupId].data.length)
            .map((groupId, index) => {
              const group = data[groupId];
              return (
                <React.Fragment key={groupId}>
                  {index > 0 ? <Divider /> : null}
                  <SelectMenuGroup {...group} />
                </React.Fragment>
              );
            })
        : null}
    </>
  );
};

export default SelectMenu;
