import React from "react";
import { TableInteractionsContext } from "@decathlon/react-table";

// HACK at the time of writing ITableInteractionsManagerProps is not exported by react-table
type ITableInteractionsManagerProps = typeof TableInteractionsContext extends React.Context<infer T> ? T : never;

const useTableInteractions = (): ITableInteractionsManagerProps => {
  return React.useContext(TableInteractionsContext);
};

export default useTableInteractions;
