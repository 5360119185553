import { I18n } from "react-redux-i18n";

import DateUtils from "core/utils/date";
import { ISport } from "core/api/rest/queries/select-sport";
import IDataSource from "core/models/data-source";
import { ITeam } from "core/api/rest/queries/select-teams";
import { parseWeek } from "./weeks";
import { parseMonth } from "./months";
import { IAggregateHeader } from "./aggregates";
import { pad } from "./number";
import { Sport } from "core/models/api/sports-piloting/sports-piloting";
import { ISportsGroupComposition } from "core/models/api/sports-group/sports-group-piloting";

export type IAbstractHeader = IIndicatorHeader | IColumnHeader | IAggregateHeader;

export interface IHeaders<T = IAbstractHeader> {
  [key: string]: T;
}

export interface IHeader {
  value: string | number;
  index: number;
  content?: IHeaderContent;
  id?: string;
  /** used to define if header column is fixed in table on scroll **/
  isFixed?: boolean;
  /** used to define header column can be extend/collapse **/
  isToggleable?: boolean;
  isAggregate?: boolean;
  isHidden?: boolean;
}

export interface IHeaderContent {
  title: string;
  subtitle?: string;
}

export interface IIndicatorHeader extends IHeader {
  isFixed: boolean;
  value: string;
}

export interface IColumnHeader extends IHeader {
  content: IHeaderContent;
}

export interface IHeaderIndex {
  id?: string | number;
  sport_id?: number;
  index: number;
}

/**
 * Used to get account's column information
 * @headerIndex return index position of indicator column
 * Allow components which build table to apply style/effects if indicator column has different positions into the table
 */
export const ACCOUNT_HEADER_ID = "ACCOUNTS";

export const SORT_STATUS = {
  ASC: "ASC",
  DESC: "DESC",
  NONE: "NONE"
};

export const getFixedColumnsIndexes = (headers: IHeaders): number[] =>
  Object.keys(headers).reduce<number[]>((fixedColumns, headerId) => {
    const { isFixed, index } = headers[headerId];
    if (isFixed && index !== undefined) {
      return [...new Set([...fixedColumns, index])];
    }
    return fixedColumns;
  }, []);

/**
 * Used to build and get indicator header
 * @return indicator header
 */
export const getIndicatorHeader = (index = 0): IHeaders<IIndicatorHeader> => ({
  [ACCOUNT_HEADER_ID]: {
    isFixed: true,
    value: I18n.t("pilotable.indicators"),
    index
  }
});

export const getSortedSportHeaders = (
  headers: IHeaders,
  sportsIndexes: IHeaderIndex[],
  offsetIndex = 0,
  idAttribute: "sport_id" | "id" = "id"
): IHeaders => {
  if (!sportsIndexes) {
    return Object.keys(headers).reduce<IHeaders>(
      (headers, headerId, index) => ({
        ...headers,
        [headerId]: { ...headers?.[headerId], index: index + offsetIndex }
      }),
      headers
    );
  }
  return sportsIndexes?.reduce<IHeaders>(
    (headers, { [idAttribute]: id, index }) => ({ ...headers, [id]: { ...headers[id], index: index + offsetIndex } }),
    headers
  );
};

/**
 * @param sports
 * @param sportsIndexes
 * @param offsetIndex // used when there are columns headersdisplayed before aggregates headers columns
 */
export const getStoreSportHeaders = (
  sports: Sport[],
  sportsIndexes: ISportsGroupComposition[] | null,
  offsetIndex = 0
): IHeaders => {
  const sportsHeaders = sports.reduce<IHeaders<IColumnHeader>>((headers, sport, index) => {
    return {
      ...headers,
      [sport.id]: {
        content: {
          title: sport.store_label
        },
        value: `${sport.department_id} - ${sport.label}`,
        index: index + offsetIndex
      }
    };
  }, {});
  return getSortedSportHeaders(sportsHeaders, sportsIndexes, offsetIndex, "sport_id");
};

export const getDataHeaders = (teams: IDataSource[], headerIndexes: IHeaderIndex[], offsetIndex: number): IHeaders => {
  const headers = teams?.reduce<IHeaders>((headers, team, index) => {
    return {
      ...headers,
      [Number(team.id)]: {
        value: team.label,
        index: index + offsetIndex
      }
    };
  }, {});

  return getSortedSportHeaders(headers, headerIndexes, offsetIndex);
};

/**
 * @param sports
 * @param sportsIndexes
 * @param offsetIndex // used when there are columns headersdisplayed before aggregates headers columns
 */
export const getSportWithDepartmentIdHeaders = (
  sports: Sport[],
  sportsIndexes: IHeaderIndex[],
  offsetIndex = 0
): IHeaders => {
  const sportsHeaders = sports.reduce<IHeaders>((headers, sport, index) => {
    return {
      ...headers,
      [sport.id]: {
        content: {
          title: sport.department_id
        },
        value: sport.label,
        index: index + offsetIndex
      }
    };
  }, {});

  return getSortedSportHeaders(sportsHeaders, sportsIndexes, offsetIndex, "sport_id");
};

/**
 * @param teams
 * @param sportsIndexes
 * @param offsetIndex // used when there are columns headersdisplayed before aggregates headers columns
 */
export const getTeamsWithDepartmentIdHeaders = (
  teams: ITeam[],
  sportsIndexes: IHeaderIndex[],
  offsetIndex = 0
): IHeaders => {
  const teamsHeaders = teams.reduce<IHeaders>((headers, team, index) => {
    return {
      ...headers,
      [Number(team.id)]: {
        content: {
          title: team.id
        },
        value: team.label,
        index: index + offsetIndex
      }
    };
  }, {});

  return getSortedSportHeaders(teamsHeaders, sportsIndexes, offsetIndex, "sport_id");
};

/**
 * Used to build and get week headers
 * @param weekList
 * @param offsetIndex // used when there are columns headersdisplayed before aggregates headers columns
 * @return week headers
 * @example getWeekHeaders(["2019W01, ..., 2019W52"]) =>
 * {"2019W01": { year: "2019", week: "01" }, ..., "2019W52": { year: "2019", week: "52" }}
 */
export const getWeekHeaders = (weekList: string[], offsetIndex = 0): IHeaders<IColumnHeader> =>
  weekList.reduce((headers, header, index) => {
    const { year, week } = parseWeek(header);
    return {
      ...headers,
      [header]: {
        content: {
          title: year
        },
        value: week,
        index: index + offsetIndex
      }
    };
  }, {});

/**
 * Used to build and get month headers
 * @param monthList
 * @param offsetIndex // used when there are columns headersdisplayed before aggregates headers columns
 * @return month headers
 * @example getMonthHeaders(["2019-01", ..., "2019-12"]) =>
 * {"2019-01": { year: "2019", month: "01" }, ..., "2019-12": { year: "2019", month: "12" }}
 */
export const getMonthHeaders = (monthList: string[], offsetIndex = 0): IHeaders<IColumnHeader> =>
  monthList.reduce((headers, header, index) => {
    const { year, month } = parseMonth(header);
    return {
      ...headers,
      [header]: {
        content: {
          title: year
        },
        value: month,
        index: index + offsetIndex
      }
    };
  }, {});

/**
 * Used to build and get week headers
 * @param sportList
 * @param offsetIndex
 * @return sport headers
 * @example getSportHeaders(["BAD", ..., "FOOT"]) =>
 * {"BAD": { content: {title: "BAD"}, value: "BAD", index: ... }, ...}
 */
export const getSportHeaders = (sportList: string[], offsetIndex = 0): IHeaders<IColumnHeader> => {
  return sportList.reduce((headers, header, index) => {
    return {
      ...headers,
      [header]: {
        content: {
          title: header
        },
        value: header,
        index: index + offsetIndex
      }
    };
  }, {});
};

enum Timeline {
  MONTH = "MONTH",
  TWELVE_MONTHS = "TWELVE_MONTHS",
  DDA = "DDA"
}

export enum Period {
  HISTORY = "HISTORY",
  PILOTING = "PILOTING",
  TRAJECTORY = "TRAJECTORY",
  PILOTING_N_1 = "PILOTING_N_1"
}

export const headerTranslations = {
  history: "mygame_store.manager_piloting.month_by_month.column_labels.history_n-1",
  piloting: "mygame_store.manager_piloting.month_by_month.column_labels.piloting",
  piloting_n_1: "mygame_store.manager_piloting.month_by_month.column_labels.piloting_n_1",
  trajectory: "mygame_store.manager_piloting.month_by_month.column_labels.trajectory"
};

/**
 * Used to build and get period headers
 * @param period
 * @param timelineLabel
 * @return period headers
 */
export const getPeriodHeaders = (period: string, timelineLabel: string): IHeaderContent => {
  let title;
  let subtitle;

  const date = new Date();
  const year = date.getFullYear();
  const month = DateUtils.getMonthNumeric(date);
  const nextYear = DateUtils.getNextYearNumber(date);
  const prevYear = DateUtils.getPreviousYearNumber(date);
  const twoYearsAgo = DateUtils.getPreviousYearNumber(date, 2);
  const nextMonth = DateUtils.getNextMonthIndex(date);

  switch (timelineLabel) {
    case Timeline.MONTH:
      switch (period) {
        case Period.HISTORY:
          title = `${prevYear}${I18n.t("monthLetter")}${month}`;
          subtitle = I18n.t(headerTranslations.history);
          break;
        case Period.PILOTING:
          title = `${year}${I18n.t("monthLetter")}${month}`;
          subtitle = I18n.t(headerTranslations.piloting);
          break;
        case Period.TRAJECTORY:
          title = `${year}${I18n.t("monthLetter")}${month}`;
          subtitle = I18n.t(headerTranslations.trajectory);
          break;
        case Period.PILOTING_N_1:
          title = `${nextYear}${I18n.t("monthLetter")}${month}`;
          subtitle = I18n.t(headerTranslations.piloting_n_1);
          break;
        default:
          return null;
      }
      break;

    case Timeline.TWELVE_MONTHS:
      switch (period) {
        case Period.HISTORY:
          title = `${twoYearsAgo}${I18n.t("monthLetter")}${nextMonth} - ${prevYear}${I18n.t("monthLetter")}${month}`;
          subtitle = I18n.t(headerTranslations.history);
          break;
        case Period.PILOTING:
          title = `${prevYear}${I18n.t("monthLetter")}${nextMonth} - ${year}${I18n.t("monthLetter")}${month}`;
          subtitle = I18n.t(headerTranslations.piloting);
          break;
        case Period.TRAJECTORY:
          title = `${prevYear}${I18n.t("monthLetter")}${nextMonth} - ${year}${I18n.t("monthLetter")}${month}`;
          subtitle = I18n.t(headerTranslations.trajectory);
          break;
        case Period.PILOTING_N_1:
          title = `${year}${I18n.t("monthLetter")}${nextMonth} - ${nextYear}${I18n.t("monthLetter")}${month}`;
          subtitle = I18n.t(headerTranslations.piloting_n_1);
          break;
        default:
          return null;
      }
      break;

    case Timeline.DDA:
      switch (period) {
        case Period.HISTORY:
          title = `${prevYear}${I18n.t("monthLetter")}01 - ${prevYear}${I18n.t("monthLetter")}${month}`;
          subtitle = I18n.t(headerTranslations.history);
          break;
        case Period.PILOTING:
          title = `${year}${I18n.t("monthLetter")}01 - ${year}${I18n.t("monthLetter")}${month}`;
          subtitle = I18n.t(headerTranslations.piloting);
          break;
        case Period.TRAJECTORY:
          title = `${year}${I18n.t("monthLetter")}01 - ${year}${I18n.t("monthLetter")}${month}`;
          subtitle = I18n.t(headerTranslations.trajectory);
          break;
        case Period.PILOTING_N_1:
          title = `${nextYear}${I18n.t("monthLetter")}01 - ${nextYear}${I18n.t("monthLetter")}${month}`;
          subtitle = I18n.t(headerTranslations.piloting_n_1);
          break;
        default:
          return null;
      }
      break;
    default:
      return null;
  }

  return { title, subtitle };
};

/**
 * Used to build and get timeline headers
 * @param periodList
 * @param timelineLabel
 * @param offsetIndex // used when there are columns headersdisplayed before aggregates headers columns
 * @return timeline headers
 */
export const getTimelineHeaders = (
  periodList: string[],
  timelineLabel: string,
  offsetIndex = 0
): IHeaders<IColumnHeader> =>
  periodList.reduce((headers, period, index) => {
    const { title, subtitle } = getPeriodHeaders(period, timelineLabel);

    return {
      ...headers,
      [period]: {
        content: {
          title
        },
        value: subtitle,
        index: index + offsetIndex
      }
    };
  }, {});

/**
 * Transform a period to a date
 * @param period
 */
export const periodToDate = (period: Period): string => {
  const date = DateUtils.getCurrentDate();
  const month = pad(DateUtils.getMonthNumeric(date));

  switch (period) {
    case Period.HISTORY: {
      const prevYear = DateUtils.getPreviousYearNumber(date);
      return `${prevYear}-${month}`;
    }
    case Period.PILOTING:
    case Period.TRAJECTORY: {
      const year = date.getFullYear();
      return `${year}-${month}`;
    }
    case Period.PILOTING_N_1: {
      const nextYear = DateUtils.getNextYearNumber(date);
      return `${nextYear}-${month}`;
    }
    default:
      return null;
  }
};

/**
 * Transform a date to a period
 * Period.TRAJECTORY is not editable so we return Period.PILOTING for the current year
 * @param date format "year-month" => "2018-11"
 */
export const dateToEditablePeriod = (date: string): Period => {
  const year = parseInt(date.split("-")[0], 10);
  const currentYear = DateUtils.getCurrentDate().getFullYear();
  if (year < currentYear) {
    return Period.HISTORY;
  }
  if (year === currentYear) {
    return Period.PILOTING;
  }
  return Period.PILOTING_N_1;
};

/**
 * It's possible that the backend does not return each team sports
 * So we add missing sports (with only their id) to the sport list
 */
export const getFullSportListFromTeam = (sports: Sport[], team: ITeam): Sport[] => {
  const sportsIds = sports?.map(sport => sport.id);

  const missingSportsIds = team?.composition?.filter(sport => !sportsIds.includes(sport)) || [];
  const missingSports = missingSportsIds.map<ISport>(sportId => ({
    id: sportId,
    department_id: null,
    label: sportId.toString(),
    store_id: null,
    store_label: null
  }));
  return [...sports, ...missingSports];
};
