import * as actionTypes from "./actionTypes";
import { IFilterKey, IFilterValue, IFilters } from "./reducers";

export interface SetFilters {
  type: actionTypes.SET_FILTERS;
  filters: Partial<IFilters>;
}

export interface SetFilter {
  type: actionTypes.SET_FILTER;
  filter: {
    name: IFilterKey;
    value: IFilterValue;
  };
}

export const setFilters = (filters: Partial<IFilters>): SetFilters => {
  return {
    type: actionTypes.SET_FILTERS,
    filters
  };
};

export const setFilter = (name: IFilterKey, value: IFilterValue): SetFilter => ({
  type: actionTypes.SET_FILTER,
  filter: { name, value }
});

export type Filters = SetFilters | SetFilter;
