export enum Icons {
  Return = "reply",
  Sport = "sport",
  Team = "equipe",
  PolyvalentMode = "local_parking",
  Warning = "warning",
  Schedule = "moteur-heures",
  Weeks = "view_week",
  Publish = "publish",
  CopyTrajectory = "playlist_add_check",
  Restore = "settings_backup_restore",
  Share = "share",
  Delete = "delete",
  Download = "download",
  Computation = "trending_up",
  Save = "arrow_forward_ios",
  Dimensions = "line_weight",
  Error = "error",
  PilotingMode = "tri",
  Copy = "copy",
  Photo = "picture",
  StoreForecastCircle = "pilotage-remontant",
  StoreForecast = "pilotage-remontant2"
}
