import { memo } from "react";
import { IContentCellProps, CellSize, CellDimension } from "@decathlon/react-table";

import ThumbUpDownIcon from "components/common/icons/thumb-up-down-icon";
import useDataManager from "core/hooks/useDataManager";
import useTableInteractions from "core/hooks/useTableInteractions";
import { useQueryClient } from "@tanstack/react-query";
import {
  ApiGetCountryComparisonIndicatorsMappingResponse,
  countryComparisonIndicatorsMappingEndPoint,
  QUERY_KEY_ACCOUNT_COUNTRY_RELATION
} from "core/api/account/comparison-account/useApiGetCountryComparisonIndicatorsMapping";

const getAccountCountryRelationByAccountLabel = (
  accountLabel: string,
  countryComparisonIndicatorsMappingResponse: ApiGetCountryComparisonIndicatorsMappingResponse[]
): ApiGetCountryComparisonIndicatorsMappingResponse | undefined => {
  return countryComparisonIndicatorsMappingResponse?.find(el => el.account_label === accountLabel);
};

const CountryComparisonIcon = ({ dataCoordinates }: IContentCellProps) => {
  const queryClient = useQueryClient();
  const queryKeyAccountCountryRelation = [
    QUERY_KEY_ACCOUNT_COUNTRY_RELATION,
    countryComparisonIndicatorsMappingEndPoint
  ];
  const countryComparisonIndicatorsMappingResponse =
    queryClient.getQueryData<ApiGetCountryComparisonIndicatorsMappingResponse[]>(queryKeyAccountCountryRelation);
  const accountCountryRelation = getAccountCountryRelationByAccountLabel(
    dataCoordinates?.rowId,
    countryComparisonIndicatorsMappingResponse
  );

  const { getCell } = useDataManager();
  const { cellWidth } = useTableInteractions();
  const currentCell = getCell(dataCoordinates);
  const indicatorCountryCell = getCell({
    rowId: accountCountryRelation?.account_country_label,
    columnId: dataCoordinates?.columnId
  });
  if (!indicatorCountryCell) {
    return null;
  }

  const currentCellValue = currentCell?.value;
  const indicatorCountryCellValue = indicatorCountryCell?.value;
  const isNeedSuperior = accountCountryRelation?.need_superior;

  return (
    <CountryComparisonIconDumb
      currentCellValue={currentCellValue as number}
      countryValue={indicatorCountryCellValue as number}
      cellWidth={cellWidth}
      isNeedSuperior={isNeedSuperior}
    />
  );
};

interface CountryComparisonIconDumbProps {
  currentCellValue: number;
  countryValue: number;
  cellWidth: CellDimension;
  isNeedSuperior: boolean;
}

export const CountryComparisonIconDumb = memo(
  ({ currentCellValue, countryValue, cellWidth, isNeedSuperior }: CountryComparisonIconDumbProps) => {
    const iconSize = cellWidth.size === CellSize.small ? 16 : 22;

    const comparisonResult = isNeedSuperior ? currentCellValue - countryValue : countryValue - currentCellValue;

    if (comparisonResult > 0) {
      return <ThumbUpDownIcon className="fixed" size={iconSize} color="green" isThumbUp />;
    }
    if (comparisonResult < 0) {
      return <ThumbUpDownIcon className="fixed" size={iconSize} color="red" />;
    }
    return null;
  }
);

export default CountryComparisonIcon;
