import { I18n } from "react-redux-i18n";
import { ITableProps, IRow } from "@decathlon/react-table";

import { IDataCoordinates } from "core/utils/cells";
import { IForecastExceptionStore, IUpwardForecast } from "core/api/rest/queries/upward-forecast";
import ActionsCell from "components/specific/upward-forecast/data-manager/form/actions-cell";
import SinceCell from "components/specific/upward-forecast/data-manager/form/since-cell";
import StoreCell from "components/specific/upward-forecast/data-manager/form/store-cell";

export const getHeaderRow = (isCountryActivated: boolean): IRow => {
  return {
    id: "header",
    className: "header-upward-forecast",
    isHeader: true,
    isSelectable: false,
    size: 50,
    cells: [
      {
        className: "store-id",
        id: "storeId",
        value: isCountryActivated
          ? I18n.t("upward-forecast.store.deactivated")
          : I18n.t("upward-forecast.store.activated")
      },
      {
        className: "since",
        id: "since",
        value: I18n.t("since")
      },
      {
        className: "actions",
        id: "actions",
        value: I18n.t("actions")
      }
    ]
  };
};

const getRows = (data: [Partial<IForecastExceptionStore>, ...IForecastExceptionStore[]]) => {
  return data.reduce((acc, exception) => {
    const { id, since, store_id, store_label, isNew } = exception;
    const row: IRow = {
      className: isNew ? "new" : "",
      id: String(id),
      cells: [
        {
          id: `${id || ""}-store`,
          cellContent: StoreCell,
          cellContentProps: {
            exceptionId: id,
            storeId: store_id,
            storeLabel: store_label
          }
        },
        {
          id: `${id || ""}-since`,
          cellContent: SinceCell,
          cellContentProps: {
            exceptionId: id,
            since
          }
        },
        {
          id: `${id || ""}-actions`,
          cellContent: ActionsCell,
          value: store_label,
          cellContentProps: {
            exceptionId: id
          }
        }
      ]
    };
    acc.push(row);
    return acc;
  }, []);
};

const getTableProps = (data: IUpwardForecast): Pick<ITableProps<IDataCoordinates>, "id" | "rows" | "columns"> => {
  const iForecastExceptionStores = data?.exception_stores.filter(exception => exception.activated !== data.activated);
  const rows = getRows([{ id: null }, ...iForecastExceptionStores]);
  const headerRow = getHeaderRow(data.activated);
  return { id: "events-table", rows: [headerRow, ...rows] };
};

export default getTableProps;
