import * as React from "react";
import Icon from "components/common/icons/customIcon";
import { I18n } from "react-redux-i18n";

export type IProps = {
  messageKey: string;
  options?: any;
  iconName?: string;
  errorStatus?: number;
  link?: string;
  children?: React.ReactNode;
};

const LogCardMessage = ({ messageKey, options, children, iconName, errorStatus, link }: IProps) => {
  return (
    <div className="mg-dashboard-content-no-filter">
      <div className="log-card-message">
        <div className="no-user-preferences-filter-error__container__error-message">
          {iconName ? <Icon name={iconName} /> : <span className="material-icons">info</span>}
          <div className="mg-typo-title-6">
            {errorStatus > 0 && <span>({errorStatus})&nbsp;</span>}
            {I18n.t(messageKey, options)}
          </div>
          {children}
          {link && (
            <div>
              <a className="link-log" href={link} title="" target="_blank" rel="noopener noreferrer">
                {link}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LogCardMessage;
