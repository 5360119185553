export const DEFAULT_LOCALE = "en";

export const defaultTranslations = {
  "fr-FR": {
    MyGame: "MyGame",
    loading: "Chargement...",
    unavailable: {
      title: "Mygame est temporairement indisponible.",
      content:
        "Mygame est temporairement indisponible, notre équipe est en train d'examiner le problème. Nous nous excusons pour la gêne occasionnée."
    }
  },
  en: {
    MyGame: "MyGame",
    loading: "Loading...",
    unavailable: {
      title: "Mygame is temporary unavailable.",
      content:
        "Mygame is temporary unavailable, our team is looking into it. We are sorry for the inconvenience caused."
    }
  }
};

/** Translations by Phrase **/

/* HEADER */
export const HEADER_PREFERENCES = "header.preferences";
export const HEADER_MANAGEMENT_RULES = "header.management_rules";
export const HEADER_FORMATION = "header.formation";
export const HEADER_HOME = "header.mygame-title-home";

/* PAGES.PILOTING */
export const PILOTING_WEEKLY_BY_SPORTS = "pages.piloting.weekly.by_sports";
export const PILOTING_WEEKLY_BY_SPORTS_GROUPS = "pages.piloting.weekly.by_sports_groups";
export const PILOTING_WEEKLY_BY_TEAMS = "pages.piloting.weekly.by_teams";
export const PILOTING_WEEKLY_BY_STORES = "pages.piloting.weekly.by_stores";
export const PILOTING_SPORTS_BY_SPORTS_GROUPS = "pages.piloting.sports.by_sports_groups";
export const PILOTING_SPORTS_BY_TEAMS = "pages.piloting.sports.by_teams";
export const PILOTING_SPORTS_BY_STORES = "pages.piloting.sports.by_stores";
export const PILOTING_ANNUAL_INITIALIZATION_BY_SPORTS_GROUPS = "pages.piloting.initialization.by_sports_groups";
export const PILOTING_ANNUAL_INITIALIZATION_BY_TEAMS = "pages.piloting.initialization.by_teams";
export const PILOTING_HOURS = "pages.piloting.hours";
export const PILOTING_HOURS_COMMERCIAL_TEAM = "piloting.hours_commercial_team";
export const PILOTING_HOURS_COMMERCIAL_SPORT = "piloting.hours_commercial_sport";
export const PILOTING_HOURS_CASHIER = "piloting.hours_cashier";
export const PILOTING_HOURS_GENERAL_SERVICES = "piloting.hours_general_services";
export const PILOTING_DAYS_HOURS = "pages.piloting.days_hours";
export const PILOTING_AREAS = "pages.piloting.areas";
export const PILOTING_DAILY = "pages.piloting.days_days";
export const PILOTING_MONTHS = "pages.piloting.months";
export const PILOTING_COMPARISON_BY_TEAMS = "pages.piloting.comparison.by_teams";
export const PILOTING_COMPARISON_BY_STORES = "pages.piloting.comparison.by_stores";
export const PILOTING_COMPARISON_BY_SPORTS = "pages.piloting.comparison.by_sports";
export const PILOTING_COMPARISON_BY_SPORTS_GROUPS = "pages.piloting.comparison.by_sports_groups";
export const PILOTING_ASKR = "pages.piloting.askr";
export const PILOTING_DAILY_BY_STORE = "pages.piloting.days_by_store";
export const PILOTING_DAILY_BY_TEAM = "pages.piloting.days_by_team";

/* PAGES.SETTINGS */
export const SETTINGS_USER_PREFERENCES = "pages.settings.user_preferences";
export const SETTINGS_FAVORITE_SPORTS = "pages.settings.favorite_sports";
export const SETTINGS_TEMPLATES = "pages.settings.templates";
export const SETTINGS_EVENTS = "pages.settings.events";
export const SETTINGS_UPWARD_FORECAST = "pages.settings.upward_forecast";
export const SETTINGS_MY_OFFER = "pages.settings.my_offer";
export const SETTINGS_REPORTING_EXTRACTION = "pages.settings.reporting_extraction";
export const SETTINGS_REPORTING_PROCESS_MANAGEMENT = "pages.settings.reporting_process_management";
export const SETTINGS_REPORTING_EXTRACTION_MONTHLY_DATA = "pages.settings.reporting_extraction_monthly_data";
export const SETTINGS_REPORTING_EXTRACTION_DAYDAY = "pages.settings.reporting_extraction_dayday";
export const SETTINGS_REPORTING_EXTRACTION_TRAJECTORY = "pages.settings.reporting_extraction_trajectory";
export const SETTINGS_REPORTING_EXTRACTION_WEEKLY = "pages.settings.reporting_extraction_weekly";
export const SETTINGS_DOCUMENT = "pages.settings.document";
export const SETTINGS_SCHOOL = "pages.settings.school";
export const SETTINGS_SALES_PERFORMANCES = "pages.settings.sales_performances";
export const SETTINGS_MANAGEMENT_RULES = "pages.settings.management_rules";
export const SETTINGS_HOME = "pages.settings.home";
export const SETTINGS_HOME_WEEKLY = "pages.settings.home_weekly";
export const SETTINGS_HOME_COMPARISON = "pages.settings.home_comparison";
export const SETTINGS_REPORTING_USAGE_BY_COUNTRY = "reporting.usage-by-country";
export const SETTINGS_REPORTING_EFFITIME_RELIABILITY = "reporting.effitime_reliability";
export const SETTINGS_REPORTING_HOURS_EFFICIENCY_PER_COUNTRY = "reporting.hours_efficiency_per_country";
export const SETTINGS_REPORTING_HOURS_EFFICIENCY_PER_COUNTRY_PROBABLE_YEAR =
  "reporting.hours_efficiency_per_country_probable_year";
export const SETTINGS_REPORTING_QUALITY_FORECASTING = "reporting.quality_forecasting";
export const SETTINGS_PLANNING = "pages.settings.planning";
export const SETTINGS_EFFITIMES = "pages.settings.effitime";
export const SETTINGS_EMBEDDED_SALES_ANIMATION = "pages.settings.embedded_sales_animation";

/* BTN ACTION */
export const BTN_SAVE = "action-button.save";
export const BTN_LOAD_AGGREGATE = "action-button.load-aggregate";
export const BTN_CAMERA = "action-button.camera";
export const BTN_CAMERA_CONFIRM_MSG = "action-button.camera-confirm-message";
export const BTN_ENGINE_MOTOR = "action-button.engine-motor";
export const BTN_POLYVALENT = "action-button.polyvalent";
export const BTN_NON_POLYVALENT = "action-button.non-polyvalent";
export const BTN_GO_VIEW_SPORT = "action-button.go-view-sport";
export const BTN_GO_VIEW_TEAM = "action-button.go-view-team";
export const BTN_UNLOCK_TRAJECTORY = "groups-of-sports.trajectory.locked-title";
export const BTN_LOCK_TRAJECTORY = "groups-of-sports.trajectory.unlocked-title";
export const BTN_WEEKLY_HOURS_PAST_WEEKS_UNLOCK_TITLE = "weekly.hours_past_weeks_unlock_weeks_btn_title";
export const BTN_WEEKLY_HOURS_PAST_WEEKS_LOCKED__TITLE = "weekly.hours_past_weeks_locked_btn_title";

/*MODAL MESSAGE*/
export const MODAL_MESSAGE_TRAJECTORY_PADLOCK_UNLOCK = "groups-of-sports.trajectory.locked-message";
export const MODAL_MESSAGE_TRAJECTORY_PADLOCK_LOCK = "groups-of-sports.trajectory.unlocked-message";

/* Token Expiration Modal */
export const TOKEN_EXPIRATION_MODAL_SESSION_WILL_EXPIRE = "token-expiration-modal.session_will_expire"; // Your session will expire in
export const TOKEN_EXPIRATION_MODAL_SESSION_HAS_EXPIRED = "token-expiration-modal.session_has_expired"; // Your session has expired. Please log in.
export const TOKEN_EXPIRATION_MODAL_HAVE_TIME_TO_FINISH = "token-expiration-modal.have_time_to_finish"; // I have time to finish my session
export const TOKEN_EXPIRATION_MODAL_LOGIN_AGAIN = "token-expiration-modal.login_again"; // OK, I'll log in again

/* Home page */
export const HOME_PAGE_N1 = "home-page.n_1"; // N-1
export const HOME_PAGE_PROG = "home-page.prog"; // Prog
export const HOME_PAGE_YESTERDAY = "home-page.yesterday"; // Yesterday

/* Weekly */
export const WEEKLY_UNLOCK_HOURS_PAST_WEEKS_CONFIRMATION = "weekly.unlock_hours_past_weeks_confirmation";

/* Coach */
export const COACH_GREETING = "coach.greeting";
export const COACH_LATER = "coach.later";

/* Fill in the filters */
export const SPORTS_GROUPS_PILOTING_FILL_IN_FILTERS = "sports_groups_piloting.fill_in_filters";
export const FILTERS_BAR_FILL_IN = "filters-bar.fill_in";

/* PAGES.ANNOUNCEMENT */
export const ANNOUNCEMENT_PAGE = "announcement.page";
