import dateUtils from "core/utils/date";

export type YearWeek = string;

export interface IYearWeek {
  year: YearWeek;
  week: YearWeek;
}

export const WEEK_SEPARATOR = "W";

/**
 * Used to get parsed week
 * @param yearWeek
 * @example parseWeek("2019W01") =>  {year: 2019, week: 01}
 */
export const parseWeek = (yearWeek: YearWeek): IYearWeek => {
  const [year, week] = yearWeek.split(WEEK_SEPARATOR);
  return { year, week };
};

export const isEditableByYearWeek = (yearWeek: YearWeek, includeCurrentWeek = false): boolean => {
  const currentDate = dateUtils.getCurrentDate();
  const currentYear = currentDate.getFullYear();
  let currentWeek = dateUtils.getWeekNumber(currentDate);
  currentWeek = includeCurrentWeek ? currentWeek - 1 : currentWeek;
  const { year, week } = parseWeek(yearWeek);
  const numericalYear = parseInt(year, 10);
  return (numericalYear === currentYear && parseInt(week, 10) > currentWeek) || numericalYear > currentYear;
};
