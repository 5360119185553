/* eslint-disable @typescript-eslint/no-redeclare */
export const SET_TOKEN = "SET_TOKEN";
export type SET_TOKEN = typeof SET_TOKEN;

export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export type GET_USER_INFO_SUCCESS = typeof GET_USER_INFO_SUCCESS;

export const GET_USER_INFO_FAILURE = "GET_USER_INFO_FAILURE";
export type GET_USER_INFO_FAILURE = typeof GET_USER_INFO_FAILURE;

export const SET_USER_PREFERENCES_FILTERS = "SET_USER_PREFERENCES_FILTERS";
export type SET_USER_PREFERENCES_FILTERS = typeof SET_USER_PREFERENCES_FILTERS;

export const SET_USER_PREFERENCES = "SET_USER_PREFERENCES";
export type SET_USER_PREFERENCES = typeof SET_USER_PREFERENCES;
