import * as React from "react";
import { I18n } from "react-redux-i18n";
import { useApiGetForecastByCountryCode } from "../../../core/api/upward-forecast/useApiGetForecastByCountryCode";
import { useFilters } from "../../common/filters3/filters/hooks/useFilters";
import { GenericFilters } from "../../common/filters3/filters/definitions";

const UpwardForecastMessage = () => {
  const {
    appliedFilters: { country }
  } = useFilters<GenericFilters>();
  const { data: dataForecastByCountryCode } = useApiGetForecastByCountryCode({ countryCode: country });
  const isActivated = dataForecastByCountryCode ? dataForecastByCountryCode.activated : false;
  return dataForecastByCountryCode ? (
    <p data-testid={`forecast-message-${isActivated ? "activated" : "deactivated"}`}>
      {isActivated ? (
        <span className="upward-forecast__important-message">{I18n.t("upward-forecast.activated.message")} </span>
      ) : null}
      {I18n.t(isActivated ? "upward-forecast.activated.exception-message" : "upward-forecast.deactivated.message")}
    </p>
  ) : null;
};

export default React.memo(UpwardForecastMessage);
