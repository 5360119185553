import * as React from "react";

import CollapseUncollapseController from "components/common/table/table-interactions-manager/collapse-uncollapse-controller";

const CollapseUncollapseCellRaw = () => {
  return (
    <div className="collapse-uncollapse-cell fixed">
      <CollapseUncollapseController />
    </div>
  );
};

const CollapseUncollapseCell = React.memo(CollapseUncollapseCellRaw);

export default CollapseUncollapseCell;
