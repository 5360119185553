import { memo, useState, useRef, useEffect } from "react";
import { getPathFromRouteId } from "core/utils/route-utils";
import { RouteId } from "core/routing/router/route-ids";
import { NavLink } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import { HEADER_FORMATION, HEADER_MANAGEMENT_RULES } from "core/constants/translations";
import { DirectorWord } from "../director-word/director-word";
import { PopoverHeaderCountry } from "../popover/popover-header-country";
import { PopoverHeaderPreferences } from "../popover/popover-header-preferences";
import { trackAnalyticsEvent } from "../../../../core/hooks/useAnalytics";

const HeaderLinks = () => {
  const [modalisOpen, setModalIsOpen] = useState(false);
  const [isViewDirector, setIsViewDirector] = useState(false);
  const idDay = 1; // 0 = Sunday, 1 = Monday, ...
  const today = new Date();
  const isTodayDayView = today.getDay() === idDay;
  const lastSeenDirectorDate = "lastSeenDirectorDate";
  const [isOpenCountryPopover, setIsOpenCountryPopover] = useState<boolean>(false);
  const [isOpenPreferencesPopover, setIsOpenPreferencesPopover] = useState<boolean>(false);
  const buttonHeadersRef = useRef<HTMLButtonElement | undefined>();
  const buttonPreferencesRef = useRef<HTMLButtonElement | undefined>();

  const handleModal = () => {
    trackAnalyticsEvent("click_navbar_info_from_store_manager");
    setModalIsOpen(!modalisOpen);
    setIsViewDirector(true);
    localStorage.setItem(lastSeenDirectorDate, today.toISOString());
  };

  const activeStyle = {
    backgroundColor: "#EFF1F3"
  };

  useEffect(() => {
    const lastSeenDate = localStorage.getItem(lastSeenDirectorDate);
    if (lastSeenDate) {
      const lastSeenDay = new Date(lastSeenDate).getDay() === idDay;
      if (lastSeenDay || !isTodayDayView) {
        setIsViewDirector(true);
      }
    }
  }, [isTodayDayView]);

  return (
    <>
      <div className="mg-header-links">
        <div className="mg-button-header" onClick={handleModal}>
          <div className="mg-button-header__icon">
            <span className="material-icons-outlined notranslate">info</span>
            {isTodayDayView && !isViewDirector && <div className="vtmn-badge vtmn-badge_variant--brand">1</div>}
          </div>
          <span className="mg-button-header__text">{I18n.t("homepage.manager_word")}</span>
        </div>
        <NavLink
          className="mg-button-header"
          to={getPathFromRouteId(RouteId.School)}
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          onClick={() => trackAnalyticsEvent("click_navbar_formation")}
          end
        >
          <div className="mg-button-header__icon">
            <span className="material-icons-outlined notranslate">school</span>
          </div>
          <span className="mg-button-header__text">{I18n.t(HEADER_FORMATION)}</span>
        </NavLink>

        <NavLink
          className="mg-button-header"
          to={getPathFromRouteId(RouteId.Document)}
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          onClick={() => trackAnalyticsEvent("click_navbar_management_rules")}
          end
        >
          <div className="mg-button-header__icon" id="headerIcon">
            <span className="material-icons-outlined notranslate">help_outline</span>
          </div>
          <span className="mg-button-header__text">{I18n.t(HEADER_MANAGEMENT_RULES)}</span>
        </NavLink>
        <PopoverHeaderPreferences
          isOpenPopover={isOpenPreferencesPopover}
          setIsOpenPopover={setIsOpenPreferencesPopover}
          buttonRef={buttonPreferencesRef}
        />

        <PopoverHeaderCountry
          isOpenPopover={isOpenCountryPopover}
          setIsOpenPopover={setIsOpenCountryPopover}
          buttonRef={buttonHeadersRef}
        />
      </div>
      {<DirectorWord modalIsOpen={modalisOpen} setModalIsOpen={setModalIsOpen} />}
    </>
  );
};

export default memo(HeaderLinks);
