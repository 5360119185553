import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ITeamsByStoreId } from "../../models/api/team/team";
import { ITeam } from "../rest/queries/select-teams";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

interface ITeamsParamsByStoreIdParams {
  storeId: number;
}

interface ITeamsResponseContentByStoreIdDto {
  id: number;
  label: string;
  store_id: number;
  composition: number[];
  department_ids: number[];
  team_type: string;
}

interface ITeamsResponseByStoreIdDto {
  content: ITeamsResponseContentByStoreIdDto[];
}

const QUERY_KEY_TEAMS = "get-teams";

const getTeamsFn = async (config: AxiosRequestConfig): Promise<ITeamsByStoreId[]> => {
  const axiosPromise: AxiosResponse<ITeamsResponseByStoreIdDto> = await axios(config);
  const modelTypedData: ITeamsByStoreId[] = axiosPromise.data.content.map(dto => ({
    id: dto.id,
    label: dto.label,
    store_id: dto.store_id,
    composition: dto.composition,
    department_ids: dto.department_ids,
    team_type: dto.team_type
  }));
  return modelTypedData.sort((teamA: ITeam, teamB: ITeam) => teamA.id - teamB.id);
};

export const useApiGetTeams = (
  params: ITeamsParamsByStoreIdParams,
  options?: UseQueryOptionsWithoutQueryKey<ITeamsByStoreId[]>
) => {
  const url = "/teams";
  const config: AxiosRequestConfig = { method: "get", url, params };
  return useQuery<ITeamsByStoreId[]>({
    queryKey: [QUERY_KEY_TEAMS, url, [...Object.values(params)]],
    queryFn: () => getTeamsFn(config),
    enabled: !!params?.storeId,
    ...options
  });
};
