import { RouteId } from "../../routing/router/route-ids";
import {
  SETTINGS_FAVORITE_SPORTS,
  SETTINGS_MY_OFFER,
  SETTINGS_TEMPLATES,
  SETTINGS_USER_PREFERENCES
} from "../translations";

export type Link = {
  link: RouteId;
  icon: string;
  title: string;
  key: number;
  role: string | null;
  googleAnalyticsAction: string;
};

export const links: Link[] = [
  {
    link: RouteId.UserPreferences,
    icon: "person",
    title: SETTINGS_USER_PREFERENCES,
    key: 1,
    role: null,
    googleAnalyticsAction: "click_navbar_my_preferences_users"
  },
  {
    link: RouteId.MyTemplates,
    icon: "visibility",
    title: SETTINGS_TEMPLATES,
    key: 2,
    role: null,
    googleAnalyticsAction: "click_navbar_my_preferences_view_management"
  },
  {
    link: RouteId.MyOffer,
    icon: "diversity_3",
    title: SETTINGS_MY_OFFER,
    key: 3,
    role: null,
    googleAnalyticsAction: "click_navbar_my_preferences_manage_my_teams"
  },
  {
    link: RouteId.GroupsCreation,
    icon: "group_work",
    title: SETTINGS_FAVORITE_SPORTS,
    key: 4,
    role: null,
    googleAnalyticsAction: "click_navbar_my_preferences_creation_of_groups"
  }
];
