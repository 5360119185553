import * as React from "react";
import { ListSubheader } from "@mui/material";
import { FixedSizeList as List } from "react-window";

import IDataSource from "core/models/data-source";
import SelectMenuItem from "./select-menu-item";
import { SelectContext } from "./select";
import { IDataGroup } from "./index";

export interface IProps extends IDataGroup {
  className?: string;
}

const ITEM_HEIGHT = 35;

const SelectMenuGroup: React.FunctionComponent<IProps> = ({ label, data, className }) => {
  const { isVirtualized, virtualizedItemsToDisplay, disabledItemsIds } = React.useContext(SelectContext);

  const itemsNumber = data.length;
  const maxHeight = ITEM_HEIGHT * virtualizedItemsToDisplay;
  const height = itemsNumber >= virtualizedItemsToDisplay ? maxHeight : itemsNumber * ITEM_HEIGHT;

  return (
    <React.Fragment>
      {label ? <ListSubheader className="menu-group__subheader">{label}</ListSubheader> : null}
      {isVirtualized ? (
        <List
          height={height}
          width={300}
          itemData={data}
          itemCount={data.length}
          itemSize={ITEM_HEIGHT}
          className="menu-group__list"
          data-testid="select-menu"
        >
          {({ data, index, style }) => {
            const dataSource: IDataSource = data[index];
            const disabled = disabledItemsIds.includes(dataSource.id);
            return (
              <SelectMenuItem
                key={dataSource.id}
                dataSource={dataSource}
                className={className}
                disabled={disabled}
                style={style}
              />
            );
          }}
        </List>
      ) : (
        data.map(dataSource => {
          const disabled = disabledItemsIds.includes(dataSource.id);
          return (
            <SelectMenuItem key={dataSource.id} dataSource={dataSource} className={className} disabled={disabled} />
          );
        })
      )}
    </React.Fragment>
  );
};

export default SelectMenuGroup;
