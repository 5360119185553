import IDataSource from "core/models/data-source";
import { IRange } from "core/models/date";
import DateUtils from "core/utils/date";
import { AggLevelType, DataTypesType, TimeUnitType } from "./reporting-extracts";

export default class FilterConstants {
  public static FILTER_AUTOCOMPLETE_MAX_RESULTS = 8;
}

export interface IFilterDateRangeYearConstant {
  from: number;
  to: number;
}

const threeYearRangeList = DateUtils.getThreeYearsRange(new Date().getFullYear());

export const FILTER_DATE_RANGE_YEAR: IFilterDateRangeYearConstant = {
  from: new Date().getFullYear() - 1,
  to: new Date().getFullYear() + 1
};

export const FILTER_DATE_RANGE: IRange<Date> = {
  from: new Date(threeYearRangeList[0], 0, 1),
  to: new Date(threeYearRangeList[2], 0, 1)
};

export const TRAJECTORY_FILTER_DATE_RANGE_YEAR: IFilterDateRangeYearConstant = {
  from: new Date().getFullYear() + 1,
  to: new Date().getFullYear() + 3
};

export const TRAJECTORY_FILTER_YEARLIST: IDataSource[] = Array.from(new Array(3), (_year: number, idx: number) => ({
  id: TRAJECTORY_FILTER_DATE_RANGE_YEAR.from + idx
}));

export const ALL_MONTH_FILTER_DATE_RANGE_YEAR: IFilterDateRangeYearConstant = {
  from: new Date().getFullYear() - 1,
  to: new Date().getFullYear() + 4
};

export const ANNUAL_INITIALIZATION_FILTER_DATE_RANGE_YEAR: IFilterDateRangeYearConstant = {
  from: new Date().getFullYear(),
  to: new Date().getFullYear() + 2
};

export const ALL_MONTH_FILTER_YEARLIST: IDataSource[] = Array.from(new Array(5), (_year: number, idx: number) => ({
  id: ALL_MONTH_FILTER_DATE_RANGE_YEAR.from + idx
}));

export const getFilterYearOrCurrentYear = (
  dateRange: IFilterDateRangeYearConstant,
  filterYear: number,
  firstYear: number
): number => {
  return DateUtils.isInFilterRange(dateRange, filterYear) ? filterYear : firstYear;
};

export const STORE = "store";
export const SPORT = "sport";
export const TEAM = "team";
export const TEMPLATE = "template";
export const YEAR = "year";
export const MONTH = "month";
export const AGGREGATE = "aggregate";
export const WEEK = "week";
export const SPORT_GROUP = "sports_group";
export const FRAME = "frame";

export const pilotingDaysUrl = "/piloting/days";
export const pilotingManagerByMonth = "/piloting/manager-by-month";

export const SELECT_DATA_TYPES = [
  { id: DataTypesType.PILOTED, label: "reporting.piloted" },
  { id: DataTypesType.REALIZED, label: "reporting.realized" }
];

export const SELECT_DATA_TYPES_MONTHLY = [
  { id: DataTypesType.PILOTED, label: "reporting.piloted" },
  { id: DataTypesType.REALIZED, label: "reporting.realized" },
  {
    id: DataTypesType.DIRECTOR,
    label: "reporting.director"
  }
];

export const SELECT_AGG_LEVEL = [
  { id: AggLevelType.STORE, label: "reporting.store" },
  { id: AggLevelType.TEAM, label: "reporting.team" },
  { id: AggLevelType.SPORT, label: "reporting.sport" }
];

export const SELECT_TYPE_UNIT = [
  { id: TimeUnitType.WEEKLY, label: "reporting.weekly" },
  { id: TimeUnitType.YEARLY, label: "reporting.yearly" }
];

export const SELECT_AGG_LEVEL_WEEKLY_MONTHLY = [
  { id: AggLevelType.STORE, label: "reporting.store" },
  { id: AggLevelType.TEAM, label: "reporting.team" },
  { id: AggLevelType.SPORT, label: "reporting.sport" },
  { id: AggLevelType.COUNTRY, label: "reporting.country" }
];
