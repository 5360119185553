import React, { useState } from "react";
import { toast } from "react-toastify";
import { I18n } from "react-redux-i18n";
import {
  GROUPS_ALERT_STORES,
  GROUPS_NOTIFICATION_STORES_DELETE_FAILED,
  GROUPS_NOTIFICATION_STORES_DELETE_SUCCEDED
} from "core/constants/translations/groups";
import { TemplateSharePopover } from "../templates/template-share-popover";
import Modal from "../../common/mygame/modal/modal";
import { StoresGroup } from "../../../core/models/stores-group";
import {
  useApiDeleteSharedStoresGroup,
  useApiDeleteStoresGroup
} from "../../../core/api/stores-group/useApiDeleteStoresGroup";

export interface IIStoresGroupItemProps {
  id?: string;
  storesGroup: StoresGroup;
  onEditStoresGroup?: (storesGroup: StoresGroup) => void;
}

const StoresGroupItem = ({ storesGroup, onEditStoresGroup }: IIStoresGroupItemProps) => {
  const deleteMutation = useApiDeleteStoresGroup();
  const deleteSharedMutation = useApiDeleteSharedStoresGroup();
  const [showModal, setShowModal] = useState(false);
  const [modalInformations, setModalInformations] = useState({
    content: null,
    handleClickCancel: null,
    handleClickContinue: null
  });
  const handleOpenModal = (content, handleClickCancel, handleClickContinue) => {
    setShowModal(true);
    setModalInformations({
      content: content,
      handleClickCancel: handleClickCancel,
      handleClickContinue: handleClickContinue
    });
  };

  const cancelToDeleteStoresGroup = () => {
    setModalInformations({ content: "", handleClickCancel: "", handleClickContinue: "" });
    setShowModal(false);
  };

  const confirmToDeleteStoresGroup = () => {
    if (storesGroup.is_owner) {
      deleteMutation
        .mutateAsync(storesGroup)
        .then(() => {
          toast.success(I18n.t(GROUPS_NOTIFICATION_STORES_DELETE_SUCCEDED));
          setShowModal(false);
        })
        .catch(() => toast.error(I18n.t(GROUPS_NOTIFICATION_STORES_DELETE_FAILED)));
    } else {
      deleteSharedMutation
        .mutateAsync(storesGroup)
        .then(() => {
          toast.success(I18n.t(GROUPS_NOTIFICATION_STORES_DELETE_SUCCEDED));
          setShowModal(false);
        })
        .catch(() => toast.error(I18n.t(GROUPS_NOTIFICATION_STORES_DELETE_FAILED)));
    }
  };
  const linkToShare = `${window.location.origin}/settings/groups-creation/share/${storesGroup.id}`;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditStoresGroup = () => {
    onEditStoresGroup(storesGroup);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onCopyLinkAction: () => void = () => {
    navigator.clipboard.writeText(linkToShare);
  };

  return (
    <>
      {showModal && (
        <Modal
          content={modalInformations.content}
          handleClickCancel={modalInformations.handleClickCancel}
          handleClickContinue={modalInformations.handleClickContinue}
        />
      )}

      <TemplateSharePopover
        linkToShare={linkToShare}
        handlePopoverClose={handlePopoverClose}
        onCopyLinkAction={onCopyLinkAction}
        anchorEl={anchorEl}
      />
      <div>
        <div className="mg-groups-card" role="button" aria-pressed="true">
          <div className="mg-groups-card__actions">
            <span
              className="material-icons"
              aria-hidden="true"
              onClick={() =>
                handleOpenModal(I18n.t(GROUPS_ALERT_STORES), cancelToDeleteStoresGroup, confirmToDeleteStoresGroup)
              }
            >
              delete
            </span>
            <span className="material-icons" aria-hidden="true" onClick={handlePopoverOpen}>
              share
            </span>
          </div>
          <div className={"mg-groups-card__name"} onClick={handleEditStoresGroup} onKeyDown={handleEditStoresGroup}>
            <span>{storesGroup.label}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoresGroupItem;
