export default class ArrayUtils {
  public static flatten(array: any[]): any[] {
    return [].concat.apply([], array);
  }

  // Example :
  // const myArray = [{isArchived: false}, {isArchived: true}, {isArchived: false}];
  // Use with : partition(myArray, (item) => item.isArchived)
  // Returns [[{isArchived: true}], [{isArchived: false}, {isArchived: false}]]
  public static partition(arr: any[], predicate: any, initialValue = []): any[] {
    const initialGroups = [initialValue, initialValue];

    return arr.reduce((groups, item) => {
      const index = predicate(item) ? 0 : 1;
      const nextGroups = [...groups];
      nextGroups[index] = [...nextGroups[index], item];
      return nextGroups;
    }, initialGroups);
  }

  public static set(index: number, value: any, arr: any[]) {
    return [...arr.slice(0, index), value, ...arr.slice(index)];
  }

  public static move(from: number, to: number, arr: any[]) {
    const newArr = [...arr];
    newArr.splice(to, 0, newArr.splice(from, 1)[0]);
    return newArr;
  }

  public static sortAscByIndex = arr => arr.sort((a, b) => a.index - b.index);
}
