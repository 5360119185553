import { memo } from "react";
import { I18n } from "react-redux-i18n";
import { ROLES } from "core/constants/roles";
import { useUserHasRoles } from "core/hooks/useUserHasRoles";
import { useApiGetForecastByCountryCode } from "core/api/upward-forecast/useApiGetForecastByCountryCode";
import { useApiPutForecastUnlockButton } from "core/api/upward-forecast/useApiPutForecastUnlockButton";

interface IUnblockButtonProps {
  isDisabled: boolean;
  country: string;
}

const UnblockButton = ({ isDisabled, country }: IUnblockButtonProps) => {
  const { data: dataForecastByCountryCode } = useApiGetForecastByCountryCode({ countryCode: country });
  const isAuthorized = dataForecastByCountryCode ? dataForecastByCountryCode.authorized : false;
  const userIsAdmin = useUserHasRoles([ROLES.ROLE_ADMIN]);

  const { mutate } = useApiPutForecastUnlockButton();

  const handleOnClick = () => {
    mutate({
      countryCode: country,
      isAuthorized: isAuthorized
    });
  };

  return userIsAdmin ? (
    <button
      id={`upward-forecast-${dataForecastByCountryCode?.authorized ? "authorized" : "unauthorized"}`}
      className="vtmn-btn vtmn-btn_variant--primary vtmn-btn_size--small"
      onClick={isDisabled ? null : handleOnClick}
      disabled={isDisabled}
    >
      {dataForecastByCountryCode?.authorized ? I18n.t("block") : I18n.t("unblock")}
    </button>
  ) : null;
};

export default memo(UnblockButton);
