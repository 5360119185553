import * as React from "react";
import { EditableCell as EditableCellBase, IEdiTableCellProps as IEditableCellPropsBase } from "@decathlon/react-table";

import { DataManagerContext } from "../data-manager/data-context";

export type IEditableCellProps = Omit<IEditableCellPropsBase, "onConfirmValue">;

const EditableCell: React.FunctionComponent<IEditableCellProps> = props => {
  const { setCellValue } = React.useContext(DataManagerContext);
  const { dataCoordinates } = props;
  const updateCellValue = (value: number) => {
    setCellValue(dataCoordinates, value);
  };
  return <EditableCellBase {...props} onConfirmValue={updateCellValue} />;
};

export default EditableCell;
