// Local enums to avoid unit test failures and enhance type safety
enum Aggregates {
  TRAJECTORY_SPORT = "TRAJECTORY_SPORT",
  TRAJECTORY_DDA = "TRAJECTORY_DDA",
  TRAJECTORY_YTG = "TRAJECTORY_YTG",
  TRAJECTORY_N_PLUS_1 = "TRAJECTORY_N_PLUS_1"
}

enum IndicatorsType {
  PROGRESSION = "PROGRESSION"
}

// CSS Class Configurations
const cssConfigurations = {
  indicators: {
    [IndicatorsType.PROGRESSION]: "cell-progression"
  },
  headerAggregate: {
    [Aggregates.TRAJECTORY_SPORT]: "cell-trajectory-header",
    [Aggregates.TRAJECTORY_DDA]: "cell-trajectory-header",
    [Aggregates.TRAJECTORY_YTG]: "cell-trajectory-header",
    [Aggregates.TRAJECTORY_N_PLUS_1]: "cell-trajectory-header"
  },
  aggregateCells: {
    [Aggregates.TRAJECTORY_SPORT]: "cell-trajectory-cells",
    [Aggregates.TRAJECTORY_DDA]: "cell-trajectory-cells",
    [Aggregates.TRAJECTORY_YTG]: "cell-trajectory-cells",
    [Aggregates.TRAJECTORY_N_PLUS_1]: "cell-trajectory-cells"
  }
};

// Function to get the CSS class for a header aggregate
export const getCssHeaderAggregate = (aggregateName: string): string =>
  cssConfigurations.headerAggregate[aggregateName];

// Function to get the CSS configuration based on indicator and header ID
export const getCssConfiguration = (indicator: string, headerId?: string): string => {
  const cssClasses: string[] = [];

  if (indicator?.includes(IndicatorsType.PROGRESSION)) {
    cssClasses.push(cssConfigurations.indicators[IndicatorsType.PROGRESSION]);
  }

  if (headerId && cssConfigurations.aggregateCells[headerId]) {
    cssClasses.push(cssConfigurations.aggregateCells[headerId]);
  }

  return cssClasses.join(" ");
};

export const getCssStyleEventIcon = (eventsLength: number, isDayEvent: boolean) => {
  return isDayEvent
    ? {
        fontSize: "calc(100% - 1vh)",
        backgroundColor: `rgb(2, ${191 - eventsLength * 20}, 141) `,
        borderRadius: "50%",
        height: "10px",
        width: "10px"
      }
    : {
        color: "rgb(0, 128, 500)",
        fontSize: "calc(100% - 0.4vh)",
        width: "15px"
      };
};

export const isIndicatorProgression = (indicator: string): boolean => indicator?.includes(IndicatorsType.PROGRESSION);
