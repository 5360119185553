export const DATA_EXPORT_ENDPOINT = "/api/send_export_mail";

export enum DataTypesType {
  PILOTED = "PILOTED",
  REALIZED = "REALIZED",
  DIRECTOR = "DIRECTOR",
  TRAJECTORY = "TRAJECTORY"
}

export enum DaydayAccountsType {
  INSTORE_DAY = "INSTORE",
  OMNI_DAY = "OMNI",
  OUTSTORE_DAY = "OUTSTORE"
}

export enum TimeUnitType {
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  WEEKLY = "WEEKLY",
  YEARLY = "YEARLY"
}

export enum AggLevelType {
  COUNTRY = "COUNTRY",
  COUNTRIES = "countries",
  STORE = "STORE",
  TEAM = "TEAM",
  SPORT = "SPORT"
}
