import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { ICountry } from "../rest/queries/upward-forecast-country";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

interface CountryDto {
  id: string;
  label: string;
}

const QUERY_KEY_COUNTRIES = "get-countries";

const getCountriesFn = async (): Promise<ICountry[]> => {
  const response: AxiosResponse<CountryDto[]> = await axios({ method: "get", url: "/forecast/countries" });
  return response.data.map(item => {
    return {
      ...item
    };
  });
};

export const useCountries = (options?: UseQueryOptionsWithoutQueryKey<ICountry[]>) => {
  return useQuery<ICountry[], Error>({
    queryKey: [QUERY_KEY_COUNTRIES, "/forecast/countries"],
    queryFn: () => getCountriesFn(),
    staleTime: Infinity,
    ...options
  });
};
