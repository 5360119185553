import { useLocation } from "react-router-dom";
import { WebInterface } from "core/constants/web-interfaces";
import { getRouteIdFromPath } from "core/utils/route-utils";
import { RouteId } from "core/routing/router/route-ids";

const useView = () => {
  const { pathname } = useLocation();
  return getRouteIdFromPath(pathname);
};

const ROUTES_WEB_INTERFACE = {
  [RouteId.Weekly]: WebInterface.WEEKLY_PILOTING,
  [RouteId.WeeklySportsGroup]: WebInterface.WEEKLY_PILOTING,
  [RouteId.WeeklyTeams]: WebInterface.WEEKLY_PILOTING,
  [RouteId.WeeklyStores]: WebInterface.WEEKLY_PILOTING,
  [RouteId.Daily]: WebInterface.DAY_DAY_PILOTING,
  [RouteId.Sports]: WebInterface.GROUP_SPORT_PILOTING,
  [RouteId.SportsTeams]: WebInterface.GROUP_SPORT_PILOTING,
  [RouteId.SportsStores]: WebInterface.GROUP_SPORT_PILOTING,
  [RouteId.AnnualInitialization]: WebInterface.ANNUAL_INIT_PILOTING,
  [RouteId.AnnualInitializationTeams]: WebInterface.ANNUAL_INIT_PILOTING,
  [RouteId.Areas]: WebInterface.AREAS_PILOTING,
  [RouteId.ManagerByMonth]: WebInterface.STORE_MONTHLY_PILOTING,
  [RouteId.ComparisonTeams]: WebInterface.COMPARISON_PILOTING,
  [RouteId.ComparisonStores]: WebInterface.COMPARISON_PILOTING,
  [RouteId.ComparisonSports]: WebInterface.COMPARISON_PILOTING,
  [RouteId.ComparisonSportGroups]: WebInterface.COMPARISON_PILOTING
};

export const useWebInterface = (): WebInterface => {
  const { pathname } = useLocation();
  const routeId = getRouteIdFromPath(pathname);
  return ROUTES_WEB_INTERFACE[routeId];
};

export const useIsRootPath = (path: string): boolean => {
  const location = useLocation();
  try {
    return location.pathname.split("/")[1] === path;
  } catch (e) {
    throw e;
  }
};

const ROOTS_NAMES = {
  home: "home",
  piloting: "piloting",
  settings: "settings"
};

export const useIsSettingsPath = (): boolean => {
  return useIsRootPath(ROOTS_NAMES.settings);
};

export default useView;
