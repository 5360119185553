import { IconProps } from "components/common/icons/icon";
import { getCssStyleEventIcon } from "core/utils/cssConfiguration";

const style = getCssStyleEventIcon;

interface IEventProps extends Partial<IconProps> {
  isDayEvent?: boolean;
  className?: string;
  eventsLength?: number;
}

export const EventIcon = ({ isDayEvent, eventsLength, ...othersIconProps }: IEventProps) => {
  if (isDayEvent) {
    return <div aria-hidden="true" style={style(eventsLength, isDayEvent)} />;
  } else {
    return (
      <span
        className="material-icons-outlined notranslate"
        style={style(eventsLength, isDayEvent)}
        {...othersIconProps}
      >
        event_available
      </span>
    );
  }
};

export default EventIcon;
