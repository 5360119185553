import { FiltersContainer, FiltersContainerInit } from "../hoc/types";
import { AdditionalFiltersOpts, Filter, URLFilter } from "../definitions";
import { parse as parseParams } from "qs";
import { getFilterSnapshots } from "./snapshot";

const getURLFilterParser = <T>(filter: URLFilter<T>) => {
  let parser = rawValue => rawValue;
  const inURL = filter.inUrl;
  if (inURL) {
    const { urlConfig } = filter;
    if (urlConfig?.format) {
      parser = urlConfig.format.parse || parser;
    }
  }
  return parser;
};

export const filtersEntries = <T>(filters: FiltersContainerInit<T> | FiltersContainer<T>) =>
  Object.entries(filters) as [string, Filter<unknown>][];

export const initFilters = <T>(
  baseFilters: FiltersContainerInit<T>,
  defaultHooksValues: { [K in keyof T]: unknown },
  opts?: Partial<AdditionalFiltersOpts>
) => {
  const entries = filtersEntries(baseFilters);
  const { search } = location;
  const urlValues = parseParams((search || "").replace(/^\?/, ""));
  const withSnapshots = opts?.withSnapshots;
  const snapshotValues = (withSnapshots ? getFilterSnapshots(withSnapshots) : {}) ?? {};

  const buildFilters = (container, pair) => {
    const [name, filter] = pair;
    const inURL = filter.inUrl;
    const parser = getURLFilterParser(filter);

    container[name] = {
      ...filter,
      value:
        (inURL && urlValues[name] ? parser(urlValues[name]) : null) ??
        (snapshotValues[name] || defaultHooksValues[name] || filter.default)
    };

    return container;
  };

  return entries.reduce(buildFilters, {} as FiltersContainer<T>);
};
