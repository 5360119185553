import { Dispatch, SetStateAction, createContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import usePrevious from "core/hooks/usePrevious";
import { useIsSettingsPath } from "core/hooks/useView";

interface INavigationContext {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}

export const initialContext: INavigationContext = {
  setIsOpen: () => {
    /* empty */
  },
  isOpen: false
};

export const NavigationContext = createContext<INavigationContext>(initialContext);
NavigationContext.displayName = "NavigationContext";

const NavigationContextProvider = ({ children }) => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const isCurrentLocationSettings = useIsSettingsPath();
  const [isOpen, setIsOpen] = useState(isCurrentLocationSettings);

  const contextValue = useMemo(() => {
    return { setIsOpen, isOpen };
  }, [isOpen, setIsOpen]);

  useEffect(() => {
    const isNewLocation = location !== prevLocation;
    if (isNewLocation && isCurrentLocationSettings && !isOpen) {
      setIsOpen(true);
    }
    if (isNewLocation && !isCurrentLocationSettings && isOpen) {
      setIsOpen(false);
    }
  }, [isOpen, isCurrentLocationSettings, prevLocation, location]);

  return <NavigationContext.Provider value={contextValue}>{children}</NavigationContext.Provider>;
};

export default NavigationContextProvider;
