import { PERMISSIONS } from "core/constants/roles";
import { IRoute, ROUTES } from "core/routing/router/routes";
import { RouteId } from "core/routing/router/route-ids";
import {
  MENU_ANIMATION_LINKS_GROUPS_ANNUAL_INITIALIZATION,
  MENU_ANIMATION_LINKS_GROUPS_COMPARISON,
  MENU_ANIMATION_LINKS_GROUPS_SPORT,
  MENU_ANIMATION_LINKS_GROUPS_WEEKLY
} from "core/constants/translations/menu";
import { PILOTING_DAILY, PILOTING_HOURS } from "../../../../core/constants/translations";

type MenuSingleLink = IRoute<{
  [key: string]: unknown;
}>;

export interface MenuMultipleLinks {
  name: string;
  icon: string;
  filled: boolean;
  rights: PERMISSIONS[];
  children: IRoute<{
    [key: string]: unknown;
  }>[];
}

type MenuLinks = MenuSingleLink | MenuMultipleLinks;
export const getRoute = (routeId: string) => {
  return ROUTES.find(allRoute => allRoute.id === routeId);
};

export const menuLinks: MenuLinks[] = [
  {
    name: MENU_ANIMATION_LINKS_GROUPS_WEEKLY,
    icon: "date_range",
    filled: false,
    rights: [PERMISSIONS.PILOTING_WEEKLY_ACCESS],
    children: [
      getRoute(RouteId.Weekly),
      getRoute(RouteId.WeeklySportsGroup),
      getRoute(RouteId.WeeklyTeams),
      getRoute(RouteId.WeeklyStores)
    ]
  },
  // getRoute(RouteId.HoursLanding),
  {
    name: PILOTING_HOURS,
    icon: "schedule",
    filled: false,
    rights: [],
    children: [
      getRoute(RouteId.HoursTeam),
      getRoute(RouteId.HoursCashierServices),
      getRoute(RouteId.HoursGeneralServices)
    ]
  },
  getRoute(RouteId.Planning),
  getRoute(RouteId.ManagerByMonth),
  // getRoute(RouteId.Daily),
  {
    name: PILOTING_DAILY,
    icon: "date_range",
    filled: false,
    rights: [PERMISSIONS.PILOTING_WEEKLY_ACCESS],
    children: [getRoute(RouteId.DailyTeam), getRoute(RouteId.DailyStore)]
  },
  getRoute(RouteId.Events),
  {
    name: MENU_ANIMATION_LINKS_GROUPS_SPORT,
    icon: "sports_tennis",
    filled: false,
    rights: [],
    children: [getRoute(RouteId.Sports), getRoute(RouteId.SportsTeams), getRoute(RouteId.SportsStores)]
  },
  getRoute(RouteId.EmbeddedSalesAnimation),
  getRoute(RouteId.ReportingProcessManagement),
  {
    name: MENU_ANIMATION_LINKS_GROUPS_COMPARISON,
    icon: "join_left",
    filled: false,
    rights: [PERMISSIONS.PILOTING_COMPARISON_ACCESS],
    children: [
      getRoute(RouteId.ComparisonSports),
      getRoute(RouteId.ComparisonSportGroups),
      getRoute(RouteId.ComparisonTeams),
      getRoute(RouteId.ComparisonStores)
    ]
  },
  getRoute(RouteId.DayByDayHourByHour),
  {
    name: MENU_ANIMATION_LINKS_GROUPS_ANNUAL_INITIALIZATION,
    icon: "ads_click",
    filled: false,
    rights: [PERMISSIONS.PILOTING_ANNUAL_INITIALIZATION_ACCESS],
    children: [getRoute(RouteId.AnnualInitialization), getRoute(RouteId.AnnualInitializationTeams)]
  },
  getRoute(RouteId.EffitimeIframe),
  getRoute(RouteId.Areas),
  getRoute(RouteId.UpwardForecast),
  getRoute(RouteId.ReportingExtraction),
  getRoute(RouteId.Askr)
];
