import { I18n } from "react-redux-i18n";
import { isNil } from "lodash";

import NumberUtils, { getLeadingZeroNumber } from "core/utils/number";
import { IRange } from "core/models/date";
import { IMonths, IWeekDays } from "components/common/date-picker/calendar";
import { IFilterDateRangeYearConstant } from "core/constants/filter-constants";

import {
  addWeeks,
  endOfWeek,
  getISOWeek,
  getISOWeeksInYear,
  getWeek,
  getWeekYear,
  parseISO,
  startOfDay,
  startOfWeek,
  startOfYear,
  subWeeks
} from "date-fns";
import moment from "moment";

export const NUMBER_WEEK_DAYS = 7;

const dayById = {
  "1": "days.sun",
  "2": "days.mon",
  "3": "days.tue",
  "4": "days.wed",
  "5": "days.thu",
  "6": "days.fri",
  "7": "days.sat"
};

/**
 * Get the week number and the year this week belongs to for the current date
 *
 * First week is `1` (not `0`)
 *
 * /!\ At the beginning of the year, it might return the corresponding week in the previous year.
 * For instance, on 2021-01-01, it will return [2020, 53] because this date belongs to the last week
 * of 2020.
 */
export const getYearAndWeek = (today: Date = new Date()) => {
  // the first week is the week with the first thursday of january
  const year = getWeekYear(today, { weekStartsOn: 0, firstWeekContainsDate: 3 });
  const week = getWeek(today, { weekStartsOn: 0, firstWeekContainsDate: 3 });
  return [year, week];
};

/**
 * Get the number of weeks in the given year
 */
export const getNumberOfWeeks = (year: number): 52 | 53 => {
  const firstDayOfYear = new Date(year, 0, 1);
  const totalWeeks = getISOWeeksInYear(firstDayOfYear);
  if (totalWeeks !== 52 && totalWeeks !== 53) {
    throw new Error("A year should have 52 or 53 weeks");
  }
  return totalWeeks;
};

export const getYesterday = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const formattedDate = yesterday.toISOString().split("T")[0];
  return formattedDate;
};

export const getWeeksOfYear = (year: number) => {
  if (year) {
    const startDate = startOfYear(new Date(year, 0, 1));
    const totalWeeks = getISOWeeksInYear(startDate);
    const weeks = [];
    for (let i = 1; i <= totalWeeks; i++) {
      weeks.push({ id: i, label: `W${i < 10 ? "0" + i : i}` });
    }
    return weeks;
  }
  return [];
};

export const getNextWeeksFromDate = (
  date: Date,
  yearFilter: number,
  weekToPlan = 18
): { id: number; label: string }[] => {
  let week = getISOWeek(date);
  let year = date.getFullYear();
  const weeks = [];

  for (let i = 0; i < weekToPlan; i++) {
    if (year === yearFilter) {
      weeks.push({ id: week, label: `W${week.toString().padStart(2, "0")}` });
    }
    week++;
    if ((week > 52 && year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
      week = 1;
      year++;
    } else if (week > 52) {
      week = 1;
      year++;
    }
  }
  return weeks;
};

/**
 * Get the YEAR-Wnb (ex: 2023-W37) representation for a given year & week number
 * @param year
 * @param week
 */
export const getYearWeekFormat = (year: number, week: number, withCaretSeparator = true) => {
  const weekWith0IfWeekNumberUnderTen = `0${week}`.slice(-2);
  const separator = withCaretSeparator ? "-" : "";
  return `${year}${separator}W${weekWith0IfWeekNumberUnderTen}`;
};

export default class DateUtils {
  public static getDefaultFromTo = () => {
    // Get the current date
    const currentDate = new Date();

    // Subtract one week to get the date of the last week
    const lastWeek = subWeeks(currentDate, 1);

    // Find the start of the last week (Sunday)
    const lastWeekStart = startOfWeek(lastWeek, { weekStartsOn: 0 });

    // Create an array to store the 7 days of the last week
    const lastWeekDays = [];

    // Loop to add each day of the week
    for (let i = 1; i < 8; i++) {
      const day = new Date(lastWeekStart);
      day.setDate(lastWeekStart.getDate() + i);
      lastWeekDays.push(day);
    }

    return lastWeekDays;
  };

  public static getFirstDayLastDayOfLastWeek = (currentDate: Date = new Date()): { firstDay: Date; lastDay: Date } => {
    const currentDay = currentDate.getDay(); // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
    const lastSunday = new Date(currentDate); // Clone the current date
    lastSunday.setDate(currentDate.getDate() - currentDay - 7); // Subtract the current day to get last Sunday
    const lastSaturday = new Date(lastSunday);
    lastSaturday.setDate(lastSunday.getDate() + 6); // Add 6 days to get last Saturday

    return {
      firstDay: lastSunday,
      lastDay: lastSaturday
    };
  };

  public static getUTCDate = (date?: number | string | Date, offset = 0): Date => {
    // create Date object for current location
    const d = date ? new Date(date) : new Date();
    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    d.setUTCMilliseconds(0);
    // convert to msec
    // add local time zone offset
    // get UTC time in msec
    const utc = d.getTime() + Math.abs(d.getTimezoneOffset()) * 60000;
    // create new Date object for different city
    // using supplied offset
    return new Date(utc + 3600000 * offset);
  };

  public static getCurrentWeek = (): number => {
    return DateUtils.getWeekNumber(DateUtils.getCurrentDate());
  };

  public static getCurrentYear = (): number => {
    return DateUtils.getCurrentDate().getFullYear();
  };

  public static getCurrentDate(): Date {
    return new Date();
  }

  public static formatDate(date: Date, separator = "-"): string {
    const month = getLeadingZeroNumber(date?.getMonth() + 1);
    const day = getLeadingZeroNumber(date?.getDate());
    const year = date?.getFullYear();

    return [year, month, day].join(separator);
  }

  public static getDate(date: string): Date {
    if (Number.isNaN(Date.parse(date))) {
      return null;
    }
    const dayDate = new Date(date);
    dayDate.setHours(0);
    return dayDate;
  }

  public static isWeekIncludedInYear(weekNumber: number, year: number): boolean {
    const totalYearWeeks = this.getTotalYearWeeksNumber(year);

    return weekNumber <= totalYearWeeks;
  }

  /**
   * @param dayId the day id in the week example : "1" is first day of week (sunday), "7" is the last day of week (saturday)
   * @param weekId "W01" the week number prefixed by "w" letter
   * @param year "2020"
   * @return date with format "day dd/MM"
   */
  public static dayToLabel(date: string): string {
    if (Number.isNaN(Date.parse(date))) {
      return null;
    }
    const dayDate = startOfDay(parseISO(date));
    const dayIndex = dayDate.getDay() + 1;
    const day = this.setLeadingZeroNumber(dayDate.getDate());
    // getMonth index starts with 0 so we add 1 to jave january = 1, etc
    const month = this.setLeadingZeroNumber(dayDate.getMonth() + 1);
    return `${I18n.t(dayById[dayIndex])} ${day}/${month}`;
  }

  /**
   * @return date with format "day DD month AA"
   */
  public static dayToYearMonthLabel(date: string | Date): string {
    if (!(date instanceof Date) && Number.isNaN(Date.parse(date as unknown as string))) {
      return null;
    }
    const dayDate = this.getUTCDate(date);
    const dayIndex = dayDate.getUTCDay() + 1;
    const day = this.setLeadingZeroNumber(dayDate.getUTCDate());
    // getMonth index starts with 0 so we add 1 to jave january = 1, etc
    const month = this.getMonthsLabel()[dayDate.getUTCMonth()];
    return dayById && dayIndex ? `${I18n.t(dayById[dayIndex])} ${day} ${month} ${dayDate.getUTCFullYear()}` : "";
  }

  public static getWeekNumber(date: Date, weekStartsOnSunday = true): number {
    // This condition is added as Fix for MG-3522
    if (date == null) {
      return 1;
    } else {
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      weekStartsOnSunday
        ? date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 0))
        : date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
      const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
      const weekNo = Math.ceil(((date.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7);
      return weekNo;
    }
  }

  public static getNextWeekDateFromDate(date: Date): Date {
    let currentDate = this.getUTCDate(date);
    currentDate = this.getFirstDayOfWeekFromDate(currentDate);
    const totalMillisOfAWeek = 7 * 24 * 60 * 60 * 1000;
    return this.getUTCDate(currentDate.getTime() + totalMillisOfAWeek);
  }

  public static getPreviousWeekDateFromDate(date: Date): Date {
    let currentDate = this.getUTCDate(date);
    currentDate = this.getFirstDayOfWeekFromDate(currentDate);
    const totalMillisOfAWeek = 7 * 24 * 60 * 60 * 1000;
    return this.getUTCDate(currentDate.getTime() - totalMillisOfAWeek);
  }

  public static getWeekDateFromWeekRange(date: Date, range: number): Date {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return new Date(date.getTime() + range * 7 * 24 * 60 * 60 * 1000);
  }

  public static getWeekDateRange(date: Date): IRange<Date> {
    return {
      from: this.getFirstDayOfWeekFromDate(date),
      to: this.getLastDayOfWeekFromDate(date)
    };
  }

  public static getCurrentFromEndYearToDateRange(date: Date): IRange<Date> {
    const endingDate = new Date(date.getFullYear(), 11, 31);
    return {
      from: this.getFirstDayOfWeekFromDate(date),
      to: endingDate
    };
  }

  public static getDatepickerDateRange(previousAvalaibleYears: number, nextAvalaibleYears: number): IRange<Date> {
    const currentYear = new Date().getFullYear();
    const fromYear = currentYear - previousAvalaibleYears;
    const toYear = currentYear + nextAvalaibleYears;

    const beginningDate = new Date(fromYear, 0);
    const endingDate = new Date(toYear, 11, 31);

    return {
      from: beginningDate,
      to: endingDate
    };
  }

  public static getFirstDayOfWeekFromDate(date: Date): Date {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    date.setUTCDate(date.getUTCDate() - (date.getUTCDay() || 0));
    return this.getUTCDate(date);
  }

  public static getLastDayOfWeekFromWeekLabel(weekLabel: string): Date {
    const date = this.getDateFromWeekLabel(weekLabel);
    return this.getLastDayOfWeekFromDate(date);
  }

  public static getFirstDayOfWeekFromWeekLabel(weekLabel: string): Date {
    if (this.isValidWeekLabel(weekLabel)) {
      const date = this.getDateFromWeekLabel(weekLabel);
      return this.getFirstDayOfWeekFromDate(date);
    }
  }

  public static getFirstDayOfWeekFromWeekLabelDayDay(weekLabel: string): Date {
    if (this.isValidWeekLabel(weekLabel)) {
      const date = this.getDateFromWeekLabelDayDay(weekLabel);
      return this.getFirstDayOfWeekFromDate(date);
    }
  }

  public static getLastDayOfWeekFromDate(date: Date): Date {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    date.setUTCDate(date.getUTCDate() + 6 - (date.getUTCDay() || 0));
    return this.getUTCDate(date);
  }

  /**
   * Gets the first day of the week (monday)
   */
  public static getFirstDayOfWeek(year: number, week: number) {
    // MG-2712
    const weekString = `${year}${week}`;
    const weekDate = moment(weekString, "YYYYWW");
    return new Date(weekDate.weekday(1).format());
  }

  public static getDateFromWeekLabel(yearWeekLabel: string) {
    const firstDayOfYear = new Date(this.getYearNumberFromLabel(yearWeekLabel), 0, 1);
    const day = 8.64e7;
    const weekDayCount = 7;
    const weekNumber = this.getWeekNumberFromLabel(yearWeekLabel);
    const minusWeek = this.getWeekNumber(firstDayOfYear) === 1 ? 1 : 0;
    return new Date(firstDayOfYear.getTime() + day * weekDayCount * (weekNumber - minusWeek));
  }

  public static formatFromWeekLabelToYYYYMMDDFirstDayOfWeek(yearWeekLabel: string) {
    const date = this.getDateFromWeekLabel(yearWeekLabel);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1 and pad with leading zeros if needed.
    const day = String(date.getDate()).padStart(2, "0"); // Pad with leading zeros if needed.

    return `${year}-${month}-${day}`;
  }
  public static formatFromWeekLabelToYYYYMMDDLastDayOfWeek(yearWeekLabel: string) {
    const date = this.getDateFromWeekLabel(yearWeekLabel);
    const year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1 and pad with leading zeros if needed.
    const day = this.getLastDayOfWeekFromWeekLabel(yearWeekLabel).getDate().toString().padStart(2, "0"); // Pad with leading zeros if needed.

    // If the next month is 1 (i.e., December to January), update the year to the next year.
    if (parseInt(day) < 7 && date.getMonth() === 11 /* December */) {
      month = "01"; // January is month 0, so set it to 01 and update the year.
      return `${year + 1}-${month}-${day}`;
    }

    // Otherwise, increment the month normally.
    if (parseInt(day) < 7) {
      month = `${parseInt(month) + 1}`.padStart(2, "0");
    }

    return `${year}-${month}-${day}`;
  }

  public static getDateFromWeekLabelDayDay(yearWeekLabel: string) {
    // Création d'un objet Date représentant le premier jour de l'année correspondant à l'étiquette de semaine fournie
    const firstDayOfYear = new Date(Date.UTC(this.getYearNumberFromLabel(yearWeekLabel), 0, 1));

    // Nombre de millisecondes dans une journée
    const day = 8.64e7;

    // Nombre de jours dans une semaine
    const weekDayCount = 7;

    // Numéro de la semaine correspondant à l'étiquette de semaine fournie
    const weekNumber = this.getWeekNumberFromLabel(yearWeekLabel);

    // Nombre de semaines à soustraire pour obtenir le jour de la semaine correspondant au premier jour de l'année
    const minusWeek = this.getWeekNumber(firstDayOfYear) === 1 ? 1 : 0;

    // Calcul de la date correspondant à l'étiquette de semaine fournie
    const targetDate = new Date(
      Date.UTC(firstDayOfYear.getUTCFullYear(), 0, 1) + day * weekDayCount * (weekNumber - minusWeek)
    );

    // Retour de la date calculée
    return targetDate;
  }

  public static getFirstAndLastDaysOfYear(year: number) {
    return {
      firstDayOfYear: this.getUTCDate(`${year}-01-01`),
      lastDayOfYear: this.getUTCDate(`${year}-12-31`)
    };
  }

  /**
   * get the correct yearNumber
   * when the date in one of the first days of january but belong to the last week of the previous year
   * when the date in one of the last days of december but belong to the first week of the next year
   */
  public static getYearWeekFromDate(date: Date = DateUtils.getCurrentDate()): number {
    const year = date.getFullYear();
    const month = date.getMonth();
    const weekNumber = this.getWeekNumber(date);
    // when it's last week
    const isLastYearWeek = weekNumber === 52 || weekNumber === 53;
    const isInJanuary = month === 0;
    // when it's first week
    const isFirstYearWeek = weekNumber === 1;
    const isInDecember = month === 11;

    if (isLastYearWeek && isInJanuary) {
      return year - 1;
    }

    if (isFirstYearWeek && isInDecember) {
      return year + 1;
    }

    return year;
  }

  public static getLabelFromDate(date: Date): string {
    const weekNumber = this.getWeekNumber(date);
    const yearNumber = this.getYearWeekFromDate(date);
    if (isNaN(yearNumber) || isNaN(date.getMonth()) || isNaN(date.getDate())) {
      return "";
    }
    return this.getWeekLabel(yearNumber, weekNumber);
  }

  public static getTranslatedWeekLabelFromDate(date: Date): string {
    const weekNumber = NumberUtils.leadingZeroDate(this.getWeekNumber(date));
    const yearNumber = this.getYearWeekFromDate(date);

    if (isNaN(yearNumber) || isNaN(date.getMonth()) || isNaN(date.getDate())) {
      return "";
    }
    return I18n.t("weekLabel", { year: yearNumber, week: weekNumber });
  }

  public static compareDates(a: Date, b: Date) {
    const dt1 = new Date(a);
    const dt2 = new Date(b);
    return Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
  }

  public static getMonthsLabel(): IMonths {
    return [
      I18n.t("months.jan"),
      I18n.t("months.feb"),
      I18n.t("months.mar"),
      I18n.t("months.apr"),
      I18n.t("months.may"),
      I18n.t("months.jun"),
      I18n.t("months.jul"),
      I18n.t("months.aug"),
      I18n.t("months.sep"),
      I18n.t("months.oct"),
      I18n.t("months.nov"),
      I18n.t("months.dec")
    ];
  }

  public static getSelectMonths() {
    return [
      { id: 1, label: I18n.t("months.jan") },
      { id: 2, label: I18n.t("months.feb") },
      { id: 3, label: I18n.t("months.mar") },
      { id: 4, label: I18n.t("months.apr") },
      { id: 5, label: I18n.t("months.may") },
      { id: 6, label: I18n.t("months.jun") },
      { id: 7, label: I18n.t("months.jul") },
      { id: 8, label: I18n.t("months.aug") },
      { id: 9, label: I18n.t("months.sep") },
      { id: 10, label: I18n.t("months.oct") },
      { id: 11, label: I18n.t("months.nov") },
      { id: 12, label: I18n.t("months.dec") }
    ];
  }

  public static getMonthLabelFromDate(date: Date): string {
    return this.getMonthsLabel()[date.getMonth()];
  }

  public static getLongWeekDays(): IWeekDays {
    return [
      I18n.t("days.sun"),
      I18n.t("days.mon"),
      I18n.t("days.tue"),
      I18n.t("days.wed"),
      I18n.t("days.thu"),
      I18n.t("days.fri"),
      I18n.t("days.sat")
    ];
  }

  public static getShortWeekDays(): IWeekDays {
    return [
      I18n.t("days.min.sun"),
      I18n.t("days.min.mon"),
      I18n.t("days.min.tue"),
      I18n.t("days.min.wed"),
      I18n.t("days.min.thu"),
      I18n.t("days.min.fri"),
      I18n.t("days.min.sat")
    ];
  }

  public static isCurrentWeekLabel(week: string, currentWeek: number): boolean {
    return this.getWeekNumberFromLabel(week).toString() === currentWeek.toString();
  }

  public static getWeekNumberFromLabel(week: string): number {
    const rexp = new RegExp(`([0-9]{4})[W,${I18n.t("weekLetter")}]([0-9]{1,2})`);
    const result = rexp.exec(week);
    return result ? parseFloat(result[2].replace(/\b0+/g, "")) : null;
  }

  public static getYearNumberFromLabel(week: string): number {
    const yearMatch = week.match(/^\d{4}/);
    return parseInt(yearMatch[0]);
  }

  public static getMonthNumeric(date: Date): number {
    return date.getMonth() + 1;
  }

  public static getDateBeginningMonth(date: Date = new Date()): Date {
    date.setDate(1);
    return date;
  }

  public static getDateStringFromMonthLabel(subtitle: string): string {
    return subtitle.replace("M", "-");
  }

  public static getDateFromMonth(month: number, year?: number) {
    const date = new Date();
    date.setFullYear(year || date.getFullYear(), month - 1, 1);
    return date;
  }

  public static getFormattedDate(date: Date) {
    const month = NumberUtils.leadingZeroDate(date.getMonth() + 1);
    const day = NumberUtils.leadingZeroDate(date.getDate());
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  public static getFormattedYearMonth(date: Date) {
    const month = NumberUtils.leadingZeroDate(date.getMonth() + 1);
    const year = date.getFullYear();

    return `${year}-${month}`;
  }

  public static getThreeYearsRange(yearDefault: number) {
    const yearPlusOne: number = yearDefault + 1;
    const yearMinusOne: number = yearDefault - 1;
    const years: number[] = [yearMinusOne, yearDefault, yearPlusOne];
    return years;
  }

  public static getNextNYears(yearDefault: number, n: number) {
    return Array.from({ length: n }, (_, index) => yearDefault + index);
  }

  public static getMonthLabel(date: Date): string {
    const numericMonthValue = NumberUtils.leadingZeroDate(this.getMonthNumeric(date));
    return I18n.t("monthLabel", { year: date.getFullYear(), month: numericMonthValue });
  }

  // translated from english always
  public static getTranslatedWeekLabel(weekLabel: string): string {
    if (this.isValidEnWeekLabel(weekLabel)) {
      const labelParts = weekLabel.split("W");
      const yearNumber = parseInt(labelParts[0], 10);
      const weekNumber = parseInt(labelParts[1], 10);
      return yearNumber && weekNumber
        ? I18n.t("weekLabel", { year: yearNumber, week: NumberUtils.leadingZeroDate(weekNumber) })
        : "";
    }
    return "";
  }

  public static getWeekLabel(yearNumber: number, weekNumber: number): string {
    return weekNumber && yearNumber && this.isWeekIncludedInYear(weekNumber, yearNumber)
      ? `${yearNumber}W${NumberUtils.leadingZeroDate(weekNumber)}`
      : "";
  }

  public static getEnWeekLabel(yearNumber: number, weekNumber: number): string {
    return `${yearNumber}W${NumberUtils.leadingZeroDate(weekNumber)}`;
  }

  public static getEnWeekLabelFromIntWeekLabel(internationalWeekLabel: string): string {
    return internationalWeekLabel.replace(I18n.t("weekLetter"), "W");
  }

  public static isValidEnWeekLabel(weekLabel: string): boolean {
    const rexp = /(\d{4})W(\d{1,2})/;
    return !!rexp.exec(weekLabel);
  }

  // from english always
  public static getTranslatedYearMonthLabel(monthLabel: string): string {
    if (this.isValidEnYearMonthLabel(monthLabel)) {
      const labelParts = monthLabel.split("M");
      return this.getYearMonthLabel(parseInt(labelParts[0], 10), parseInt(labelParts[1], 10));
    }
    return "";
  }

  public static isValidEnYearMonthLabel(monthLabel: string): boolean {
    const rexp = /(\d{4})M(\d{1,2})/;
    return !!rexp.exec(monthLabel);
  }

  public static getYearMonthLabel(yearNumber: number, monthNumber: number): string {
    return monthNumber && yearNumber
      ? I18n.t("monthLabel", { year: yearNumber, month: NumberUtils.leadingZeroDate(monthNumber) })
      : "";
  }

  public static getTotalYearWeeksNumber(year: number): number {
    return moment(`${year}-12-31`, "YYYY-MM-DD").isoWeeksInYear();
  }

  public static getLastYearWeekLabel(year: number) {
    return this.getWeekLabel(year, this.getTotalYearWeeksNumber(year));
  }

  public static isValidWeekLabel(weekLabel: string): boolean {
    if (!isNil(weekLabel)) {
      const rexp = new RegExp(`([0-9]{4})[${I18n.t("weekLabel")},W]([0-9]{1,2})`);
      return !!rexp.exec(weekLabel);
    }
    return false;
  }

  public static getPreviousYear(date: Date, yearsToSubtract = 1): Date {
    return new Date(date.setFullYear(date.getFullYear() - yearsToSubtract));
  }

  public static getPreviousYearNumber(date: Date, yearsToSubtract = 1): number {
    return date.getFullYear() - yearsToSubtract;
  }

  public static getNextYearNumber(date: Date, yearsToAdd = 1): number {
    return date.getFullYear() + yearsToAdd;
  }

  public static setLeadingZeroNumber(monthIndex): string {
    return monthIndex < 10 ? `0${monthIndex}` : monthIndex.toString();
  }

  public static getNextMonthIndex(date: Date, monthToAdd = 1): string {
    const monthIndex = this.getMonthNumeric(date) + monthToAdd;
    const validMonthIndex = monthIndex > 12 ? monthIndex % 12 : monthIndex;
    return this.setLeadingZeroNumber(validMonthIndex);
  }

  public static isInFilterRange(FILTER_DATE_RANGE_YEAR: IFilterDateRangeYearConstant, year: number): boolean {
    return year >= FILTER_DATE_RANGE_YEAR.from && year <= FILTER_DATE_RANGE_YEAR.to;
  }

  public static isFutureWeek(weekLabel: string): boolean {
    const currentWeek = DateUtils.getCurrentWeek();
    const currentYear = DateUtils.getCurrentYear();
    const currentWeekLabel = DateUtils.getWeekLabel(currentYear, currentWeek);
    const weekNumber = DateUtils.getWeekNumberFromLabel(weekLabel);
    const year = DateUtils.getYearNumberFromLabel(weekLabel);
    return !DateUtils.isPrevWeek(weekNumber, year) && weekLabel !== currentWeekLabel;
  }

  public static addDays(date: Date, days: number): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  /**
   * Checks if the given week represented by 'weekNumber' and 'year' is in a previous week
   * compared to the current week and year.
   * @param weekNumber The week number to be checked.
   * @param year The year associated with the week number.
   * @returns 'true' if the specified week is in a previous week, 'false' otherwise.
   */
  public static isPrevWeek(weekNumber: number, year: number): boolean {
    // Get the current week and year
    const currentWeek = DateUtils.getCurrentWeek();
    const currentYear = DateUtils.getCurrentYear();

    // Compare the given year and week to the current year and week
    return year < currentYear || (year === currentYear && weekNumber < currentWeek);
  }

  public static isPrevOrActualWeek(weekNumber: number, year: number): boolean {
    // Get the current week and year
    const currentWeek = DateUtils.getCurrentWeek();
    const currentYear = DateUtils.getCurrentYear();

    // Compare the given year and week to the current year and week
    return year < currentYear || (year === currentYear && weekNumber <= currentWeek);
  }

  // format date in YYYY-MM-DD
  public static toShortIsoString(date: Date) {
    return date.toISOString().split("T")[0];
  }

  public static getFirstDayOfMonth(year: number, month: number): Date {
    return new Date(year, month - 1, 1);
  }

  public static getLastDayOfMonth(year: number, month: number): Date {
    return new Date(year, month, 0);
  }

  public static getYearsByRange = (yearDefault: number, nbYearsBefore: number, nbYearsAfter: number) => {
    let yearStart: number = yearDefault - nbYearsBefore;

    return {
      yearStart: yearStart,
      yearDefault: yearDefault,
      yearEnd: yearDefault + nbYearsAfter,
      yearsArray: Array(1 + nbYearsBefore + nbYearsAfter)
        .fill(0)
        .map(() => ({ id: yearStart++ }))
    };
  };

  /**
   *
   * @param myDate as timestamp number
   * @returns as string formatted (2000W01)
   */
  public static formatDateWeek = (timestampDate: number) => {
    const date = new Date(timestampDate);
    const week = this.getWeekNumber(date);
    const year = this.getYearWeekFromDate(date);
    const formattedDate = this.getWeekLabel(year, week);
    return formattedDate;
  };

  /**
   *
   * @param date to verify
   * @param range interval of date
   * @returns boolean if date is in interval date
   */
  public static rangeContainsDate(date: Date, range: IRange<Date>): boolean {
    return date > range.from && date < range.to;
  }

  public static dateToYearWeekFormat(date: Date): string {
    if (!date) return "";
    const weekNumber = NumberUtils.leadingZeroDate(this.getWeekNumber(date));
    const yearNumber = this.getYearWeekFromDate(date);
    return [yearNumber, weekNumber].join("W");
  }

  public static yearWeekFormatParts(formatted: string): [string, string] | [] {
    if (!formatted) return [];
    return formatted.split("W") as [string, string];
  }

  public static yearWeekFormatToDate(formatted: string): Date {
    if (formatted) {
      const [year, week] = this.yearWeekFormatParts(formatted);
      if (year && week) {
        return this.getDateFromWeekLabel(formatted);
      }
    }
    return null;
  }

  public static formatYYYYMMDD = dateString => {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return `${year}-${month}-${day}`;
  };

  public static getYesterdayDate = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday;
  };

  public static formatDateToYYYYMMDD = (date: Date) => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  public static rangeDateIsInsideRangeDateReference = (rangeDate: Date[], rangeDateReference: Date[]) => {
    const rangeDateFrom = rangeDate[0];
    const rangeDateTo = rangeDate[1];

    const rangeDateReferenceFrom = rangeDateReference[0];
    const rangeDateReferenceTo = rangeDateReference[1];

    return rangeDateFrom >= rangeDateReferenceFrom && rangeDateTo <= rangeDateReferenceTo;
  };

  public static getPreviousWeekNumber(weekNumber, year) {
    const startDateOfWeek = startOfWeek(new Date(year, 0, 1 + (weekNumber - 1) * 7), { weekStartsOn: 0 });
    const previousStartDate = addWeeks(startDateOfWeek, -1);

    return getWeek(previousStartDate, { weekStartsOn: 0 });
  }

  public static getWeekDates(week, year) {
    const startDateUTC = startOfWeek(new Date(Date.UTC(year, 0, 1 + (week - 1) * 7)), { weekStartsOn: 0 });
    const endDateUTC = endOfWeek(startDateUTC, { weekStartsOn: 0 });

    return { fromDate: startDateUTC, toDate: endDateUTC };
  }
}
