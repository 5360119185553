import { ReactElement } from "react";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { I18n } from "react-redux-i18n";

interface IProps {
  onConfirmAction: () => void;
  onCancelAction: () => void;
  message: string;
  isOpen: boolean;
  canClickOutside?: boolean;
  actions?: ReactElement[];
}

export default class ConfirmModal extends React.Component<IProps> {
  constructor(props) {
    super(props);
    this.getActions = this.getActions.bind(this);
  }

  private getActions(): ReactElement[] {
    const { actions, onCancelAction, onConfirmAction } = this.props;
    if (actions && actions.length > 0) {
      return actions;
    }
    return [
      <Button key="noBtn" onClick={onCancelAction}>
        {I18n.t("no")}
      </Button>,
      <Button key="yesBtn" color="primary" onClick={onConfirmAction}>
        {I18n.t("yes")}
      </Button>
    ];
  }

  public render() {
    const { isOpen, onCancelAction, onConfirmAction, message, canClickOutside } = this.props;

    return (
      <Dialog className="mg-alert" open={isOpen} onClose={canClickOutside && onCancelAction} id="modal">
        <DialogContent>
          <div className="mg-alert__content">
            <div className="mg-alert__content__icon">
              <span className="material-icons notranslate">info</span>
            </div>
            <span className="mg-alert__content__text">{message}</span>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="mg-alert__buttons">
            <button
              className="vtmn-btn vtmn-btn_size--small vtmn-btn_variant--secondary mg-button-delete"
              onClick={onCancelAction}
            >
              {I18n.t("no")}
            </button>
            <button className="vtmn-btn vtmn-btn_size--small" onClick={onConfirmAction}>
              {I18n.t("yes")}
            </button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}
