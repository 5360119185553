import * as React from "react";
import { useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";

import { IErrors } from "pages/templates/templates";
import SportsGroupPanel from "./sports-group-panel";
import GroupsRead from "components/common/mygame/groups/groups-read";
import GroupsTabs from "components/common/mygame/groups/groups-tabs";
import {
  GROUPS_CRUD_CREATE_SPORTS,
  GROUPS_CRUD_EDIT_SPORTS,
  GROUPS_CRUD_LIST_SPORTS,
  GROUPS_INFORMATION_EMPTY_SPORTS
} from "core/constants/translations/groups";
import { useApiGetSportsGroup } from "core/api/sports-group/useApiGetSportsGroup";
import { ISportsGroup } from "core/models/api/sports-group/sports-group-piloting";

export interface IDumbSportsGroupListProps {
  sportsGroups: ISportsGroup[];
}

export const DumbSportsGroupList = ({ sportsGroups }: IDumbSportsGroupListProps) => {
  const [errors, setErrors] = React.useState<IErrors>({});
  const [isActiveTab, setIsActiveTab] = useState("read");
  const handleClickOnTab = action => {
    if (action === "create") {
      setSportsGroup({
        id: null,
        label: "",
        composition: [],
        contains_more_than30_department: false,
        can_be_use_on_comparison_screen: false,
        contains_unique_store: true,
        main_store_id: null
      });
      setIsActiveTab(action);
      return;
    }
    setIsActiveTab(action);
  };

  const [sportsGroup, setSportsGroup] = React.useState<ISportsGroup>(null);
  const sportsGroupId = sportsGroup ? sportsGroup.id : null;
  const sportsGrouplabel = sportsGroup ? sportsGroup.label : null;

  const onSportsGroupChange = React.useCallback(
    (newSportsGroup: ISportsGroup | Partial<ISportsGroup>) => {
      const id = newSportsGroup.id || sportsGroupId;
      const label = newSportsGroup.label || sportsGrouplabel;
      setSportsGroup(actualSportsGroup => ({ ...actualSportsGroup, ...newSportsGroup }));
      const hasSportsGroup = sportsGroups.some(
        currentSportsGroup => (!id || currentSportsGroup.id !== id) && currentSportsGroup.label === label
      );
      if (hasSportsGroup) {
        setErrors({ ...errors, label: I18n.t("error.label-exists") });
      } else if (errors.label) {
        setErrors({ ...errors, label: null });
      }
    },
    [errors, sportsGroups, sportsGroupId, sportsGrouplabel]
  );

  const onEditSportsGroup = React.useCallback((sportsGroup: ISportsGroup) => setSportsGroup(sportsGroup), []);

  const onClosePanel = React.useCallback(() => {
    setSportsGroup(null);
    setErrors({});
    setIsActiveTab("read");
  }, []);

  useEffect(() => {
    sportsGroupId !== null && setIsActiveTab("edit");
  }, [sportsGroupId]);

  useEffect(() => {
    isActiveTab === "read" && setSportsGroup(null);
  }, [isActiveTab]);

  return (
    <>
      <div className="mg-groups-tab">
        <GroupsTabs
          isActiveTab={isActiveTab}
          handleClickOnTab={handleClickOnTab}
          read={I18n.t(GROUPS_CRUD_LIST_SPORTS)}
          create={I18n.t(GROUPS_CRUD_CREATE_SPORTS)}
          edit={I18n.t(GROUPS_CRUD_EDIT_SPORTS)}
        />
      </div>

      <div className="mg-groups-content">
        {isActiveTab === "read" && (
          <GroupsRead
            onEditGroup={onEditSportsGroup}
            groups={sportsGroups}
            informationWhenIsEmpty={I18n.t(GROUPS_INFORMATION_EMPTY_SPORTS)}
            groupSelected={"sport"}
          />
        )}

        {isActiveTab === "create" && (
          <SportsGroupPanel
            sportsGroup={sportsGroup}
            onSportsGroupChange={onSportsGroupChange}
            onClose={onClosePanel}
            errors={errors}
            setIsActiveTab={setIsActiveTab}
          />
        )}

        {isActiveTab === "edit" && (
          <SportsGroupPanel
            sportsGroup={sportsGroup}
            onSportsGroupChange={onSportsGroupChange}
            onClose={onClosePanel}
            errors={errors}
            setIsActiveTab={setIsActiveTab}
          />
        )}
      </div>
    </>
  );
};

const SportsGroupList = () => {
  const { data: sportsGroups, isLoading } = useApiGetSportsGroup();
  return !isLoading && <DumbSportsGroupList sportsGroups={sportsGroups} />;
};

export default SportsGroupList;
