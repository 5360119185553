import * as React from "react";

import LogCardMessage from "components/common/cards/log-card-message";
import { CUSTOM_ROW_HEIGHT_OPTIONS } from "core/constants/table";
import UpwardForecastTableToolbar from "components/specific/upward-forecast/upward-forecast-table-toolbar";
import UpwardForecastRequestsManager from "components/specific/upward-forecast/containers/upward-forecast-request-container-manager";
import UpwardForecastExceptionManager from "components/specific/upward-forecast/data-manager/upward-forecast-exception-manager";
import UpwardForecastDataManager from "components/specific/upward-forecast/data-manager/upward-forecast-data-manager";
import UpwardForecastTable from "components/specific/upward-forecast/upward-forecast-table";
import TableInteractionsManagerCustom from "components/common/table/table-interactions-manager/table-interactions-manager-custom";
import { withFiltersManager } from "../../components/common/filters3/filters/hoc/manager";
import { useFilters } from "../../components/common/filters3/filters/hooks/useFilters";
import { FilterKey } from "../../core/redux/filters/reducers";
import countryFilter from "components/common/filters3/filters/definitions/composite/country";

interface UpwardForecastFilters {
  country: string;
}

const UpwardForecastContainer: React.FunctionComponent = () => {
  const {
    appliedFilters: { country },
    applyBulkChanges
  } = useFilters<UpwardForecastFilters>();

  return (
    <div className="settings">
      <div className="piloting-page upward-forecast-table">
        <UpwardForecastExceptionManager key={country} countryId={country}>
          {({ setIsSaveInProgress }) => {
            return (
              <React.Fragment>
                <UpwardForecastTableToolbar country={country} setFilters={applyBulkChanges} />
                {country ? (
                  <UpwardForecastRequestsManager country={country}>
                    {({ data }) => (
                      <UpwardForecastDataManager key={country} data={data} setIsSaveInProgress={setIsSaveInProgress}>
                        {({ table }) => {
                          return table && table.rows.length > 0 ? (
                            <TableInteractionsManagerCustom
                              key={country}
                              initialConfig={{
                                rowHeight: {
                                  value: CUSTOM_ROW_HEIGHT_OPTIONS.large,
                                  size: "large"
                                },
                                cellWidth: { value: 300, size: "large" }
                              }}
                            >
                              <UpwardForecastTable key={country} tableProps={table} />
                            </TableInteractionsManagerCustom>
                          ) : null;
                        }}
                      </UpwardForecastDataManager>
                    )}
                  </UpwardForecastRequestsManager>
                ) : (
                  <LogCardMessage messageKey="upward_forecast.fill_in_filters" />
                )}
              </React.Fragment>
            );
          }}
        </UpwardForecastExceptionManager>
      </div>
    </div>
  );
};

export default withFiltersManager<UpwardForecastFilters>(
  UpwardForecastContainer,
  {
    ...countryFilter
  },
  {
    withSnapshots: [FilterKey.COUNTRY]
  }
);
