import { memo, useCallback, useMemo } from "react";
import useObjFromList from "core/hooks/useObjFromList";
import { useSportsForStore } from "core/hooks/useSports";
import { ISport } from "core/api/rest/queries/select-sport";
import { comparing } from "core/utils/sort";
import classnames from "classnames";

import { I18n } from "react-redux-i18n";

import { Select } from "../select";
import { IProps, IDumbProps, dataLabelFormat, renderSelectAction } from "./select-sport";

export const DumbSelectSports = memo(
  ({
    id,
    sports = [],
    sportSortFunction = comparing<ISport>(it => it.department_id),
    disabledItemsIds,
    className,
    value,
    hasBottomLine,
    isLoading,
    onChange,
    isMultiple
  }: IDumbProps) => {
    const disabledItems = useMemo(() => {
      if (typeof disabledItemsIds === "function") {
        return disabledItemsIds(sports);
      }
      return disabledItemsIds;
    }, [sports, disabledItemsIds]);

    const sortedReducedSportsList = useMemo(() => [...sports].sort(sportSortFunction), [sports, sportSortFunction]);

    const selectData = useMemo(() => {
      return {
        sports: { label: I18n.t("filters.search_results.label"), data: sortedReducedSportsList }
      };
    }, [sortedReducedSportsList]);

    const sportsMap = useObjFromList(sports, "id");

    const renderValueSelectedItem = useCallback(
      selectedSports => {
        if (isLoading) {
          return I18n.t("loading");
        }
        if (!isMultiple) {
          const sport = sportsMap[selectedSports as unknown as number];
          return sport ? dataLabelFormat(sport) : I18n.t("settings.select_sport");
        }
        if (selectedSports.length === 1) {
          const indicator = sportsMap[selectedSports[0]];
          return indicator ? dataLabelFormat(indicator) : I18n.t("settings.select_sport");
        }
        return I18n.t("filters.multiple_selected_values");
      },
      [isLoading, sportsMap, isMultiple, disabledItems]
    );
    return (
      <Select
        id={id || "toolbar-filters_sport"}
        data={selectData}
        value={value}
        label={I18n.t("sport")}
        placeholder={I18n.t("settings.select_sport")}
        searchPlaceholder={I18n.t("settings.search_sport")}
        className={classnames("filter-select", className)}
        hasBottomLine={hasBottomLine}
        renderValue={renderValueSelectedItem}
        onChange={onChange}
        disabledItemsIds={disabledItems}
        selectItemLabelFormat={dataLabelFormat}
        isLoading={isLoading}
        isVirtualized
        isSearchable
        isMultiple={true}
        renderActions={renderSelectAction}
      />
    );
  }
);

export const SelectSportMulti = (props: IProps) => {
  const { storeId } = props;
  const { data: sports, isLoading } = useSportsForStore(storeId);
  return <DumbSelectSports sports={sports} isMultiple={true} {...props} isLoading={isLoading} />;
};

export default SelectSportMulti;
