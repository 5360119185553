import { ReactElement } from "react";
import classnames from "classnames";
import MUIDialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";

export interface IDialogProps extends Omit<DialogProps, "open"> {
  title?: string;
  titleClassname?: string;
  message?: string | ReactElement;
  actions?: ReactElement[];
  isOpen: boolean;
  id?: string;
}

export const Dialog = ({ id, isOpen, title, titleClassname, message, actions, className, ...others }: IDialogProps) => {
  return (
    <MUIDialog
      data-testid={`dialog-${id}`}
      className={classnames("settings-modal-confirm", className)}
      open={isOpen}
      {...others}
    >
      {title ? <DialogTitle className={titleClassname}>{title}</DialogTitle> : null}
      {message ? <DialogContent>{message}</DialogContent> : null}
      <DialogActions>{actions}</DialogActions>
    </MUIDialog>
  );
};

export default Dialog;
