import { FC, useCallback, useContext } from "react";
import { TableInteractionsContext } from "@decathlon/react-table";
import { IData } from "core/models/data";
import { DataManagerContext } from "components/common/data-manager/data-context";
import { SORT_STATUS } from "core/utils/headers";

export interface ISortColumnsButtonProps {
  rowId: string;
  getOrder: (data: IData, rowId: string, orderStatus: string) => Record<string, number>;
}

const SortColumnsButton: FC<ISortColumnsButtonProps> = ({ getOrder, rowId }) => {
  const { openTrees, table, fixedColumnsIds, updateFixedColumnsIds } = useContext(TableInteractionsContext);
  const {
    data,
    currentColumnsOrder: { orderId },
    sortTableColumns,
    resetTableColumnsOrder,
    getSortStatus,
    setSortStatus
  } = useContext(DataManagerContext);

  const status = getSortStatus();
  const isCurrentOrder = orderId === rowId;

  const sortColumns = useCallback(() => {
    if (!isCurrentOrder || status === SORT_STATUS.NONE) {
      setSortStatus(SORT_STATUS.ASC);
      const order = getOrder(data, rowId, SORT_STATUS.ASC);
      sortTableColumns({ orderId: rowId, itemsOrder: order });
      openTrees({});
      updateFixedColumnsIds(fixedColumnsIds);
    } else {
      if (status === SORT_STATUS.ASC) {
        setSortStatus(SORT_STATUS.DESC);
        const order = getOrder(data, rowId, SORT_STATUS.DESC);
        sortTableColumns({ orderId: rowId, itemsOrder: order });
        openTrees({});
        updateFixedColumnsIds(fixedColumnsIds);
      }

      if (status === SORT_STATUS.DESC) {
        setSortStatus(SORT_STATUS.NONE);
        resetTableColumnsOrder();
      }
    }
  }, [
    data,
    fixedColumnsIds,
    rowId,
    isCurrentOrder,
    getOrder,
    openTrees,
    sortTableColumns,
    table.current,
    updateFixedColumnsIds
  ]);

  return (
    <div onClick={sortColumns} className="fixed">
      {!isCurrentOrder && <span className="material-icons notranslate mg-button-sort-asc">signal_cellular_alt</span>}
      {isCurrentOrder && status === SORT_STATUS.ASC && (
        <span className="material-icons notranslate mg-button-sort-desc">signal_cellular_alt</span>
      )}
      {isCurrentOrder && status === SORT_STATUS.DESC && (
        <span className="material-icons notranslate mg-button-sort-close">cancel</span>
      )}
    </div>
  );
};

export default SortColumnsButton;
