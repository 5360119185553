import * as actionTypes from "./actionTypes";
import { UserAction } from "./actions";
import { IPreferences } from "../../models/api-user-data";

export interface UserRelatedState {
  uid: string;
  preferences: IPreferences;
  token: string;
}

export const INITIAL_STATE = {
  uid: null,
  preferences: {},
  token: null
};

const reducers = (state: UserRelatedState = INITIAL_STATE, action: UserAction) => {
  switch (action.type) {
    case actionTypes.SET_TOKEN:
      return { ...state, token: action.token };

    case actionTypes.SET_USER_PREFERENCES_FILTERS: {
      const currentUserPreferencesFilters = state.preferences && state.preferences.default_filters;
      return {
        ...state,
        preferences: { ...state.preferences, default_filters: { ...currentUserPreferencesFilters, ...action.filters } }
      };
    }
    default:
      return state;
  }
};

export default reducers;
