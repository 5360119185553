import { ComponentType, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { AdditionalFiltersOpts, Filter, SnapshotId } from "../definitions";
import { stringify as stringifyParams } from "qs";
import {
  AppliedFilters,
  FilterRegistrar,
  FilterRegistrarWithPlainValue,
  FiltersContainer,
  FiltersContainerInit,
  HandleBulkChangesFunc,
  ValidationReport,
  ValidatorsReport
} from "./types";
import {
  getFilterSnapshot,
  getFilterSnapshots,
  getSnapshotPlaceholder,
  saveFilterSnapshot,
  saveSnapshotValues
} from "../utils/snapshot";
import { filtersEntries, initFilters } from "../utils/filters";
import { buildFiltersURLParams } from "../utils/url";
import { FiltersContextProvider } from "../context/context";
import { getValidationReport, validateFilter } from "../utils/validators";
import FiltersBar from "../../../filters2/filters/filter-bar/filter-bar";
import { useNavigate } from "react-router";
import { IFilter } from "core/redux/filters/reducers";
import { setFilters as setFiltersAction } from "core/redux/filters/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "core/redux/store";
import { pastLastUsedFiltersFilters } from "core/hooks/useQueryParameters";

const convertFiltersToUrlFilters = (filters: IFilter) => {
  const urlFilters = {};
  if (filters) {
    for (const key in filters) {
      if (Object?.prototype?.hasOwnProperty?.call(filters, key)) {
        urlFilters[key] = filters?.[key]?.value;
      }
    }
  }
  return urlFilters;
};

export const useDispatchNewFilters = (filters: IFilter) => {
  // Ensure `filters` are valued at initialisation time only (this allows relying on
  // identity equality even if the caller gives a new object on every render)
  if (filters) {
    const baseFiltersKey = Object.keys(filters);
    const lastUsedFilters = useSelector<AppStore, IFilter>(state => state.filters);
    const dispatch = useDispatch();

    // Conversion of filters
    const urlFilters = convertFiltersToUrlFilters(filters);

    useEffect(() => {
      if (baseFiltersKey?.every(filter => filter in urlFilters)) {
        // the URL is complete => update last used filters (ensure we do not value non relevant query
        // parameters), the URL should stay untouched
        const newFilters = pastLastUsedFiltersFilters(lastUsedFilters, urlFilters);
        if (newFilters) {
          dispatch(setFiltersAction(newFilters));
        }
      }
    }, [baseFiltersKey, lastUsedFilters, dispatch]);
  }
};

const updateUrlInternal = (location, navigate, lastFilters, filters) => {
  const { pathname } = location;
  const filtersParams = stringifyParams(buildFiltersURLParams(lastFilters, filters));

  if (pathname !== "/" && filtersParams !== "") {
    navigate({ pathname, search: filtersParams });
  }
};

export const withFiltersManager = <T,>(
  Component: ComponentType<unknown>,
  baseFilters: FiltersContainerInit<T>,
  opts?: Partial<AdditionalFiltersOpts>
) => {
  // Note : This part is preparing the functions of default values in the baseFilters
  // Those functions are stored in order to be called in the right order
  // Thus, it can also be "react hooks" like useSelector()
  const filterDefaultsHooksValues = {} as { [K in keyof T]: unknown };
  const filtersDefaultFunctions = filtersEntries(baseFilters)
    .filter(pair => pair[1].useDefault)
    .map(pair => {
      const [name, { useDefault }] = pair as [string, { useDefault: () => unknown }];
      return () => (filterDefaultsHooksValues[name] = useDefault());
    });
  const filterDefaultHooksCycle = () => filtersDefaultFunctions.forEach(fn => fn());

  const FiltersBarComponent = () => <FiltersBar definitions={baseFilters} />;

  return ({ ...props }) => {
    filterDefaultHooksCycle();

    const location = useLocation();
    const navigate = useNavigate();

    const [changed, setChanged] = useState(false);
    const [filters, setFilters] = useState<FiltersContainer<T>>(() =>
      initFilters(baseFilters, filterDefaultsHooksValues, opts)
    );
    const lastFilters = useRef(filters);

    // Use for Piloting the Futur
    useDispatchNewFilters(filters);

    const getValidatorsReport = useCallback(() => getValidationReport(filters), [filters]);
    const filtersValid = useCallback(
      (validatorsReport: ValidatorsReport<T>) =>
        Object.values(validatorsReport).every((report: ValidationReport) => !report.length),
      []
    );

    const [validationReport, setValidationReport] = useState<ValidatorsReport<T>>(() => getValidatorsReport());
    const [isValid, setIsValid] = useState(() => filtersValid(validationReport));

    const getAppliedFilterStruct = useCallback(() => {
      return filtersEntries(filters).reduce((struct, pair) => {
        const [key, filter] = pair;
        struct[key] = filter.value;
        return struct;
      }, {}) as AppliedFilters<T>;
    }, [filters]);

    const [appliedFilters, setAppliedFilters] = useState<AppliedFilters<T>>(getAppliedFilterStruct());

    const clear = useCallback(() => {
      Object.values(filters).forEach((filter: Filter<unknown>) => (filter.value = null));
      setFilters({ ...filters });
    }, []);

    const setFilter = useCallback(
      (name, value) => {
        if (!filters[name]) return;

        const transform = filters[name].transform || (v => v);

        setFilters(oldFilters => ({
          ...oldFilters,
          [name]: {
            ...oldFilters[name],
            value: transform(value)
          }
        }));
      },
      [filters]
    );

    const registerWithHTMLEvent = useCallback<FilterRegistrar<T>>(
      name => ({ onChange: e => setFilter(name, e.target.value), value: filters[name].value }),
      [filters]
    );

    const registerWithPlainValueEvent = useCallback<FilterRegistrarWithPlainValue<T>>(
      name => ({ onChange: v => setFilter(name, v), value: filters[name].value }),
      [filters]
    );

    const updateUrl = useCallback(() => {
      updateUrlInternal(location, navigate, lastFilters, filters);
    }, [filters]);

    useEffect(() => {
      const report = getValidatorsReport();
      const valid = filtersValid(report);
      setValidationReport(report);
      setIsValid(valid);
      updateUrl();
    }, [filters]);

    const isAppliedValid = useMemo(() => {
      return Object.entries(appliedFilters).reduce((validation, [name, value]) => {
        const filter = filters[name];
        const isValid = !validateFilter(filter, value).filter(report => report !== null).length;
        return validation && isValid;
      }, true);
    }, [appliedFilters, filters]);

    useEffect(() => setChanged(JSON.stringify(lastFilters.current) !== JSON.stringify(filters)), [filters]);

    const updateFilterSnapshots = useCallback(() => {
      filtersEntries(filters).forEach(pair => {
        const [name, filter] = pair;
        const snapshot = filter.snapshot;
        if (snapshot) {
          const [id, value, merge] = [snapshot.id, filter.value, snapshot.merge];
          const values = (merge ? getFilterSnapshot(id) : {}) ?? {};
          saveSnapshotValues(id, {
            ...values,
            [name]: value
          });
        }
      });
    }, [filters]);

    const apply = () => {
      lastFilters.current = filters;
      setChanged(false);
      setAppliedFilters(getAppliedFilterStruct());
      updateUrl();
    };

    useEffect(() => {
      updateFilterSnapshots();
    }, [appliedFilters]);

    const snapshot = useCallback(
      (id: SnapshotId, withFilters?: Array<keyof T>) => saveFilterSnapshot(id, appliedFilters, withFilters),
      [appliedFilters]
    );

    const applySnapshots = useCallback((...ids: Array<SnapshotId>) => {
      const snapshots = getFilterSnapshots(ids);
      const patchedFilters = { ...filters };
      for (const filterKey in snapshots) {
        const currentFilter = patchedFilters[filterKey];
        patchedFilters[filterKey] = {
          ...currentFilter,
          value: snapshots[filterKey]
        };
      }
      setFilters(patchedFilters);
    }, []);

    const clearSnapshot = useCallback((id: SnapshotId) => {
      sessionStorage.removeItem(getSnapshotPlaceholder(id));
    }, []);

    const [lastHandleBulkChanges, setLastHandleBulkChanges] = useState<number>();
    const handleChanges = useCallback(name => value => setFilter(name, value), [filters]);
    const handleBulkChanges: HandleBulkChangesFunc<T> = useCallback(
      nameValueMap => {
        Object.entries(nameValueMap).forEach(([name, value]) => setFilter(name, value));
        setLastHandleBulkChanges(+new Date());
      },
      [filters]
    );

    useEffect(apply, [lastHandleBulkChanges]);

    return (
      <FiltersContextProvider
        value={{
          filters,
          registerWithHTMLEvent,
          registerWithPlainValueEvent,
          setFilter,
          changed,
          apply,
          appliedFilters,
          isValid,
          isAppliedValid,
          validationReport,
          clear,
          snapshot,
          clearSnapshot,
          applySnapshots,
          handleChanges,
          applyBulkChanges: handleBulkChanges,
          FiltersBar: FiltersBarComponent
        }}
      >
        <Component {...props} />
      </FiltersContextProvider>
    );
  };
};
