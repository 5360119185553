import * as React from "react";
import { I18n } from "react-redux-i18n";
import { useDispatch } from "react-redux";

import SportsGroupComposition from "components/specific/favorite-sports/sport-group-composition";
import Panel from "components/common/settings-panel/settings-panel";
import useSelector from "core/hooks/useSelector";
import { IErrors } from "pages/templates/templates";
import { IFilters, FilterKey, PartialFilters } from "core/redux/filters/reducers";
import { ReduxDispatch } from "core/redux/store";
import { getSynchronizedFilters } from "core/utils/filters";
import { setFilters } from "core/redux/filters/actions";
import { ISportsGroup } from "core/models/api/sports-group/sports-group-piloting";
import { useApiDeleteSportsGroup } from "core/api/sports-group/useApiDeleteSportsGroup";
import { useApiPostSportsGroup } from "core/api/sports-group/useApiPostSportsGroup";

interface ISportsGroupPanelProps {
  sportsGroup: ISportsGroup;
  onClose: () => void;
  onSportsGroupChange: (sportsGroup: ISportsGroup | Partial<ISportsGroup>) => void;
  errors?: IErrors;
  setIsActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

interface IDumbSportsGroupPanelProps extends ISportsGroupPanelProps {
  onSave: () => void;
  onDelete: () => void;
  isLoading: boolean;
}

const DumbSportsGroupPanel = ({
  sportsGroup,
  onClose,
  onSportsGroupChange,
  onSave,
  onDelete,
  isLoading,
  errors
}: IDumbSportsGroupPanelProps) => {
  const currentStoreId = useSelector(state => state.filters.store_id);

  const canBeSaved = React.useCallback(
    (hasBeenEdited: boolean) =>
      sportsGroup ? hasBeenEdited && sportsGroup.label && sportsGroup.composition.length >= 2 : false,
    [sportsGroup]
  );
  return (
    <Panel
      item={sportsGroup}
      onClose={onClose}
      onSave={onSave}
      onDelete={onDelete}
      onChange={onSportsGroupChange}
      isLoading={isLoading}
      canEdit={true}
      defaultTitle={I18n.t("settings.sport_groups.new_sport_group")}
      deleteActionLabel={I18n.t("settings.sport_groups.delete_sport_group")}
      deleteActionMessage={I18n.t("settings.sport_groups.delete.confirm")}
      editTitle={I18n.t("settings.sport_groups.title")}
      editFieldLabel={I18n.t("settings.sport_groups.name_sport_group")}
      checkCanBeSaved={canBeSaved}
      errors={errors}
      groupType="Sport"
    >
      <>
        <label className="mg-groups-label vtmn-typo_text-3">
          {I18n.t("settings.sport_groups.sport_group_composition")}
        </label>

        <SportsGroupComposition
          isEditable={true}
          sportsGroup={sportsGroup}
          onSportsGroupChange={onSportsGroupChange}
          currentStoreId={currentStoreId}
          isFetchingSports={false}
        />
      </>
    </Panel>
  );
};

const SportsGroupPanel = (props: ISportsGroupPanelProps) => {
  const { sportsGroup, onClose, setIsActiveTab } = props;
  const sessionFilters = useSelector<PartialFilters>(state => state.filters);
  const dispatch = useDispatch<ReduxDispatch>();
  const postMutation = useApiPostSportsGroup();
  const deleteMutation = useApiDeleteSportsGroup();

  return (
    <DumbSportsGroupPanel
      onSave={async () => {
        await postMutation.mutateAsync(sportsGroup);
        onClose();
      }}
      onDelete={async () => {
        await deleteMutation.mutateAsync(sportsGroup);
        if (sportsGroup.id === sessionFilters.sports_group) {
          const newFilters = getSynchronizedFilters({ [FilterKey.SPORTS_GROUP]: null } as unknown as PartialFilters);
          const nextFilters = { ...sessionFilters, ...newFilters } as IFilters;
          dispatch(setFilters(nextFilters));
        }
        onClose();
        setIsActiveTab("read");
      }}
      isLoading={postMutation.isPending || deleteMutation.isPending}
      {...props}
    />
  );
};

export default SportsGroupPanel;
