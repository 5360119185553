import React, { useEffect, useState, useCallback } from "react";
import { useApiGetAnnouncement } from "core/api/announcement/useApiGetAnnouncement";

const REFETCH_DELAY_MS = 3600000;

export const AnnouncementBanner = React.memo(() => {
  const { data: announcementData, isLoading: isLoadingData, refetch } = useApiGetAnnouncement();
  const [bannerIsVisible, setBannerIsVisible] = useState(false);

  const checkBannerVisibility = useCallback(() => {
    const isBannerHidden = localStorage.getItem("mg-banner") === announcementData?.message;
    setBannerIsVisible(!isBannerHidden);
  }, [announcementData?.message]);

  useEffect(() => {
    checkBannerVisibility();
  }, [checkBannerVisibility]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, REFETCH_DELAY_MS);

    return () => clearInterval(interval);
  }, [refetch]);

  const handleCloseBanner = useCallback(() => {
    setBannerIsVisible(false);
    localStorage.setItem("mg-banner", announcementData?.message);
  }, [announcementData?.message]);

  if (isLoadingData || !announcementData?.active || !bannerIsVisible) return null;

  return (
    <div className={`mg-announcement-banner__${announcementData.type.toLowerCase()}`}>
      <div className="mg-announcement-banner-left">
        {announcementData.message && <div>{announcementData.message}</div>}
        {announcementData.link && (
          <a href={announcementData.link} target="_blank" rel="noopener noreferrer">
            To know more...
          </a>
        )}
      </div>
      <div className="mg-announcement-banner-right">
        <button onClick={handleCloseBanner}>
          <span className="material-icons-outlined">close</span>
        </button>
      </div>
    </div>
  );
});
