import axios, { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { I18n } from "react-redux-i18n";
import { toast } from "react-toastify";

import { ISportsGroup } from "core/models/api/sports-group/sports-group-piloting";
import { CreateSportsGroupDto, PutSportsGroupDto } from "../rest/mutations/sports-group-save";
import { QUERY_KEY_SPORTS_GROUP } from "./useApiGetSportsGroup";
import {
  GROUPS_NOTIFICATION_SPORTS_CREATION_FAILED,
  GROUPS_NOTIFICATION_SPORTS_CREATION_SUCCEDED,
  GROUPS_NOTIFICATION_SPORTS_EDITION_FAILED,
  GROUPS_NOTIFICATION_SPORTS_EDITION_SUCCEDED
} from "core/constants/translations/groups";

export const useApiPostSportsGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (sportsGroup: ISportsGroup) => {
      if (sportsGroup.id) {
        const dto: PutSportsGroupDto = {
          id: sportsGroup.id,
          label: sportsGroup.label,
          composition: sportsGroup.composition.map(item => {
            return {
              index: item.index,
              sport_id: item.sport_id
            };
          })
        };
        return axios.put(`/sports_groups/${sportsGroup.id}`, dto);
      } else {
        const dto: CreateSportsGroupDto = {
          label: sportsGroup.label,
          composition: sportsGroup.composition.map(item => {
            return {
              index: item.index,
              sport_id: item.sport_id
            };
          })
        };
        return axios.post("/sports_groups", dto);
      }
    },
    onSuccess: (response: AxiosResponse) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_SPORTS_GROUP] });
      response.config.method === "post" && toast.success(I18n.t(GROUPS_NOTIFICATION_SPORTS_CREATION_SUCCEDED));
      response.config.method === "put" && toast.success(I18n.t(GROUPS_NOTIFICATION_SPORTS_EDITION_SUCCEDED));
    },
    onError: (response: AxiosResponse) => {
      response.config.method === "post" && toast.error(I18n.t(GROUPS_NOTIFICATION_SPORTS_CREATION_FAILED));
      response.config.method === "put" && toast.error(I18n.t(GROUPS_NOTIFICATION_SPORTS_EDITION_FAILED));
    }
  });
};
