export enum AggregateType {
  REALIZED = "REALIZED",
  FORECASTED = "FORECASTED",
  TRAJECTORY = "TRAJECTORY",
  FORECAST_PHOTO = "FORECAST_PHOTO",
  OTHERS = "OTHERS"
}

export interface CalendarAggregate {
  id: number;
  label_code: string;
  display_order: number;
  from: string;
  to: string;
  comparison_allowed: boolean;
  type: AggregateType;
  order_in_type: number;
  display_when_load_aggregate: boolean;
  display_in_calendar: boolean;
}

export interface Aggregate {
  calendar: CalendarAggregate[];
  load_aggregate: CalendarAggregate[];
}
