import { ReactElement } from "react";

type IActionButtonsParentComponent = {
  childrenLeft?: ReactElement;
  childrenRight?: ReactElement;
};

const ActionButtonsParentComponent = ({ childrenLeft, childrenRight }: IActionButtonsParentComponent) => {
  return (
    <div className="mg-action-buttons-parent">
      <div className="mg-action-buttons-parent-table">{childrenLeft}</div>
      {childrenRight}
    </div>
  );
};

export default ActionButtonsParentComponent;
