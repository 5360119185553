import { ReactElement } from "react";
import classNames from "classnames";

interface IProps {
  /** The CSS class name of the section. */
  className?: string;
  /** Outlined styling. */
  outlined?: boolean;
  /** Dense styling. */
  dense?: boolean;
  children?: ReactElement | ReactElement[];
}

const ToolbarSection = ({ outlined, dense, className, children }: IProps) => {
  return <div className={classNames("toolbar-section", className, { outlined, dense })}>{children}</div>;
};

export default ToolbarSection;
