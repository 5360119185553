// In its own file to avoid circular dependencies leading to "cannot read property ... of undefined"
// in tests
export enum RouteId {
  Error = "Error",
  Home = "Home",
  HomeTeam = "HomeTeam",
  HomeSport = "HomeSport",
  HomeStore = "HomeStore",
  HomeWeekly = "HomeWeekly",
  HomeComparison = "HomeComparison",
  Weekly = "Weekly",
  WeeklySports = "WeeklySports",
  Daily = "Daily",
  DailyStore = "DailyStore",
  DailyTeam = "DailyTeam",
  WeeklySportsGroup = "WeeklySportsGroup",
  WeeklyTeams = "WeeklyTeams",
  WeeklyStores = "WeeklyStores",
  Sports = "Sports",
  SportsTeams = "SportsTeams",
  SportsStores = "SportsStores",
  AnnualInitialization = "AnnualInitialization",
  AnnualInitializationTeams = "AnnualInitializationTeams",
  // used to share a common route for the navigation menu, but the hours screen will trigger
  // redirects based on the use case
  HoursLanding = "HoursLanding",
  HoursTeam = "HoursTeam",
  HoursSport = "HoursSport",
  HoursCashierServices = "HoursCashierServices",
  HoursGeneralServices = "HoursGeneralServices",
  Areas = "Areas",
  ManagerByMonth = "ManagerByMonth",
  ComparisonTeams = "ComparisonTeams",
  ComparisonStores = "ComparisonStores",
  ComparisonSports = "ComparisonSports",
  ComparisonSportGroups = "ComparisonSportGroups",
  UserPreferences = "UserPreferences",
  Templates = "Templates",
  MyTemplates = "MyTemplates",
  Events = "Events",
  UpwardForecast = "UpwardForecast",
  Askr = "Askr",
  MyOffer = "MyOffer",
  Document = "Document",
  School = "School",
  DayByDayHourByHour = "DayByDayHourByHour",
  ReportingExtraction = "ReportingExtraction",
  SalesPerformancesIframe = "SalesPerformancesIframe",
  ReportingExtractionMonthlyData = "ReportingExtractionMonthlyData",
  ReportingExtractionDayDay = "ReportingExtractionDayDay",
  ReportingExtractionTrajectory = "ReportingExtractionTrajectory",
  ReportingExtractionWeekly = "ReportingExtractionWeekly",
  GroupsCreation = "GroupsCreation",
  GroupsCreationShare = "GroupsCreationShare",
  ReportingUsageByCountry = "ReportingUsageByCountry",
  ReportingEffitimeReliability = "ReportingEffitimeReliability",
  ReportingHoursEfficiencyPerCountry = "ReportingHoursEfficiencyPerCountry",
  ReportingHoursEfficiencyPerCountryProbableYear = "ReportingHoursEfficiencyPerCountryProbableYear",
  ReportingQualityForecasting = "ReportingQualityForecasting",
  HomeNew = "HomeNew",
  Planning = "Planning",
  EffitimeIframe = "EffitimeIframe",
  ReportingProcessManagement = "ReportingProcessManagement",
  EmbeddedSalesAnimation = "EmbeddedSalesAnimation",
  Announcement = "Announcement"
}
