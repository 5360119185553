import { memo, useCallback, useContext, useMemo, useState } from "react";
import { TableInteractionsContext, ITrees } from "@decathlon/react-table";
import { IconButton, Icon } from "@mui/material";

import { getAccountColumnIndex } from "core/utils/columns";
import { DataManagerContext } from "../../data-manager/data-context";

const EMPTY_OBJECT = {};
const CollapseUncollapseController = () => {
  const columnIndex = getAccountColumnIndex();
  const { closeTrees, openTrees, table } = useContext(TableInteractionsContext);
  const { tableProps } = useContext(DataManagerContext);
  const openedTrees = table.current ? table.current.state.openedTrees : EMPTY_OBJECT;
  const [hasOpenedTrees, setHasOpenedTrees] = useState<boolean>(!!Object.keys(openedTrees).length);

  const allTrees = useMemo(
    () =>
      tableProps
        ? tableProps.rows.reduce<ITrees>((result, row, index) => {
            const indicatorCell = row.cells[columnIndex];
            if (indicatorCell?.subItems?.length) {
              result[index] = { rowIndex: index, columnIndex };
            }
            return result;
          }, {})
        : {},
    [tableProps, columnIndex]
  );

  const close = useCallback(() => {
    closeTrees(allTrees);
    setHasOpenedTrees(false);
  }, [allTrees, closeTrees]);

  const open = useCallback(() => {
    openTrees(allTrees);
    setHasOpenedTrees(true);
  }, [allTrees, openTrees]);

  return (
    <IconButton data-testid="table-toggle-rows-btn" onClick={hasOpenedTrees ? close : open} size="large">
      <Icon>{hasOpenedTrees ? "keyboard_arrow_down" : "keyboard_arrow_right"}</Icon>
    </IconButton>
  );
};

export default memo(CollapseUncollapseController);
