import { FunctionComponent, memo, useState } from "react";
import { IContentCellProps } from "@decathlon/react-table";
import { EventIcon } from "./event-icon";
import { Popover, Typography, IconButton } from "@mui/material";
import EventList from "../events/event-list";
import { StoreEventById } from "core/models/store-events";
import { I18n } from "react-redux-i18n";
interface IStoreEvent extends IContentCellProps {
  isDayEvent?: boolean;
  currentEventListInWeek?: StoreEventById[];
}

interface StoreEventIconIndicator {
  isDayEvent: boolean;
  eventsLength: number;
}

const StoreEventIcon: FunctionComponent<IStoreEvent> = ({ isDayEvent, currentEventListInWeek }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  const eventsLength = currentEventListInWeek?.length;
  return (
    <>
      <div className="fixed" onClick={handlePopoverOpen}>
        <StoreEventIconIndicator isDayEvent={isDayEvent} eventsLength={eventsLength} />
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          zIndex: "1500",
          overflow: "auto",
          align: "center",
          pointerEvents: "fill"
        }}
        open={openPopOver}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>{I18n.t("settings.events")}</Typography>
        <EventList events={currentEventListInWeek} />
      </Popover>
    </>
  );
};

export const StoreEventIconIndicator = memo(({ isDayEvent, eventsLength }: StoreEventIconIndicator) => {
  if (isDayEvent) {
    return (
      <IconButton size="large">
        <EventIcon isDayEvent={isDayEvent} eventsLength={eventsLength} />
      </IconButton>
    );
  }
  return (
    <IconButton size="large">
      <EventIcon />
    </IconButton>
  );
});

export default StoreEventIcon;
