import { I18n } from "react-redux-i18n";
import { MENU_CONFIGURATION_VUE_TEXT, MENU_MODAL_CANCEL, MENU_MODAL_SAVE } from "core/constants/translations/menu";
import { useContext } from "react";
import { MenuContext } from "../menu";
import _ from "lodash";
import { trackAnalyticsEvent } from "../../../../../core/hooks/useAnalytics";

const MenuConfigurationTitle = ({ menuNavigationUserPreference }) => {
  const {
    getUserId,
    openModal,
    modalCancelNotContinue,
    modalCancelContinue,
    modalSaveNotContinue,
    modalSaveContinue,
    isEditing,
    setIsEditing,
    handleOpenModal,
    getAllJobsViews,
    modifiedData,
    viewId
  } = useContext(MenuContext);

  let editButton = null;
  const idsAreEquals = _.isEqual(viewId, getUserId);
  const datasAreEquals = _.isEqual(getAllJobsViews, modifiedData);
  const isModificationExist = !idsAreEquals || !datasAreEquals;

  const handleClickCancel = isModificationExist
    ? () => handleOpenModal(openModal, I18n.t(MENU_MODAL_CANCEL), modalCancelNotContinue, modalCancelContinue)
    : () => setIsEditing(false);

  const handleClickContinue = isModificationExist
    ? () => handleOpenModal(openModal, I18n.t(MENU_MODAL_SAVE), modalSaveNotContinue, modalSaveContinue)
    : () => setIsEditing(false);

  const handleEditCurentRole = () => {
    trackAnalyticsEvent("click_top_burger_menu_edit_current_role");
    setIsEditing(true);
  };

  if (menuNavigationUserPreference) {
    editButton = (
      <div data-testid="configuration-vue-edit-button" className="mg-menu-configuration-vue__text__edit">
        <span
          data-testid="edit-profile-button"
          className="material-icons-outlined notranslate"
          onClick={handleEditCurentRole}
        >
          edit
        </span>
      </div>
    );
  }

  return (
    <div className="mg-menu-configuration__title">
      <div className="mg-menu-configuration-vue__text__title">
        <span>{I18n.t(MENU_CONFIGURATION_VUE_TEXT)}</span>
        {modifiedData?.map(item => {
          if (item.id === viewId) {
            return (
              <span
                data-testid="current-role-text"
                key={item.id}
                className="mg-menu-configuration-vue__text__title__role"
              >
                {I18n.t(item.label)}
              </span>
            );
          }
        })}
      </div>
      {isEditing ? (
        <div className="mg-menu-configuration-vue__text__edition">
          <div
            data-testid={"configuration-vue-save-button"}
            className={`mg-menu-configuration-vue__text__edition__element ${
              !datasAreEquals || !idsAreEquals ? "isModified" : "isNotModified"
            }`}
          >
            <span
              data-testid="save-profile-button"
              className="material-icons-outlined notranslate"
              onClick={handleClickContinue}
            >
              save
            </span>
          </div>
          <div data-testid="close-profile-button">
            <span className="material-icons-outlined notranslate" onClick={handleClickCancel}>
              close
            </span>
          </div>
        </div>
      ) : (
        editButton
      )}
    </div>
  );
};

export default MenuConfigurationTitle;
