import MenuConfigurationVue from "./menu-configuration-vue";
import MenuConfigurationLinks from "./menu-configuration-links";
import MenuConfigurationTitle from "./menu-configuration-title";

const MenuConfiguration = ({ menuNavigationUserPreference }) => {
  return (
    <div className="mg-menu-configuration">
      <MenuConfigurationTitle menuNavigationUserPreference={menuNavigationUserPreference} />
      <MenuConfigurationVue />
      <MenuConfigurationLinks />
    </div>
  );
};

export default MenuConfiguration;
