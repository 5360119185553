import { Action, applyMiddleware, combineReducers, compose, createStore, Store } from "redux";
import { i18nReducer, I18nState, loadTranslations } from "react-redux-i18n";
import { ThunkDispatch } from "redux-thunk";

import { defaultTranslations } from "core/constants/translations";
import { dispatchTranslations, getNavigatorLanguage } from "core/utils/i18n";
import userReducer, { UserRelatedState } from "./user/reducers";
import filtersReducer, { IFilters } from "./filters/reducers";
import staffPlanningReducer from "./staff-planning/reducers";
import middlewares from "./middlewares";
import teamSportByStoreReducer, { TeamSportByStoreState } from "./team-sport-by-store";

export interface AppStore {
  i18n: I18nState;
  user: UserRelatedState;
  filters: IFilters;
  teamSportByStore: TeamSportByStoreState;
}

export type ReduxDispatch = ThunkDispatch<AppStore, any, Action>;

let store: Store<AppStore, Action<any>> = null;

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const createAppStore = (initialState?: AppStore): Store<AppStore, Action<any>> => {
  const enhancer = window.devToolsExtension
    ? composeEnhancers(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares);

  const reducers = combineReducers({
    i18n: i18nReducer,
    user: userReducer,
    filters: filtersReducer,
    teamSportByStore: teamSportByStoreReducer,
    staffPlanning: staffPlanningReducer
  });

  store = createStore<AppStore, Action<any>, Record<string, unknown>, Record<string, unknown>>(
    reducers,
    initialState,
    enhancer
  );

  const userLocale = getNavigatorLanguage();
  dispatchTranslations(store, userLocale);
  const dispatchCallback = loadTranslations(defaultTranslations);
  dispatchCallback(store.dispatch);

  return store;
};

export default () => {
  if (!store) {
    store = createAppStore();
  }

  return store;
};
