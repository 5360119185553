export const GROUPS_SPORTS = "groups.sports";
export const GROUPS_STORES = "groups.stores";
export const GROUPS_MANAGEMENT_EMPTY = "groups.management.empty";
export const GROUPS_MANAGEMENT_SPORTS = "groups.management.sports";
export const GROUPS_MANAGEMENT_STORES = "groups.management.stores";
export const GROUPS_CRUD_LIST_SPORTS = "groups.crud.list_sports";
export const GROUPS_CRUD_CREATE_SPORTS = "groups.crud.create_sports";
export const GROUPS_CRUD_EDIT_SPORTS = "groups.crud.edit_sports";
export const GROUPS_CRUD_LIST_STORES = "groups.crud.list_stores";
export const GROUPS_CRUD_MY_LIST_STORES_GROUP = "groups.crud.my_list_stores_group";
export const GROUPS_CRUD_SHARED_WITH_ME = "groups.crud.list-stores-shared";
export const GROUPS_CRUD_STORES_GROUP_ADDED_TO_SHARED_WITH_ME = "groups.crud.stores-group-added-to-shared-with-me";
export const GROUPS_CRUD_ADD_STORES_GROUP_SHARED_CONFIRMATION_MESSAGE =
  "groups.crud.add-stores-group-to-shared-confirmation-message";
export const GROUPS_CRUD_CREATE_STORES = "groups.crud.create_stores";
export const GROUPS_CRUD_EDIT_STORES = "groups.crud.edit_stores";
export const GROUPS_INFORMATION_EMPTY_SPORTS = "groups.information.empty_sports";
export const GROUPS_INFORMATION_EMPTY_STORES = "groups.informations.empty_stores";
export const GROUPS_INFORMATION_EMPTY_SHARED_WITH_ME_STORES_GROUPS =
  "groups.informations.empty_shared_with_me_stores_groups";
export const GROUPS_ALERT_SPORTS = "groups.alert_sports";
export const GROUPS_ALERT_STORES = "groups.alert_stores";
export const GROUPS_NOTIFICATION_SPORTS_CREATION_SUCCEDED = "groups.notification_sports_creation_succeded";
export const GROUPS_NOTIFICATION_SPORTS_CREATION_FAILED = "groups.notification_sports_creation_failed";
export const GROUPS_NOTIFICATION_SPORTS_EDITION_SUCCEDED = "groups.notification_sports_edition_succeded";
export const GROUPS_NOTIFICATION_SPORTS_EDITION_FAILED = "groups.notification_sports_edition_failed";
export const GROUPS_NOTIFICATION_SPORTS_DELETE_SUCCEDED = "groups.notification_sports_delete_succeded";
export const GROUPS_NOTIFICATION_SPORTS_DELETE_FAILED = "groups.notification_sports_delete_failed";
export const GROUPS_NOTIFICATION_STORES_CREATION_SUCCEDED = "groups.notification_stores_creation_succeded";
export const GROUPS_NOTIFICATION_STORES_CREATION_FAILED = "groups.notification_stores_creation_failed";
export const GROUPS_NOTIFICATION_STORES_EDITION_SUCCEDED = "groups.notification_stores_edition_succeded";
export const GROUPS_NOTIFICATION_STORES_EDITION_FAILED = "groups.notification_stores_edition_failed";
export const GROUPS_NOTIFICATION_STORES_DELETE_SUCCEDED = "groups.notification_stores_delete_succeded";
export const GROUPS_NOTIFICATION_STORES_DELETE_FAILED = "groups.notification_stores_delete_failed";
export const GROUPS_NOTIFICATION_STORES_GROUP_DOES_NOT_EXIST = "groups.notification_stores_group_does_not_exist";
export const GROUPS_STORE_FORM_BUTTON_DELETE = "groups.button.delete_store_group";
export const GROUPS_SPORT_FORM_BUTTON_DELETE = "groups.button.delete_sport_group";
