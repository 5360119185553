import { ICexAccountsData } from "core/api/rest/queries";
import { IHeaders, IHeaderIndex } from "./headers";
import { IAggregateHeader } from "./aggregates";
import { getNavigatorLanguage } from "./i18n";

export interface IToggleableColumn {
  id: string;
  index: number;
  label: string;
  isHidden?: boolean;
}

export interface IPropsMapping {
  [key: string]: string[];
}

export interface IObjectWithIndex {
  index: number;
}

export interface IObjectWithId {
  id: number | string;
  label?: string;
}

export interface IObjectsWithIndex {
  [key: string]: IObjectWithIndex;
}

export type IObjectsWithValue = {
  [key in string | number]: {
    value: number;
  };
};

/**
 * Get the items sorted by value
 * @param items
 * @param data object with keys corresponding to items ids
 * @return sorted items
 */
export const getSortedItemsByValue = <T>(
  items: (T & IObjectWithId)[],
  data: IObjectsWithValue,
  isASC = true,
  locale = getNavigatorLanguage()
): (T & IObjectWithId)[] =>
  [...items].sort((itemA, itemB) => {
    if (!data) {
      return 0;
    }
    const dataA = data[itemA.id];
    const dataB = data[itemB.id];
    let diff = 0;
    if (dataA && dataB) {
      if (isASC) {
        diff = dataA.value - dataB.value;
      } else {
        diff = dataB.value - dataA.value;
      }
      if (diff === 0 && itemA.label !== undefined && itemB.label !== undefined) {
        return itemA.label.localeCompare(itemB.label, locale, { sensitivity: "base" });
      }
      return diff;
    }

    return diff;
  });

export const getSortedCEXColumnsIndexes = (
  row: { data: ICexAccountsData },
  idAttribute: "sport_id" | "id" = "id",
  labelGetter?: (id: string) => string
): IHeaderIndex[] => {
  const columns = row ? row.data : undefined;
  const columnsToSort = columns
    ? Object.keys(columns).map(columnId => ({
        id: columnId,
        label: labelGetter ? labelGetter(columnId) : undefined
      }))
    : [];
  return getSortedItemsByValue(columnsToSort, columns, false).map((column, index) => ({
    [idAttribute]: column.id,
    index
  }));
};

/**
 * Get the items sorted by index
 * @param items
 * @return sorted items
 */
export const getObjectKeysSortedByIndex = (items: IObjectsWithIndex): string[] =>
  Object.keys(items).sort((a, b) => items[a].index - items[b].index);

export const getToggleableColumns = (aggregateIds: string[], headers: IHeaders): IToggleableColumn[] => {
  const toggleableColumns = aggregateIds.reduce<IToggleableColumn[]>((result, id) => {
    const { value, index, isToggleable } = headers[id] as IAggregateHeader;

    if (isToggleable) {
      result.push({
        id,
        index,
        label: value
      });
    }

    return result;
  }, []);

  toggleableColumns.sort((a, b) => {
    return a.index - b.index;
  });

  return toggleableColumns;
};

export const getSubPropsMapping = (object: Record<string, unknown>): IPropsMapping =>
  Object.keys(object).reduce<IPropsMapping>((mapping, prop) => {
    const dependencies = Object.keys(object[prop]);
    dependencies.forEach(depKey => {
      // eslint-disable-next-line no-param-reassign
      mapping[depKey] = mapping[depKey] || [];
      mapping[depKey].push(prop);
    });
    return mapping;
  }, {});

export const listToObj = <T, R = { [key: string]: T }>(list: T[], keyName: string): R => {
  return list ? list.reduce<R>((result, item) => ({ ...result, [item[keyName]]: item }), {} as R) : ({} as R);
};

const hasObjectPrototype = (o: any): boolean => Object.prototype.toString.call(o) === "[object Object]";

// Copied from: https://github.com/jonschlinkert/is-plain-object
// eslint-disable-next-line @typescript-eslint/ban-types
const isPlainObject = (o: any): o is Object => {
  if (!hasObjectPrototype(o)) {
    return false;
  }

  // If has modified constructor
  const ctor = o.constructor;
  if (typeof ctor === "undefined") {
    return true;
  }

  // If has modified prototype
  const prot = ctor.prototype;
  if (!hasObjectPrototype(prot)) {
    return false;
  }

  // If constructor does not have an Object-specific method
  // eslint-disable-next-line no-prototype-builtins
  if (!prot.hasOwnProperty("isPrototypeOf")) {
    return false;
  }

  // Most likely a plain Object
  return true;
};

const stableStringifyReplacer = (_key: string, value: any): unknown => {
  if (typeof value === "function") {
    return "function";
  }

  if (isPlainObject(value)) {
    return Object.keys(value)
      .sort()
      .reduce((result, key) => {
        result[key] = JSON.stringify(value[key], stableStringifyReplacer);
        return result;
      }, {} as any);
  }

  return value;
};

export const stableStringify = (value: any): string =>
  JSON.stringify(value, stableStringifyReplacer).replace(/(\r\n|\n|\r| )/gm, "");
