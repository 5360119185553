import { useContext } from "react";
import { MenuContext } from "../menu";
import { useUserHasRoles } from "core/hooks/useUserHasRoles";
import { ROLES } from "core/constants/roles";

const FavoriteLink = ({ sublink, selectedView, modifiedData }) => {
  const { setModifiedData } = useContext(MenuContext);
  const hasFavoriteLink = (routeOfSelectedView, routeId) => {
    if (routeOfSelectedView && routeId) {
      for (const routeOfSelectedViewValue of routeOfSelectedView) {
        if (routeOfSelectedViewValue?.label === routeId) {
          return true;
        }
      }
    }
    return false;
  };

  const isFavoriteLink = hasFavoriteLink(selectedView?.route, sublink.id);
  const isAdmin = useUserHasRoles([ROLES.ROLE_ADMIN]);

  const canEditTheView = () => {
    const isCustomViewSelected = !selectedView.default;
    return isAdmin || (!isAdmin && isCustomViewSelected);
  };

  const addLink = () => {
    const listOfLinksToUpdate = selectedView?.route;
    const listOfLinksUpdated = {
      index: listOfLinksToUpdate?.length + 1,
      label: sublink.id,
      type: sublink.type || "commercial"
    };

    listOfLinksToUpdate.push(listOfLinksUpdated);
    const array = modifiedData.map(item => {
      if (item.id == selectedView.id) {
        return { ...item, route: listOfLinksToUpdate };
      } else {
        return { ...item };
      }
    });
    setModifiedData(array);
  };

  const removeLink = () => {
    const listOfLinksToUpdate = selectedView?.route;
    const listOfLinksUpdated = listOfLinksToUpdate.filter(item => item.label != sublink.id);
    const array = modifiedData.map(item => {
      if (item.id == selectedView.id) {
        return { ...item, route: listOfLinksUpdated };
      } else {
        return { ...item };
      }
    });
    setModifiedData(array);
  };

  return (
    <div className="mg-menu-animation-single-link__favorite">
      {canEditTheView() && (
        <span className="material-icons-outlined notranslate" onClick={isFavoriteLink ? removeLink : addLink}>
          {isFavoriteLink ? "star" : "star_outline"}
        </span>
      )}
    </div>
  );
};

export default FavoriteLink;
