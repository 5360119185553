import { I18n } from "react-redux-i18n";
import { UpwardForecastExceptionManagerContext } from "components/specific/upward-forecast/data-manager/upward-forecast-exception-context";
import { useApiGetForecastByCountryCode } from "core/api/upward-forecast/useApiGetForecastByCountryCode";
import { useApiPutForecastActivateButton } from "core/api/upward-forecast/useApiPutForecastActivateButton";
import { memo, useContext } from "react";

interface IActivateButtonProps {
  isDisabled: boolean;
  country: string;
}

const ActivateButton = ({ isDisabled, country }: IActivateButtonProps) => {
  const { clearException } = useContext(UpwardForecastExceptionManagerContext);
  const { data: dataForecastByCountryCode } = useApiGetForecastByCountryCode({ countryCode: country });
  const { mutate } = useApiPutForecastActivateButton();
  const upwardForecastData = dataForecastByCountryCode;
  const isActivated = upwardForecastData ? upwardForecastData.activated : false;
  const isAuthorized = upwardForecastData ? upwardForecastData.authorized : false;

  const handleOnClick = () => {
    clearException();
    mutate({
      isActivated: isActivated,
      countryCode: country
    });
  };

  return (
    <button
      id={`upward-forecast-${upwardForecastData?.activated ? "activated" : "deactivated"}`}
      className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small"
      onClick={isDisabled || !isAuthorized ? null : handleOnClick}
      title={!isAuthorized && country ? I18n.t("upward-forecast.disabled-activation") : null}
      disabled={isDisabled || !isAuthorized}
    >
      {upwardForecastData?.activated ? I18n.t("deactivate") : I18n.t("activate")}
    </button>
  );
};

export default memo(ActivateButton);
