import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { StoresGroup } from "../../models/stores-group";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

interface IStoresGroupDto {
  id: number;
  label: string;
  composition: IStoresGroupCompositionDto[];
  regionLabel: boolean;
  is_owner: boolean;
}

interface IStoresGroupCompositionDto {
  store_id: number;
  index: number;
}

export const QUERY_KEY = "get-stores-group";

const getStoresGroupFn = async (config: AxiosRequestConfig): Promise<StoresGroup[]> => {
  const axiosPromise: AxiosResponse<IStoresGroupDto[]> = await axios(config);
  const groups = axiosPromise.data.map(group => {
    return {
      id: group.id,
      label: group.label,
      is_owner: group.is_owner,
      edit_disabled: !group.is_owner,
      region_label: group.regionLabel,
      composition: group.composition.map(item => {
        return { index: item.index, store_id: item.store_id };
      })
    };
  });
  return groups.sort((storeA: StoresGroup, storeB: StoresGroup) => storeA.id - storeB.id);
};

export const useApiGetStoresGroup = (options?: UseQueryOptionsWithoutQueryKey<StoresGroup[]>) => {
  const url = "/stores_groups";
  const config: AxiosRequestConfig = { method: "get", url };
  return useQuery<StoresGroup[], Error>({
    queryKey: [QUERY_KEY, url],
    queryFn: () => getStoresGroupFn(config),
    staleTime: Infinity,
    ...options
  });
};
