import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { I18n } from "react-redux-i18n";

interface ISaveModalProps {
  save: (value?: unknown) => void;
  callbackWithoutSave: (value?: unknown) => void;
  open: boolean;
  close: (value?: unknown) => void;
}

export default class SaveModal extends React.Component<ISaveModalProps> {
  public render() {
    const { close, open, callbackWithoutSave, save } = this.props;
    return (
      <Dialog disableEscapeKeyDown open={open} onClose={close} className="piloting-save-modal" maxWidth="md">
        <DialogTitle>{I18n.t("pilotable.warning_popup.document_changes")}</DialogTitle>
        <DialogActions>
          <button
            className="vtmn-btn vtmn-btn_variant--tertiary vtmn-btn_size--small"
            onClick={close}
            key="pilotable.warning_popup.cancel"
            data-testid="warning_popup_cancel"
          >
            {I18n.t("pilotable.warning_popup.cancel")}
          </button>
          <button
            className="vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small"
            onClick={callbackWithoutSave}
            key="pilotable.warning_popup.continue_without_saving"
            data-testid="warning_popup_continue_without_saving"
          >
            {I18n.t("pilotable.warning_popup.continue_without_saving")}
          </button>
          <button
            className="vtmn-btn vtmn-btn_variant--primary vtmn-btn_size--small"
            onClick={save}
            key="pilotable.warning_popup.save_and_continue"
            color="primary"
            data-testid="warning_popup_save_and_continue"
          >
            {I18n.t("pilotable.warning_popup.save_and_continue")}
          </button>
        </DialogActions>
      </Dialog>
    );
  }
}
