import { createTheme } from "@mui/material";

export const blueDkt = "#0082c3";

const muiDktTheme = createTheme({
  typography: {
    fontFamily: "Roboto Condensed"
  },
  palette: {
    primary: {
      light: blueDkt,
      dark: blueDkt,
      main: blueDkt
    },
    secondary: {
      light: blueDkt,
      dark: blueDkt,
      main: blueDkt
    }
  }
});

export default muiDktTheme;
