import { getTeam } from "./useTeams";
import { useApiGetSport } from "core/api/sport/useApiGetSport";
import { useApiGetTeams } from "../api/team/useApiGetTeams";

/**
 * Fetch detailed information for a list of sports
 */
export const useSportsById = (ids: number[] | null) => {
  return useApiGetSport({ ids: ids }, { enabled: ids?.length > 0, gcTime: Infinity });
};

/**
 * Fetch detailed information for sports a team is made of
 */
export const useSportsForTeam = (storeId: number, teamId: number) => {
  const { data: dataTeams } = useApiGetTeams({ storeId: storeId });
  const team = getTeam(dataTeams, teamId);
  const sportIds = team?.composition;
  return useSportsById(sportIds);
};

export const sportsByStoreQueryKey = "sportsByStore";

/**
 * Fetch detailed information for all sports of a given store
 */
export const useSportsForStore = (storeId: number) => {
  return useApiGetSport({ storeId: storeId }, { enabled: !!storeId });
};
