/* eslint-disable react/require-default-props */
import { memo, useCallback, useMemo } from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import classnames from "classnames";

import useObjFromList from "core/hooks/useObjFromList";
import { useSportsForStore } from "core/hooks/useSports";
import { AppStore } from "core/redux/store";
import { comparing } from "core/utils/sort";
import { Select } from "../select";
import SelectAll from "../select/actions/select-all";
import { Sport } from "core/models/api/sports-piloting/sports-piloting";
export interface IProps {
  id?: string;
  label?: string;
  className?: string;
  value?: number | number[];
  isDisabled?: boolean;
  hasBottomLine?: boolean;
  sportSortFunction?: (a: Sport, b: Sport) => number;
  onChange?: (value: number | number[]) => void;
  disabledItemsIds?: ((items: Sport[]) => number[]) | number[];
  isMultiple?: boolean;
  initialIsOpen?: boolean;
  canBeReset?: boolean;
  storeId?: number;
}

export interface IDumbProps extends IProps {
  sports: Sport[];
  isLoading?: boolean;
}

export const dataLabelFormat = (data: Sport) => `${data.department_id} - ${data.label}`;
export const renderSelectAction = () => <SelectAll />;

export const DumbSelectSport = memo(
  ({
    id,
    label,
    sports = [],
    sportSortFunction = comparing<Sport>(it => it.department_id),
    disabledItemsIds,
    className,
    value,
    hasBottomLine,
    isLoading,
    isDisabled,
    onChange,
    isMultiple,
    initialIsOpen,
    canBeReset
  }: IDumbProps) => {
    const disabledItems = useMemo(() => {
      if (typeof disabledItemsIds === "function") {
        return disabledItemsIds(sports);
      }
      return disabledItemsIds;
    }, [sports, disabledItemsIds]);

    const sortedReducedSportList = useMemo(() => [...sports].sort(sportSortFunction), [sports, sportSortFunction]);

    const selectData = useMemo(() => {
      return {
        sports: { label: I18n.t("filters.search_results.label"), data: sortedReducedSportList }
      };
    }, [sortedReducedSportList]);

    const sportsMap = useObjFromList(sports, "id");
    const renderValue = useCallback(
      selectedSport => {
        if (isLoading) {
          return I18n.t("loading");
        }
        if (!isMultiple) {
          const sport = sportsMap[selectedSport as unknown as number];
          return sport ? dataLabelFormat(sport) : I18n.t("settings.select_sport");
        }
        if (selectedSport.length === 1) {
          const indicator = sportsMap[selectedSport[0]];
          return indicator ? dataLabelFormat(indicator) : I18n.t("settings.select_sport");
        }
        return I18n.t("filters.multiple_selected_values");
      },
      [sports, sportsMap, isMultiple]
    );

    return (
      <Select
        id={id || "toolbar-filters_sport"}
        data={selectData}
        value={value || null}
        label={label ?? I18n.t("sport")}
        placeholder={I18n.t("settings.select_sport")}
        searchPlaceholder={I18n.t("settings.search_sport")}
        className={classnames("filter-select", className)}
        hasBottomLine={hasBottomLine}
        renderValue={renderValue}
        onChange={onChange}
        disabledItemsIds={disabledItems}
        selectItemLabelFormat={dataLabelFormat}
        isLoading={isLoading}
        isVirtualized
        isSearchable
        isDisabled={isDisabled}
        isMultiple={isMultiple}
        initialIsOpen={initialIsOpen}
        renderActions={isMultiple ? renderSelectAction : undefined}
        canBeReset={canBeReset}
      />
    );
  }
);

export const SelectSport: React.FunctionComponent<IProps> = props => {
  const { id, className, hasBottomLine, storeId } = props;
  const { data: sports, isLoading } = useSportsForStore(storeId);
  return !storeId ? (
    <Select
      id={id || "toolbar-filters_sport"}
      data={{}}
      label={I18n.t("sport")}
      placeholder={I18n.t("settings.select_sport")}
      searchPlaceholder={I18n.t("settings.search_sport")}
      className={classnames("filter-select", className)}
      hasBottomLine={hasBottomLine}
      isSearchable
    />
  ) : (
    <DumbSelectSport sports={sports} {...props} isLoading={isLoading} />
  );
};

const mapStateToProps = (state: AppStore, { value, storeId }: IProps) => ({
  storeId: storeId !== undefined ? storeId : get(state, "filters.store_id"),
  value: value !== undefined ? value : get(state, "filters.sport")
});

export default connect(mapStateToProps)(SelectSport);
