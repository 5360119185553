import { ReactElement, useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";
import { navigation_menu } from "../../lang/en.json";

import BlockedPage from "pages/errors/blocked-page";
import LoadingProgress from "components/common/loader/loading-progress";
import TranslationsProvider from "core/providers/translations-provider";
import { useApiGetStores } from "../api/store/useApiGetStores";
import { useApiGetTemplates } from "../api/template/useApiGetTemplates";
import { useApiGetAccounts } from "../api/account/useApiGetAccounts";
import { useApiMenuGetUserPreferences } from "../api/menu/useApiMenuGetUserPreferences";
import { useApiMenuGetAllJobsViews } from "../api/menu/useApiMenuGetAllJobsViews";
import { useCountries } from "../api/country/useCountries";
import { useApiGetApiDailyTemplates } from "../api/template/useApiGetDailyTemplates";
import { useApiGetCoach } from "../api/coach/useApiGetCoach";
import { useApiGetDirMessage } from "../api/director/useApiGetDirector";
import { getTeam } from "core/hooks/useTeams";
import { useApiGetTeams } from "core/api/team/useApiGetTeams";
import { useApiGetAnnouncement } from "../api/announcement/useApiGetAnnouncement";
import { useAnalytics } from "../hooks/useAnalytics";
import { useApiGetSportsGroup } from "../api/sports-group/useApiGetSportsGroup";
import { mapRoleIDToRoleName } from "../utils/user";
import { useApiGetAggregates } from "../api/aggregate/useApiGetCalenderAggregate";

interface IAppInitializeProps {
  children: ReactElement;
}
const RessourcesProvider = ({ children }: IAppInitializeProps) => {
  // API Call : No PUT endpoint. The data will always stay fresh.
  // Have the option: staleTime: Infinity
  useCountries();
  useApiGetSportsGroup();
  const { error: storesError, isLoading: storesLoading } = useApiGetStores();
  const { error: accountsError, isLoading: accountsLoading } = useApiGetAccounts();
  useApiGetAggregates({ gcTime: Infinity });

  // API Call: Have PUT endpoint but very few used.
  // Have the option : staleTime: Infinity
  // After a PUT, the GET query cache is invalidate that trigger a new fetch.
  const { data: user, error: userError, isLoading: userLoading } = useApiMenuGetUserPreferences();
  const { error: templateError, isLoading: templatesLoading } = useApiGetTemplates();
  const { data: roleMapping } = useApiMenuGetAllJobsViews();
  const [roleName, setRoleName] = useState<string | "NOT-SET">("NOT-SET");
  useApiGetApiDailyTemplates();
  useApiGetAnnouncement();
  useAnalytics(user, roleName);

  const teamId = JSON.parse(sessionStorage.getItem("__mgf_snap(team)"))?.team;
  const storeId = JSON.parse(sessionStorage.getItem("__mgf_snap(store_id)"))?.store_id;

  // API Call : The rest of the API calls. The api call are fetch or refetch when the route has changed
  const { data: teams } = useApiGetTeams({ storeId }, { enabled: !!storeId });
  const team = getTeam(teams, teamId);
  useApiGetCoach(teamId, storeId, { enabled: !!team });
  useApiGetDirMessage(storeId, { enabled: !!storeId });

  useEffect(() => {
    if (user && roleMapping) {
      const name = mapRoleIDToRoleName(user?.default_navigation_menu_id, roleMapping, navigation_menu);
      setRoleName(name);
    }
  }, [user, roleMapping]);

  const ressourcesHaveError = userError || accountsError || templateError || storesError;
  const areRessourcesPending = userLoading || storesLoading || templatesLoading || accountsLoading;

  const renderChildren = () => {
    if (ressourcesHaveError) {
      return <BlockedPage title={I18n.t("unavailable.title")} content={I18n.t("unavailable.content")} />;
    }
    if (areRessourcesPending) {
      return <LoadingProgress />;
    }
    return children;
  };

  return <TranslationsProvider>{renderChildren()}</TranslationsProvider>;
};

export default RessourcesProvider;
