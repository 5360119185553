import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { StoresGroup } from "../../models/stores-group";
import { toast } from "react-toastify";
import { I18n } from "react-redux-i18n";
import { QUERY_KEY } from "./useApiGetStoresGroup";

export const useApiDeleteStoresGroup = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, Error, StoresGroup>({
    mutationFn: (storesGroup: StoresGroup) => {
      return axios.delete(`/stores_groups/${storesGroup.id}`);
    },

    onError: (error: Error) => {
      toast.error(I18n.t(error?.message || "error.global.message"));
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    }
  });
};

export const useApiDeleteSharedStoresGroup = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, Error, StoresGroup>({
    mutationFn: (storeGroup: StoresGroup) => {
      return axios.delete(`/stores_groups/shared/${storeGroup.id}`);
    },

    onError: (error: Error) => {
      toast.error(I18n.t(error?.message || "error.global.message"));
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    }
  });
};
