import { FC, memo } from "react";
import { I18n } from "react-redux-i18n";

const FilterBarComparison: FC = () => {
  return (
    <div className="mg-filters-bar__comparison">
      <div className="mg-filters-bar__comparison__icon">
        <span className="material-icons-outlined notranslate">arrow_forward</span>
      </div>
      <div className="mg-filters-bar__comparison__title">{I18n.t("filters-bar.compared-to")}</div>
    </div>
  );
};

export default memo(FilterBarComparison);
