// @ts-ignore
import { version } from "../../../package.json";
const APP_VERSION = version;
export const DATADOG_SITE = "datadoghq.eu";
export const DATADOG_SERVICE = "mygame-front";
// Global const
export const PACKAGE_VERSION = APP_VERSION;
export const ENV_PROD = "production";
export const ENV_PREPROD = "preprod";
export const DEVELOP = "development";
