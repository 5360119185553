import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { I18n } from "react-redux-i18n";
import {
  GROUPS_CRUD_STORES_GROUP_ADDED_TO_SHARED_WITH_ME,
  GROUPS_NOTIFICATION_STORES_GROUP_DOES_NOT_EXIST
} from "../../constants/translations/groups";
import { QUERY_KEY } from "./useApiGetStoresGroup";

export const useApiPostSharedStoresGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (storeGroupId: string) => {
      return axios.post(`/stores_groups/shared/${storeGroupId}`);
    },

    onSuccess: (response, variables) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      if (response.status === 201) {
        toast.info(`${variables}: ${I18n.t(GROUPS_CRUD_STORES_GROUP_ADDED_TO_SHARED_WITH_ME)}`);
      } else if (response.status === 204) {
        toast.info(`${variables}: ${I18n.t("settings.templates.info.already-exist")}`);
      }
    },

    onError: (response: AxiosResponse, variables) => {
      if (response.status === 404) {
        toast.error(`${variables}: ${I18n.t(GROUPS_NOTIFICATION_STORES_GROUP_DOES_NOT_EXIST)}`);
      } else {
        toast.error(`${I18n.t("settings.templates.error.error-server-side-retry-later")}`);
      }
    }
  });
};
