import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { QUERY_KEY_JOBS_VIEWS } from "./useApiMenuGetAllJobsViews";

export const useApiMenuPutJobsViews = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: any) => axios.put("/navigation_menu", payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_JOBS_VIEWS] });
    }
  });
};
