import { AxiosRequestConfig } from "axios";

import { getNavigatorLanguage } from "core/utils/i18n";
import getAppStore from "core/redux/store";
import qs from "qs";

const store = getAppStore();

const setLanguage = (config: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> => ({
  ...config,
  headers: {
    ...config.headers,
    "accept-language": getNavigatorLanguage()
  }
});

export const setParamsSerializer = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const paramsSerializer = (params: any) => qs.stringify(params, { arrayFormat: "repeat" });
  return {
    ...config,
    paramsSerializer
  };
};

const setToken = (config: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  const { token } = store.getState().user;
  return {
    ...config,
    headers: {
      ...config.headers,
      "content-type": "application/json",
      authorization: `Bearer ${token}`
    },
    timeout: parseInt(import.meta.env.REACT_APP_REQUEST_TIMEOUT)
  };
};

const addApiKeyHeader =
  (apiKey?: string): ((request: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>) =>
  (request: AxiosRequestConfig) => ({
    ...request,
    headers: {
      ...request.headers,
      ...(apiKey != null && { "x-api-key": apiKey })
    }
  });
// API_MYGAME_SPORT_API_KEY
const commonInterceptors = [
  { onFulfilled: setToken },
  { onFulfilled: setLanguage },
  { onFulfilled: setParamsSerializer }
];

export const sportInterceptors = [
  ...commonInterceptors,
  { onFulfilled: addApiKeyHeader(import.meta.env.REACT_APP_API_MYGAME_SPORT_API_KEY) }
];
