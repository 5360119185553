import { FC, ReactNode } from "react";
import axios, { AxiosRequestConfig } from "axios";
import { sportInterceptors } from "core/moon-config";
import { DefaultError, QueryClient, QueryClientProvider, QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

interface Props {
  children: ReactNode;
}

export type UseQueryOptionsWithoutQueryKey<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "queryKey">;

// rely on existing interceptors
sportInterceptors.forEach(({ onFulfilled }) => axios.interceptors.request.use(onFulfilled));

const setBaseURL = (config: AxiosRequestConfig): AxiosRequestConfig => ({
  ...config,
  baseURL: import.meta.env.REACT_APP_API_MYGAME_SPORT_URL
});

axios.interceptors.request.use(setBaseURL);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 20,
      retry: false
    }
  }
});

const ReactQueryProviderTryout: FC<Props> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools />
    {children}
  </QueryClientProvider>
);

export default ReactQueryProviderTryout;
