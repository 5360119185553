import { NULL_INITIAL_VALUE } from "core/constants/table";
import { getNavigatorLanguage } from "core/utils/i18n";
import { IMask } from "core/api/rest/queries";

export const COMMON_HUMAN_RADIX = 10;

const COMMON_NUMBER_DECIMALS = 12;

const authorizedChars: string[] = [".", "+", "e"];

export const pad = (number: number): string => (number < 10 ? `0${number.toString()}` : number.toString());

export const formatFloatDecimal = (num: number, decimal = 2): number => {
  const decimalPow = Math.pow(10, decimal);
  return parseFloat((Math.round(num * decimalPow) / decimalPow).toFixed(decimal));
};

export default class NumberUtils {
  public static isACorrectNumber(val: string): string {
    const nextVal: string =
      typeof parseFloat(val).toString().split("e")[1] === "undefined"
        ? val
        : parseFloat(val).toExponential(3).toString();
    let charOk = true;
    const formattedVal: string = nextVal === "" ? "" : nextVal.replace(",", ".");
    let numberOfPoints = 0;
    for (let i = 0; i < formattedVal.length; i++) {
      if (
        Number.isNaN(parseInt(formattedVal[i], COMMON_HUMAN_RADIX)) &&
        !authorizedChars.includes(formattedVal[i]) &&
        !(i === 0 && formattedVal[i] === "-")
      ) {
        charOk = false;
      } else if (formattedVal[i] === ".") {
        numberOfPoints++;
      }
    }

    return ((!Number.isNaN(parseFloat(formattedVal)) || formattedVal === "-") && charOk && numberOfPoints <= 1) ||
      formattedVal === ""
      ? formattedVal
      : null;
  }

  public static formatNumber(
    num?: number | null,
    mask?: IMask | null,
    locale: string = getNavigatorLanguage()
  ): string {
    if (num === null || num === undefined) {
      return NULL_INITIAL_VALUE;
    }
    if (mask === null || mask === undefined) {
      throw new Error(`Cannot format number ${num} with a null mask`);
    }

    num = typeof num.toString().split("e")[1] === "undefined" ? num : parseFloat(num.toExponential(3));

    return num
      .toLocaleString(locale, {
        style: mask.is_percentage ? "percent" : "decimal",
        minimumFractionDigits: mask.decimals,
        maximumFractionDigits: mask.decimals
      })
      .replace(/\u202f/g, " ");
  }

  public static isNumeric(num: any): boolean {
    return !Number.isNaN(parseFloat(num)) && isFinite(num);
  }

  public static leadingZeroDate(date: number): string {
    if (Number.isNaN(date)) {
      return "";
    }
    return (date < 10 && date > -10 ? "0" : "") + Math.abs(date).toString();
  }

  public static multiply(firstValue: number, secondValue: number) {
    try {
      return NumberUtils.floatIssue(this.notNull(firstValue) * this.notNull(secondValue), COMMON_NUMBER_DECIMALS);
    } catch (error) {
      return null;
    }
  }

  public static divide(numerator: number, denominator: number) {
    try {
      return NumberUtils.floatIssue(this.notNull(numerator) / this.notZero(denominator), COMMON_NUMBER_DECIMALS);
    } catch (error) {
      return null;
    }
  }

  public static notZero(num: number) {
    if (!this.notNull(num)) {
      // Matches +0, -0, NaN, null
      throw new RangeError(`Invalid dividend : ${num}`);
    }
    return num;
  }

  public static notNull(num: number) {
    if (num === null) {
      throw new RangeError("Number is null");
    }
    return num;
  }

  public static floatIssue(num: number, decimals: number): number {
    return num === null ? null : Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }

  public static roundNumberWithMask(num: number, mask: IMask): number {
    if (num === null || num === undefined) {
      return null;
    }
    if (mask === null || mask === undefined) {
      throw new Error(`Cannot round number ${num} with a null mask`);
    }
    if (
      mask.decimals === null ||
      mask.decimals === undefined ||
      mask.is_percentage === null ||
      mask.is_percentage === undefined ||
      Number.isNaN(parseInt(mask.decimals.toString(), COMMON_HUMAN_RADIX)) ||
      parseInt(mask.decimals.toString(), COMMON_HUMAN_RADIX) !== mask.decimals
    ) {
      throw new Error(`Cannot round number ${num} : mask is malformed`);
    }

    return mask.is_percentage
      ? NumberUtils.floatIssue(num, mask.decimals + 2)
      : NumberUtils.floatIssue(num, mask.decimals);
  }
}

export const getLeadingZeroNumber = (number: number): string => {
  if (number === undefined) {
    return "";
  }
  if (number === 0) {
    return "0";
  }
  if (number < 0 && number > -10) {
    return `-0${number}`;
  }
  if (number > 0 && number < 10) {
    return `0${number}`;
  }
  return number.toString();
};

export const truncateDecimals = (number: number, decimalPlaces: number) => {
  const factor = Math.pow(10, decimalPlaces);
  const truncatedNumber = Math.trunc(number * factor) / factor;
  return truncatedNumber;
};
