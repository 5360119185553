import MenuDataVisualization from "./data-visualization/menu-data-visualization";
import MenuConfiguration from "./configuration/menu-configuration";
import MenuAnimation from "./animation/menu-animation";
import { useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { createContext, useEffect, useMemo, useRef, useState } from "react";
import Modal from "components/common/mygame/modal/modal";
import { I18n } from "react-redux-i18n";
import { MENU_ALERT_CREATE, MENU_ALERT_SAVE } from "core/constants/translations/menu";
import { useApiMenuGetAllJobsViews } from "core/api/menu/useApiMenuGetAllJobsViews";
import { useApiMenuGetUserPreferences } from "core/api/menu/useApiMenuGetUserPreferences";
import { useApiMenuPutJobsViews } from "core/api/menu/useApiMenuPutJobsViews";
import { useApiMenuPostJobsViews } from "core/api/menu/useApiMenuPostJobsViews";
import { useApiMenuPutUserPreferences } from "core/api/menu/useApiMenuPutUserPreferences";
import { MenuComponentProps, MenuContextType } from "./menu-interface";
import _ from "lodash";
import { toast } from "react-toastify";
import { trackAnalyticsEvent } from "../../../../core/hooks/useAnalytics";

export const MenuContext = createContext<MenuContextType>({} as MenuContextType);

const MenuComponent = ({ isOpen, setIsOpen, url }: MenuComponentProps) => {
  const { data: getUserPreferences } = useApiMenuGetUserPreferences();
  const { data: getAllJobsViews } = useApiMenuGetAllJobsViews();
  const { mutate: mutatePutUserPreferences } = useApiMenuPutUserPreferences();
  const { mutate: mutatePostJobsViews } = useApiMenuPostJobsViews();
  const { mutate: mutatePutJobsViews } = useApiMenuPutJobsViews();

  const [modifiedData, setModifiedData] = useState<any>([]);
  const [viewId, setViewId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalInformations, setModalInformations] = useState({
    content: null,
    handleClickCancel: null,
    handleClickContinue: null
  });

  const refMenu = useRef(null);
  const navigate = useNavigate();
  const getUserId = getUserPreferences?.preferences?.default_filters?.menu_navigation_id
    ? getUserPreferences?.preferences?.default_filters?.menu_navigation_id
    : getUserPreferences?.default_navigation_menu_id;
  const selectedView = getAllJobsViews ? modifiedData?.find(item => item.id == viewId) : null;

  const handleOutsideClick = () => {
    if (event.target != document.getElementById("trigger-menu")) {
      setIsOpen(false);
    }
  };

  const handleOpenModal = (toggleModal, content, handleClickCancel, handleClickContinue) => {
    toggleModal();
    setModalInformations({
      content: content,
      handleClickCancel: handleClickCancel,
      handleClickContinue: handleClickContinue
    });
  };

  const openModal = () => setShowModal(true);

  const modalCancelNotContinue = () => setShowModal(false);
  const modalCancelContinue = () => {
    setModifiedData(_.cloneDeep(getAllJobsViews));
    setViewId(_.cloneDeep(getUserId));
    setShowModal(false);
    setIsEditing(false);
  };

  const modalSaveNotContinue = () => setShowModal(false);
  const modalSaveContinue = () => {
    if (getUserPreferences?.preferences?.default_filters?.menu_navigation_id !== viewId) {
      const { default_filters } = getUserPreferences.preferences;
      default_filters.menu_navigation_id = viewId;
      mutatePutUserPreferences(default_filters);
    }

    if (!_.isEqual(getAllJobsViews, modifiedData)) {
      const modifiedDataToUpdate = modifiedData?.find(item => item.id == viewId);
      modifiedDataToUpdate.route.forEach((item, i) => {
        item.index = i + 1;
      });
      mutatePostJobsViews(modifiedDataToUpdate);
    }

    setShowModal(false);
    toast.info(I18n.t(MENU_ALERT_SAVE));
  };

  const modalCreateNotContinue = () => setShowModal(false);
  const modalCreateContinue = () => {
    const checkCustomizedMenu = modifiedData?.find(item => !item.default);
    if (typeof checkCustomizedMenu == "undefined") {
      const routesOfCustomizedJobView = modifiedData?.find(item => item.id == viewId);
      const labelOfCustomizedJobView = "MY MENU";
      const customizedJobView = {
        label: labelOfCustomizedJobView,
        route: routesOfCustomizedJobView.route
      };
      mutatePutJobsViews(customizedJobView);
      trackAnalyticsEvent("click_top_burger_menu_create_custom_menu");
    }

    if (typeof checkCustomizedMenu == "object") {
      const routesOfCustomizedJobView = modifiedData?.find(item => item.id == viewId);
      const getMenuCustomized = modifiedData?.find(item => !item.default);
      const menuCustomizedUpdated = { ...getMenuCustomized, route: routesOfCustomizedJobView.route };
      const { default_filters } = getUserPreferences.preferences;
      default_filters.menu_navigation_id = getMenuCustomized.id;
      mutatePostJobsViews(menuCustomizedUpdated);
      mutatePutUserPreferences(default_filters);
      setViewId(_.cloneDeep(getMenuCustomized.id));
    }
    setShowModal(false);
    toast.info(I18n.t(MENU_ALERT_CREATE));
    trackAnalyticsEvent("click_top_burger_menu_create_custom_menu_from_predefined role");
  };

  const dataToProvide = useMemo(
    () => ({
      modifiedData,
      setModifiedData,
      modalInformations,
      setModalInformations,
      viewId,
      setViewId,
      isEditing,
      setIsEditing,
      showModal,
      setShowModal,
      selectedView,
      handleOpenModal,
      navigate,
      openModal,
      modalCancelNotContinue,
      modalCancelContinue,
      modalSaveNotContinue,
      modalSaveContinue,
      modalCreateNotContinue,
      modalCreateContinue,
      getAllJobsViews,
      getUserId,
      getUserPreferences
    }),
    [
      modifiedData,
      setModifiedData,
      modalInformations,
      setModalInformations,
      viewId,
      setViewId,
      isEditing,
      setIsEditing,
      showModal,
      setShowModal,
      selectedView,
      handleOpenModal,
      navigate,
      openModal,
      modalCancelNotContinue,
      modalCancelContinue,
      modalSaveNotContinue,
      modalSaveContinue,
      modalCreateNotContinue,
      modalCreateContinue,
      getUserId,
      getUserPreferences
    ]
  );

  useEffect(() => {
    setViewId(_.cloneDeep(getUserId));
  }, [getUserPreferences]);

  useEffect(() => {
    setModifiedData(_.cloneDeep(getAllJobsViews));
  }, [getAllJobsViews]);

  const menuNavigationUserPreference = getUserPreferences?.default_navigation_menu_id;
  return (
    <>
      {
        <MenuContext.Provider value={dataToProvide}>
          <OutsideClickHandler onOutsideClick={handleOutsideClick}>
            <div className={`mg-menu${isOpen ? " isOpen" : ""}`} ref={refMenu}>
              <div className="mg-menu-block">
                {showModal && (
                  <Modal
                    content={modalInformations.content}
                    handleClickCancel={modalInformations.handleClickCancel}
                    handleClickContinue={modalInformations.handleClickContinue}
                  />
                )}
                <MenuConfiguration menuNavigationUserPreference={menuNavigationUserPreference} />
                <MenuDataVisualization url={url} />
                <MenuAnimation url={url} isOpen={isOpen} isEditing={isEditing} />
              </div>
            </div>
          </OutsideClickHandler>
        </MenuContext.Provider>
      }
    </>
  );
};

export default MenuComponent;
