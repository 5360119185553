import AppWrapper from "components/common/app-wrapper";
import ErrorPage from "pages/errors/error-page";
import NotFound from "pages/errors/not-found";
import { buildRouteEls, getPathFromRouteId } from "core/utils/route-utils";
import { useMemo } from "react";
import { I18n } from "react-redux-i18n";
import { Route } from "react-router-dom";
import PostFedid from "./post-fedid";
import { RouteId } from "core/routing/router/route-ids";
import { CALLBACK_PATH } from "core/utils/authentication";
import { Navigate, Routes } from "react-router";
import { useRoutes } from "core/hooks/useRoutes";
import { useApiMenuGetUserPreferences } from "../../api/menu/useApiMenuGetUserPreferences";

const getHttpErrorRouter = () => (
  <>
    <Route
      path="/400"
      element={
        <ErrorPage title={I18n.t("error.http.bad-request.title")}>{I18n.t("error.http.bad-request.message")}</ErrorPage>
      }
    />
    <Route
      path="/401"
      element={
        <ErrorPage title={I18n.t("error.http.unauthorized.title")}>
          {I18n.t("error.http.unauthorized.message")}
        </ErrorPage>
      }
    />
    <Route
      path="/403"
      element={
        <ErrorPage title={I18n.t("error.http.forbidden.title")}>{I18n.t("error.http.forbidden.message")}</ErrorPage>
      }
    />
    <Route path="/404" element={<NotFound />} />

    <Route
      path="/500"
      element={
        <ErrorPage title={I18n.t("error.http.internal-server-error.title")}>
          {I18n.t("error.http.internal-server-error.message")}
        </ErrorPage>
      }
    />
    <Route
      path="/503"
      element={
        <ErrorPage title={I18n.t("error.http.service-unavailable.title")}>
          {I18n.t("error.http.service-unavailable.message")}
        </ErrorPage>
      }
    />
  </>
);

export default () => {
  const routes = useRoutes();
  const { data: user } = useApiMenuGetUserPreferences();

  const routeEls = useMemo(() => buildRouteEls(routes, user.roles), [routes]);

  return (
    <AppWrapper>
      <Routes>
        {getHttpErrorRouter()}
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path={CALLBACK_PATH} element={<PostFedid />} />
        <Route path="/settings" element={<Navigate to={getPathFromRouteId(RouteId.UserPreferences)} />} />
        {routeEls}
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </AppWrapper>
  );
};
