import { I18n } from "react-redux-i18n";
import { Fragment, useContext, useEffect, useState } from "react";
import ROLES_MAPPING, { ROLES } from "core/constants/roles";
import { NavLink } from "react-router-dom";
import { checkIsHasPermission } from "core/utils/checkIsHasPermission";
import FavoriteLink from "./favoriteLink";
import { MenuContext } from "../menu";
import { useUserHasRoles } from "core/hooks/useUserHasRoles";
import getRouteDisabled from "./get-route-disabled";
import { useApiMenuGetUserPreferences } from "../../../../../core/api/menu/useApiMenuGetUserPreferences";
import { trackAnalyticsEvent } from "../../../../../core/hooks/useAnalytics";

const MenuAnimationsAccordion = ({ link, sublinks, url, isOpen }) => {
  const isAdmin = useUserHasRoles([ROLES.ROLE_ADMIN]);
  const { isEditing, selectedView, modifiedData } = useContext(MenuContext);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isParentToRouteSelected, setIsParentToRouteSelected] = useState(false);
  const activeStyle = { color: "#0082c3" };
  const { data: user } = useApiMenuGetUserPreferences();

  const handleClickOnMenuSubLink = (event, action, isDisabledLink) => {
    trackAnalyticsEvent(action);
    if (isEditing || (isDisabledLink && !isAdmin)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    setIsCollapsed(false);
    setIsParentToRouteSelected(false);
    sublinks.map(item => {
      if (item.path === url) {
        setIsCollapsed(true);
        setIsParentToRouteSelected(true);
      }
    });
  }, [isOpen]);

  return (
    <div className={`mg-menu-animation-accordion ${isCollapsed ? "isCollapsed" : ""}`}>
      <div
        className={`mg-menu-animation-accordion__text ${isParentToRouteSelected ? "isParentToRouteSelected" : ""}`}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <div className="mg-menu-animation-accordion__text__title">
          <span className={`${link?.filled ? "material-icons notranslate" : "material-icons-outlined notranslate"}`}>
            {link.icon}
          </span>
          <span>{I18n.t(link?.name)}</span>
        </div>
        <span className="material-icons notranslate">chevron_right</span>
      </div>
      <div className="mg-menu-animation-accordion__sublinks">
        {sublinks.map(item => {
          const actionGoogleAnalytics = `click_bottom_burger_menu_${item?.googleAnalyticsAction}`;
          const isDisabledLink = getRouteDisabled(item?.id);
          return (
            <Fragment key={item.id}>
              {checkIsHasPermission(user.roles, ROLES_MAPPING, link.rights[0]) ? (
                <NavLink
                  to={item.path}
                  className={`mg-menu-animation-accordion__sublinks__title ${isDisabledLink ? "disabled" : ""}`}
                  onClick={event => handleClickOnMenuSubLink(event, actionGoogleAnalytics, isDisabledLink)}
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  end
                >
                  <div className="mg-menu-animation-accordion__sublinks__title__text">
                    {item.path && <span>{I18n.t(item?.navigationMenuInformation?.menuName)}</span>}
                    {isEditing && (
                      <FavoriteLink sublink={item} selectedView={selectedView} modifiedData={modifiedData} />
                    )}
                  </div>
                </NavLink>
              ) : null}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default MenuAnimationsAccordion;
