import Askr from "pages/askr/askr";
import MyOfferIFrame from "pages/my-offer/my-offer";
import DocumentIFrame from "pages/document/document-iframe";
import SchoolIFrame from "pages/school/school-iframe";
import { PERMISSIONS } from "core/constants/roles";
import { RouteId } from "./route-ids";
import { ComponentType, LazyExoticComponent, Suspense } from "react";
import { Navigate } from "react-router";
import {
  AnnualInitializationPiloting,
  AnnualInitializationTeamsPiloting,
  AreasPiloting,
  ComparisonSportGroups,
  ComparisonSports,
  ComparisonStores,
  ComparisonTeams,
  DailyPiloting,
  DatavizComparison,
  DatavizWeekly,
  EventsPiloting,
  GroupsCreation,
  ManagerByMonth,
  Planning,
  SportsPiloting,
  SportsStoresPiloting,
  SportsTeamsPiloting,
  Templates,
  UserPreferences,
  WeeklyPiloting,
  WeeklySportsGroupsPiloting,
  WeeklyStoresPiloting,
  WeeklyTeamsPiloting,
  ReportingUsageByCountryIFrame,
  ReportingHoursEfficiencyPerCountryIFrame,
  ReportingEffitimeReliabilityIFrame,
  EffitimeIframe,
  SalesPerformancesIframe,
  ReportingProcessManagement,
  ReportingExtraction,
  ReportingExtractionMonthlyData,
  ReportingExtractionDayDay,
  ReportingExtractionTrajectory,
  ReportingExtractionWeekly,
  DayByDayHourByHourEmbeded,
  HomeNew,
  SalesAnimationEmbedded,
  ReportingHoursEfficiencyPerCountryProbableYearIFrame,
  ReportingQualityForecastingIFrame,
  Announcement,
  DailyPilotingStore,
  DailyPilotingTeam,
  HoursCommercial,
  HoursCashier,
  HoursGeneralServices,
  ErrorPage
} from "./lazy-imports";

import {
  PILOTING_ANNUAL_INITIALIZATION_BY_SPORTS_GROUPS,
  PILOTING_ANNUAL_INITIALIZATION_BY_TEAMS,
  PILOTING_AREAS,
  PILOTING_ASKR,
  PILOTING_COMPARISON_BY_SPORTS,
  PILOTING_COMPARISON_BY_SPORTS_GROUPS,
  PILOTING_COMPARISON_BY_STORES,
  PILOTING_COMPARISON_BY_TEAMS,
  PILOTING_DAILY,
  PILOTING_DAYS_HOURS,
  PILOTING_HOURS,
  PILOTING_MONTHS,
  PILOTING_SPORTS_BY_SPORTS_GROUPS,
  PILOTING_SPORTS_BY_STORES,
  PILOTING_SPORTS_BY_TEAMS,
  PILOTING_WEEKLY_BY_SPORTS,
  PILOTING_WEEKLY_BY_SPORTS_GROUPS,
  PILOTING_WEEKLY_BY_STORES,
  PILOTING_WEEKLY_BY_TEAMS,
  SETTINGS_EFFITIMES,
  SETTINGS_EVENTS,
  SETTINGS_FAVORITE_SPORTS,
  SETTINGS_HOME,
  SETTINGS_HOME_COMPARISON,
  SETTINGS_HOME_WEEKLY,
  SETTINGS_MANAGEMENT_RULES,
  SETTINGS_MY_OFFER,
  SETTINGS_PLANNING,
  SETTINGS_REPORTING_EXTRACTION,
  SETTINGS_REPORTING_EXTRACTION_DAYDAY,
  SETTINGS_REPORTING_EXTRACTION_MONTHLY_DATA,
  SETTINGS_REPORTING_EXTRACTION_TRAJECTORY,
  SETTINGS_REPORTING_EXTRACTION_WEEKLY,
  SETTINGS_REPORTING_HOURS_EFFICIENCY_PER_COUNTRY,
  SETTINGS_REPORTING_PROCESS_MANAGEMENT,
  SETTINGS_REPORTING_USAGE_BY_COUNTRY,
  SETTINGS_REPORTING_EFFITIME_RELIABILITY,
  SETTINGS_SALES_PERFORMANCES,
  SETTINGS_SCHOOL,
  SETTINGS_TEMPLATES,
  SETTINGS_UPWARD_FORECAST,
  SETTINGS_USER_PREFERENCES,
  SETTINGS_EMBEDDED_SALES_ANIMATION,
  SETTINGS_REPORTING_HOURS_EFFICIENCY_PER_COUNTRY_PROBABLE_YEAR,
  ANNOUNCEMENT_PAGE,
  SETTINGS_REPORTING_QUALITY_FORECASTING,
  PILOTING_HOURS_GENERAL_SERVICES,
  PILOTING_HOURS_CASHIER,
  PILOTING_HOURS_COMMERCIAL_TEAM,
  PILOTING_HOURS_COMMERCIAL_SPORT
} from "core/constants/translations";
import {
  DESCRIPTION_ANNUAL_INITIALIZATION_BY_SPORTS_GROUPS,
  DESCRIPTION_ANNUAL_INITIALIZATION_BY_TEAMS,
  DESCRIPTION_AREAS,
  DESCRIPTION_ASKR,
  DESCRIPTION_COMPARISON_BY_SPORTS,
  DESCRIPTION_COMPARISON_BY_SPORTS_GROUPS,
  DESCRIPTION_COMPARISON_BY_STORES,
  DESCRIPTION_COMPARISON_BY_TEAMS,
  DESCRIPTION_DAILY,
  DESCRIPTION_DAYS_HOURS,
  DESCRIPTION_EFFITIME,
  DESCRIPTION_EVENTS,
  DESCRIPTION_FAVORITE_SPORTS,
  DESCRIPTION_HOME,
  DESCRIPTION_HOME_COMPARISON,
  DESCRIPTION_HOME_WEEKLY,
  // DESCRIPTION_HOURS,
  DESCRIPTION_MANAGEMENT_RULES,
  DESCRIPTION_MONTHS,
  DESCRIPTION_MY_OFFER,
  DESCRIPTION_PLANNING,
  DESCRIPTION_REPORTING_EXTRACTION,
  DESCRIPTION_REPORTING_EXTRACTION_DAYDAY,
  DESCRIPTION_REPORTING_EXTRACTION_MONTHLY_DATA,
  DESCRIPTION_REPORTING_EXTRACTION_TRAJECTORY,
  DESCRIPTION_REPORTING_EXTRACTION_WEEKLY,
  DESCRIPTION_REPORTING_HOURS_EFFICIENCY_PER_COUNTRY,
  DESCRIPTION_REPORTING_PROCESS_MANAGEMENT,
  DESCRIPTION_REPORTING_USAGE_BY_COUNTRY,
  DESCRIPTION_REPORTING_EFFITIME_RELIABILITY,
  DESCRIPTION_SALES_PERFORMANCES,
  DESCRIPTION_SCHOOL,
  DESCRIPTION_SPORTS_BY_SPORTS_GROUPS,
  DESCRIPTION_SPORTS_BY_STORES,
  DESCRIPTION_SPORTS_BY_TEAMS,
  DESCRIPTION_TEMPLATES,
  DESCRIPTION_UPWARD_FORECAST,
  DESCRIPTION_USER_PREFERENCES,
  DESCRIPTION_WEEKLY_BY_SPORTS,
  DESCRIPTION_WEEKLY_BY_SPORTS_GROUPS,
  DESCRIPTION_WEEKLY_BY_STORES,
  DESCRIPTION_WEEKLY_BY_TEAMS,
  DESCRIPTION_EMBEDDED_SALES_ANIMATION,
  DESCRIPTION_REPORTING_HOURS_EFFICIENCY_PER_COUNTRY_PROBABLE_YEAR,
  DESCRIPTION_ANNOUNCEMENT,
  DESCRIPTION_REPORTING_QUALITY_FORECASTING,
  DESCRIPTION_HOURS_TEAM,
  DESCRIPTION_HOURS_SPORT,
  DESCRIPTION_HOURS_CASHIER,
  DESCRIPTION_HOURS_GENERAL_SERVICES
} from "../../constants/translations/page-description";
import {
  MENU_ANIMATION_SUBLINK_SPORT,
  MENU_ANIMATION_SUBLINK_SPORTS_GROUPS,
  MENU_ANIMATION_SUBLINK_STORE,
  MENU_ANIMATION_SUBLINK_TEAM
} from "../../constants/translations/menu";
import { IBreadcrumbs } from "components/common/mygame/subheader/nav/subheader";
import LoadingDialog from "components/common/loader/loading-progress";
import UpwardForecastContainer from "../../../pages/upward-forecast/upward-forecast";
import { PILOTING_DAILY_BY_STORE, PILOTING_DAILY_BY_TEAM } from "../../constants/translations";
export interface IRoute<P = unknown> {
  id: RouteId;
  name: string;
  rights: PERMISSIONS[];
  path: string;
  isDisabled?: boolean;
  component: LazyExoticComponent<ComponentType<P>> | ComponentType<P>;
  description: string;
  shortcutSportsGroups: boolean;
  shortcutStoresGroups: boolean;
  navigationMenuInformation?: { iconName: string; iconIsFilled: boolean; menuName: string };
  breadcrumbs?: IBreadcrumbs[];
  isVisibleSubheader?: boolean;
  type?: string;
  googleAnalyticsAction: string;
}

export const ROUTES: Array<IRoute<{ [key: string]: unknown }>> = [
  {
    id: RouteId.Error,
    path: "/error",
    name: "Error Page",
    description: "Error Page Description",
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [],
    isVisibleSubheader: false,
    googleAnalyticsAction: "error",
    component: () => (
      <Suspense fallback={<LoadingDialog />}>
        <ErrorPage />
      </Suspense>
    )
  },
  {
    id: RouteId.Home,
    path: "/home",
    name: SETTINGS_HOME,
    description: DESCRIPTION_HOME,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [],
    isVisibleSubheader: false,
    googleAnalyticsAction: "home",
    component: () => (
      <Suspense fallback={<LoadingDialog />}>
        <HomeNew />
      </Suspense>
    )
  },
  {
    id: RouteId.HomeNew,
    path: "/home/new",
    name: SETTINGS_HOME,
    description: DESCRIPTION_HOME,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [],
    isVisibleSubheader: false,
    googleAnalyticsAction: "home_new",
    component: () => <Navigate to="/home" />
  },
  {
    id: RouteId.HomeSport,
    path: "/home/sport",
    name: SETTINGS_HOME,
    description: DESCRIPTION_HOME,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [],
    isVisibleSubheader: false,
    googleAnalyticsAction: "home_sport",
    component: () => <Navigate to="/home" />
  },
  {
    id: RouteId.HomeTeam,
    path: "/home/team",
    name: SETTINGS_HOME,
    description: DESCRIPTION_HOME,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [],
    isVisibleSubheader: false,
    googleAnalyticsAction: "home_team",
    component: () => <Navigate to="/home" />
  },
  {
    id: RouteId.HomeStore,
    path: "/home/store",
    name: SETTINGS_HOME,
    description: DESCRIPTION_HOME,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [],
    isVisibleSubheader: false,
    googleAnalyticsAction: "home_store",
    component: () => <Navigate to="/home" />
  },
  {
    id: RouteId.HomeWeekly,
    path: "/home/weekly",
    name: SETTINGS_HOME_WEEKLY,
    description: DESCRIPTION_HOME_WEEKLY,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [],
    isVisibleSubheader: true,
    googleAnalyticsAction: "visualization_by_week",
    component: DatavizWeekly
  },
  {
    id: RouteId.HomeComparison,
    path: "/home/comparison",
    name: SETTINGS_HOME_COMPARISON,
    description: DESCRIPTION_HOME_COMPARISON,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [],
    isVisibleSubheader: true,
    googleAnalyticsAction: "comparison_by_week",
    component: DatavizComparison
  },
  {
    id: RouteId.Weekly,
    path: "/piloting/weekly",
    name: PILOTING_WEEKLY_BY_SPORTS,
    description: DESCRIPTION_WEEKLY_BY_SPORTS,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "date_range", iconIsFilled: false, menuName: MENU_ANIMATION_SUBLINK_SPORT },
    isVisibleSubheader: true,
    rights: [PERMISSIONS.PILOTING_WEEKLY_ACCESS],
    googleAnalyticsAction: "weekly_by_sport",
    component: WeeklyPiloting
  },
  {
    id: RouteId.WeeklySportsGroup,
    path: "/piloting/weekly/sports-groups",
    name: PILOTING_WEEKLY_BY_SPORTS_GROUPS,
    description: DESCRIPTION_WEEKLY_BY_SPORTS_GROUPS,
    shortcutSportsGroups: true,
    shortcutStoresGroups: false,
    navigationMenuInformation: {
      iconName: "date_range",
      iconIsFilled: false,
      menuName: MENU_ANIMATION_SUBLINK_SPORTS_GROUPS
    },
    rights: [PERMISSIONS.PILOTING_WEEKLY_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "weekly_by_sports_group",
    component: WeeklySportsGroupsPiloting
  },
  {
    id: RouteId.WeeklyTeams,
    path: "/piloting/weekly/teams",
    name: PILOTING_WEEKLY_BY_TEAMS,
    description: DESCRIPTION_WEEKLY_BY_TEAMS,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "date_range", iconIsFilled: false, menuName: MENU_ANIMATION_SUBLINK_TEAM },
    rights: [PERMISSIONS.PILOTING_WEEKLY_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "weekly_by_teams",
    component: WeeklyTeamsPiloting
  },
  {
    id: RouteId.WeeklyStores,
    path: "/piloting/weekly/stores",
    name: PILOTING_WEEKLY_BY_STORES,
    description: DESCRIPTION_WEEKLY_BY_STORES,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "date_range", iconIsFilled: false, menuName: MENU_ANIMATION_SUBLINK_STORE },
    rights: [PERMISSIONS.PILOTING_WEEKLY_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "weekly_by_store",
    component: WeeklyStoresPiloting
  },
  {
    id: RouteId.Sports,
    path: "/piloting/sports",
    name: PILOTING_SPORTS_BY_SPORTS_GROUPS,
    description: DESCRIPTION_SPORTS_BY_SPORTS_GROUPS,
    shortcutSportsGroups: true,
    shortcutStoresGroups: false,
    navigationMenuInformation: {
      iconName: "sports_tennis",
      iconIsFilled: false,
      menuName: MENU_ANIMATION_SUBLINK_SPORTS_GROUPS
    },
    rights: [PERMISSIONS.PILOTING_SPORTS_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "sport_analysis_by_sports_group",
    component: SportsPiloting,
    breadcrumbs: [{ routeId: RouteId.ComparisonSportGroups, name: PILOTING_COMPARISON_BY_SPORTS_GROUPS }]
  },
  {
    id: RouteId.SportsTeams,
    path: "/piloting/sports/teams",
    name: PILOTING_SPORTS_BY_TEAMS,
    description: DESCRIPTION_SPORTS_BY_TEAMS,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: {
      iconName: "sports_tennis",
      iconIsFilled: false,
      menuName: MENU_ANIMATION_SUBLINK_TEAM
    },
    rights: [PERMISSIONS.PILOTING_SPORTS_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "sport_analysis_by_team",
    component: SportsTeamsPiloting,
    breadcrumbs: [
      { routeId: RouteId.ComparisonStores, name: PILOTING_COMPARISON_BY_STORES },
      { routeId: RouteId.SportsStores, name: PILOTING_SPORTS_BY_STORES }
    ]
  },
  {
    id: RouteId.SportsStores,
    path: "/piloting/sports/stores",
    name: PILOTING_SPORTS_BY_STORES,
    description: DESCRIPTION_SPORTS_BY_STORES,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: {
      iconName: "sports_tennis",
      iconIsFilled: false,
      menuName: MENU_ANIMATION_SUBLINK_STORE
    },
    rights: [PERMISSIONS.PILOTING_SPORTS_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "sport_analysis_by_store",
    component: SportsStoresPiloting,
    breadcrumbs: [{ routeId: RouteId.ComparisonStores, name: PILOTING_COMPARISON_BY_STORES }]
  },
  {
    id: RouteId.AnnualInitialization,
    path: "/piloting/annual-initialization",
    name: PILOTING_ANNUAL_INITIALIZATION_BY_SPORTS_GROUPS,
    description: DESCRIPTION_ANNUAL_INITIALIZATION_BY_SPORTS_GROUPS,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: {
      iconName: "ads_click",
      iconIsFilled: false,
      menuName: MENU_ANIMATION_SUBLINK_SPORTS_GROUPS
    },
    rights: [PERMISSIONS.PILOTING_ANNUAL_INITIALIZATION_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "annual_init_sports_group",
    component: AnnualInitializationPiloting,
    breadcrumbs: [{ routeId: RouteId.WeeklySportsGroup, name: PILOTING_WEEKLY_BY_SPORTS_GROUPS }]
  },
  {
    id: RouteId.AnnualInitializationTeams,
    path: "/piloting/annual-initialization/teams",
    name: PILOTING_ANNUAL_INITIALIZATION_BY_TEAMS,
    description: DESCRIPTION_ANNUAL_INITIALIZATION_BY_TEAMS,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "ads_click", iconIsFilled: false, menuName: MENU_ANIMATION_SUBLINK_TEAM },
    rights: [PERMISSIONS.PILOTING_ANNUAL_INITIALIZATION_ACCESS],
    googleAnalyticsAction: "annual_init_team",
    component: AnnualInitializationTeamsPiloting,
    isVisibleSubheader: true,
    breadcrumbs: [{ routeId: RouteId.WeeklyTeams, name: PILOTING_WEEKLY_BY_TEAMS }]
  },
  {
    id: RouteId.HoursTeam,
    path: "/piloting/hours",
    name: PILOTING_HOURS_COMMERCIAL_TEAM,
    description: DESCRIPTION_HOURS_TEAM,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.PILOTING_HOURS_ACCESS],
    component: HoursCommercial,
    isVisibleSubheader: true,
    googleAnalyticsAction: "hours_department_team",
    breadcrumbs: [{ routeId: RouteId.WeeklyTeams, name: PILOTING_WEEKLY_BY_TEAMS }],
    navigationMenuInformation: { iconName: "schedule", iconIsFilled: false, menuName: PILOTING_HOURS_COMMERCIAL_TEAM },
    type: "hours"
  },
  {
    id: RouteId.HoursSport,
    path: "/piloting/hours-sport",
    name: PILOTING_HOURS_COMMERCIAL_SPORT,
    description: DESCRIPTION_HOURS_SPORT,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.PILOTING_HOURS_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "hours_department_sport",
    component: HoursCommercial,
    navigationMenuInformation: { iconName: "schedule", iconIsFilled: false, menuName: PILOTING_HOURS_CASHIER },
    type: "hours"
  },
  {
    id: RouteId.HoursCashierServices,
    path: "/piloting/hours-cashier-services",
    name: PILOTING_HOURS_CASHIER,
    description: DESCRIPTION_HOURS_CASHIER,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.PILOTING_HOURS_ACCESS],
    googleAnalyticsAction: "hours_cashier",
    component: HoursCashier,
    isVisibleSubheader: true,
    breadcrumbs: [{ routeId: RouteId.WeeklyTeams, name: PILOTING_WEEKLY_BY_TEAMS }],
    navigationMenuInformation: { iconName: "schedule", iconIsFilled: false, menuName: PILOTING_HOURS_CASHIER },
    type: "hours"
  },
  {
    id: RouteId.HoursGeneralServices,
    path: "/piloting/hours-general-services",
    name: PILOTING_HOURS_GENERAL_SERVICES,
    description: DESCRIPTION_HOURS_GENERAL_SERVICES,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.PILOTING_HOURS_ACCESS],
    googleAnalyticsAction: "hours_general_services",
    component: HoursGeneralServices,
    isVisibleSubheader: true,
    breadcrumbs: [{ routeId: RouteId.WeeklyTeams, name: PILOTING_WEEKLY_BY_TEAMS }],
    navigationMenuInformation: { iconName: "schedule", iconIsFilled: false, menuName: PILOTING_HOURS_GENERAL_SERVICES },
    type: "hours"
  },
  {
    id: RouteId.Areas,
    path: "/piloting/areas",
    name: PILOTING_AREAS,
    description: DESCRIPTION_AREAS,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "zoom_in_map", iconIsFilled: false, menuName: PILOTING_AREAS },
    rights: [PERMISSIONS.PILOTING_WEEKLY_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "area_piloting",
    component: AreasPiloting
  },
  //deprecated route : RouteId.Daily,
  {
    id: RouteId.Daily,
    path: "/piloting/days",
    name: PILOTING_DAILY,
    description: DESCRIPTION_DAILY,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "today", iconIsFilled: false, menuName: PILOTING_HOURS },
    rights: [PERMISSIONS.PILOTING_DAILY_PILOTING],
    component: DailyPiloting,
    isVisibleSubheader: true,
    googleAnalyticsAction: "piloting_day",
    breadcrumbs: [{ routeId: RouteId.ManagerByMonth, name: PILOTING_MONTHS }]
  },
  {
    id: RouteId.DailyStore,
    path: "/piloting/days/store",
    name: PILOTING_DAILY_BY_STORE,
    description: DESCRIPTION_DAILY,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "today", iconIsFilled: false, menuName: MENU_ANIMATION_SUBLINK_STORE },
    rights: [PERMISSIONS.PILOTING_DAILY_PILOTING],
    googleAnalyticsAction: "day_day_store",
    component: DailyPilotingStore,
    isVisibleSubheader: true,
    breadcrumbs: [{ routeId: RouteId.ManagerByMonth, name: PILOTING_MONTHS }]
  },
  {
    id: RouteId.DailyTeam,
    path: "/piloting/days/team",
    name: PILOTING_DAILY_BY_TEAM,
    description: DESCRIPTION_DAILY,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "today", iconIsFilled: false, menuName: MENU_ANIMATION_SUBLINK_TEAM },
    rights: [PERMISSIONS.PILOTING_DAILY_PILOTING],
    googleAnalyticsAction: "day_day_team",
    component: DailyPilotingTeam,
    isVisibleSubheader: true,
    breadcrumbs: [{ routeId: RouteId.ManagerByMonth, name: PILOTING_MONTHS }]
  },
  {
    id: RouteId.ManagerByMonth,
    path: "/piloting/manager-by-month",
    name: PILOTING_MONTHS,
    description: DESCRIPTION_MONTHS,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "calendar_month", iconIsFilled: false, menuName: PILOTING_MONTHS },
    rights: [PERMISSIONS.PILOTING_MANAGER_BY_MONTH],
    googleAnalyticsAction: "monthly",
    component: ManagerByMonth,
    isVisibleSubheader: true,
    breadcrumbs: [{ routeId: RouteId.Daily, name: PILOTING_DAILY }]
  },
  {
    id: RouteId.ComparisonTeams,
    path: "/piloting/comparison",
    name: PILOTING_COMPARISON_BY_TEAMS,
    description: DESCRIPTION_COMPARISON_BY_TEAMS,
    shortcutSportsGroups: false,
    shortcutStoresGroups: true,
    navigationMenuInformation: { iconName: "join_left", iconIsFilled: false, menuName: MENU_ANIMATION_SUBLINK_TEAM },
    rights: [PERMISSIONS.PILOTING_COMPARISON_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "comparison_team",
    component: ComparisonTeams
  },
  {
    id: RouteId.ComparisonStores,
    path: "/piloting/comparison/stores",
    name: PILOTING_COMPARISON_BY_STORES,
    description: DESCRIPTION_COMPARISON_BY_STORES,
    shortcutSportsGroups: false,
    shortcutStoresGroups: true,
    navigationMenuInformation: { iconName: "join_left", iconIsFilled: false, menuName: MENU_ANIMATION_SUBLINK_STORE },
    rights: [PERMISSIONS.PILOTING_COMPARISON_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "comparison_store",
    component: ComparisonStores
  },
  {
    id: RouteId.ComparisonSports,
    path: "/piloting/comparison/sports",
    name: PILOTING_COMPARISON_BY_SPORTS,
    description: DESCRIPTION_COMPARISON_BY_SPORTS,
    shortcutSportsGroups: false,
    shortcutStoresGroups: true,
    navigationMenuInformation: { iconName: "join_left", iconIsFilled: false, menuName: MENU_ANIMATION_SUBLINK_SPORT },
    rights: [PERMISSIONS.PILOTING_COMPARISON_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "comparison_sports",
    component: ComparisonSports
  },
  {
    id: RouteId.ComparisonSportGroups,
    path: "/piloting/comparison/sports-groups",
    name: PILOTING_COMPARISON_BY_SPORTS_GROUPS,
    description: DESCRIPTION_COMPARISON_BY_SPORTS_GROUPS,
    shortcutSportsGroups: true,
    shortcutStoresGroups: true,
    navigationMenuInformation: {
      iconName: "join_left",
      iconIsFilled: false,
      menuName: MENU_ANIMATION_SUBLINK_SPORTS_GROUPS
    },
    rights: [PERMISSIONS.PILOTING_COMPARISON_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "comparison_sports_group",
    component: ComparisonSportGroups
  },
  {
    id: RouteId.Askr,
    path: "/piloting/askr",
    name: PILOTING_ASKR,
    description: DESCRIPTION_ASKR,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "dashboard_customize", iconIsFilled: false, menuName: PILOTING_ASKR },
    rights: [],
    isVisibleSubheader: true,
    googleAnalyticsAction: "askr",
    component: Askr
  },
  {
    id: RouteId.UserPreferences,
    path: "/settings/user-preferences",
    name: SETTINGS_USER_PREFERENCES,
    description: DESCRIPTION_USER_PREFERENCES,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.SETTINGS_USER_PREFERENCES_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "users",
    component: UserPreferences
  },
  {
    id: RouteId.GroupsCreation,
    path: "/settings/groups-creation",
    name: SETTINGS_FAVORITE_SPORTS,
    description: DESCRIPTION_FAVORITE_SPORTS,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.SETTINGS_FAVORITE_SPORTS_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "creation_of_groups",
    component: GroupsCreation
  },
  {
    id: RouteId.MyTemplates,
    path: "/settings/templates",
    name: SETTINGS_TEMPLATES,
    description: DESCRIPTION_TEMPLATES,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.SETTINGS_TEMPLATES_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "my_templates",
    component: Templates
  },
  {
    id: RouteId.Templates,
    path: "/settings/templates/share/:id",
    name: "settings.templates.templates.share",
    description: DESCRIPTION_TEMPLATES,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.SETTINGS_TEMPLATES_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "view_management",
    component: Templates
  },
  {
    id: RouteId.GroupsCreationShare,
    path: "/settings/groups-creation/share/:id",
    name: "pages.settings.groups_creation.share",
    description: DESCRIPTION_FAVORITE_SPORTS,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.SETTINGS_FAVORITE_SPORTS_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "creation_of_groups",
    component: GroupsCreation
  },
  {
    id: RouteId.Events,
    path: "/settings/events",
    name: SETTINGS_EVENTS,
    description: DESCRIPTION_EVENTS,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "event_note", iconIsFilled: false, menuName: PILOTING_MONTHS },
    rights: [PERMISSIONS.EVENTS_ACCESS],
    isVisibleSubheader: true,
    component: EventsPiloting,
    googleAnalyticsAction: "store_agenda",
    breadcrumbs: [{ routeId: RouteId.WeeklyStores, name: PILOTING_WEEKLY_BY_STORES }]
  },
  {
    id: RouteId.UpwardForecast,
    path: "/settings/upward-forecast",
    name: SETTINGS_UPWARD_FORECAST,
    description: DESCRIPTION_UPWARD_FORECAST,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "link", iconIsFilled: false, menuName: SETTINGS_UPWARD_FORECAST },
    rights: [PERMISSIONS.UPWARD_FORECAST_ACCESS],
    isVisibleSubheader: true,
    googleAnalyticsAction: "board_upward_forecast",
    component: UpwardForecastContainer,
    breadcrumbs: [
      { routeId: RouteId.Daily, name: PILOTING_DAILY },
      { routeId: RouteId.ManagerByMonth, name: PILOTING_MONTHS }
    ]
  },
  {
    id: RouteId.MyOffer,
    path: "/my-offer",
    name: SETTINGS_MY_OFFER,
    description: DESCRIPTION_MY_OFFER,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [],
    isVisibleSubheader: true,
    googleAnalyticsAction: "my_offer",
    component: MyOfferIFrame
  },
  {
    id: RouteId.Document,
    path: "/document",
    name: SETTINGS_MANAGEMENT_RULES,
    description: DESCRIPTION_MANAGEMENT_RULES,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [],
    isVisibleSubheader: true,
    googleAnalyticsAction: "management_rules",
    component: DocumentIFrame
  },
  {
    id: RouteId.School,
    path: "/school",
    name: SETTINGS_SCHOOL,
    description: DESCRIPTION_SCHOOL,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [],
    isVisibleSubheader: true,
    googleAnalyticsAction: "training",
    component: SchoolIFrame
  },
  {
    id: RouteId.DayByDayHourByHour,
    path: "/daybyday-hourbyhour",
    name: PILOTING_DAYS_HOURS,
    description: DESCRIPTION_DAYS_HOURS,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "timelapse", iconIsFilled: true, menuName: PILOTING_DAYS_HOURS },
    rights: [],
    googleAnalyticsAction: "day_hours_analysis",
    component: DayByDayHourByHourEmbeded,
    isVisibleSubheader: true,
    type: "hours"
  },
  {
    id: RouteId.ReportingExtraction,
    path: "/reporting-extraction",
    name: SETTINGS_REPORTING_EXTRACTION,
    description: DESCRIPTION_REPORTING_EXTRACTION,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: {
      iconName: "document_scanner",
      iconIsFilled: false,
      menuName: SETTINGS_REPORTING_EXTRACTION
    },
    rights: [PERMISSIONS.REPORTING_EXTRACTION],
    googleAnalyticsAction: "reporting_extraction",
    component: () => (
      <Suspense fallback={<LoadingDialog />}>
        <ReportingExtraction />
      </Suspense>
    ),
    isVisibleSubheader: true
  },
  {
    id: RouteId.SalesPerformancesIframe,
    path: "/sales-performances/",
    name: SETTINGS_SALES_PERFORMANCES,
    description: DESCRIPTION_SALES_PERFORMANCES,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [],
    googleAnalyticsAction: "sales_performance",
    component: () => (
      <Suspense fallback={<LoadingDialog />}>
        <SalesPerformancesIframe />
      </Suspense>
    ),
    isVisibleSubheader: true,
    breadcrumbs: [
      { routeId: RouteId.ComparisonStores, name: PILOTING_COMPARISON_BY_STORES },
      { routeId: RouteId.SportsStores, name: PILOTING_SPORTS_BY_STORES },
      { routeId: RouteId.SportsTeams, name: PILOTING_SPORTS_BY_TEAMS }
    ]
  },
  {
    id: RouteId.EffitimeIframe,
    path: "/effitime",
    name: SETTINGS_EFFITIMES,
    description: DESCRIPTION_EFFITIME,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "list_alt", iconIsFilled: false, menuName: SETTINGS_EFFITIMES },
    rights: [],
    googleAnalyticsAction: "annual_hours_need",
    component: () => (
      <Suspense fallback={<LoadingDialog />}>
        <EffitimeIframe />
      </Suspense>
    ),
    isVisibleSubheader: true,
    type: "hours"
  },
  {
    id: RouteId.ReportingExtractionMonthlyData,
    path: "/reporting-extraction-monthly-data",
    name: SETTINGS_REPORTING_EXTRACTION_MONTHLY_DATA,
    description: DESCRIPTION_REPORTING_EXTRACTION_MONTHLY_DATA,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.REPORTING_EXTRACTION],
    component: () => (
      <Suspense fallback={<LoadingDialog />}>
        <ReportingExtractionMonthlyData />
      </Suspense>
    ),
    isVisibleSubheader: true,
    googleAnalyticsAction: "reporting_extraction_monthly_data",
    breadcrumbs: [{ routeId: RouteId.ReportingExtraction, name: SETTINGS_REPORTING_EXTRACTION }]
  },
  {
    id: RouteId.ReportingExtractionDayDay,
    path: "/reporting-extraction-dayday",
    name: SETTINGS_REPORTING_EXTRACTION_DAYDAY,
    description: DESCRIPTION_REPORTING_EXTRACTION_DAYDAY,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.REPORTING_EXTRACTION],
    component: () => (
      <Suspense fallback={<LoadingDialog />}>
        <ReportingExtractionDayDay />
      </Suspense>
    ),
    isVisibleSubheader: true,
    googleAnalyticsAction: "reporting_extraction_day_day",
    breadcrumbs: [{ routeId: RouteId.ReportingExtraction, name: SETTINGS_REPORTING_EXTRACTION }]
  },
  {
    id: RouteId.ReportingExtractionTrajectory,
    path: "/reporting-extraction-trajectory",
    name: SETTINGS_REPORTING_EXTRACTION_TRAJECTORY,
    description: DESCRIPTION_REPORTING_EXTRACTION_TRAJECTORY,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.REPORTING_EXTRACTION],
    component: () => (
      <Suspense fallback={<LoadingDialog />}>
        <ReportingExtractionTrajectory />
      </Suspense>
    ),
    isVisibleSubheader: true,
    googleAnalyticsAction: "reporting_extraction_trajectory",
    breadcrumbs: [{ routeId: RouteId.ReportingExtraction, name: SETTINGS_REPORTING_EXTRACTION }]
  },
  {
    id: RouteId.ReportingExtractionWeekly,
    path: "/reporting-extraction-weekly",
    name: SETTINGS_REPORTING_EXTRACTION_WEEKLY,
    description: DESCRIPTION_REPORTING_EXTRACTION_WEEKLY,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.REPORTING_EXTRACTION],
    component: () => (
      <Suspense fallback={<LoadingDialog />}>
        <ReportingExtractionWeekly />
      </Suspense>
    ),
    isVisibleSubheader: true,
    googleAnalyticsAction: "reporting_extraction_weekly",
    breadcrumbs: [{ routeId: RouteId.ReportingExtraction, name: SETTINGS_REPORTING_EXTRACTION }]
  },
  {
    id: RouteId.ReportingUsageByCountry,
    path: "/reporting-usage-by-country",
    name: SETTINGS_REPORTING_USAGE_BY_COUNTRY,
    description: DESCRIPTION_REPORTING_USAGE_BY_COUNTRY,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.REPORTING_EXTRACTION],
    component: () => (
      <Suspense fallback={<LoadingDialog />}>
        <ReportingUsageByCountryIFrame />
      </Suspense>
    ),
    isVisibleSubheader: true,
    googleAnalyticsAction: "reporting_usage_by_country",
    breadcrumbs: [{ routeId: RouteId.ReportingExtraction, name: SETTINGS_REPORTING_EXTRACTION }]
  },
  {
    id: RouteId.ReportingEffitimeReliability,
    path: "/reporting-effitime-reliability",
    name: SETTINGS_REPORTING_EFFITIME_RELIABILITY,
    description: DESCRIPTION_REPORTING_EFFITIME_RELIABILITY,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.REPORTING_EXTRACTION],
    component: () => (
      <Suspense fallback={<LoadingDialog />}>
        <ReportingEffitimeReliabilityIFrame />
      </Suspense>
    ),
    isVisibleSubheader: true,
    googleAnalyticsAction: "reporting_effitime_reliability",
    breadcrumbs: [{ routeId: RouteId.ReportingExtraction, name: SETTINGS_REPORTING_EXTRACTION }]
  },
  {
    id: RouteId.ReportingHoursEfficiencyPerCountry,
    path: "/reporting-hours-efficiency-per-country",
    name: SETTINGS_REPORTING_HOURS_EFFICIENCY_PER_COUNTRY,
    description: DESCRIPTION_REPORTING_HOURS_EFFICIENCY_PER_COUNTRY,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.REPORTING_EXTRACTION],
    component: () => (
      <Suspense fallback={<LoadingDialog />}>
        <ReportingHoursEfficiencyPerCountryIFrame />
      </Suspense>
    ),
    isVisibleSubheader: true,
    googleAnalyticsAction: "reporting_hours_efficiency_per_country",
    breadcrumbs: [{ routeId: RouteId.ReportingExtraction, name: SETTINGS_REPORTING_EXTRACTION }]
  },
  {
    id: RouteId.ReportingHoursEfficiencyPerCountryProbableYear,
    path: "/reporting-hours-efficiency-per-country-probable-year",
    name: SETTINGS_REPORTING_HOURS_EFFICIENCY_PER_COUNTRY_PROBABLE_YEAR,
    description: DESCRIPTION_REPORTING_HOURS_EFFICIENCY_PER_COUNTRY_PROBABLE_YEAR,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.REPORTING_EXTRACTION],
    component: () => (
      <Suspense fallback={<LoadingDialog />}>
        <ReportingHoursEfficiencyPerCountryProbableYearIFrame />
      </Suspense>
    ),
    isVisibleSubheader: true,
    googleAnalyticsAction: "reporting_hours_efficiency_per_country_probable_year",
    breadcrumbs: [{ routeId: RouteId.ReportingExtraction, name: SETTINGS_REPORTING_EXTRACTION }]
  },
  {
    id: RouteId.ReportingQualityForecasting,
    path: "/reporting-quality-forecasting",
    name: SETTINGS_REPORTING_QUALITY_FORECASTING,
    description: DESCRIPTION_REPORTING_QUALITY_FORECASTING,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.REPORTING_EXTRACTION],
    component: () => (
      <Suspense fallback={<LoadingDialog />}>
        <ReportingQualityForecastingIFrame />
      </Suspense>
    ),
    isVisibleSubheader: true,
    googleAnalyticsAction: "reporting_quality_forecasting",
    breadcrumbs: [{ routeId: RouteId.ReportingExtraction, name: SETTINGS_REPORTING_EXTRACTION }]
  },
  {
    id: RouteId.Planning,
    path: "/planning",
    name: SETTINGS_PLANNING,
    description: DESCRIPTION_PLANNING,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "groups", iconIsFilled: false, menuName: SETTINGS_PLANNING },
    rights: [PERMISSIONS.PILOTING_WEEKLY_ACCESS],
    isVisibleSubheader: false,
    googleAnalyticsAction: "staff_planning",
    component: Planning,
    type: "hours"
  },
  {
    id: RouteId.ReportingProcessManagement,
    path: "/reporting-process-management",
    name: SETTINGS_REPORTING_PROCESS_MANAGEMENT,
    description: DESCRIPTION_REPORTING_PROCESS_MANAGEMENT,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: {
      iconName: "document_scanner",
      iconIsFilled: false,
      menuName: SETTINGS_REPORTING_PROCESS_MANAGEMENT
    },
    isVisibleSubheader: true,
    rights: [PERMISSIONS.ADMIN_ONLY],
    googleAnalyticsAction: "reporting_process_management",
    component: () => (
      <Suspense fallback={<LoadingDialog />}>
        <ReportingProcessManagement />
      </Suspense>
    )
  },
  {
    id: RouteId.EmbeddedSalesAnimation,
    path: "/sales-animation",
    name: SETTINGS_EMBEDDED_SALES_ANIMATION,
    description: DESCRIPTION_EMBEDDED_SALES_ANIMATION,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    navigationMenuInformation: { iconName: "point_of_sale", iconIsFilled: true, menuName: PILOTING_DAYS_HOURS },
    rights: [],
    component: SalesAnimationEmbedded,
    googleAnalyticsAction: "sales_animation",
    isVisibleSubheader: true
  },
  {
    id: RouteId.Announcement,
    path: "/announcement",
    name: ANNOUNCEMENT_PAGE,
    description: DESCRIPTION_ANNOUNCEMENT,
    shortcutSportsGroups: false,
    shortcutStoresGroups: false,
    rights: [PERMISSIONS.ADMIN_ONLY],
    isVisibleSubheader: true,
    component: Announcement,
    googleAnalyticsAction: "announcement"
  }
];
