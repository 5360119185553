import * as React from "react";
import classnames from "classnames";
import Icon from "@mui/material/Icon";

interface IIconProps {
  name: string;
  className?: string;
  id?: string;
  onClick?: (event: React.MouseEvent) => void;
}

export const CustomIcon = ({ className, name, id, onClick }: IIconProps) => {
  const dataTestId = "custom-icon" + (id ? "-" + id : "");
  return (
    <Icon
      data-testid={dataTestId}
      className={classnames("material-icons notranslate", className)}
      id={id}
      onClick={onClick}
    >
      {name}
    </Icon>
  );
};

export default CustomIcon;
