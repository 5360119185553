import * as React from "react";
import { I18n } from "react-redux-i18n";
import MyGameIcon from "components/common/icons/icon";
import ToolbarAction from "components/common/toolbar/toolbar-action";
import { IForecastExceptionStore } from "core/api/rest/queries/upward-forecast";
import HistoryDialog from "./history-dialog";
import { useFilters } from "../../../../../common/filters3/filters/hooks/useFilters";
import { GenericFilters } from "../../../../../common/filters3/filters/definitions";
import { useCountries } from "../../../../../../core/api/country/useCountries";
import { useApiGetForecastHistory } from "../../../../../../core/api/forecast/useApiGetForecast";

interface IHistoryToolbarActionProps {
  onClick: () => void;
}

const HistoryToolbarAction: React.FunctionComponent<IHistoryToolbarActionProps> = ({ onClick }) => {
  return (
    <ToolbarAction
      id="history-button"
      SourceIcon={MyGameIcon}
      iconName="week"
      className="contained"
      tooltipTitle={I18n.t("history")}
      onClick={onClick}
    />
  );
};

interface IHistoryTableActionProps {
  isDisabled: boolean;
  onClick: () => void;
}

const HistoryTableAction: React.FunctionComponent<IHistoryTableActionProps> = ({ isDisabled, onClick }) => {
  return (
    <div
      className={`mg-button-base ${isDisabled ? "disabled" : ""}`}
      {...(!isDisabled ? { onClick: () => onClick() } : {})}
    >
      <span className="material-icons-outlined notranslate">event_note</span>
    </div>
  );
};

interface IHistoryButtonProps {
  exception?: IForecastExceptionStore;
  isDisabled?: boolean;
  isToolbarAction?: boolean;
}

const HistoryButton: React.FunctionComponent<IHistoryButtonProps> = ({ isToolbarAction, isDisabled, exception }) => {
  const {
    appliedFilters: { country }
  } = useFilters<GenericFilters>();
  const { data: countries } = useCountries();

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const { data, isLoading, refetch } = useApiGetForecastHistory(country, exception?.store_id);

  const handleOnClick = React.useCallback(() => {
    refetch();
    setIsDialogOpen(true);
  }, [refetch]);

  const handleToggleIsOpen = React.useCallback(() => {
    setIsDialogOpen(!isDialogOpen);
  }, [isDialogOpen]);

  const actionToDisplay = isToolbarAction ? (
    <HistoryToolbarAction onClick={isDisabled ? null : handleOnClick} />
  ) : (
    <HistoryTableAction onClick={handleOnClick} isDisabled={isDisabled} />
  );

  const countryLabel = countries.find(item => item.id === country)?.label || "";
  const storeLabel = exception ? ` : ${exception.store_label}` : "";
  const dialogTitle = `${countryLabel} ${storeLabel}`;

  return (
    <React.Fragment>
      {actionToDisplay}
      <HistoryDialog
        title={dialogTitle}
        loading={isLoading}
        data={data}
        isOpen={isDialogOpen}
        toggleIsOpen={handleToggleIsOpen}
      />
    </React.Fragment>
  );
};

export default HistoryButton;
