/* Filters labels */
export const FILTERS_LABELS_COUNTRIES = "filters.labels.countries";
export const FILTERS_LABELS_REGIONS = "filters.labels.regions";
export const FILTERS_LABELS_STORES = "filters.labels.stores";

/* Filters selects */
export const FILTERS_SELECTS_COUNTRIES = "filters.selects.countries";
export const FILTERS_SELECTS_REGIONS = "filters.selects.regions";
export const FILTERS_SELECTS_STORES = "filters.selects.stores";

/* Filters search */
export const FILTERS_SEARCH_COUNTRIES = "filters.search.countries";
export const FILTERS_SEARCH_REGIONS = "filters.search.regions";
export const FILTERS_SEARCH_STORES = "filters.search.stores";
