import { I18n } from "react-redux-i18n";
import { cloneDeep } from "lodash";

import dateUtils from "core/utils/date";
import { isEditableByYearWeek, WEEK_SEPARATOR, YearWeek } from "core/utils/weeks";
import { ITeam } from "core/api/rest/queries/select-teams";
import {
  I2DQueryAPIData,
  IAPIAggregate,
  ICexAccount,
  ICexAccountsData,
  IMask,
  IQueryAPIData
} from "core/api/rest/queries";
import { getIdsFromFormula, TypedValue } from "./computation-engine";
import { generateAggregate } from "./aggregates";
import { COLUMNS } from "./columns";
import { ROWS } from "./rows";

import { formatFloatDecimal, pad } from "./number";
import { AreasCex, I2DQueryAPIAreasData, ITeamData } from "core/models/api/areas-piloting/areas-piloting";
import { Accounts } from "../models/accounts";

export const ACCOUNT_TRANSLATION_PREFIX = "account.";

export enum DEPARTMENT_TYPE {
  COMMERCIAL = "commercial",
  RECEPTION = "reception and till",
  GENERAL_SERVICE = "general services"
}

export enum INDICATORS {
  ADVISED_PRODUCTIVITY = "ADVISED_PRODUCTIVITY",
  CASH_HOURS = "CASH_HOURS",
  COMMERCIAL_HOURS = "COMMERCIAL_HOURS",
  DEPARTMENT_HOURS = "DEPARTMENT_HOURS",
  ECOMMERCE_HOURS = "ECOMMERCE_HOURS",
  ECOMMERCE_HOURS_STORE = "ECOMMERCE_HOURS_STORE",
  INSTORE_COUNTRY_MARGIN_RATE = "INSTORE_COUNTRY_MARGIN_RATE",
  INSTORE_COUNTRY_TURNOVER_PROGRESSION = "INSTORE_COUNTRY_TURNOVER_PROGRESSION",
  INSTORE_MARGIN_CASH_REGISTER = "INSTORE_MARGIN_CASH_REGISTER",
  INSTORE_MARGIN_RATE = "INSTORE_MARGIN_RATE",
  INSTORE_PART_OF_OMNI_MARGIN_CASH_REGISTER = "INSTORE_PART_OF_OMNI_MARGIN_CASH_REGISTER",
  INSTORE_PART_OF_OMNI_MARGIN_CASH_REGISTER_N_1 = "INSTORE_PART_OF_OMNI_MARGIN_CASH_REGISTER_N_1",
  INSTORE_PART_OF_OMNI_TURNOVER = "INSTORE_PART_OF_OMNI_TURNOVER",
  INSTORE_PART_OF_OMNI_TURNOVER_N_1 = "INSTORE_PART_OF_OMNI_TURNOVER_N_1",
  INSTORE_STORE_TURNOVER = "INSTORE_STORE_TURNOVER",
  INSTORE_STORE_TURNOVER_PER_STORE_CASH_HOUR = "INSTORE_STORE_TURNOVER_PER_STORE_CASH_HOURS",
  INSTORE_TURNOVER = "INSTORE_TURNOVER",
  INSTORE_TURNOVER_PER_DEPARTMENT_HOUR = "INSTORE_TURNOVER_PER_DEPARTMENT_HOUR",
  INSTORE_TURNOVER_PER_CASH_HOUR = "INSTORE_TURNOVER_PER_CASH_HOURS",
  INSTORE_TURNOVER_PER_HOUR = "INSTORE_TURNOVER_PER_HOUR",
  INSTORE_TURNOVER_PROGRESSION = "INSTORE_TURNOVER_PROGRESSION",
  LINEAR_METERS = "LINEAR_METERS",
  NON_COMMERCIAL_HOURS = "NON_COMMERCIAL_HOURS",
  OMNICHANNEL_COUNTRY_MARGIN_RATE = "OMNICHANNEL_COUNTRY_MARGIN_RATE",
  OMNICHANNEL_COUNTRY_TURNOVER_PROGRESSION = "OMNICHANNEL_COUNTRY_TURNOVER_PROGRESSION",
  OMNICHANNEL_MARGIN_RATE = "OMNICHANNEL_MARGIN_RATE",
  OMNICHANNEL_TICKET_PROGRESSION = "OMNICHANNEL_TICKET_PROGRESSION",
  OMNICHANNEL_QUANTITY_PROGRESSION = "OMNICHANNEL_QUANTITY_PROGRESSION",
  OMNICHANNEL_TURNOVER = "OMNICHANNEL_TURNOVER",
  OMNICHANNEL_TURNOVER_PER_HOUR = "OMNICHANNEL_TURNOVER_PER_HOUR",
  OMNICHANNEL_TURNOVER_PROGRESSION = "OMNICHANNEL_TURNOVER_PROGRESSION",
  OUTSTORE_COUNTRY_MARGIN_RATE = "OUTSTORE_COUNTRY_MARGIN_RATE",
  OUTSTORE_COUNTRY_TURNOVER_PROGRESSION = "OUTSTORE_COUNTRY_TURNOVER_PROGRESSION",
  OUTSTORE_MARGIN_CASH_REGISTER = "OUTSTORE_MARGIN_CASH_REGISTER",
  OUTSTORE_MARGIN_RATE = "OUTSTORE_MARGIN_RATE",
  OUTSTORE_PART_OF_OMNI_MARGIN_CASH_REGISTER = "OUTSTORE_PART_OF_OMNI_MARGIN_CASH_REGISTER",
  OUTSTORE_PART_OF_OMNI_MARGIN_CASH_REGISTER_N_1 = "OUTSTORE_PART_OF_OMNI_MARGIN_CASH_REGISTER_N_1",
  OUTSTORE_PART_OF_OMNI_TURNOVER = "OUTSTORE_PART_OF_OMNI_TURNOVER",
  OUTSTORE_PART_OF_OMNI_TURNOVER_N_1 = "OUTSTORE_PART_OF_OMNI_TURNOVER_N_1",
  OUTSTORE_TURNOVER = "OUTSTORE_TURNOVER",
  OUTSTORE_TURNOVER_PROGRESSION = "OUTSTORE_TURNOVER_PROGRESSION",
  PHOTO_STORE_CASH_HOURS = "PHOTO_CASH_HOURS",
  SQUARE_METERS = "SQUARE_METERS",
  STORE_CASH_HOURS = "STORE_CASH_HOURS",
  TARGET_PRODUCTIVITY = "TARGET_PRODUCTIVITY",
  THEORETICAL_HOURS = "THEORETICAL_HOURS",
  TOTAL_HOURS_INSTORE = "TOTAL_HOURS_INSTORE",
  TOTAL_HOURS_OMNI = "TOTAL_HOURS_OMNI",
  TURNOVER = "OMNICHANNEL_TURNOVER",
  TURNOVER_PER_HOUR = "OMNICHANNEL_TURNOVER_PER_HOUR",
  CC_FROM_STORE_BIKE_ORDER_NUMBER_STORE = "CC_FROM_STORE_BIKE_ORDER_NUMBER_STORE",
  CC_FROM_STORE_STANDARD_ORDER_NUMBER_STORE = "CC_FROM_STORE_STANDARD_ORDER_NUMBER_STORE",
  CC_FROM_LOG_BIKE_ORDER_NUMBER_STORE = "CC_FROM_LOG_BIKE_ORDER_NUMBER_STORE",
  CC_FROM_LOG_STANDARD_ORDER_NUMBER_STORE = "CC_FROM_LOG_STANDARD_ORDER_NUMBER_STORE",
  ADVISED_CC_FROM_STORE_BIKE_ORDER_NUMBER_STORE = "ADVISED_CC_FROM_STORE_BIKE_ORDER_NUMBER_STORE",
  ADVISED_CC_FROM_STORE_STANDARD_ORDER_NUMBER_STORE = "ADVISED_CC_FROM_STORE_STANDARD_ORDER_NUMBER_STORE",
  ADVISED_CC_FROM_LOG_BIKE_ORDER_NUMBER_STORE = "ADVISED_CC_FROM_LOG_BIKE_ORDER_NUMBER_STORE",
  ADVISED_CC_FROM_LOG_STANDARD_ORDER_NUMBER_STORE = "ADVISED_CC_FROM_LOG_STANDARD_ORDER_NUMBER_STORE",
  DIGITAL_STORES_PART_OF_INSTORE_TURNOVER = "DIGITAL_STORES_PART_OF_INSTORE_TURNOVER",
  PHYSICAL_STORES_PART_OF_INSTORE_TURNOVER = "PHYSICAL_STORES_PART_OF_INSTORE_TURNOVER",
  DIGITAL_STORES_PART_OF_INSTORE_TURNOVER_N_1 = "DIGITAL_STORES_PART_OF_INSTORE_TURNOVER_N_1",
  PHYSICAL_STORES_PART_OF_INSTORE_TURNOVER_N_1 = "PHYSICAL_STORES_PART_OF_INSTORE_TURNOVER_N_1",
  PHYSICAL_STORES_TURNOVER = "PHYSICAL_STORES_TURNOVER",
  PHYSICAL_STORES_TURNOVER_N_1 = "PHYSICAL_STORES_TURNOVER_N_1",
  DIGITAL_STORES_TURNOVER = "DIGITAL_STORES_TURNOVER",
  DIGITAL_STORES_TURNOVER_N_1 = "DIGITAL_STORES_TURNOVER_N_1",
  INSTORE_AVERAGE_RETAIL_BASKET = "INSTORE_AVERAGE_RETAIL_BASKET",
  INSTORE_COUNTRY_AVERAGE_RETAIL_BASKET = "INSTORE_COUNTRY_AVERAGE_RETAIL_BASKET",
  INSTORE_AVERAGE_RETAIL_BASKET_PROGRESSION = "INSTORE_AVERAGE_RETAIL_BASKET_PROGRESSION",
  INSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESSION = "INSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESSION",
  OMNICHANNEL_AVERAGE_RETAIL_BASKET = "OMNICHANNEL_AVERAGE_RETAIL_BASKET",
  OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET = "OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET",
  OMNICHANNEL_AVERAGE_RETAIL_BASKET_PROGRESSION = "OMNICHANNEL_AVERAGE_RETAIL_BASKET_PROGRESSION",
  OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESS = "OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESS",
  OUTSTORE_AVERAGE_RETAIL_BASKET = "OUTSTORE_AVERAGE_RETAIL_BASKET",
  OUTSTORE_COUNTRY_AVERAGE_RETAIL_BASKET = "OUTSTORE_COUNTRY_AVERAGE_RETAIL_BASKET",
  OUTSTORE_AVERAGE_RETAIL_BASKET_PROGRESSION = "OUTSTORE_AVERAGE_RETAIL_BASKET_PROGRESSION",
  OUTSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESSION = "OUTSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESSION",

  INSTORE_AVERAGE_RETAIL_BASKET_N_1 = "INSTORE_AVERAGE_RETAIL_BASKET_N_1",
  INSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_N_1 = "INSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_N_1",
  INSTORE_AVERAGE_RETAIL_BASKET_N_2 = "INSTORE_AVERAGE_RETAIL_BASKET_N_2",
  INSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_N_2 = "INSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_N_2",
  INSTORE_AVERAGE_RETAIL_BASKET_PROGRESSION_N_1 = "INSTORE_AVERAGE_RETAIL_BASKET_PROGRESSION_N_1",
  INSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESSION_N_1 = "INSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESSION_N_1",
  OMNICHANNEL_AVERAGE_RETAIL_BASKET_N_1 = "OMNICHANNEL_AVERAGE_RETAIL_BASKET_N_1",
  OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET_N_1 = "OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET_N_1",
  OMNICHANNEL_AVERAGE_RETAIL_BASKET_N_2 = "OMNICHANNEL_AVERAGE_RETAIL_BASKET_N_2",
  OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET_N_2 = "OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET_N_2",
  OMNICHANNEL_AVERAGE_RETAIL_BASKET_PROGRESSION_N_1 = "OMNICHANNEL_AVERAGE_RETAIL_BASKET_PROGRESSION_N_1",
  OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESS_N_2 = "OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESS_N_2",
  OUTSTORE_AVERAGE_RETAIL_BASKET_N_1 = "OUTSTORE_AVERAGE_RETAIL_BASKET_N_1",
  OUTSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_N_1 = "OUTSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_N_1",
  OUTSTORE_AVERAGE_RETAIL_BASKET_N_2 = "OUTSTORE_AVERAGE_RETAIL_BASKET_N_2",
  OUTSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_N_2 = "OUTSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_N_2",
  OUTSTORE_AVERAGE_RETAIL_BASKET_PROGRESSION_N_1 = "OUTSTORE_AVERAGE_RETAIL_BASKET_PROGRESSION_N_1",
  OUTSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESSION_N_1 = "OUTSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESSION_N_1",

  INSTORE_AVERAGE_SELLING_PRICE = "INSTORE_AVERAGE_SELLING_PRICE",
  INSTORE_COUNTRY_AVERAGE_SELLING_PRICE = "INSTORE_COUNTRY_AVERAGE_SELLING_PRICE",
  INSTORE_AVERAGE_SELLING_PRICE_PROGRESSION = "INSTORE_AVERAGE_SELLING_PRICE_PROGRESSION",
  INSTORE_COUNTRY_AVERAGE_SELLING_PRICE_PROGRESSION = "INSTORE_COUNTRY_AVERAGE_SELLING_PRICE_PROGRESSION",
  INSTORE_AVERAGE_SELLING_PRICE_N_1 = "INSTORE_AVERAGE_SELLING_PRICE_N_1",
  INSTORE_COUNTRY_AVERAGE_SELLING_PRICE_N_1 = "INSTORE_COUNTRY_AVERAGE_SELLING_PRICE_N_1",
  INSTORE_AVERAGE_SELLING_PRICE_N_2 = "INSTORE_AVERAGE_SELLING_PRICE_N_2",
  INSTORE_COUNTRY_AVERAGE_SELLING_PRICE_N_2 = "INSTORE_COUNTRY_AVERAGE_SELLING_PRICE_N_2",
  INSTORE_AVERAGE_SELLING_PRICE_PROGRESSION_N_1 = "INSTORE_AVERAGE_SELLING_PRICE_PROGRESSION_N_1",
  INSTORE_COUNTRY_AVERAGE_SELLING_PRICE_PROGRESSION_N_1 = "INSTORE_COUNTRY_AVERAGE_SELLING_PRICE_PROGRESSION_N_1",
  OMNICHANNEL_AVERAGE_SELLING_PRICE = "OMNICHANNEL_AVERAGE_SELLING_PRICE",
  OMNICHANNEL_COUNTRY_AVERAGE_SELLING_PRICE = "OMNICHANNEL_COUNTRY_AVERAGE_SELLING_PRICE",
  OMNICHANNEL_AVERAGE_SELLING_PRICE_N_1 = "OMNICHANNEL_AVERAGE_SELLING_PRICE_N_1",
  OMNICHANNEL_COUNTRY_AVERAGE_SELLING_PRICE_N_1 = "OMNICHANNEL_COUNTRY_AVERAGE_SELLING_PRICE_N_1",
  OMNICHANNEL_AVERAGE_SELLING_PRICE_N_2 = "OMNICHANNEL_AVERAGE_SELLING_PRICE_N_2",
  OMNICHANNEL_COUNTRY_AVERAGE_SELLING_PRICE_N_2 = "OMNICHANNEL_COUNTRY_AVERAGE_SELLING_PRICE_N_2",
  OMNICHANNEL_AVERAGE_SELLING_PRICE_PROGRESSION = "OMNICHANNEL_AVERAGE_SELLING_PRICE_PROGRESSION",
  OMNICHANNEL_COUNTRY_AVERAGE_SELLING_PRICE_PROGRESS = "OMNICHANNEL_COUNTRY_AVERAGE_SELLING_PRICE_PROGRESS",

  INSTORE_QUANTITY_PER_CUSTOMER = "INSTORE_QUANTITY_PER_CUSTOMER",
  INSTORE_COUNTRY_QUANTITY_PER_CUSTOMER = "INSTORE_COUNTRY_QUANTITY_PER_CUSTOMER",
  INSTORE_QUANTITY_PER_CUSTOMER_N_1 = "INSTORE_QUANTITY_PER_CUSTOMER_N_1",
  INSTORE_COUNTRY_QUANTITY_PER_CUSTOMER_N_1 = "INSTORE_COUNTRY_QUANTITY_PER_CUSTOMER_N_1",
  INSTORE_QUANTITY_PER_CUSTOMER_N_2 = "INSTORE_QUANTITY_PER_CUSTOMER_N_2",
  INSTORE_COUNTRY_QUANTITY_PER_CUSTOMER_N_2 = "INSTORE_COUNTRY_QUANTITY_PER_CUSTOMER_N_2",
  INSTORE_QUANTITY_PER_CUSTOMER_PROGRESSION = "INSTORE_QUANTITY_PER_CUSTOMER_PROGRESSION",
  INSTORE_COUNTRY_QUANTITY_PER_CUSTOMER_PROGRESSION = "INSTORE_COUNTRY_QUANTITY_PER_CUSTOMER_PROGRESSION",
  INSTORE_QUANTITY_PER_CUSTOMER_PROGRESSION_N_1 = "INSTORE_QUANTITY_PER_CUSTOMER_PROGRESSION_N_1",
  INSTORE_COUNTRY_QUANTITY_PER_CUSTOMER_PROGRESSION_N_1 = "INSTORE_COUNTRY_QUANTITY_PER_CUSTOMER_PROGRESSION_N_1",
  INSTORE_QUANTITY_PROGRESSION = "INSTORE_QUANTITY_PROGRESSION",
  INSTORE_COUNTRY_QUANTITY_PROGRESSION = "INSTORE_COUNTRY_QUANTITY_PROGRESSION",
  INSTORE_QUANTITY_PROGRESSION_N_1 = "INSTORE_QUANTITY_PROGRESSION_N_1",
  INSTORE_COUNTRY_QUANTITY_PROGRESSION_N_1 = "INSTORE_COUNTRY_QUANTITY_PROGRESSION_N_1",

  OMNICHANNEL_QUANTITY_PER_CUSTOMER = "OMNICHANNEL_QUANTITY_PER_CUSTOMER",
  OMNICHANNEL_COUNTRY_QUANTITY_PER_CUSTOMER = "OMNICHANNEL_COUNTRY_QUANTITY_PER_CUSTOMER",
  OMNICHANNEL_QUANTITY_PER_CUSTOMER_N_1 = "OMNICHANNEL_QUANTITY_PER_CUSTOMER_N_1",
  OMNICHANNEL_COUNTRY_QUANTITY_PER_CUSTOMER_N_1 = "OMNICHANNEL_COUNTRY_QUANTITY_PER_CUSTOMER_N_1",
  OMNICHANNEL_QUANTITY_PER_CUSTOMER_N_2 = "OMNICHANNEL_QUANTITY_PER_CUSTOMER_N_2",
  OMNICHANNEL_COUNTRY_QUANTITY_PER_CUSTOMER_N_2 = "OMNICHANNEL_COUNTRY_QUANTITY_PER_CUSTOMER_N_2",
  OMNICHANNEL_QUANTITY_PER_CUSTOMER_PROGRESSION = "OMNICHANNEL_QUANTITY_PER_CUSTOMER_PROGRESSION",
  OMNICHANNEL_COUNTRY_QUANTITY_PER_CUSTOMER_PROGRESS = "OMNICHANNEL_COUNTRY_QUANTITY_PER_CUSTOMER_PROGRESS",
  OMNICHANNEL_QUANTITY_PER_CUSTOMER_PROGRESSION_N_1 = "OMNICHANNEL_QUANTITY_PER_CUSTOMER_PROGRESSION_N_1",
  OMNICHANNEL_COUNTRY_QUANTITY_PER_CUSTOMER_PROGRESS_N_1 = "OMNICHANNEL_COUNTRY_QUANTITY_PER_CUSTOMER_PROGRESS_N_1",
  OMNICHANNEL_COUNTRY_QUANTITY_PROGRESSION = "OMNICHANNEL_COUNTRY_QUANTITY_PROGRESSION",
  OMNICHANNEL_QUANTITY_PROGRESSION_N_1 = "OMNICHANNEL_QUANTITY_PROGRESSION_N_1",
  OMNICHANNEL_COUNTRY_QUANTITY_PROGRESSION_N_1 = "OMNICHANNEL_COUNTRY_QUANTITY_PROGRESSION_N_1",

  INSTORE_TICKET_PROGRESSION = "INSTORE_TICKET_PROGRESSION",
  INSTORE_COUNTRY_TICKET_PROGRESSION = "INSTORE_COUNTRY_TICKET_PROGRESSION",
  INSTORE_TICKET_PROGRESSION_N_1 = "INSTORE_TICKET_PROGRESSION_N_1",
  INSTORE_COUNTRY_TICKET_PROGRESSION_N_1 = "INSTORE_COUNTRY_TICKET_PROGRESSION_N_1",
  OMNICHANNEL_COUNTRY_TICKET_PROGRESSION = "OMNICHANNEL_COUNTRY_TICKET_PROGRESSION",
  OMNICHANNEL_TICKET_PROGRESSION_N_1 = "OMNICHANNEL_TICKET_PROGRESSION_N_1",
  OMNICHANNEL_COUNTRY_TICKET_PROGRESSION_N_1 = "OMNICHANNEL_COUNTRY_TICKET_PROGRESSION_N_1",
  OUTSTORE_TICKET_PROGRESSION = "OUTSTORE_TICKET_PROGRESSION",
  OUTSTORE_COUNTRY_TICKET_PROGRESSION = "OUTSTORE_COUNTRY_TICKET_PROGRESSION",
  OUTSTORE_TICKET_PROGRESSION_N_1 = "OUTSTORE_TICKET_PROGRESSION_N_1",
  OUTSTORE_COUNTRY_TICKET_PROGRESSION_N_1 = "OUTSTORE_COUNTRY_TICKET_PROGRESSION_N_1",

  DECATHLON_PRO_INSTORE_PART_OF_INSTORE_TURNOVER = "DECATHLON_PRO_INSTORE_PART_OF_INSTORE_TURNOVER",
  DECATHLON_PRO_INSTORE_PART_OF_INSTORE_TURNOVER_N_1 = "DECATHLON_PRO_INSTORE_PART_OF_INSTORE_TURNOVER_N_1",
  DECATHLON_PRO_INSTORE_TURNOVER = "DECATHLON_PRO_INSTORE_TURNOVER",
  DECATHLON_PRO_INSTORE_TURNOVER_N_1 = "DECATHLON_PRO_INSTORE_TURNOVER_N_1",
  //HOURS_ENVELOPE is a front indicator only, it used in hours piloting screen.
  HOURS_ENVELOPE = "HOURS_ENVELOPE",

  // Home Page Indicators
  INSTORE_TICKET = "INSTORE_TICKET",
  OMNICHANNEL_TICKET = "OMNICHANNEL_TICKET",
  OUTSTORE_TICKET = "OUTSTORE_TICKET",
  OUTSTORE_AVERAGE_SELLING_PRICE = "OUTSTORE_AVERAGE_SELLING_PRICE",
  OUTSTORE_AVERAGE_SELLING_PRICE_PROGRESSION = "OUTSTORE_AVERAGE_SELLING_PRICE_PROGRESSION",
  INSTORE_COUNTRY_TICKET = "INSTORE_COUNTRY_TICKET",
  OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESSION = "OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESSION",
  OMNICHANNEL_COUNTRY_TICKET = "OMNICHANNEL_COUNTRY_TICKET",
  OMNICHANNEL_COUNTRY_AVERAGE_SELLING_PRICE_PROGRESSION = "OMNICHANNEL_COUNTRY_AVERAGE_SELLING_PRICE_PROGRESSION",
  OUTSTORE_COUNTRY_TICKET = "OUTSTORE_COUNTRY_TICKET",
  INSTORE_TURNOVER_N_1 = "INSTORE_TURNOVER_N_1",
  INSTORE_MARGIN_CASH_REGISTER_N_1 = "INSTORE_MARGIN_CASH_REGISTER_N_1",
  INSTORE_MARGIN_CASH_REGISTER_PROGRESSION = "INSTORE_MARGIN_CASH_REGISTER_PROGRESSION",
  OUTSTORE_TURNOVER_N_1 = "OUTSTORE_TURNOVER_N_1",
  OMNICHANNEL_TURNOVER_N_1 = "OMNICHANNEL_TURNOVER_N_1",
  OMNICHANNEL_MARGIN_CASH_REGISTER = "OMNICHANNEL_MARGIN_CASH_REGISTER",
  OUTSTORE_MARGIN_CASH_REGISTER_N_1 = "OUTSTORE_MARGIN_CASH_REGISTER_N_1",
  OMNICHANNEL_MARGIN_CASH_REGISTER_N_1 = "OMNICHANNEL_MARGIN_CASH_REGISTER_N_1",
  OUTSTORE_MARGIN_CASH_REGISTER_PROGRESSION = "OUTSTORE_MARGIN_CASH_REGISTER_PROGRESSION",
  OMNICHANNEL_MARGIN_CASH_REGISTER_PROGRESSION = "OMNICHANNEL_MARGIN_CASH_REGISTER_PROGRESSION",
  INSTORE_QUANTITY_PER_HOUR = "INSTORE_QUANTITY_PER_HOUR",
  INSTORE_QUANTITY = "INSTORE_QUANTITY"
}

/** Match basic indicators with their corresponding country aggregated indicator
 *
 * Used to list all indicators eligible for country comparison (thumb up/down icon)
 */
export const COUNTRY_COMPARISON_INDICATORS_MAPPING = {
  [INDICATORS.OMNICHANNEL_TURNOVER_PROGRESSION]: INDICATORS.OMNICHANNEL_COUNTRY_TURNOVER_PROGRESSION,
  [INDICATORS.OUTSTORE_TURNOVER_PROGRESSION]: INDICATORS.OUTSTORE_COUNTRY_TURNOVER_PROGRESSION,
  [INDICATORS.INSTORE_TURNOVER_PROGRESSION]: INDICATORS.INSTORE_COUNTRY_TURNOVER_PROGRESSION,
  [INDICATORS.OMNICHANNEL_MARGIN_RATE]: INDICATORS.OMNICHANNEL_COUNTRY_MARGIN_RATE,
  [INDICATORS.OUTSTORE_MARGIN_RATE]: INDICATORS.OUTSTORE_COUNTRY_MARGIN_RATE,
  [INDICATORS.INSTORE_MARGIN_RATE]: INDICATORS.INSTORE_COUNTRY_MARGIN_RATE,
  [INDICATORS.INSTORE_AVERAGE_RETAIL_BASKET]: INDICATORS.INSTORE_COUNTRY_AVERAGE_RETAIL_BASKET,
  [INDICATORS.OMNICHANNEL_AVERAGE_RETAIL_BASKET]: INDICATORS.OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET,
  [INDICATORS.INSTORE_AVERAGE_RETAIL_BASKET_PROGRESSION]: INDICATORS.INSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESSION,
  [INDICATORS.OMNICHANNEL_AVERAGE_RETAIL_BASKET_PROGRESSION]:
    INDICATORS.OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESS,
  [INDICATORS.OUTSTORE_AVERAGE_RETAIL_BASKET]: INDICATORS.OUTSTORE_COUNTRY_AVERAGE_RETAIL_BASKET,
  [INDICATORS.OUTSTORE_AVERAGE_RETAIL_BASKET_PROGRESSION]:
    INDICATORS.OUTSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESSION,

  [INDICATORS.INSTORE_AVERAGE_RETAIL_BASKET_N_1]: INDICATORS.INSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_N_1,
  [INDICATORS.INSTORE_AVERAGE_RETAIL_BASKET_N_2]: INDICATORS.INSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_N_2,
  [INDICATORS.INSTORE_AVERAGE_RETAIL_BASKET_PROGRESSION_N_1]:
    INDICATORS.INSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESSION_N_1,
  [INDICATORS.OMNICHANNEL_AVERAGE_RETAIL_BASKET_N_1]: INDICATORS.OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET_N_1,
  [INDICATORS.OMNICHANNEL_AVERAGE_RETAIL_BASKET_N_2]: INDICATORS.OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET_N_2,
  [INDICATORS.OMNICHANNEL_AVERAGE_RETAIL_BASKET_PROGRESSION_N_1]:
    INDICATORS.OMNICHANNEL_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESS_N_2,
  [INDICATORS.OUTSTORE_AVERAGE_RETAIL_BASKET_N_1]: INDICATORS.OUTSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_N_1,
  [INDICATORS.OUTSTORE_AVERAGE_RETAIL_BASKET_N_2]: INDICATORS.OUTSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_N_2,
  [INDICATORS.OUTSTORE_AVERAGE_RETAIL_BASKET_PROGRESSION_N_1]:
    INDICATORS.OUTSTORE_COUNTRY_AVERAGE_RETAIL_BASKET_PROGRESSION_N_1,

  [INDICATORS.INSTORE_AVERAGE_SELLING_PRICE]: INDICATORS.INSTORE_COUNTRY_AVERAGE_SELLING_PRICE,
  [INDICATORS.INSTORE_AVERAGE_SELLING_PRICE_PROGRESSION]: INDICATORS.INSTORE_COUNTRY_AVERAGE_SELLING_PRICE_PROGRESSION,
  [INDICATORS.INSTORE_AVERAGE_SELLING_PRICE_N_1]: INDICATORS.INSTORE_COUNTRY_AVERAGE_SELLING_PRICE_N_1,
  [INDICATORS.INSTORE_AVERAGE_SELLING_PRICE_N_2]: INDICATORS.INSTORE_COUNTRY_AVERAGE_SELLING_PRICE_N_2,
  [INDICATORS.INSTORE_AVERAGE_SELLING_PRICE_PROGRESSION_N_1]:
    INDICATORS.INSTORE_COUNTRY_AVERAGE_SELLING_PRICE_PROGRESSION_N_1,
  [INDICATORS.OMNICHANNEL_AVERAGE_SELLING_PRICE]: INDICATORS.OMNICHANNEL_COUNTRY_AVERAGE_SELLING_PRICE,
  [INDICATORS.OMNICHANNEL_AVERAGE_SELLING_PRICE_N_1]: INDICATORS.OMNICHANNEL_COUNTRY_AVERAGE_SELLING_PRICE_N_1,
  [INDICATORS.OMNICHANNEL_AVERAGE_SELLING_PRICE_N_2]: INDICATORS.OMNICHANNEL_COUNTRY_AVERAGE_SELLING_PRICE_N_2,
  [INDICATORS.OMNICHANNEL_AVERAGE_SELLING_PRICE_PROGRESSION]:
    INDICATORS.OMNICHANNEL_COUNTRY_AVERAGE_SELLING_PRICE_PROGRESS,
  [INDICATORS.INSTORE_QUANTITY_PER_CUSTOMER]: INDICATORS.INSTORE_COUNTRY_QUANTITY_PER_CUSTOMER,
  [INDICATORS.INSTORE_QUANTITY_PER_CUSTOMER_N_1]: INDICATORS.INSTORE_COUNTRY_QUANTITY_PER_CUSTOMER_N_1,
  [INDICATORS.INSTORE_QUANTITY_PER_CUSTOMER_N_2]: INDICATORS.INSTORE_COUNTRY_QUANTITY_PER_CUSTOMER_N_2,
  [INDICATORS.INSTORE_QUANTITY_PER_CUSTOMER_PROGRESSION]: INDICATORS.INSTORE_COUNTRY_QUANTITY_PER_CUSTOMER_PROGRESSION,
  [INDICATORS.INSTORE_QUANTITY_PER_CUSTOMER_PROGRESSION_N_1]:
    INDICATORS.INSTORE_COUNTRY_QUANTITY_PER_CUSTOMER_PROGRESSION_N_1,
  [INDICATORS.INSTORE_QUANTITY_PROGRESSION]: INDICATORS.INSTORE_COUNTRY_QUANTITY_PROGRESSION,
  [INDICATORS.INSTORE_QUANTITY_PROGRESSION_N_1]: INDICATORS.INSTORE_COUNTRY_QUANTITY_PROGRESSION_N_1,

  [INDICATORS.OMNICHANNEL_QUANTITY_PER_CUSTOMER]: INDICATORS.OMNICHANNEL_COUNTRY_QUANTITY_PER_CUSTOMER,
  [INDICATORS.OMNICHANNEL_QUANTITY_PER_CUSTOMER_N_1]: INDICATORS.OMNICHANNEL_COUNTRY_QUANTITY_PER_CUSTOMER_N_1,
  [INDICATORS.OMNICHANNEL_QUANTITY_PER_CUSTOMER_N_2]: INDICATORS.OMNICHANNEL_COUNTRY_QUANTITY_PER_CUSTOMER_N_2,
  [INDICATORS.OMNICHANNEL_QUANTITY_PER_CUSTOMER_PROGRESSION]:
    INDICATORS.OMNICHANNEL_COUNTRY_QUANTITY_PER_CUSTOMER_PROGRESS,
  [INDICATORS.OMNICHANNEL_QUANTITY_PER_CUSTOMER_PROGRESSION_N_1]:
    INDICATORS.OMNICHANNEL_COUNTRY_QUANTITY_PER_CUSTOMER_PROGRESS_N_1,
  [INDICATORS.OMNICHANNEL_QUANTITY_PROGRESSION]: INDICATORS.OMNICHANNEL_COUNTRY_QUANTITY_PROGRESSION,
  [INDICATORS.OMNICHANNEL_QUANTITY_PROGRESSION_N_1]: INDICATORS.OMNICHANNEL_COUNTRY_QUANTITY_PROGRESSION_N_1,

  [INDICATORS.INSTORE_TICKET_PROGRESSION]: INDICATORS.INSTORE_COUNTRY_TICKET_PROGRESSION,
  [INDICATORS.INSTORE_TICKET_PROGRESSION_N_1]: INDICATORS.INSTORE_COUNTRY_TICKET_PROGRESSION_N_1,
  [INDICATORS.OMNICHANNEL_TICKET_PROGRESSION]: INDICATORS.OMNICHANNEL_COUNTRY_TICKET_PROGRESSION,
  [INDICATORS.OMNICHANNEL_TICKET_PROGRESSION_N_1]: INDICATORS.OMNICHANNEL_COUNTRY_TICKET_PROGRESSION_N_1,
  [INDICATORS.OUTSTORE_TICKET_PROGRESSION]: INDICATORS.OUTSTORE_COUNTRY_TICKET_PROGRESSION,
  [INDICATORS.OUTSTORE_TICKET_PROGRESSION_N_1]: INDICATORS.OUTSTORE_COUNTRY_TICKET_PROGRESSION_N_1
};

export enum VIEWS {
  HOURS = "HOURS",
  AREAS = "AREAS",
  ALL_MONTH = "ALL_MONTH",
  TRAJECTORY = "TRAJECTORY"
}

const CONSOLIDATED_LABEL_SEPARATOR = "@";

export interface IAccounts {
  [key: string]: IAccount;
}

export interface IAccount {
  index: number;
  aggregate_formula: string;
  formulas: string[];
  label: string;
  label_code: string;
  mask: IMask;

  is_persisted: boolean;
  is_temporal_average: boolean;
  is_editable: boolean;
  weight: number;
  is_visible?: boolean;
  is_hidden: boolean;
  is_child?: boolean;
  cells: ICells;
  lockedCells: string[];
  children: string[];
  are_children_fixed?: boolean;
}

export interface ICells {
  [key: string]: ICell;
}

export interface ICell {
  value: number;
  /**
   * used to differentiate the case when input is set to value "0"
   * and the case when input isn't Set yet and the value is "null"
   * If input is cleared, value is set to initial_value
   */
  initial_value: number;
  is_editable: boolean;
  type: TypedValue;
  mask?: IMask;
  isEdited?: boolean;
  hasAnnexeDetails?: boolean;
  isComputed?: boolean;
  is_persisted?: boolean;
  is_hidden?: boolean;
}

// Note: reconcile with the existing ICell, but I think the API might return some `null`. I guess the
// problem might exist somewhere in the current codebase, but the `strictNullChecks` typescript
// option was turned off
export interface IApiCell extends Omit<ICell, "value" | "initial_value"> {
  value: number | string | null;
  initial_value: number | null;
}

export interface ITemplateAccount {
  account: string;
  index: number;
  is_collapsed?: boolean;
  is_hidden?: boolean;
  children?: ITemplateAccountChildren[];
  are_children_fixed?: boolean;
  isHiddenForComputation?: boolean;
}

export interface ITemplateAccountChildren {
  account: string;
  index: number;
}

export interface IFormattedAPIData<D> {
  [key: string]: D & { index: number };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type IFormattedAPIQueryData<D = ICexAccount | IAPIAggregate, E = {}> = IQueryAPIData<IFormattedAPIData<D>, E>;

export interface IAccountsGetterOptions {
  disableEditableCells?: boolean;
}

export const joinLabels = (labels: string[], separator: string = CONSOLIDATED_LABEL_SEPARATOR): string => {
  return labels.join(separator);
};

export const splitLabel = (label: string, separator: string = CONSOLIDATED_LABEL_SEPARATOR): string[] => {
  return label.split(separator);
};

export const preFormatAreasData = (
  dataToFormat: AreasCex,
  year: number,
  teamIndex: number,
  teams: ITeam[]
): I2DQueryAPIAreasData => {
  const { data, aggregates, seasons, opened_weeks: openedWeeks = [] } = dataToFormat.data;
  const aggregatesCopy = cloneDeep(aggregates);
  const firstAggregate = aggregatesCopy[0];
  const sportsData = {};
  const theoreticalAggregateValues = {};
  const totalAggregateValues = {};

  const getNumericalValue = (value: number) => (Number.isNaN(value) ? 0 : formatFloatDecimal(value));

  let teamData = null;
  const hasTeam = teamIndex !== null;

  if (hasTeam) {
    const currentTeam = data.find(team => team.team_id === teamIndex);

    Object.keys(currentTeam.sports).forEach((sportId: string) => {
      Object.keys(currentTeam.sports[sportId].data).forEach((areaLabel: string) => {
        if (areaLabel === INDICATORS.SQUARE_METERS) {
          Object.keys(currentTeam.sports[sportId].data[areaLabel]).forEach((date: YearWeek) => {
            const theoreticalAggregateValue = currentTeam.total.SQUARE_METERS[date];

            theoreticalAggregateValues[date] = getNumericalValue(theoreticalAggregateValue);

            if (!sportsData[date]) sportsData[date] = {};

            if (!totalAggregateValues[date]) totalAggregateValues[date] = 0;

            const totalAggregateValue = currentTeam.sports[sportId].data[areaLabel][date];

            totalAggregateValues[date] += totalAggregateValue;

            sportsData[date][sportId] = {
              value: totalAggregateValue,
              is_editable: openedWeeks.includes(date) || isEditableByYearWeek(date, true)
            };
          });
        }
      });
    });

    const account_values = {};

    Object.keys(firstAggregate.account_values).forEach((accountId: string) => {
      account_values[accountId] = {
        value: currentTeam.total[INDICATORS.SQUARE_METERS][accountId]
      };
    });

    firstAggregate.account_values = account_values;
    firstAggregate.label = currentTeam.team_label;
  } else {
    Object.keys(firstAggregate.account_values).forEach((date: YearWeek) => {
      theoreticalAggregateValues[date] = getNumericalValue(firstAggregate.account_values[date].value);
    });

    data.forEach(account => {
      Object.keys(account.total).forEach((areaLabel: string) => {
        if (areaLabel === INDICATORS.SQUARE_METERS) {
          Object.keys(account.total[areaLabel]).forEach((date: YearWeek) => {
            if (!sportsData[date]) sportsData[date] = {};

            if (!totalAggregateValues[date]) totalAggregateValues[date] = 0;

            const totalAggregateValue = account.total[areaLabel][date];

            totalAggregateValues[date] += totalAggregateValue;

            sportsData[date][account.team_id] = {
              value: totalAggregateValue,
              is_editable: openedWeeks.includes(date) || isEditableByYearWeek(date, true)
            };
          });
        }
      });
    });

    firstAggregate.label = I18n.t("areas.available_store_surface");

    teamData = {};

    Object.keys(teams).forEach(team => {
      const teamId = teams[team].id;

      teamData[teamId] = {
        total: {},
        composition: {}
      };

      teams[team].composition.forEach((sportId: string) => (teamData[teamId].composition[sportId] = {}));
    });

    Object.keys(teamData).forEach((teamId: string) => {
      const currentAccount = data.filter(account => account.team_id.toString() === teamId)[0];

      Object.keys(currentAccount.total).forEach((areaLabel: string) => {
        if (areaLabel === INDICATORS.LINEAR_METERS) {
          Object.keys(currentAccount.total[areaLabel]).forEach(
            (date: YearWeek) => (teamData[teamId].total[date] = currentAccount.total[areaLabel][date])
          );
        }
      });

      Object.keys(currentAccount.sports).forEach((sportId: string) => {
        Object.keys(currentAccount.sports[sportId].data).forEach((areaLabel: string) => {
          if (areaLabel === INDICATORS.LINEAR_METERS) {
            Object.keys(currentAccount.sports[sportId].data[areaLabel]).forEach(
              (date: YearWeek) =>
                (teamData[teamId].composition[sportId][date] = currentAccount.sports[sportId].data[areaLabel][date])
            );
          }
        });
      });
    });
  }

  const aggregatesValues = Object.keys(firstAggregate.account_values).reduce(
    (result, date: YearWeek) => {
      // It's normal to mutate the accumulator in a reduce function
      // eslint-disable-next-line no-param-reassign
      result.total[date] = {
        ...result.total,
        value: totalAggregateValues[date]
      };

      // eslint-disable-next-line no-param-reassign
      result.difference[date] = {
        ...result.difference,
        value: theoreticalAggregateValues[date] - totalAggregateValues[date]
      };

      return result;
    },
    { total: {}, difference: {} }
  );

  const differenceAggregate = generateAggregate(
    firstAggregate,
    I18n.t("areas.diff_column_label"),
    COLUMNS.DIFFERENCE,
    aggregatesValues.difference
  );

  const totalAggregate = generateAggregate(
    firstAggregate,
    I18n.t(hasTeam ? "areas.total_sports_column_label" : "areas.total_store_column_label"),
    COLUMNS.TOTAL,
    aggregatesValues.total
  );

  aggregatesCopy.push(differenceAggregate, totalAggregate);

  const newData = {};
  const newAggregates = [];

  const yearWeeksNumber = dateUtils.getTotalYearWeeksNumber(year);
  const allWeeks = getAllWeeksByYearWeeks(year);
  seasons.forEach((season, index) => {
    const accounts = [];
    const from = dateUtils.getWeekNumber(dateUtils.getUTCDate(season.start_date));
    const children = [];
    const seasonValues = {};
    const seasonLabel = joinLabels(
      [season.label, dateUtils.getUTCDate(season.start_date).getFullYear().toString()],
      ""
    );
    const seasonLabelConsolidated = joinLabels([seasonLabel, pad(from)], WEEK_SEPARATOR);

    let to = dateUtils.getWeekNumber(dateUtils.getUTCDate(season.end_date));

    if (from > to) to = yearWeeksNumber + 1;

    for (let i = from; i < to; i++) {
      const accountLabel = joinLabels([year.toString(), pad(i)], WEEK_SEPARATOR);
      allWeeks.splice(allWeeks.indexOf(accountLabel), 1);
      aggregatesCopy.forEach((aggregate, index) => {
        newAggregates[index] = newAggregates[index] || {
          ...aggregate,
          account_values: {}
        };

        seasonValues[index] = seasonValues[index] || {};

        seasonValues[index][accountLabel] = {
          value: aggregatesCopy[index].account_values[accountLabel].value
        };

        newAggregates[index].account_values[joinLabels([seasonLabelConsolidated, INDICATORS.SQUARE_METERS])] =
          seasonValues[index];
      });

      const label_code = joinLabels([accountLabel, INDICATORS.SQUARE_METERS]);

      if (i !== from) {
        children.push(label_code);
      }

      accounts.push({
        label_code,
        data: sportsData[accountLabel],
        is_hidden: false,
        index,
        children: []
      });
    }

    accounts[0].children = children;
    newData[seasonLabelConsolidated] = accounts;
  });

  const outOfSeasonData =
    allWeeks.length > 0 ? getOutOfSeasonData(year, allWeeks, aggregatesCopy, newAggregates, sportsData) : {};

  return {
    ...dataToFormat,
    data: {
      ...dataToFormat.data,
      data: { ...newData, ...outOfSeasonData } as ITeamData[],
      aggregates: newAggregates
    },
    teams: teamData
  };
};

interface ISportsData {
  [key: string]: {
    [key: string]: {
      value: number;
      is_editable: boolean;
    };
  };
}

export const getAllWeeksByYearWeeks = (
  year: number,
  yearWeeksNumber: number = dateUtils.getTotalYearWeeksNumber(year)
): string[] => {
  const allWeeks = [];
  for (let i = 1; i <= yearWeeksNumber; i++) {
    allWeeks.push(joinLabels([year.toString(), pad(i)], WEEK_SEPARATOR));
  }
  return allWeeks;
};

const getOutOfSeasonData = (
  year: number,
  allWeeks: string[],
  aggregatesCopy,
  newAggregates,
  sportsData: ISportsData
) => {
  const accounts = [];
  const children = [];
  const seasonValues = {};

  const outOfSeasonLabel = joinLabels([I18n.t("areas.out_of_season"), year.toString()], "");
  const outOfSeasonLabelConsolidated = joinLabels([outOfSeasonLabel, allWeeks[0]], WEEK_SEPARATOR);

  allWeeks.forEach((week, index) => {
    aggregatesCopy.forEach((aggregate, index) => {
      newAggregates[index] = newAggregates[index] || {
        ...aggregate,
        account_values: {}
      };

      seasonValues[index] = seasonValues[index] || {};

      seasonValues[index][week] = {
        value: aggregatesCopy[index].account_values[week].value
      };

      newAggregates[index].account_values[joinLabels([outOfSeasonLabelConsolidated, INDICATORS.SQUARE_METERS])] =
        seasonValues[index];
    });

    const label_code = joinLabels([week, INDICATORS.SQUARE_METERS]);

    if (index > 0) {
      children.push(label_code);
    }

    accounts.push({
      label_code,
      data: sportsData[week],
      is_hidden: false,
      index,
      children: []
    });
  });

  accounts[0].children = children;

  return { [outOfSeasonLabelConsolidated]: accounts };
};

export const formatData = <D extends { label_code: string } = ICexAccount, E = object>(
  dataToFormat: IQueryAPIData<D[], E>
): IFormattedAPIQueryData<D, E> => {
  const { data, aggregates = [], ...others } = dataToFormat?.data || { data: [] };
  const newData = [...data, ...aggregates];

  const accountFormatted = newData.reduce<IFormattedAPIData<D | IAPIAggregate>>((accounts, account, index) => {
    const newAccount = { ...account, index };
    accounts = { ...accounts, [account.label_code]: newAccount };
    return accounts;
  }, {});

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore The expected type comes from property 'data' which is declared here on type 'IFormattedAPIQueryData<D, E>'
    data: {
      data: accountFormatted,
      aggregates,
      ...others
    }
  };
};

interface IFlatDataOptions {
  getAccountLabel?: (label: string) => string;
  getAggregateLabel?: (label: string) => string;
  includeCurrentWeek?: boolean;
}

const defaultOptions: IFlatDataOptions = {
  getAccountLabel: (label: string): string => label,
  getAggregateLabel: (label: string): string => label,
  includeCurrentWeek: false
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const flatData = <D = Record<string, ICexAccount[]>, E = {}>(
  dataToFlat: I2DQueryAPIData<D, E>,
  options: IFlatDataOptions = defaultOptions
): IQueryAPIData<ICexAccount[], E> => {
  const { getAccountLabel, getAggregateLabel } = { ...defaultOptions, ...options };

  const { data, aggregates, sticky_data } = dataToFlat.data;
  const accounts = [];
  const account_values = {};

  Object.keys(data).forEach(groupLabel => {
    const accountLabel = getAccountLabel(groupLabel);

    data[groupLabel].forEach(account => {
      if (account.children.length > 0) {
        account.children = account.children.map(childLabel => joinLabels([accountLabel, childLabel]));
      }
      account.label_code = `${accountLabel}${CONSOLIDATED_LABEL_SEPARATOR}${account.label_code}`;
      accounts.push(account);
    });
  });

  const stickyDataAggregates = {};

  if (sticky_data) {
    sticky_data.forEach(account => {
      if (account.children.length > 0) {
        account.children = account.children.map(
          childLabel => `${ROWS.TOTAL}${CONSOLIDATED_LABEL_SEPARATOR}${childLabel}`
        );
      } else {
        account.is_hidden = true;
      }

      const dataContent = {
        value: null,
        type: "R",
        is_editable: false
      };

      if (Object.keys(account.data).length > 0) {
        account.data = Object.keys(account.data).reduce(
          (result, column) => ({
            ...result,
            [column]: {
              ...dataContent,
              value: account.data[column]
            }
          }),
          {}
        );
      } else {
        const columns = Object.keys(sticky_data[0].data);

        account.data = columns.reduce(
          (result, column) => ({
            ...result,
            [column]: dataContent
          }),
          {}
        );
      }

      const consolidatedLabel = joinLabels([ROWS.TOTAL, account.label_code]);

      stickyDataAggregates[consolidatedLabel] = {
        is_editable: false,
        value: account.total
      };

      account.index = accounts.length;
      account.label_code = consolidatedLabel;

      accounts.push(account);
    });
  }

  Object.keys(aggregates).forEach(aggregate => {
    Object.keys(aggregates[aggregate].account_values).forEach(label => {
      const [groupLabel, ...otherLabels] = splitLabel(label);
      const consolidatedLabel = joinLabels([groupLabel, ...otherLabels]);
      const accountsLabels = aggregates[aggregate]?.account_values[consolidatedLabel];
      Object.keys(accountsLabels).forEach(accountLabel => {
        const consolidatedAccountLabel = joinLabels([groupLabel, accountLabel, ...otherLabels]);
        const aggregateLabel = getAggregateLabel(consolidatedAccountLabel);
        account_values[aggregateLabel] = accountsLabels[accountLabel];
      });
    });

    aggregates[aggregate].account_values = { ...account_values, ...stickyDataAggregates };
  });

  return {
    ...dataToFlat,
    data: {
      ...dataToFlat.data,
      data: accounts
    }
  };
};

export const getCells = (
  dataAggregates: ICells,
  dataAccounts: ICexAccountsData,
  options?: IAccountsGetterOptions
): [ICells, string[], boolean] => {
  let hasEditableCells = false;
  const lockedCells = [];
  const aggregates = Object.keys(dataAggregates).reduce<ICells>((cells, cellId) => {
    const aggregate = dataAggregates[cellId];
    const isEditableCell = options && options.disableEditableCells ? false : aggregate.is_editable;
    hasEditableCells = isEditableCell || hasEditableCells;
    return {
      ...cells,
      [cellId]: {
        value: aggregate.value,
        initial_value: aggregate.initial_value,
        isComputed: aggregate.isComputed,
        is_editable: isEditableCell,
        type: TypedValue.Pilotable,
        is_persisted: aggregate.is_persisted || false
      }
    };
  }, {});

  const accounts = Object.keys(dataAccounts).reduce<ICells>((cells, cellId) => {
    const cell = dataAccounts[cellId];
    const { is_editable, has_annexe_details: hasAnnexeDetails, type, is_persisted } = cell;
    const isEditableCell = options && options.disableEditableCells ? false : is_editable;
    hasEditableCells = isEditableCell || hasEditableCells;
    if (type === TypedValue.PilotedManually) {
      lockedCells.push(cellId);
    }
    // Fix to replace cell.value (=-0) by 0
    const value = cell.value === 0 ? 0 : cell.value;
    return {
      ...cells,
      [cellId]: {
        value,
        initial_value: value,
        type,
        is_editable: isEditableCell,
        hasAnnexeDetails,
        is_persisted: is_persisted || false
      }
    };
  }, {});

  return [{ ...aggregates, ...accounts }, lockedCells, hasEditableCells];
};

export const getAccount = (
  dataFromCex: IFormattedAPIQueryData,
  accounts: Accounts,
  { children, account, index, are_children_fixed, isHiddenForComputation }: ITemplateAccount,
  dataAggregates: ICells = {},
  options?: IAccountsGetterOptions,
  accountLabelFromConsolidatedLabel?: string,
  displayLabel?: string
): IAccount | null => {
  const accountObj = accounts[accountLabelFromConsolidatedLabel || account];
  const accountData = dataFromCex.data.data[account] as ICexAccount;

  if (accountData && accountObj) {
    const { aggregate_formula, formulas, label, label_code, mask, weight, is_persisted, is_temporal_average } =
      accountObj;
    const { data, is_hidden }: ICexAccount = accountData;
    const [cells, lockedCells, hasEditableCells] = getCells(dataAggregates, data, options);

    return {
      index,
      aggregate_formula,
      formulas,
      label: displayLabel || label,
      label_code,
      mask,
      is_persisted,
      is_temporal_average,
      weight,
      cells,
      is_editable: hasEditableCells,
      lockedCells,
      is_hidden: is_hidden || isHiddenForComputation || false,
      children: children ? children.map(child => child.account) : null,
      is_child: false,
      are_children_fixed
    };
  }
  return null;
};

export const getChild = (
  accounts: Accounts,
  childLabel: string,
  dataFromCex: IFormattedAPIQueryData,
  dataAggregates: ICells = {},
  options?: IAccountsGetterOptions,
  childLabelFromConsolidatedLabel?: string,
  displayLabel?: string
): IAccount | null => {
  const { aggregate_formula, formulas, label, label_code, mask, weight, is_persisted, is_temporal_average } =
    accounts[childLabelFromConsolidatedLabel || childLabel];
  const child = dataFromCex.data.data[childLabel];
  if (child) {
    const { data, index, is_hidden }: ICexAccount = dataFromCex.data.data[childLabel] as ICexAccount;
    const [cells, lockedCells, hasEditableCells] = getCells(dataAggregates, data, options);
    return {
      index,
      aggregate_formula,
      formulas,
      label: displayLabel || label,
      label_code,
      mask,
      is_persisted,
      is_temporal_average,
      weight,
      children: [], // Currently, children accounts can't be parents
      cells,
      is_editable: hasEditableCells,
      lockedCells,
      is_hidden,
      is_child: true
    };
  }
  return null;
};

export const deserializeFormula = (formulas: string[], rowId: string): string[] => {
  return formulas.map(formula => {
    const formulaIds = getIdsFromFormula([formula]);
    return formulaIds.reduce((result, param) => {
      const newParamId = joinLabels([rowId, param]);
      return result.replace(new RegExp(`\\{${param}\\}`, "g"), `{${newParamId}}`);
    }, formula);
  });
};

export const deserializeAccountFormulas = (account: IAccount, rowId: string): IAccount => {
  account.formulas = deserializeFormula(account.formulas, rowId);
  const aggregateFormula = account.aggregate_formula ? [account.aggregate_formula] : [];
  const deserializedAggregateFormula = deserializeFormula(aggregateFormula, rowId);
  account.aggregate_formula = deserializedAggregateFormula[0] || null;
  account.label_code = account.label_code && joinLabels([rowId, account.label_code]);
  return account;
};

/**
 * Get an account by label code
 * @param accountLabel
 * @param accounts
 * @return account
 * @example getAccountByLabelCode("TOTO", {TOTO: {...}, ...}) => {...}
 */
export const getAccountByLabelCode = (accountLabel: string, accounts: IAccounts): IAccount | null =>
  accounts[accountLabel] ? { ...accounts[accountLabel] } : null;

export const getAccountTranslation = (accountLabelCode: string) =>
  I18n.t(ACCOUNT_TRANSLATION_PREFIX + accountLabelCode);
