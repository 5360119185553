import * as React from "react";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { I18n } from "react-redux-i18n";

import ErrorPage from "../../../pages/errors/error-page";
import { RouteId } from "core/routing/router/route-ids";
import { getPathFromRouteId } from "core/utils/route-utils";
import Button from "@mui/material/Button";

type ErrorInfo = { componentStack: string };

interface IProps {
  children: ReactElement;
}

interface State {
  error: Error;
  errorInfo: ErrorInfo;
}

export default class ErrorBoundary extends React.Component<IProps, State> {
  constructor(props: IProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  private clearError = () => {
    this.setState({ error: null, errorInfo: null });
  };

  render(): ReactElement {
    const { children } = this.props;
    const { error, errorInfo } = this.state;
    if (errorInfo) {
      return (
        <ErrorPage title={I18n.t("error.description")}>
          <div>
            <Link to={getPathFromRouteId(RouteId.Home)}>
              <Button className="error-home-link" onClick={this.clearError}>
                {I18n.t("error.home")}
              </Button>
            </Link>
          </div>

          <details className="error-details">
            {error?.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        </ErrorPage>
      );
    }
    return children;
  }
}
