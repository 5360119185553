import { ChangeEvent, ReactElement, useCallback } from "react";
import TextField from "@mui/material/TextField";
import PanelItemType from "./settings-panel.d";

import { IErrors } from "pages/templates/templates";

export interface IPanelEditorProps {
  item: PanelItemType;
  errors?: IErrors;
  onItemChange: (item: Partial<PanelItemType>) => void;
  canEdit: boolean;
  children?: ReactElement | ReactElement[];
  editTitle: string;
  editFieldLabel: string;
}

const PanelEditor = ({
  item,
  onItemChange,
  children,
  canEdit,
  editFieldLabel,
  errors
}: IPanelEditorProps): ReactElement => {
  const handleTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => onItemChange({ label: event.target.value }),
    [onItemChange]
  );
  const errorMessage = errors?.label;
  return (
    <div className="mg-setting-editor_content">
      <label
        data-testid="settings-panel-editor-input"
        htmlFor="settings-panel-editor-input"
        className="mg-subheader-form__label vtmn-typo_text-3"
      >
        {editFieldLabel}
      </label>
      <div className="mg-subheader-form__input">
        <TextField
          variant="standard"
          error={!!errorMessage}
          name="label"
          required
          autoFocus
          disabled={!canEdit}
          fullWidth
          value={item.label || ""}
          onChange={handleTextFieldChange}
          id="settings-panel-editor-input"
          data-testid="settings-panel-editor-input"
          helperText={errorMessage}
        />
      </div>
      {children}
    </div>
  );
};

export default PanelEditor;
