import { useQuery } from "@tanstack/react-query";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";
import axios from "axios";
import qs from "qs";

export type ICoach = {
  trajectory_actions: ICoachActions;
};

export type ICoachActions = {
  action_title: string;
  call_to_action_title: string;
  trajectory_realized: boolean;
  year: number;
};

export const QUERY_KEY_COACH = "get-coach";

export const endPointGetCoach = "/coach";

const getApiCoach = async (endPoint: string): Promise<ICoach> => {
  const response = await axios.get(endPoint);
  return response?.data;
};

const endPoint = (teamId: number, storeId: number): string => {
  return [endPointGetCoach, qs.stringify({ teamId, storeId }, { addQueryPrefix: true })].join("");
};

export const useApiGetCoach = (
  teamId: number,
  storeId: number,
  options?: UseQueryOptionsWithoutQueryKey<ICoach, Error>
) => {
  return useQuery<ICoach, Error>({
    queryKey: [QUERY_KEY_COACH],
    queryFn: () => getApiCoach(endPoint(teamId, storeId)),
    ...options
  });
};
