import * as React from "react";
import { I18n } from "react-redux-i18n";
import { useFilters } from "../../../../common/filters3/filters/hooks/useFilters";
import { GenericFilters } from "../../../../common/filters3/filters/definitions";
import Modal from "../../../../common/mygame/modal/modal";
import { useApiPutDeleteForecast } from "../../../../../core/api/upward-forecast/useApiPutDeleteForecast";

interface IDeleteButtonProps {
  isDisabled: boolean;
  storeId: number;
}

const DeleteButton: React.FunctionComponent<IDeleteButtonProps> = ({ isDisabled, storeId }) => {
  const {
    appliedFilters: { country }
  } = useFilters<GenericFilters>();
  const { mutate } = useApiPutDeleteForecast();
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = React.useState(false);

  const handleRemoveException = React.useCallback(() => {
    mutate({ countryCode: country, storeId: storeId });
    setIsDeleteAlertOpen(false);
  }, [mutate]);

  const toggleIsDeleteAlertOpen = React.useCallback(() => {
    setIsDeleteAlertOpen(!isDeleteAlertOpen);
  }, [isDeleteAlertOpen]);

  return (
    <>
      <div
        className={`mg-button-base ${isDisabled ? "disabled" : ""}`}
        {...(!isDisabled ? { onClick: () => toggleIsDeleteAlertOpen() } : {})}
      >
        <span className="material-icons-outlined notranslate">delete</span>
      </div>
      {isDeleteAlertOpen && (
        <Modal
          content={I18n.t("upward-forecast.alert_remove_message")}
          handleClickCancel={toggleIsDeleteAlertOpen}
          handleClickContinue={handleRemoveException}
        />
      )}
    </>
  );
};

export default React.memo(DeleteButton);
