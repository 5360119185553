import { I18n } from "react-redux-i18n";
import {
  MENU_SECTION_CONFIGURATION_COMMERCIAL,
  MENU_SECTION_CONFIGURATION_HOURS
} from "core/constants/translations/menu";
import DefaultList from "./defaultList/defaultList";
import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../menu";
import { ROLES } from "core/constants/roles";
import { useUserHasRoles } from "core/hooks/useUserHasRoles";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DndLink from "./dndList/dndLink";

const MenuConfigurationLinks = () => {
  const [transformedCommercialItems, setTransformedCommercialItems] = useState([]);
  const [transformedHoursItems, setTransformedHoursItems] = useState([]);

  const isAdmin = useUserHasRoles([ROLES.ROLE_ADMIN]);
  const { viewId, setModifiedData, navigate, isEditing, selectedView, modifiedData } = useContext(MenuContext);

  const currentView = modifiedData?.find(v => v.id === viewId);

  useEffect(() => {
    // Whenever modifiedData changes, update the transformed lists
    if (currentView) {
      const commercialItems = currentView.route.filter(r => r.type === "commercial");
      const transformedCommercialItems = commercialItems.map(item => ({
        index: item.index.toString(),
        label: item.label,
        type: item.type
      }));

      const hoursItems = currentView.route.filter(r => r.type === "hours");
      const transformedHoursItems = hoursItems.map(item => ({
        index: item.index.toString(),
        label: item.label,
        type: item.type
      }));

      setTransformedCommercialItems(transformedCommercialItems);
      setTransformedHoursItems(transformedHoursItems);
    }
  }, [currentView]);

  const handleDragEndCommercial = result => {
    if (!result.destination) return;

    const updatedItems = [...transformedCommercialItems];
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    const allViewsUpdated = modifiedData.map(item => {
      if (item.id === viewId) {
        return { ...item, route: [...updatedItems, ...transformedHoursItems] };
      } else {
        return { ...item };
      }
    });
    setModifiedData(allViewsUpdated);
  };

  const handleDragEndHours = result => {
    if (!result.destination) return;

    const updatedItems = [...transformedHoursItems];
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    const allViewsUpdated = modifiedData.map(item => {
      if (item.id === viewId) {
        return { ...item, route: [...transformedCommercialItems, ...updatedItems] };
      } else {
        return { ...item };
      }
    });
    setModifiedData(allViewsUpdated);
  };

  return (
    <>
      <div className="mg-menu-configuration-vue__title">
        <span>{I18n.t(MENU_SECTION_CONFIGURATION_COMMERCIAL)}</span>
      </div>
      <div className="mg-menu-configuration-links">
        {(isAdmin && isEditing) || (!isAdmin && isEditing && !selectedView.default) ? (
          <DragDropContext onDragEnd={handleDragEndCommercial}>
            <Droppable droppableId="commercial-list">
              {provided => (
                <ul {...provided.droppableProps} ref={provided.innerRef}>
                  {transformedCommercialItems?.map((item, index) => (
                    <Draggable key={item.index} draggableId={item.index} index={index}>
                      {provided => (
                        <div
                          className="mg-menu-configuration-customized-links"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <DndLink link={item} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          transformedCommercialItems.length > 0 && (
            <DefaultList selectedView={{ route: transformedCommercialItems }} navigate={navigate} />
          )
        )}
      </div>
      <div className="mg-menu-configuration-vue__title">
        <span>{I18n.t(MENU_SECTION_CONFIGURATION_HOURS)}</span>
      </div>
      <div className="mg-menu-configuration-links">
        {(isAdmin && isEditing) || (!isAdmin && isEditing && !selectedView.default) ? (
          <DragDropContext onDragEnd={handleDragEndHours}>
            <Droppable droppableId="hours-list">
              {provided => (
                <ul {...provided.droppableProps} ref={provided.innerRef}>
                  {transformedHoursItems?.map((item, index) => (
                    <Draggable key={item.index} draggableId={item.index} index={index}>
                      {provided => (
                        <div
                          className="mg-menu-configuration-customized-links"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <DndLink link={item} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          transformedHoursItems.length > 0 && (
            <DefaultList selectedView={{ route: transformedHoursItems }} navigate={navigate} />
          )
        )}
      </div>
    </>
  );
};

export default MenuConfigurationLinks;
