import { VtmnButton } from "@vtmn/react";
import "react-quill/dist/quill.snow.css";
import { I18n } from "react-redux-i18n";
import { useUserHasRoles } from "core/hooks/useUserHasRoles";
import { ROLES } from "core/constants/roles";
import { useApiGetDirMessage } from "core/api/director/useApiGetDirector";
import { attempt } from "lodash";
import { useApiPutDirectorWord } from "core/api/director/useApiPutDirectorWord";
import { useEffect, useState } from "react";
import CustomModal from "../modal/custom-modal";
import { CustomModalHeader } from "../modal/custom-modal-header";
import { CustomModalContent } from "../modal/custom-modal-content";
import { CustomModalFooter } from "../modal/custom-modal-footer";
import { DirectorWordQuillToolbar, formats, modules } from "./director-word-quill-toolbar";
import { DirectorWordQuillTextarea } from "./director-word-quill-textarea";
import { DIRECTOR_WORD_EDITION, DIRECTOR_WORD_READ } from "../../../../core/constants/translations/director-word";
import { useApiMenuGetUserPreferences } from "../../../../core/api/menu/useApiMenuGetUserPreferences";

export const DirectorWord = ({ modalIsOpen, setModalIsOpen }) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const activeStoreId = parseInt(params.get("store_id"), 10);
  const { data: user } = useApiMenuGetUserPreferences();
  const preferencesStoreId = user.preferences.default_filters.store_id;
  const storeId = activeStoreId || preferencesStoreId;

  const { data, isLoading } = useApiGetDirMessage(storeId, { enabled: !!storeId });
  const { mutate } = useApiPutDirectorWord(storeId);
  const [newData, setNewData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const userHasRightToEdit = useUserHasRoles([ROLES.ROLE_ADMIN, ROLES.ROLE_STORE_MANAGER]);
  const dataIsModified = attempt(JSON.parse, data, data) !== newData;
  const handleOnEdit = () => setIsEditing(!isEditing);

  useEffect(() => {
    setNewData(attempt(JSON.parse, data, data));
  }, [data]);

  return (
    <>
      {!isLoading && (
        <CustomModal isOpen={modalIsOpen}>
          <CustomModalHeader>
            <div className="mg-modal-header-title">
              <span>{I18n.t("homepage.manager_word")}</span>
              {userHasRightToEdit && (
                <VtmnButton size="small" variant={!isEditing ? "primary" : "tertiary"} onClick={handleOnEdit}>
                  {!isEditing ? I18n.t(DIRECTOR_WORD_EDITION) : I18n.t(DIRECTOR_WORD_READ)}
                </VtmnButton>
              )}
            </div>
            <span className="mg-popover-header-title__close" onClick={() => setModalIsOpen(false)}>
              <span className="material-icons-outlined notranslate">close</span>
            </span>
          </CustomModalHeader>
          <CustomModalContent>
            {isEditing ? (
              <>
                <DirectorWordQuillToolbar />
                <DirectorWordQuillTextarea
                  newData={newData}
                  setNewData={setNewData}
                  modules={modules}
                  formats={formats}
                />
              </>
            ) : (
              <div
                className="mg-modal-content-no-quill"
                dangerouslySetInnerHTML={{ __html: attempt(JSON.parse, data, data) }}
              />
            )}
          </CustomModalContent>
          <CustomModalFooter>
            {isEditing && (
              <>
                <VtmnButton size={"small"} onClick={() => setModalIsOpen(false)} variant="secondary">
                  {I18n.t("cancel")}
                </VtmnButton>
                <VtmnButton
                  size={"small"}
                  onClick={() => {
                    mutate(newData);
                    setModalIsOpen(false);
                    setIsEditing(false);
                  }}
                  variant="primary"
                  disabled={!dataIsModified}
                >
                  {I18n.t("save")}
                </VtmnButton>
              </>
            )}
          </CustomModalFooter>
        </CustomModal>
      )}
    </>
  );
};
