import { useQuery } from "@tanstack/react-query";

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Aggregate, AggregateType, CalendarAggregate } from "../../models/api/aggregate/aggregate";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

const typeOrder: Record<AggregateType, number> = {
  [AggregateType.REALIZED]: 1,
  [AggregateType.FORECASTED]: 2,
  [AggregateType.TRAJECTORY]: 3,
  [AggregateType.FORECAST_PHOTO]: 4,
  [AggregateType.OTHERS]: 5
};

const getAggregate = async (config: AxiosRequestConfig): Promise<Aggregate> => {
  const aggregates: AxiosResponse<CalendarAggregate[]> = await axios(config);
  return {
    calendar: aggregates?.data
      .map(calendarAggregate => {
        return {
          id: calendarAggregate.id,
          label_code: calendarAggregate.label_code,
          display_order: calendarAggregate.display_order,
          from: calendarAggregate.from,
          to: calendarAggregate.to,
          comparison_allowed: calendarAggregate.comparison_allowed,
          type: AggregateType[calendarAggregate.type],
          order_in_type: calendarAggregate.order_in_type,
          display_when_load_aggregate: calendarAggregate.display_when_load_aggregate,
          display_in_calendar: calendarAggregate.display_in_calendar
        };
      })
      .sort((a, b) => {
        const orderA = typeOrder[a.type];
        const orderB = typeOrder[b.type];

        // If the aggregates are of the same type, sort based on order_in_type
        if (orderA === orderB) {
          return a.order_in_type - b.order_in_type;
        }
        return orderA - orderB;
      }),
    load_aggregate: aggregates.data
      .filter(aggregate => aggregate.display_when_load_aggregate)
      .sort((a, b) => {
        return a.display_order - b.display_order;
      })
  };
};

export const QUERY_KEY_AGGREGATE = "aggregate";

export const useApiGetAggregates = (options?: UseQueryOptionsWithoutQueryKey<Aggregate>) => {
  const url = `/aggregates/all`;
  const config: AxiosRequestConfig = { method: "get", url };
  return useQuery<Aggregate>({
    queryKey: [QUERY_KEY_AGGREGATE],
    queryFn: () => getAggregate(config),
    staleTime: Infinity,
    ...options
  });
};
