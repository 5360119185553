interface GroupsPopoverTabsProps {
  isActiveTab: string;
  handleClickOnTab: (action: string) => void;
  read: string;
  create: string;
  edit: string;
}

const GroupsTabs = ({ isActiveTab, handleClickOnTab, read, create, edit }: GroupsPopoverTabsProps) => {
  return (
    <ul className="vtmn-tabs vtmn-tabs_align--start vtmn-tabs_size--small">
      <li>
        <button aria-selected={`${isActiveTab === "read" ? "true" : "false"}`} onClick={() => handleClickOnTab("read")}>
          <span className="mg-groups-tabs__text">{read}</span>
          <span className="mg-groups-tabs__icon material-icons-outlined">format_list_bulleted</span>
        </button>
      </li>
      <li>
        <button
          aria-selected={`${isActiveTab === "create" ? "true" : "false"}`}
          onClick={() => handleClickOnTab("create")}
        >
          <span className="mg-groups-tabs__text">{create}</span>
          <span className="mg-groups-tabs__icon material-icons-outlined">add</span>
        </button>
      </li>
      <li
        className={`${isActiveTab !== "edit" && "mg-groups-tabs__disabled"}`}
        {...(isActiveTab !== "edit" && { value: "disabled" })}
      >
        <button aria-selected={`${isActiveTab == "edit" ? "true" : "false"}`}>
          <span className="mg-groups-tabs__text">{edit}</span>
          <span className="mg-groups-tabs__icon material-icons-outlined">edit</span>
        </button>
      </li>
    </ul>
  );
};

export default GroupsTabs;
