import { I18n } from "react-redux-i18n";
import LinearProgress from "@mui/material/LinearProgress";

interface IProps {
  messageId?: string;
  hasSubMessage?: boolean;
}

const LoadingDialog = ({ messageId = "loading", hasSubMessage = false }: IProps) => {
  return (
    <div className="loading-progress" data-testid="loading-progress-bar">
      <div className="loading-progress__content">
        <h1>{I18n.t(messageId)}</h1>
        <LinearProgress color="primary" />
        {hasSubMessage ? <div className="curly-quotes">{I18n.t("under_loading_message")}</div> : null}
      </div>
    </div>
  );
};

export default LoadingDialog;
