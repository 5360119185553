import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { I18n } from "react-redux-i18n";
import { isEqual, cloneDeep } from "lodash";
import CircularProgress from "@mui/material/CircularProgress";

import ConfirmModal from "components/common/modals/confirm-modal";
import PanelEditor from "components/common/settings-panel/settings-panel-editor";
import { IErrors } from "pages/templates/templates";
import PanelFooter from "./settings-panel-footer";
import PanelItemType from "./settings-panel.d";

export interface IPanelProps {
  className?: string;
  children?: ReactElement;
  onClose: () => void;
  onSave: () => void;
  onChange: (partialItem: Partial<PanelItemType>) => void;
  onDelete: () => void;
  canEdit: boolean;
  item: PanelItemType;
  isLoading: boolean;
  defaultTitle: string;
  deleteActionLabel: string;
  deleteActionMessage: string;
  editTitle: string;
  editFieldLabel: string;
  checkCanBeSaved: (hasBeenEdited: boolean) => boolean;
  errors?: IErrors;
  groupType: string;
}

const noop = () => null;
const Panel = ({
  children,
  onClose,
  onSave,
  onDelete,
  onChange,
  errors,
  canEdit,
  item,
  isLoading,
  deleteActionMessage,
  editTitle,
  editFieldLabel,
  checkCanBeSaved,
  groupType
}: IPanelProps): ReactElement => {
  const [originalItem, setOriginalItem] = useState(null);
  const hasBeenEdited = useMemo(() => !isEqual(item, originalItem), [item, originalItem]);
  const [modalMessage, setModalMessage] = useState(null);
  const [modalActions, setModalActions] = useState([noop, noop]);
  const [onConfirmAction, onCancelAction] = useMemo(() => modalActions, [modalActions]);

  const saveDisabled = useMemo(() => !checkCanBeSaved(hasBeenEdited), [hasBeenEdited, checkCanBeSaved]);

  const closeModal = useCallback(() => {
    setModalActions([noop, noop]);
    setModalMessage(null);
  }, []);

  const closePanel = useCallback(() => {
    setOriginalItem(null);
    closeModal();
    onClose();
  }, [onClose, closeModal]);

  const handleClose = useCallback(() => {
    if (hasBeenEdited) {
      setModalMessage(I18n.t("modal.changes-not-saved"));
      setModalActions([closePanel, closeModal]);
    } else {
      closePanel();
    }
  }, [closePanel, closeModal, hasBeenEdited]);

  const handleDelete = useCallback(async () => {
    closeModal();
    await onDelete();
    closePanel();
  }, [onDelete, closeModal, closePanel]);

  const showDeleteConfirm = useCallback(() => {
    setModalMessage(deleteActionMessage);
    setModalActions([handleDelete, closeModal]);
  }, [handleDelete, closeModal, deleteActionMessage]);

  useEffect(() => {
    if (item && !originalItem) return setOriginalItem(cloneDeep(item));
    if (!item) return setOriginalItem(null);
  }, [item, originalItem]);

  return (
    <>
      {isLoading ? (
        <div className="settings-panel__loader">
          <CircularProgress data-testid="loader" />
        </div>
      ) : null}

      <ConfirmModal
        isOpen={Boolean(modalMessage)}
        message={modalMessage}
        onConfirmAction={onConfirmAction}
        onCancelAction={onCancelAction}
      />

      {item ? (
        <PanelEditor
          item={item}
          canEdit={canEdit}
          onItemChange={onChange}
          editTitle={editTitle}
          editFieldLabel={editFieldLabel}
          errors={errors}
        >
          {children}
        </PanelEditor>
      ) : null}

      <PanelFooter
        item={item}
        onSaveItem={onSave}
        onClose={handleClose}
        isSaveDisabled={saveDisabled}
        errors={errors}
        canEdit={canEdit}
        showDeleteConfirm={showDeleteConfirm}
        groupType={groupType}
      />
    </>
  );
};

export default Panel;
