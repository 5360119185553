import { ROLES } from "core/constants/roles";
import { useUserHasRoles } from "core/hooks/useUserHasRoles";
import { IRoute, ROUTES } from "core/routing/router/routes";
import React from "react";

/**
 * Mark routes specified in DISABLED_ROUTES as disabled
 *
 * Useful for RODRIGUE operation to progressively reintroduce MyGame
 */
export const useRoutes = (routes: Array<IRoute> = ROUTES) => {
  const isAdmin = useUserHasRoles([ROLES.ROLE_ADMIN]);

  // MG-2494 RODRIGUE specific
  return React.useMemo(() => {
    // all routes stay visible for admins
    const disabledRoutes = import.meta.env.REACT_APP_DISABLED_ROUTES?.split(".");
    if (isAdmin || disabledRoutes.length === 0) {
      return routes;
    }
    // disable routes according to the corresponding environment variable
    return routes.map(route => ({ ...route, isDisabled: route.isDisabled || disabledRoutes.includes(route.id) }));
  }, [routes, isAdmin]);
};
