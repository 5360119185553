import { PERMISSIONS, ROLES } from "../constants/roles";

export const checkIsHasPermission = (
  userRoles: ROLES[],
  rolesMapping: Record<ROLES, PERMISSIONS[]>,
  permissionToCheck: PERMISSIONS
) => {
  if (permissionToCheck === undefined) {
    return true;
  }
  const permissions = [];
  userRoles.forEach(role => {
    const rolesMappingElement = rolesMapping[role];
    permissions.push(rolesMappingElement);
  });
  const allPermissions = permissions.flat(1);
  const allPermissionSingleValue = [...new Set(allPermissions)];

  return allPermissionSingleValue.includes(permissionToCheck);
};
