import {Provider} from "react-redux";
import {createRoot} from "react-dom/client";
import reportWebVitals from "../reportWebVitals";
import 'unfonts.css';
import getAppStore from "core/redux/store";
import App from "./app";
import ReactQueryProviderTryout from "./react-query-config";
import {createBrowserRouter,} from "react-router-dom";
import {RouterProvider} from "react-router";
import {AuthProvider} from "react-oidc-context";
import {oidcConfig} from "./utils/authentication";

const store = getAppStore();

const renderApp = () => {
  const router = createBrowserRouter([{ path: "*", Component: App }]);
  const root = createRoot(document.getElementById("root"));
  root.render(
    <Provider store={store}>
        <ReactQueryProviderTryout>
          <AuthProvider {...oidcConfig}>
            <RouterProvider router={router} />
          </AuthProvider>
        </ReactQueryProviderTryout>
    </Provider>
  );
};

const startApp = () => {
  renderApp();
};

startApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
