import { IHeaders } from "core/utils/headers";
import { IAggregateHeader } from "core/utils/aggregates";
import DateUtils from "core/utils/date";
import {
  IRows,
  IRow,
  IRowWithoutMask,
  IRowCell,
  getFormulaParams,
  evalFormula,
  updateRowCellValue,
  updateComputedRowsCells,
  IRowsData,
  IComputedCells,
  ICache,
  TypedValue
} from "./index";
import { getUpdatedRowsData } from "./vertical-engine";

export const getAggregates = (headers: IHeaders): IHeaders<IAggregateHeader> => {
  return Object.keys(headers).reduce((result, header) => {
    if (headers[header].isAggregate) {
      result[header] = headers[header];
    }
    return result;
  }, {});
};

export const getImpactedAggregates = (date: Date, aggregates: IHeaders<IAggregateHeader>): string[] => {
  if (!date || !aggregates) {
    return [];
  }
  return Object.keys(aggregates).reduce((result, aggregateId) => {
    const aggregate = aggregates[aggregateId];
    const from = DateUtils.getUTCDate(aggregate.from);
    const to = DateUtils.getUTCDate(aggregate.to);
    from.setHours(0, 0, 0, 0);
    to.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    const isInDateRange = date >= from && date <= to;
    if (isInDateRange) {
      result.push(aggregateId);
    }
    return result;
  }, []);
};

export const getAverage = (oldAggrValue: number, addedValue: number, from: string, to: string): number => {
  const fromDate = DateUtils.getUTCDate(from);
  const toDate = DateUtils.getUTCDate(to);
  const nbWeeks = Math.round(DateUtils.compareDates(fromDate, toDate) / 7);
  const addedAverage = addedValue / nbWeeks;
  let newValue = oldAggrValue;
  newValue += addedAverage;
  return newValue;
};

export const evalAggregate = <IGenericRows extends IRows<IRowWithoutMask> = IRows<IRowWithoutMask>>(
  rows: IGenericRows,
  rowCell: IRowCell,
  aggregate: IAggregateHeader
) => {
  const { rowId, columnId, prevValue } = rowCell;
  const row = rows[rowId];
  const aggregateId = aggregate.id;
  const editedCell = row.cells[columnId];
  const aggregateCell = row.cells[aggregateId];
  if (row.aggregate_formula) {
    // the initial_value of the aggregate cell
    const { initial_value } = aggregateCell;
    const formulaParams = getFormulaParams(row.aggregate_formula, rows, aggregateId);
    let formulaResult = evalFormula(row.aggregate_formula, formulaParams);
    // format formula result if not a finite number
    if (formulaResult === null) {
      formulaResult = initial_value === null ? initial_value : 0;
    }
    return formulaResult;
  }
  const aggregateValue = aggregateCell ? aggregateCell.value : 0;
  const addedValue = editedCell.value - prevValue;
  if (aggregate.isSpatialComputation || !row.is_temporal_average) {
    return aggregateValue + addedValue;
  }
  return getAverage(aggregateValue, addedValue, aggregate.from, aggregate.to);
};

/**
 * get the date from a string (the column id: the week number)
 * @param {string} columnId the week number `43`
 */
export const getWeeklyCellDate = (columnId: string) => {
  return DateUtils.getDateFromWeekLabel(columnId);
};

/**
 * get the date from a string (the column id)
 * @param {string} columnId year and month `2018-11`
 */
export const getAllMonthCellDate = (columnId: string) => {
  const monthAndYear = columnId.split("-");
  const year = parseInt(monthAndYear[0]);
  const month = parseInt(monthAndYear[1]);
  return DateUtils.getDateFromMonth(month, year);
};

export const getSportsGroupsCellDate = (weekLabel: string) => () => {
  return DateUtils.getDateFromWeekLabel(weekLabel);
};

/**
 * Go through computedRowsCells and get updated impacted aggregates data according to their aggregate_formula
 * @param rows
 * @param computedRowsCells
 * @param cache
 */
export const getUpdatedAggregateFormulasData = <IGenericRows extends IRows<IRow> = IRows<IRow>>(
  rows: IGenericRows,
  computedRowsCells: IComputedCells[],
  type: TypedValue = TypedValue.PilotedManually,
  cache: ICache
) =>
  computedRowsCells.reduce(
    ({ updatedComputedRowsCells, updatedRows }, computedCells) => {
      const { data, label_code } = computedCells;
      /**
       * For each updated cell
       * get updated impacted aggregates data according to their aggregate_formula
       */
      Object.keys(data).forEach(columnId => {
        const { value } = data[columnId];
        const newRowCell: IRowCell = {
          rowId: label_code,
          columnId,
          value
        };
        const { updatedComputedRowsCells: newupdatedComputedRowsCells, updatedRows: newUpdatedRows } =
          getUpdatedRowsData(updatedRows, updatedComputedRowsCells, newRowCell, type, cache, "aggregate_formula");
        updatedRows = newUpdatedRows;
        updatedComputedRowsCells = newupdatedComputedRowsCells;
      });
      return { updatedComputedRowsCells, updatedRows };
    },
    { updatedComputedRowsCells: computedRowsCells, updatedRows: rows }
  );

export const getUpdatedAggregatesData = <IGenericRows extends IRows<IRowWithoutMask> = IRows<IRowWithoutMask>>(
  prevRows: IGenericRows,
  rows: IGenericRows,
  aggregates: IHeaders<IAggregateHeader>,
  computedRowsCells: IComputedCells[],
  computedAggregates: IComputedCells[],
  type: TypedValue = TypedValue.PilotedManually,
  dateGetter: (columnId: string) => Date = () => null
): IRowsData<IGenericRows> => {
  const initialValue = { updatedComputedRowsCells: computedAggregates, updatedRows: rows };
  if (dateGetter) {
    return computedRowsCells.reduce(({ updatedComputedRowsCells, updatedRows }, computedCells) => {
      Object.keys(computedCells.data).forEach(columnId => {
        const { value } = computedCells.data[columnId];
        const prevRowValue = prevRows[computedCells.label_code].cells[columnId].value;
        const newRowCell: IRowCell = {
          rowId: computedCells.label_code,
          columnId,
          value,
          prevValue: prevRowValue
        };
        const { rowId } = newRowCell;
        const row = updatedRows[rowId];
        const cellDate = dateGetter(columnId);
        //aggregates
        const impactedAggregates = getImpactedAggregates(cellDate, aggregates);
        const hasAggregateFormula = row.aggregate_formula;
        if (!hasAggregateFormula) {
          const { updatedAgRowsToSave, updatedAgRows } = impactedAggregates.reduce(
            ({ updatedAgRowsToSave, updatedAgRows }, aggregateId) => {
              const aggregate = aggregates[aggregateId];
              const newAggregateValue = evalAggregate(updatedAgRows, newRowCell, aggregate);
              const newImpactedRow: IRowCell = { rowId, columnId: aggregateId, value: newAggregateValue };
              const cell = row && row.cells[aggregateId];
              if (cell && cell.value !== newAggregateValue && cell.isComputed) {
                updatedAgRows = updateRowCellValue(updatedAgRows, newImpactedRow, type) as IGenericRows;
                updatedAgRowsToSave = updateComputedRowsCells(
                  updatedAgRowsToSave,
                  rowId,
                  aggregateId,
                  newAggregateValue,
                  type
                );
              }
              return { updatedAgRowsToSave, updatedAgRows };
            },
            { updatedAgRowsToSave: updatedComputedRowsCells, updatedAgRows: updatedRows }
          );
          updatedRows = updatedAgRows;
          updatedComputedRowsCells = updatedAgRowsToSave;
        }
      });
      return { updatedComputedRowsCells, updatedRows };
    }, initialValue);
  }
  return initialValue;
};
