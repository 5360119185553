import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { I18n } from "react-redux-i18n";
import { QUERY_KEY_FORECAST_BY_COUNTRY_CODE } from "./useApiGetForecastByCountryCode";

interface IForecastPutDeleteParams {
  countryCode: string;
  storeId: number;
}

export const useApiPostForecast = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: IForecastPutDeleteParams) => {
      const url = `/forecast/${params.countryCode}/${params.storeId}`;
      const config: AxiosRequestConfig = { method: "post", url };
      return axios(config);
    },
    onSuccess: () => {
      toast.info(I18n.t("pilotable.data_saved"));
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_FORECAST_BY_COUNTRY_CODE] });
    },
    onError: () => {
      toast.error(I18n.t("error.global.title"));
    }
  });
};
