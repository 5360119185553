import { I18n } from "react-redux-i18n";
import { NavLink, useLocation } from "react-router-dom";
import ROLES_MAPPING, { ROLES } from "core/constants/roles";
import { checkIsHasPermission } from "../../../../../core/utils/checkIsHasPermission";
import FavoriteLink from "./favoriteLink";
import { useContext } from "react";
import { MenuContext } from "../menu";
import { useUserHasRoles } from "core/hooks/useUserHasRoles";
import getRouteDisabled from "./get-route-disabled";
import { useApiMenuGetUserPreferences } from "../../../../../core/api/menu/useApiMenuGetUserPreferences";
import { trackAnalyticsEvent } from "../../../../../core/hooks/useAnalytics";

const MenuAnimationSingleLink = ({ link }) => {
  const actionGoogleAnalytics = `click_bottom_burger_menu_${link?.googleAnalyticsAction}`;
  const isAdmin = useUserHasRoles([ROLES.ROLE_ADMIN]);
  const { isEditing, selectedView, modifiedData } = useContext(MenuContext);
  const isDisabledLink = getRouteDisabled(link?.id);
  const activeStyle = { color: "#007DBC" };
  const { data: user } = useApiMenuGetUserPreferences();
  const isEditingPreventRedirect = event => {
    trackAnalyticsEvent(actionGoogleAnalytics),
      (isEditing && event.preventDefault()) || (isDisabledLink && !isAdmin && event.preventDefault());
  };
  const urlPathname = useLocation()?.pathname;

  const isActiveHoursPiloting = (path: string) => {
    return (
      path === "/piloting/hours" &&
      (urlPathname === "/piloting/hours-team" ||
        urlPathname === "/piloting/general-services" ||
        urlPathname === "/piloting/cashier-services")
    );
  };

  return (
    <>
      {checkIsHasPermission(user.roles, ROLES_MAPPING, link.rights[0]) && (
        <NavLink
          {...(!isDisabledLink && { to: link?.path })}
          {...(!isDisabledLink && { onClick: isEditingPreventRedirect })}
          className={`mg-menu-animation-single-link ${isDisabledLink ? "disabled" : ""}`}
          style={({ isActive }) => (isActive || isActiveHoursPiloting(link?.path) ? activeStyle : undefined)}
          end
        >
          <div className="mg-menu-animation-single-link__title">
            <span
              className={`${
                link?.navigationMenuInformation?.iconIsFilled
                  ? "material-icons notranslate"
                  : "material-icons-outlined notranslate"
              }`}
            >
              {link?.navigationMenuInformation?.iconName}
            </span>
            <span>{I18n.t(link?.name)}</span>
          </div>
          {isEditing && <FavoriteLink sublink={link} selectedView={selectedView} modifiedData={modifiedData} />}
        </NavLink>
      )}
    </>
  );
};

export default MenuAnimationSingleLink;
