import { useCallback, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { I18n } from "react-redux-i18n";

import { GROUPS_ALERT_SPORTS } from "core/constants/translations/groups";
import { ISportsGroup } from "core/models/api/sports-group/sports-group-piloting";
import { useApiDeleteSportsGroup } from "core/api/sports-group/useApiDeleteSportsGroup";

export interface IISportsGroupItemProps {
  id?: string;
  sportsGroup: ISportsGroup;
  onEditSportsGroup?: (sportsGroup: ISportsGroup) => void;
}

const SportsGroupItem = ({ sportsGroup, onEditSportsGroup }: IISportsGroupItemProps) => {
  const handleEditSportsGroup = useCallback(() => onEditSportsGroup(sportsGroup), [onEditSportsGroup, sportsGroup]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleDeleteSportsGroup = () => setIsOpenModal(!isOpenModal);
  const deleteMutation = useApiDeleteSportsGroup();

  const confirmToDeleteSportsGroup = async () => {
    await deleteMutation.mutateAsync(sportsGroup);
  };

  return (
    <>
      <Dialog open={isOpenModal} id="modal" className="mg-alert">
        <DialogContent>
          <div className="mg-alert__content">
            <div className="mg-alert__content__icon">
              <span className="material-icons-outlined notranslate">info</span>
            </div>
            <span className="mg-alert__content__text">{I18n.t(GROUPS_ALERT_SPORTS)}</span>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="mg-alert__buttons">
            <button
              className="vtmn-btn vtmn-btn_size--small vtmn-btn_variant--secondary mg-button-delete"
              onClick={handleDeleteSportsGroup}
            >
              {I18n.t("no")}
            </button>
            <button className="vtmn-btn vtmn-btn_size--small" onClick={confirmToDeleteSportsGroup}>
              {I18n.t("yes")}
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <div>
        <div className="mg-groups-card" role="button" aria-pressed="true">
          <div className={"mg-groups-card__actions"}>
            <span className="material-icons notranslate" aria-hidden="true" onClick={handleDeleteSportsGroup}>
              delete
            </span>
          </div>
          <div className={"mg-groups-card__name vtmn-typo_text-2"} onClick={handleEditSportsGroup}>
            <span>{sportsGroup.label}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SportsGroupItem;
