import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";
import { IErrors } from "pages/templates/templates";
import StoresGroupPanel from "./storesgroup-panel";
import GroupsTabs from "components/common/mygame/groups/groups-tabs";
import GroupsRead from "components/common/mygame/groups/groups-read";
import {
  GROUPS_CRUD_ADD_STORES_GROUP_SHARED_CONFIRMATION_MESSAGE,
  GROUPS_CRUD_CREATE_STORES,
  GROUPS_CRUD_EDIT_STORES,
  GROUPS_CRUD_LIST_STORES,
  GROUPS_CRUD_MY_LIST_STORES_GROUP,
  GROUPS_CRUD_SHARED_WITH_ME
} from "core/constants/translations/groups";
import { useParams } from "react-router-dom";
import ConfirmModal from "components/common/modals/confirm-modal";
import { NavigationContext } from "components/common/navigation/navigation-provider";
import { useApiGetStoresGroup } from "../../../core/api/stores-group/useApiGetStoresGroup";
import { StoresGroup } from "../../../core/models/stores-group";
import { useApiPostSharedStoresGroup } from "../../../core/api/stores-group/useApiPostSharedStoresGroup";
interface IStoresGroupListProps {
  className?: string;
  setIsOpenedGroup?: Dispatch<SetStateAction<string>>;
}

export interface IDumbStoresGroupListProps extends IStoresGroupListProps {
  storesGroups: StoresGroup[];
}

export const DumbStoresGroupList = ({ storesGroups, setIsOpenedGroup }: IDumbStoresGroupListProps) => {
  const [errors, setErrors] = useState<IErrors>({});
  const [storesGroup, setStoresGroup] = useState<StoresGroup>(null);
  const [isActiveTab, setIsActiveTab] = useState("read");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const mutation = useApiPostSharedStoresGroup();

  // close Menu burger by default
  const { setIsOpen } = useContext(NavigationContext);
  useEffect(() => setIsOpen(false), []);

  const storesGroupId = storesGroup ? storesGroup.id : null;
  const storesGrouplabel = storesGroup ? storesGroup.label : null;
  const handleClickOnTab = action => {
    if (action === "create") {
      setStoresGroup({
        id: null,
        label: "",
        composition: [],
        region_label: false,
        is_owner: true,
        edit_disabled: false
      });
      setIsActiveTab(action);
    } else {
      setIsActiveTab(action);
    }
  };

  const onStoresGroupChange = useCallback(
    (newStoresGroup: StoresGroup | Partial<StoresGroup>) => {
      const id = newStoresGroup.id || storesGroupId;
      const label = newStoresGroup.label || storesGrouplabel;
      setStoresGroup(actualStoresGroup => ({ ...actualStoresGroup, ...newStoresGroup }));
      const hasStoresGroup = storesGroups.some(
        currentStoresGroup => (!id || currentStoresGroup.id !== id) && currentStoresGroup.label === label
      );
      if (hasStoresGroup) {
        setErrors({ ...errors, label: I18n.t("error.label-exists") });
      } else if (errors.label) {
        setErrors({ ...errors, label: null });
      }
    },
    [errors, storesGroups, storesGroupId, storesGrouplabel]
  );

  const onEditStoresGroup = (storesGroupItem: StoresGroup) => {
    setStoresGroup(storesGroupItem);
  };

  const onClosePanel = useCallback(() => {
    setStoresGroup(null);
    setErrors({});
    setIsActiveTab("read");
  }, []);

  useEffect(() => {
    storesGroupId !== null && setIsActiveTab("edit");
  }, [storesGroupId]);

  useEffect(() => {
    isActiveTab === "read" && setStoresGroup(null);
  }, [isActiveTab]);

  //share group stores section
  const { id: storesGroupSharedId } = useParams<string>();
  const storesGroupSharedIdText = `Stores group ID: ${storesGroupSharedId}`;
  const bashPathUrl = "/settings/groups-creation";

  const handleStoresGroupSharedIdChange = (storesGroupSharedId: string, storesGroups: StoresGroup[]) => {
    const myStoresGroupId = new Set(storesGroups.filter(it => it.is_owner).map(it => it.id.toString()));
    const sharedWithMeStoresGroupId = new Set(storesGroups.filter(it => !it.is_owner).map(it => it.id.toString()));

    if (
      storesGroupSharedId &&
      !myStoresGroupId.has(storesGroupSharedId) &&
      !sharedWithMeStoresGroupId.has(storesGroupSharedId)
    ) {
      setIsConfirmModalOpen(true);
    } else if (window.location.href.indexOf(bashPathUrl) > -1) {
      window.history.replaceState(null, null, bashPathUrl);
    }

    if (storesGroupSharedId) {
      setIsOpenedGroup("store");
    }
  };

  useEffect(() => {
    handleStoresGroupSharedIdChange(storesGroupSharedId, storesGroups);
  }, [storesGroupSharedId, storesGroups]);

  const onConfirmAction = () => {
    mutation.mutate(storesGroupSharedId);
    setIsConfirmModalOpen(false);
  };
  const onCancelAction = () => {
    setIsConfirmModalOpen(false);
  };

  const [myGroups, sharedWithMeGroups]: [StoresGroup[], StoresGroup[]] = storesGroups.reduce(
    (acc, storeGroup) => {
      if (storeGroup.is_owner) {
        acc[0].push(storeGroup);
      } else {
        acc[1].push(storeGroup);
      }
      return acc;
    },
    [[], []]
  );

  return (
    <>
      <div className="mg-groups-tab">
        <GroupsTabs
          isActiveTab={isActiveTab}
          handleClickOnTab={handleClickOnTab}
          read={I18n.t(GROUPS_CRUD_LIST_STORES)}
          create={I18n.t(GROUPS_CRUD_CREATE_STORES)}
          edit={I18n.t(GROUPS_CRUD_EDIT_STORES)}
        />
      </div>

      <div className="mg-groups-content">
        {isActiveTab === "read" && (
          <>
            <div className="mg-groups-label">
              <span>{I18n.t(GROUPS_CRUD_MY_LIST_STORES_GROUP)}:</span>
            </div>
            <GroupsRead onEditGroup={onEditStoresGroup} groups={myGroups} groupSelected={"store"} />
            <div className="mg-groups-label">
              <span>{I18n.t(GROUPS_CRUD_SHARED_WITH_ME)}:</span>
            </div>
            <GroupsRead onEditGroup={onEditStoresGroup} groups={sharedWithMeGroups} groupSelected={"store"} />
          </>
        )}

        {isActiveTab === "create" && (
          <StoresGroupPanel
            storesGroup={storesGroup}
            onStoresGroupChange={onStoresGroupChange}
            onClose={onClosePanel}
            errors={errors}
            setIsActiveTab={setIsActiveTab}
          />
        )}

        {isActiveTab === "edit" && (
          <StoresGroupPanel
            storesGroup={{ ...storesGroup }}
            onStoresGroupChange={onStoresGroupChange}
            onClose={onClosePanel}
            errors={errors}
            setIsActiveTab={setIsActiveTab}
          />
        )}
      </div>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        message={`${storesGroupSharedIdText}.\n ${I18n.t(GROUPS_CRUD_ADD_STORES_GROUP_SHARED_CONFIRMATION_MESSAGE)}`}
        onConfirmAction={onConfirmAction}
        onCancelAction={onCancelAction}
      />
    </>
  );
};

const StoresGroupList = (props: IStoresGroupListProps) => {
  const { data: storesGroup, isLoading } = useApiGetStoresGroup();
  return !isLoading && <DumbStoresGroupList storesGroups={storesGroup} {...props} />;
};

export default StoresGroupList;
