export enum WebInterface {
  WEEKLY_PILOTING = "WEEKLY_PILOTING",
  COMPARISON_PILOTING = "COMPARISON_PILOTING",
  AREAS_PILOTING = "AREAS_PILOTING",
  HOURS_PILOTING = "HOURS_PILOTING",
  ANNUAL_INIT_PILOTING = "ANNUAL_INIT_PILOTING",
  GROUP_SPORT_PILOTING = "GROUP_SPORT_PILOTING",
  STORE_MONTHLY_PILOTING = "STORE_MONTHLY_PILOTING",
  DAY_DAY_PILOTING = "DAY_DAY_PILOTING"
}
