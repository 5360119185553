import * as React from "react";
import { IContentCellProps } from "@decathlon/react-table";
import DateUtils from "core/utils/date";

export interface IActionsCellProps extends IContentCellProps {
  exceptionId: number;
  since: string;
}

export const SinceCell: React.FunctionComponent<IActionsCellProps> = ({ exceptionId, since }) => {
  return <div>{exceptionId && DateUtils.dayToYearMonthLabel(since)}</div>;
};

export default SinceCell;
