import { getPathFromRouteId } from "core/utils/route-utils";
import { RouteId } from "core/routing/router/route-ids";
import { I18n } from "react-redux-i18n";
import {
  MENU_DATA_VISUALIZATION_COMPARISON_BY_WEEKS,
  MENU_DATA_VISUALIZATION_VISUALIZATION_BY_WEEKS,
  MENU_SECTION_DATA_VISUALIZATION
} from "core/constants/translations/menu";
import { useContext } from "react";
import { MenuContext } from "../menu";
import { ROUTES } from "../../../../../core/routing/router/routes";
import { trackAnalyticsEvent } from "../../../../../core/hooks/useAnalytics";

const MenuDataVisualization = ({ url }) => {
  const { navigate } = useContext(MenuContext);
  const handleClickDataVisualization = (prefixActionName, routeId) => {
    const route = ROUTES.find(allRoute => allRoute.id === routeId);
    const actionGoogleAnalytics = `${prefixActionName}_${route.googleAnalyticsAction}`;
    trackAnalyticsEvent(actionGoogleAnalytics);
    navigate(getPathFromRouteId(routeId));
  };
  return (
    <div className="mg-menu-data-visualization">
      <div className="mg-menu-section-title">
        <span>{I18n.t(MENU_SECTION_DATA_VISUALIZATION)}</span>
      </div>
      <div className="mg-menu-data-visualization__columns">
        <div
          className="mg-menu-data-visualization__columns__left"
          onClick={() => handleClickDataVisualization("click_middle_burger_menu", RouteId.HomeWeekly)}
        >
          <div
            className={`mg-menu-data-visualization-link ${
              url === getPathFromRouteId(RouteId.HomeWeekly) ? "isActive" : ""
            }`}
          >
            <div className="mg-menu-data-visualization-icon" aria-hidden="true">
              <span className="material-icons-outlined notranslate">query_stats</span>
            </div>
            <span className="mg-menu-data-visualization-text">
              {I18n.t(MENU_DATA_VISUALIZATION_VISUALIZATION_BY_WEEKS)}
            </span>
          </div>
        </div>
        <div
          className="mg-menu-data-visualization__columns__right"
          onClick={() => handleClickDataVisualization("click_middle_burger_menu", RouteId.HomeComparison)}
        >
          <div
            className={`mg-menu-data-visualization-link ${
              url === getPathFromRouteId(RouteId.HomeComparison) ? "isActive" : ""
            }`}
          >
            <div className="mg-menu-data-visualization-icon" aria-hidden="true">
              <span className="material-icons-outlined notranslate">shape_line</span>
            </div>
            <span className="mg-menu-data-visualization-text">
              {I18n.t(MENU_DATA_VISUALIZATION_COMPARISON_BY_WEEKS)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuDataVisualization;
