import { I18n } from "react-redux-i18n";

const MessageAlert = ({ icon, text }) => {
  return (
    <div className="mg-message-alert">
      <div className="mg-message-alert-icon">{icon && <span className="material-icons notranslate">{icon}</span>}</div>
      <div className="mg-message-alert-text">{text && I18n.t(text)}</div>
    </div>
  );
};

export default MessageAlert;
