import { ReactElement } from "react";
import classnames from "classnames";
import { Tooltip, Icon } from "@mui/material";

export interface IProps {
  children: ReactElement | string;
  iconName?: string;
  /** The id of the button. */
  id?: string;
  /** The CSS class name of the button. */
  className?: string;
  /** The Tooltip title*/
  tooltipTitle?: string;
  /** Callback fired when a "click" event is detected. */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | null;
}

const Button = ({ id, children, tooltipTitle, className, onClick, iconName }: IProps) => {
  const isDisabled = !onClick;
  const dataTestId = "dkt-btn" + (id ? "-" + id : "") + (isDisabled ? "-disabled" : "");
  const action = (
    <button
      data-testid={dataTestId}
      id={id}
      className={classnames("dkt-button", className)}
      disabled={isDisabled}
      // tooltip on disabled item, see https://material-ui.com/components/tooltips/#disabled-elements
      style={isDisabled ? { pointerEvents: "none" } : {}}
      onClick={onClick}
      type="button"
    >
      {children} {iconName ? <Icon className="dkt-button-icon">{iconName}</Icon> : null}
    </button>
  );

  return tooltipTitle ? (
    // add span inside tooltip on disabled item, see https://material-ui.com/components/tooltips/#disabled-elements
    <Tooltip title={tooltipTitle}>
      <span>{action}</span>
    </Tooltip>
  ) : (
    action
  );
};

export default Button;
