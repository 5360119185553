import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { I18n } from "react-redux-i18n";
import { QUERY_KEY_FORECAST_BY_COUNTRY_CODE } from "./useApiGetForecastByCountryCode";

interface IForecastPutActivateParams {
  countryCode: string;
  isActivated: boolean;
}

export const useApiPutForecastActivateButton = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: IForecastPutActivateParams) => {
      const url = `/forecast/${params.isActivated ? "deactivate" : "activate"}/${params.countryCode}`;
      const config: AxiosRequestConfig = { method: "put", url };
      return axios(config);
    },
    onSuccess: () => {
      toast.info(I18n.t("pilotable.data_saved"));
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_FORECAST_BY_COUNTRY_CODE] });
    },
    onError: () => {
      toast.error(I18n.t("error.global.title"));
    }
  });
};
