import * as React from "react";
import classnames from "classnames";
import { Tooltip, IconButton, Button } from "@mui/material";
import { Link } from "react-router-dom";

import CustomIcon from "components/common/icons/customIcon";
import { FunctionComponent, ReactElement } from "react";

interface IProps {
  /** The id of the button. */
  id?: string;
  /** The CSS class name of the button. */
  className?: string;
  /** A custom icon component */
  Icon?: (props: { className: string }) => ReactElement;
  SourceIcon?: FunctionComponent<{ name: string; className: string }>;
  /** The material icon name for the icon button component. */
  iconName?: string;
  /** The displayed title */
  title?: string;
  /** The Tooltip title*/
  tooltipTitle?: string;
  /** Callback fired when a "click" event is detected. */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /** Button as a link: either `onClick` or `href` should be provided */
  href?: string;
}

const ToolbarAction = ({
  id,
  title,
  tooltipTitle,
  Icon,
  iconName,
  SourceIcon = CustomIcon,
  className,
  onClick,
  href
}: IProps) => {
  const ButtonComponent = title ? Button : IconButton;
  const isDisabled = !(onClick || href);
  const iconClassName = classnames("toolbar-action__icon", { "with-title": title });
  const dataTestId = "toolbar-action-btn" + (id ? "-" + id : "") + (isDisabled ? "-disabled" : "");
  const action = (
    // @ts-ignore ButtonComponent is a dynamic component
    <ButtonComponent
      data-testid={dataTestId}
      id={id}
      className={classnames("toolbar-action", className, { disabled: isDisabled })}
      onClick={onClick}
      to={href}
      component={href ? Link : "button"}
      disabled={isDisabled}
    >
      {Icon ? <Icon className={iconClassName} /> : null}
      {iconName ? <SourceIcon name={iconName} className={iconClassName} /> : null}
      {title ? <span className="toolbar-action__title">{title}</span> : null}
    </ButtonComponent>
  );

  // use span for tooltip on disabled item, see https://material-ui.com/components/tooltips/#disabled-elements
  return tooltipTitle ? (
    <Tooltip title={tooltipTitle}>
      <span>{action}</span>
    </Tooltip>
  ) : (
    action
  );
};

export default ToolbarAction;
