import { useEffect } from "react";
import ReactGA from "react-ga4";
import IApiUserData from "../models/api-user-data";
import { ENV_PROD } from "../constants/datadog";
import { RoleName } from "../utils/user";
import { datadogRum } from "@datadog/browser-rum";

const PRODUCTION_ENVIRONMENT = import.meta.env.REACT_APP_ENV === ENV_PROD;
type AnalyticsEvent = string;

export const useAnalytics = (user: IApiUserData, roleName: RoleName) => {
  useEffect(() => {
    if (user?.uid && PRODUCTION_ENVIRONMENT) {
      if (!ReactGA.isInitialized) ReactGA.initialize(import.meta.env.REACT_APP_GA_TRACKING_ID);
      const userCountry = user?.preferences.default_filters?.country ?? "NOT-SET";
      ReactGA.gtag("set", "user_id", user.uid);
      ReactGA.gtag("set", "user_properties", { user_country: userCountry, user_role: roleName });
    }
  }, [user, roleName]);
};

export const trackAnalyticsEvent = (action: AnalyticsEvent) => {
  ReactGA.event(action, { value: 1 });
  datadogRum.addAction(action, {
    value: 1
  });
};
