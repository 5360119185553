import * as React from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import classnames from "classnames";
import IconButton from "@mui/material/IconButton";

import CardItem from "components/common/cards/card-item";
import Icon from "components/common/icons/customIcon";
import { Store } from "core/models/store";

export interface IStoresGroupFavoriteItemProps {
  className?: string;
  store: Store;
  onRemoveStore?: (storeIndex: number) => void;
  dragProvided?: DraggableProvided;
  index: number;
  isFavorite?: boolean;
}

const StoresGroupFavoriteItem: React.FunctionComponent<IStoresGroupFavoriteItemProps> = ({
  className,
  store,
  onRemoveStore,
  dragProvided,
  index
}) => {
  const draggableProps = dragProvided ? dragProvided.draggableProps : {};
  const dragHandleProps = dragProvided ? dragProvided.dragHandleProps : {};
  const { id, label } = store;

  const handleOnRemoveStore = React.useCallback(() => onRemoveStore(index), [onRemoveStore, index]);

  return (
    <div
      ref={dragProvided?.innerRef}
      className={classnames("store-group-favorite-item", className)}
      {...draggableProps}
    >
      <CardItem
        {...dragHandleProps}
        title={`${id} - ${label}`}
        rightWithClassName={
          <div className="card-item__right">
            {onRemoveStore && (
              <IconButton data-testid="store-group-favorite-item-remove-btn" onClick={handleOnRemoveStore} size="large">
                <Icon name="clear" />
              </IconButton>
            )}
          </div>
        }
      />
    </div>
  );
};

export default StoresGroupFavoriteItem;
