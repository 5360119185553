import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { UseQueryOptionsWithoutQueryKey } from "../../react-query-config";

export const QUERY_KEY_DIRECTOR = "get-director";

export const getDirectorFn = async (config: AxiosRequestConfig): Promise<string> => {
  return axios(config).then(response => response?.data);
};

export const useApiGetDirMessage = (storeId: number, options?: UseQueryOptionsWithoutQueryKey<string>) => {
  const url = `/dir_message/${storeId}`;
  const config: AxiosRequestConfig = { method: "get", url };
  return useQuery<string, Error>({
    queryKey: [QUERY_KEY_DIRECTOR, url],
    queryFn: () => getDirectorFn(config),
    ...options
  });
};
