import { Fragment, memo } from "react";

import ApplyButton from "../apply-button";
import { FiltersContainer } from "../../../filters3/filters/hoc/types";
import { filtersEntries } from "../../../filters3/filters/utils/filters";
import FilterBarComparison from "./filter-bar-comparison";
interface FiltersBarProps<T = unknown> {
  definitions: FiltersContainer<T>;
}

const FiltersBar = <T,>({ definitions }: FiltersBarProps<T>) => {
  const sections = filtersEntries(definitions)
    .filter(([, filter]) => filter.toolbar?.control)
    .map(([name, filter]) => {
      const Component = filter.toolbar.control;
      const props = filter.toolbar.props || {};
      return (
        <Fragment key={name}>
          {name === "stores_group" ? <FilterBarComparison /> : ""}
          {name === "template" || name === "channel" ? <div className="mg-filters-bar__separator" /> : ""}
          <Component key={name} {...props} />
        </Fragment>
      );
    });

  return (
    <div className="mg-filters-bar">
      <div className="mg-filters-bar__filters-aera">
        {sections}
        <div className="mg-filters-bar__apply-aera">
          <ApplyButton />
        </div>
      </div>
    </div>
  );
};

export default memo(FiltersBar);
